import PropTypes                from 'prop-types';
import React, {
	useEffect,
	useState
}                               from 'react';
import Dialog                   from '@material-ui/core/Dialog';
import DialogTitle              from '@material-ui/core/DialogTitle';
import DialogContent            from '@material-ui/core/DialogContent';
import DialogContentText        from '@material-ui/core/DialogContentText';
import Grid                     from '@material-ui/core/Grid';
import DialogActions            from '@material-ui/core/DialogActions';
import Button                   from '../../core/input/Button';
import {
	Trans,
	useTranslation
}                               from 'react-i18next';
import { getCustomOsEditRoute } from '../../RouteController/Factory';
import { useHistory }           from 'react-router-dom';
import uuid                     from '../../../utils/uuid';
import FormControl              from '@material-ui/core/FormControl';
import InputLabel               from '@material-ui/core/InputLabel';
import Input                    from '@material-ui/core/Input';
import FormHelperText           from '@material-ui/core/FormHelperText';
import { getLocale }            from '@karpeleslab/klbfw';
import RichAlertTrans           from '../../core/feedback/RichAlertTrans';
import Tab                      from '@material-ui/core/Tab';
import Tabs                     from '@material-ui/core/Tabs';
import useMediaQuery            from '@material-ui/core/useMediaQuery';
import Panel                    from '../../layout/Container/tab/Panel';
import ShellSelector            from '../../core/input/ShellSelector';
import Form                     from './Form';
import {
	useOperatingSystemCreateEmpty,
	useOperatingSystemCreateFromFile,
	useOperatingSystemCreateFromShell
}                               from '@karpeleslab/klb-react-services';
import LinearProgress           from '../../core/feedback/LinearProgress';
import RichAlert                from '../../core/feedback/RichAlert';
import { fileExtensionIs }      from '../../../utils/misc';

const FROM_SHELL_TAB = 0;
const FROM_FILE_TAB = 1;
const EMPTY_OS_TAB = 2;

const INITIAL_DATA = { Purpose: 'unknown', Beta: 'Y' };

const CreateDialog = ({ open, setOpen }) => {
	const { t } = useTranslation();
	const history = useHistory();

	const [createFromFile, creatingFromFile, progress] = useOperatingSystemCreateFromFile();
	const [createFromShell, creatingFromShell] = useOperatingSystemCreateFromShell();
	const [createEmpty, creatingEmpty] = useOperatingSystemCreateEmpty();

	const [tab, setTab] = useState(FROM_SHELL_TAB);

	const [file, setFile] = useState(null);
	const [fileKey, setFileKey] = useState(uuid());
	const [shell, setShell] = useState();
	// This will control, the input to display it red if the file is not a .shells,
	// We default to true, because when there is no selected file we don't want to have the 'red' error outline to the input
	const [isFileValid, setIsFileValid] = useState(true);

	const [data, setData] = useState(INITIAL_DATA);

	const [isValid, setIsValid] = useState(false);
	const [saveEnabled, setSaveEnabled] = useState(false);

	const isMobile = useMediaQuery(theme => theme.breakpoints.down('xs'));

	const handleTabChange = (event, newValue) => {
		setTab(newValue);
	};

	const reset = () => {
		setTab(FROM_SHELL_TAB);
		setShell(undefined);
		setFileKey(uuid());
		setFile(null);
		setData(INITIAL_DATA);
		setIsFileValid(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleFile = e => {
		if (e.target.files.length > 0)
			setFile(e.target.files[0]);
	};

	useEffect(() => {
		if (!file) setIsFileValid(true); // No file so we default to true
		else setIsFileValid(fileExtensionIs(file, 'shells'));
	}, [file, setIsFileValid]);

	const handleCreate = e => {
		e.preventDefault();
		let promise;

		const names = { [getLocale()]: data['Name'].trim() };
		const descriptions = { [getLocale()]: (data['Description'] ?? '').trim() };
		const shortDescriptions = { [getLocale()]: (data['Short_Description'] ?? '').trim() };
		const family = data.Family;
		const boot = data.Boot;
		const cpu = data.CPU;
		const icon = data['New_Media_Image'] ?? null;
		const isPublic = data['Public'] ?? 'N';
		const purpose = data['Purpose'] ?? 'unknown';
		const beta = data['Beta'] ?? 'Y';

		if (tab === FROM_FILE_TAB) {
			promise = createFromFile(file, names, family, boot, cpu, isPublic, descriptions, shortDescriptions, purpose, beta, icon);
		} else if (tab === FROM_SHELL_TAB) {
			promise = createFromShell(shell.Shell_Volume__, names, family, boot, cpu, isPublic, descriptions, shortDescriptions, purpose, beta, icon);
		} else {
			promise = createEmpty(names, family, boot, cpu, isPublic, descriptions, shortDescriptions, purpose, beta, icon);
		}

		promise.then(d => history.push(getCustomOsEditRoute(d.Shell_OS__)));
	};

	useEffect(() => {
		if (
			!isValid ||
			(tab === FROM_SHELL_TAB && !shell) ||
			(tab === FROM_FILE_TAB && (!file || !isFileValid))
		) {
			setSaveEnabled(false);
			return;
		}

		setSaveEnabled(true);
	}, [setSaveEnabled, file, data, isValid, shell, isFileValid]);

	return (
		<Dialog
			open={open}
			aria-labelledby={t('dialog_create_os_title')}
			aria-describedby={t('dialog_create_os_title')}
			onEnter={() => reset()}
			maxWidth='xl'
			fullWidth
		>
			<form onSubmit={handleCreate}>
				<DialogTitle id='create-os'>
					{t('dialog_create_os_title')}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id='create-os-description'>
						<RichAlert severity='info'>
							<Trans i18nKey='dialog_create_os_description'>
								sample<br/>
								<a
									href={`${process.env.REACT_APP_SHELL_URL}/tutorial/Shells-com-OS-Creation-Tutorial`}
									target='_blank'
									rel='noopener noreferrer'
								>
									sample
								</a>
							</Trans>
						</RichAlert>
					</DialogContentText>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<Tabs
								variant='scrollable'
								scrollButtons={isMobile ? 'on' : 'auto'}
								value={tab}
								indicatorColor='primary'
								textColor='primary'
								onChange={handleTabChange}
							>
								<Tab
									label={t('shell_create_os_shell')}
									disabled={creatingFromFile || creatingFromShell || creatingEmpty}
								/>
								<Tab
									label={t('shell_create_os_file')}
									disabled={creatingFromFile || creatingFromShell || creatingEmpty}
								/>
								<Tab
									label={t('shell_create_os_empty')}
									disabled={creatingFromFile || creatingFromShell || creatingEmpty}
								/>
							</Tabs>
						</Grid>
						<Grid item xs={12}>
							<Panel index={FROM_FILE_TAB} value={tab} boxProps={{ p: 0 }}>
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<RichAlert severity='info'>
											<Trans i18nKey='dialog_create_os_file_description'>
												sample<br/>
												<a
													href={`${process.env.REACT_APP_SHELL_URL}/tutorial/Shells-com-OS-Creation-Tutorial`}
													target='_blank'
													rel='noopener noreferrer'
												>
													sample
												</a>
											</Trans>
										</RichAlert>
									</Grid>
									<Grid item xs={12}>
										<FormControl
											fullWidth
											required
											disabled={creatingFromFile || creatingFromShell || creatingEmpty}
										>
											<InputLabel
												shrink={true}
												htmlFor='os-file'>
												{t('custom_os_file_label')}
											</InputLabel>
											<Input
												inputProps={
													{
														accept: '.shells'
													}
												}
												key={fileKey}
												disabled={creatingFromFile || creatingFromShell || creatingEmpty}
												onChange={handleFile}
												id='os-file'
												type='file'
												error={!isFileValid}
											/>
											<FormHelperText
												error={!isFileValid}
											>
												{t(isFileValid ? 'custom_os_file_helperText' : 'custom_os_file_error_helperText')}
											</FormHelperText>
										</FormControl>
									</Grid>
								</Grid>
							</Panel>
							<Panel index={FROM_SHELL_TAB} value={tab} boxProps={{ p: 0 }}>
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<RichAlertTrans i18nKey='custom_os_from_shell_info' severity='info'/>
									</Grid>
									<Grid item xs={12}>
										<ShellSelector
											volumeRequired
											idOnly={false}
											fullWidth
											setValue={setShell}
											value={shell}
											disabled={creatingFromFile || creatingFromShell || creatingEmpty}
											required
										/>
									</Grid>
								</Grid>
							</Panel>
							<Panel index={EMPTY_OS_TAB} value={tab} boxProps={{ p: 0 }}>
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<RichAlertTrans i18nKey='custom_os_create_empty_info' severity='info'/>
									</Grid>
								</Grid>
							</Panel>
						</Grid>
						<Grid item xs={12}>
							<Form
								disabled={creatingFromFile || creatingFromShell || creatingEmpty}
								setData={setData}
								setIsValid={setIsValid}
								data={data}
							/>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					{creatingFromFile && <LinearProgress variant='determinate' value={progress * 100}/>}
					<Button
						color='default'
						onClick={handleClose}
						disabled={creatingFromFile || creatingFromShell || creatingEmpty}
					>
						{t('close_btn')}
					</Button>
					<Button
						onClick={handleCreate}
						color='primary'
						type='submit'
						variant='contained'
						disabled={creatingFromFile || creatingFromShell || creatingEmpty || !saveEnabled}
						loading={creatingFromFile || creatingFromShell || creatingEmpty}
					>
						{t('create_btn')}
					</Button>
				</DialogActions>

			</form>

		</Dialog>
	);
};

export default CreateDialog;

CreateDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	setOpen: PropTypes.func.isRequired
};
