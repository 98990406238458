import React                     from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Graphic                   from '../../../../assets/img/plans/maxPlans.svg';
import Empty                     from '../../../core/feedback/State/Empty';

const MaxPlan = () => {
	const { t } = useTranslation();
	return (
		<Empty
			title={t('shell_plan_max_title')}
			text={
				<Trans i18nKey='shell_plan_max_desc'>
					sample<br/>
					<strong>sample</strong>
				</Trans>
			}
			graphic={Graphic}
			action={
				{
					component: 'a',
					title: t('footer_link_contact'),
					rel: 'noopener noreferrer',
					target: '_blank',
					href: process.env.REACT_APP_ZENDESK
				}
			}
		/>);
};

export default MaxPlan;
