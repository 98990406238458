import React, { useState } from 'react';
import PageContainer       from '../../components/layout/Container/PageContainer';
import { useTranslation }  from 'react-i18next';
import { Grid }            from '@material-ui/core';
import Button              from '../../components/core/input/Button';
import AddIcon             from '@material-ui/icons/Add';
import Toolbar             from '../../components/core/typography/Toolbar';
import ISOTable            from '../../components/common/ISO/ISOTable';
import IsoCreateDialog     from '../../components/common/ISO/ISOCreateDialog';

const ISOList = () => {
	const { t } = useTranslation();
	const [showCreate, setShowCreate] = useState(false);
	return (
		<PageContainer>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Toolbar
						title={t('iso_title')}
						pageTitle
					>
						<Button
							onClick={() => setShowCreate(true)}
							size='small'
							variant='contained'
							color='primary'
							startIcon={<AddIcon/>}
						>
							{t('create_btn')}
						</Button>
					</Toolbar>
				</Grid>
				<Grid item xs={12}>
					<ISOTable/>
				</Grid>
			</Grid>
			<IsoCreateDialog setOpen={setShowCreate} open={showCreate}/>

		</PageContainer>
	);
};

export default ISOList;
