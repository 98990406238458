import React, { useState }       from 'react';
import PageContainer             from '../../components/layout/Container/PageContainer';
import PageTitle                 from '../../components/core/typography/PageTitle';
import { Trans, useTranslation } from 'react-i18next';
import { Grid }                  from '@material-ui/core';
import Affiliate                 from '../../components/common/Affiliate/Affiliate';
import WalletsTable              from '../../components/common/Affiliate/Wallet/WalletsTable';
import PageLoader                from '../../components/layout/Loader/PageLoader';
import Tabs                      from '@material-ui/core/Tabs';
import Tab                       from '@material-ui/core/Tab';
import Panel                     from '../../components/layout/Container/tab/Panel';
import PendingOrder              from '../../components/common/Affiliate/PendingOrder';
import PendingPayout             from '../../components/common/Affiliate/PendingPayout';
import PageNotFound              from '../System/PageNotFound';
import { useAffiliate }          from '@karpeleslab/klb-react-services';

const TRANSFER_TAB_ORDER_PENDING = 0;
const TRANSFER_TAB_BANK_PENDING = 1;

const Partnership = () => {
	const { t } = useTranslation();
	const [affiliate] = useAffiliate('@');
	const [transferTab, setTransferTab] = useState(TRANSFER_TAB_ORDER_PENDING);

	const handleTabChange = (event, newValue) => {
		setTransferTab(newValue);
	};

	if (!affiliate) return <PageLoader/>;
	if (!affiliate.data) return <PageNotFound/>;

	return (
		<PageContainer>

			<Grid container spacing={3}>
				<Grid item xs={12}>
					<PageTitle title={t('partnership_title')}/>
				</Grid>
				<Grid item xs={12}>
					<p>
						<Trans
							i18nKey='partnership_desc'>
							sample<br/>
							<a
								href={`${process.env.REACT_APP_SHELL_URL}/page/Conditions`}
								target='_blank'
								rel='noopener noreferrer'
							>
								sample
							</a>
							<a
								href={`${process.env.REACT_APP_SHELL_URL}/page/affiliates`}
								target='_blank'
								rel='noopener noreferrer'
							>
								sample
							</a>
						</Trans>
					</p>
				</Grid>
				<Grid item xs={12}>
					<Affiliate affiliate={affiliate.data}/>
				</Grid>
				<Grid item xs={12}>
					<WalletsTable toolbarProps={{ style: { paddingLeft: 0 } }}/>
				</Grid>
				<Grid item xs={12}>
					<Tabs
						variant='scrollable'
						scrollButtons='off'
						value={transferTab}
						indicatorColor='primary'
						textColor='primary'
						onChange={handleTabChange}
					>
						<Tab label={t('partnership_wallet_pending_transfer')}/>
						{affiliate.data.Payout_Method === 'transfer' &&
						<Tab label={t('partnership_bank_pending_transfer')}/>
						}
					</Tabs>
				</Grid>
				<Grid item xs={12}>
					<Panel index={TRANSFER_TAB_ORDER_PENDING} value={transferTab} boxProps={{ p: 0 }}>
						<PendingOrder affiliate={affiliate.data}/>
					</Panel>

					{affiliate.data.Payout_Method === 'transfer' &&
					<Panel index={TRANSFER_TAB_BANK_PENDING} value={transferTab} boxProps={{ p: 0 }}>
						<PendingPayout
							affiliate={affiliate.data}
							statuses={['preparing', 'new', 'aml', 'confirmed', 'todo', 'pending']}
						/>
					</Panel>
					}
				</Grid>
			</Grid>
		</PageContainer>
	);
};

export default Partnership;
