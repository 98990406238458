import PropTypes                          from 'prop-types';
import React, { useState }                from 'react';
import { useTranslation }                 from 'react-i18next';
import { useShellISOMounts }              from '@karpeleslab/klb-react-services';
import TableDefinition                    from '../../core/data/table/TableDefinition';
import Grid                               from '@material-ui/core/Grid';
import Button                             from '../../core/input/Button';
import { Link }                           from 'react-router-dom';
import { getISORoute, getShellViewRoute } from '../../RouteController/Factory';
import DeleteIcon                         from '@material-ui/icons/Delete';
import Table                              from '../../core/data/table/Table';
import AddIcon                            from '@material-ui/icons/Add';
import IsoMountDialog                     from './ISOMountDialog';
import IsoUnmountDialog                   from './ISOUnmountDialog';

const IsoMountTable = ({ filterId, forShells = false }) => {
	const { t } = useTranslation();
	const [mount, fetch, loading] = useShellISOMounts();
	const [showCreate, setShowCreate] = useState(false);
	const [showDelete, setShowDelete] = useState(false);
	const [selected, setSelected] = useState(null);

	const initialFilters = forShells ? { Shell__: filterId } : { Shell_ISO__: filterId };

	return (
		<>
			<Table
				extra={!forShells ? [] : [
					(<Button
						key='add-iso-shell'
						onClick={() => setShowCreate(true)}
						size='small'
						variant='contained'
						color='primary'
						startIcon={<AddIcon/>}
					>
						{t('mount_new_iso_btn')}
					</Button>)
				]}
				initialFilters={initialFilters}
				title={t(forShells ? 'shell_mounted_iso' : 'iso_mounted_title')}
				fetch={fetch}
				data={mount}
				search={false}
				loading={loading}
				paperProps={{ elevation: 0 }}
				toolbarProps={{ style: { paddingLeft: 0 } }}
			>
				<TableDefinition label={forShells ? t('iso_name') : t('shell_name')} name='Filename'>
					{mount => !forShells ?
						<Link to={getShellViewRoute(mount.Shell.Shell__)}>{mount.Shell.Label}</Link>
						:
						<Link to={getISORoute(mount.Shell_ISO.Shell_ISO__)}>{mount.Shell_ISO.Filename}</Link>
					}
				</TableDefinition>

				<TableDefinition align='right' label={t('tablecell_actions')} name='Shell_OS__'>
					{mount =>
						<Grid container spacing={3} justify='flex-end'>
							<Grid item>
								<Button
									onClick={() => {
										setSelected(mount);
										setShowDelete(true);
									}}
									variant='outlined'
									size='small'
									color='secondary'
									startIcon={<DeleteIcon/>}
								>
									{t('unmount_btn')}
								</Button>
							</Grid>
						</Grid>
					}
				</TableDefinition>
			</Table>
			{forShells && <IsoMountDialog
				open={showCreate}
				setOpen={setShowCreate}
				refresh={fetch}
				shellId={filterId}
			/>
			}
			{selected && <IsoUnmountDialog
				refresh={fetch}
				setOpen={setShowDelete}
				open={showDelete}
				mount={selected}

			/>}
		</>
	);
};

export default IsoMountTable;

IsoMountTable.propTypes = {
	filterId: PropTypes.string.isRequired,
	forShells: PropTypes.bool
};
