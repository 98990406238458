import React              from 'react';
import { useTranslation } from 'react-i18next';
import TableDefinition    from '../../core/data/table/TableDefinition';
import Table              from '../../core/data/table/Table';
import Button             from '../../core/input/Button';
import VisibilityIcon     from '@material-ui/icons/Visibility';
import { Link }           from 'react-router-dom';
import { getISORoute }    from '../../RouteController/Factory';
import Grid               from '@material-ui/core/Grid';
import DateUtil           from '../../core/data/Date/DateUtil';
import { useShellISOs }   from '@karpeleslab/klb-react-services';
import Chip               from '@material-ui/core/Chip';

const ISOTable = () => {
	const { t } = useTranslation();
	const [isos, fetch, loading] = useShellISOs();

	return (
		<Table
			title={t('iso_title')}
			fetch={fetch}
			data={isos}
			search={false}
			loading={loading}
			paperProps={{ elevation: 0 }}
		>
			<TableDefinition label={t('iso_name')} name='Filename'/>

			<TableDefinition label={t('custom_os_image_hash')} name='Hash'>
				{iso =>
					iso.Hash ?
						iso.Hash :
						<Chip
							color='primary'
							variant='outlined'
							size='small'
							label={t('iso_hash_pending')}
						/>}
			</TableDefinition>

			<TableDefinition label={t('iso_date')} name='Created'>
				{iso => <DateUtil klbDateObj={iso.Created} format='LLL'/>}
			</TableDefinition>

			<TableDefinition align='right' label={t('tablecell_actions')} name='Shell_OS__'>
				{iso =>
					<Grid container spacing={3} justify='flex-end'>
						<Grid item>
							<Button
								component={Link}
								to={getISORoute(iso.Shell_ISO__)}
								variant='contained'
								size='small'
								color='primary'
								startIcon={<VisibilityIcon/>}
							>
								{t('view_btn')}
							</Button>
						</Grid>
					</Grid>
				}
			</TableDefinition>


		</Table>
	);
};

export default ISOTable;
