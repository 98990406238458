import { useCallback, useEffect, useState } from 'react';
import { useCatalogProductsSearch }         from '@karpeleslab/klb-react-services';
import GRAPHIC_SHELL_LITE                   from '../assets/img/plans/lp-plan-01.png';
import GRAPHIC_SHELL_BASIC                  from '../assets/img/plans/lp-plan-02.png';
import GRAPHIC_SHELL_PRO                    from '../assets/img/plans/lp-plan-03.png';
import GRAPHIC_SHELL_PLUS                   from '../assets/img/plans/lp-plan-04.png';
import GRAPHIC_SHELL_CUSTOM                 from '../assets/img/plans/lp-plan-05.png';
import { isYearly }                         from '../utils/shell';

export const CATALOG_PRODUCT_SHELL_IP = 'cpr-stzkrd-q5lr-cwdb-4dir-kwfpjn6m';

export const SHELL_REBOOT_ENABLE_STATES = ['running', 'paused'];
export const SHELL_START_ENABLE_STATES = ['paused', 'crashed', 'shutoff', 'shutdown', 'nostate', 'pmsuspended', 'unknown'];
export const SHELL_STOP_ENABLE_STATES = ['running', 'paused', 'crashed'];

export const UNIT_CPU = 0.25;
export const UNIT_RAM = 512;
export const UNIT_HDD = 10;

export const SHELL_OS_ICON_VARIATION = 'strip&scale_crop=285x285&format=png';

export const SHELL_PLANS_SEARCH_QUERY = {
	query: {
		'Description.Type': 'shells_plan'
	},
	sort: 'Basic.Priority:asc'
};

export const isShellYearly = (shell) => {
	return isYearly(shell.Catalog_Product);
};

export const getShellGraphic = type => {
	switch (type) {
		case 'lite':
			return GRAPHIC_SHELL_LITE;
		case 'basic':
			return GRAPHIC_SHELL_BASIC;
		case 'plus':
			return GRAPHIC_SHELL_PLUS;
		case 'pro':
			return GRAPHIC_SHELL_PRO;
		case 'custom':
			return GRAPHIC_SHELL_CUSTOM;
		default :
			return GRAPHIC_SHELL_LITE;
	}
};

export const useShellPlans = () => {
	const [plans, setPlans] = useState(null);
	const [, fetchPlans] = useCatalogProductsSearch();

	const refresh = useCallback(
		data => {
			if (data) {
				setPlans({ ...(plans ?? {}), data: data });
				return;
			}

			return fetchPlans(SHELL_PLANS_SEARCH_QUERY)
				.then(({ data }) => {
					setPlans(data);
				})
				.catch(e => {
					setPlans({ error: e });
				});
		}
		, [plans]); //eslint-disable-line

	useEffect(() => {
		refresh();
	}, []); //eslint-disable-line

	return [plans, refresh];
};
