import PropTypes   from 'prop-types';
import React       from 'react';
import { Grid }    from '@material-ui/core';
import TextWarning from './TextWarning';

const TextWarnings = ({ warnings = [] }) => {
	if (warnings.length < 1) return <></>;

	return (
		<Grid container spacing={3}>
			{warnings.map(w =>
				<Grid item xs={12} key={w.Shell_Warning__}>
					<TextWarning warning={w}/>
				</Grid>
			)}
		</Grid>
	);
};

export default TextWarnings;

TextWarnings.propTypes = {
	warnings: PropTypes.array.isRequired
};
