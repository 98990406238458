import PropTypes          from 'prop-types';
import React              from 'react';
import Grid               from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';

const PaymentMethod = ({ method }) => {
	const { t } = useTranslation();

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				{t('payment_method_name', { name: method.Name })}
			</Grid>

			<Grid item xs={12}>
				{t('payment_method_expiration', { expiration: method.Expiration })}
			</Grid>
		</Grid>
	);
};

export default PaymentMethod;

PaymentMethod.propTypes = {
	method: PropTypes.object.isRequired
};
