import PropTypes                 from 'prop-types';
import React, { useState }       from 'react';
import { useTranslation }        from 'react-i18next';
import { Grid }                  from '@material-ui/core';
import Button                    from '../../core/input/Button';
import { formatShellExpireDate } from '../../../utils/shell';
import NewOrderDialog            from '../Order/order/NewOrderDialog';
import ListItemIcon              from '@material-ui/core/ListItemIcon';
import AutorenewIcon             from '@material-ui/icons/Autorenew';
import ListItemText              from '@material-ui/core/ListItemText';
import MenuItem                  from '@material-ui/core/MenuItem';

const ShellExpireButton = ({ shell, refresh, light = false, inMenu = false, ...rest }) => {
	const { t } = useTranslation();
	const [showOrder, setShowOrder] = useState(false);

	if (shell.User_Billing__) {
		return <></>;
	}

	const handleRenew = e => {
		setShowOrder(true);
		if (rest.onClick) rest.onClick(e);
	};

	const handleComplete = () => {
		refresh();
	};

	if (inMenu) {
		return (
			<MenuItem onClick={handleRenew} disabled={showOrder}>
				<ListItemIcon>
					<AutorenewIcon fontSize='small'/>
				</ListItemIcon>
				<ListItemText
					primary={!light ? t('shell_expire_renew_btn', { date: formatShellExpireDate(shell) }) : t('renew_btn')}
				/>

				{
					showOrder &&
					<NewOrderDialog
						setOpen={setShowOrder}
						request={`${shell.Catalog_Product__},shell=${shell.Shell__},mode=renew`}
						open={showOrder}
						onComplete={handleComplete}
					/>
				}
			</MenuItem>
		);
	}

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Button
					fullWidth
					size='small'
					color='primary'
					variant='outlined'
					{...rest}
					disabled={showOrder}
					onClick={handleRenew}
				>
					{
						!light ? t('shell_expire_renew_btn', { date: formatShellExpireDate(shell) }) : t('renew_btn')
					}
				</Button>
			</Grid>
			{
				showOrder &&
				<NewOrderDialog
					setOpen={setShowOrder}
					request={`${shell.Catalog_Product__},shell=${shell.Shell__},mode=renew`}
					open={showOrder}
					onComplete={handleComplete}
				/>
			}
		</Grid>
	);
};

export default ShellExpireButton;

ShellExpireButton.propTypes = {
	inMenu: PropTypes.bool,
	light: PropTypes.bool,
	refresh: PropTypes.func.isRequired,
	shell: PropTypes.object.isRequired
};
