import PropTypes                      from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Skeleton }                   from '@material-ui/lab';
import { useShellHasLicense }         from '../../../../hooks/useShellLicense';
import { Trans }                      from 'react-i18next';
import { Typography }                 from '@material-ui/core';
import RichAlert                      from '../../../core/feedback/RichAlert';

const OsItemLicense = ({ shell, os, detailed = false }) => {
	const [hasLicense, setHasLicense] = useState(false);
	const [fetch, loading] = useShellHasLicense(shell.Shell__);

	useEffect(() => {
		fetch(os.Catalog_Product['Shell.LicenseType']).then(setHasLicense);
	}, [os]);


	if (loading) return <Skeleton width='100%'/>;
	if (hasLicense) return <></>;

	if (!detailed) {
		return <Typography variant='caption'>
			<Trans i18nKey='os_license_required' values={{ price: os.Catalog_Product['Price.Price'].display }}>
				A <strong>X</strong> license is required
			</Trans>
		</Typography>;
	}

	return <RichAlert severity='warning'>
		<Trans i18nKey='os_license_required' values={{ price: os.Catalog_Product['Price.Price'].display }}>
			A <strong>X</strong> license is required
		</Trans>
	</RichAlert>;
};

export default OsItemLicense;

OsItemLicense.propTypes = {
	detailed: PropTypes.bool,
	os: PropTypes.object.isRequired,
	shell: PropTypes.object.isRequired
};
