export const isFunction = (obj) => {
	return !!(obj && obj.constructor && obj.call && obj.apply);
};
export const pad = (num, size) => {
	let s = num + '';
	while (s.length < size) s = '0' + s;
	return s;
};

export const deepCopy = (object) => {
	return JSON.parse(JSON.stringify(object));
};

export const copyToClipboard = str => {
	if (typeof document === 'undefined') return;
	const el = document.createElement('textarea');
	el.value = str;
	document.body.appendChild(el);
	el.select();
	document.execCommand('copy');
	document.body.removeChild(el);
};

export const isString = (str) => {
	return typeof str === 'string' || str instanceof String;
};

export const getCookiesOptions = () => {
	const date = new Date();
	date.setFullYear(new Date().getFullYear() + 1);

	return {
		path: '/',
		secure: true,
		expires: date
	};
};

export const fileExtensionIs = (file, extension) => {
	if (!file) return false;
	return file.name.split('.').pop() === extension;
};

export const isShellKernel = os => {
	return os.Boot === 'guest-linux';
};
