import PropTypes           from 'prop-types';
import React, { useState } from 'react';
import Box                 from '@material-ui/core/Box';
import Dialog              from '@material-ui/core/Dialog';
import DialogTitle         from '@material-ui/core/DialogTitle';
import DialogContent       from '@material-ui/core/DialogContent';
import Grid                from '@material-ui/core/Grid';
import DialogActions       from '@material-ui/core/DialogActions';
import Button              from '../../../core/input/Button';
import { useTranslation }  from 'react-i18next';
import Cart                from '../../Cart/Cart';
import OrderWithPayment    from './OrderWithPayment';
import useMediaQuery       from '@material-ui/core/useMediaQuery';

const NewOrderDialog = ({ open, setOpen, request, onComplete, onClose }) => {
	const { t } = useTranslation();
	const [creating, setCreating] = useState(false);
	const [order, setOrder] = useState(null);
	const isMobile = useMediaQuery(theme => theme.breakpoints.down('xs'), { noSsr: true });

	const [dialogActions, setDialogActions] = useState(null);

	const handleClose = () => {
		setOpen(false);
		if (onClose) onClose();
	};

	const reset = () => {
		setDialogActions(null);
	};

	const onCompleteHandler = order => {
		onComplete(order);
	};

	return (
		<Dialog
			open={open}
			aria-labelledby={t('new_order_title')}
			aria-describedby={t('new_order_title')}
			onEnter={() => reset()}
			maxWidth='lg'
			fullScreen={isMobile}
			fullWidth
		>
			<DialogTitle id='new-order'>
				{t('new_order_title')}
			</DialogTitle>
			<DialogContent>
				<Box py={2}>
					<Grid container spacing={2}>
						{!order &&
						<Grid item xs={12}>
							<Cart
								setOrder={setOrder}
								request={request}
								setCreating={setCreating}
								setDialogActions={setDialogActions}
							/>
						</Grid>
						}
						{order && <Grid item xs={12}>
							<OrderWithPayment
								order={order}
								refresh={setOrder}
								onComplete={onCompleteHandler}
								setDialogActions={setDialogActions}
							/>
						</Grid>
						}
					</Grid>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button color='default' onClick={handleClose} disabled={creating}>
					{t('close_btn')}
				</Button>
				{dialogActions}
			</DialogActions>
		</Dialog>
	);
};

export default NewOrderDialog;

NewOrderDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	request: PropTypes.string.isRequired,
	setOpen: PropTypes.func.isRequired,
	onComplete: PropTypes.func.isRequired,
	onClose: PropTypes.func
};
