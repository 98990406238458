import React              from 'react';
import { useTranslation } from 'react-i18next';

import {
	AvatarEditor as StyledAvatarEditor,
	AvatarEditorImages,
	AvatarEditorImagesInner,
	AvatarEditorRange,
	AvatarImages
}                from './avatarEditorStyles';
import PropTypes from 'prop-types';

const AvatarImage = ({ defaultAvatar, editor, handleSetImage, handleScale, state }) => {
	const { t } = useTranslation();

	if (state.imgPath !== null) {
		return (
			<AvatarEditorImages>
				<AvatarEditorImagesInner>
					<StyledAvatarEditor
						ref={editor}
						image={state.imgPath}
						width={state.width}
						height={state.height}
						borderRadius={100}
						border={0}
						scale={state.scale}
						rotate={0}
						onPositionChange={handleSetImage}
					/>
				</AvatarEditorImagesInner>
				<AvatarEditorRange
					name="scale"
					type="range"
					onChange={handleScale}
					min="1"
					max="2"
					step="0.01"
					defaultValue="1"
				/>
			</AvatarEditorImages>
		);
	} else {
		return (
			<AvatarImages>
				<img src={defaultAvatar} alt={t('default_avatar')}/>
			</AvatarImages>
		);
	}
};

export default AvatarImage;

AvatarImage.propTypes = {
	defaultAvatar: PropTypes.string,
	editor: PropTypes.object,
	handleSetImage: PropTypes.func,
	handleScale: PropTypes.func,
	state: PropTypes.object,
};
