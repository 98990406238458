import PropTypes                             from 'prop-types';
import React                                 from 'react';
import Panel                                 from '../../../layout/Container/tab/Panel';
import { OS_OFFICIAL, OS_PRIVATE, OS_SHARE } from './SourceTabs';
import OsList                                from './OSList';
import InputSharedOS                         from './InputSharedOS';
import RichAlertTrans                        from '../../../core/feedback/RichAlertTrans';
import { Grid }                              from '@material-ui/core';
import MobileOSSelector                      from './MobileOSSelector';

const SourceDisplay = ({ shell, tab, value, setValue, setShowDetailsFor, disabled = false, mobile = false, spacing = 3 }) => {
	return (
		<>
			<Panel index={OS_OFFICIAL} value={tab} boxProps={{ p: 0 }}>
				<Grid container spacing={spacing}>
					<Grid item xs={12}><RichAlertTrans severity='info' i18nKey='shell_os_tab_official_info'/></Grid>

					{mobile &&
					<Grid item xs={12}>
						<MobileOSSelector
							spacing={spacing}
							setValue={setValue}
							value={value}
							initialSource='desktop'
						/>
					</Grid>}

					{!mobile && <Grid item xs={12}>
						<OsList
							shell={shell}
							setShowDetailsFor={setShowDetailsFor}
							value={value}
							setValue={setValue}
							disabled={disabled}
							mobile={mobile}
							spacing={spacing}
							initialFilters={
								{
									Beta: 'N',
									Purpose: 'desktop'
								}
							}
						/>
					</Grid>}
				</Grid>
			</Panel>
			<Panel index={OS_PRIVATE} value={tab} boxProps={{ p: 0 }}>
				<Grid container spacing={spacing}>
					<Grid item xs={12}><RichAlertTrans severity='info' i18nKey='shell_os_tab_my_info'/></Grid>

					{mobile &&
					<Grid item xs={12}>
						<MobileOSSelector
							spacing={spacing}
							setValue={setValue}
							value={value}
							own
						/>
					</Grid>}
					{!mobile && <Grid item xs={12}>
						<OsList
							shell={shell}
							own
							setShowDetailsFor={setShowDetailsFor}
							value={value}
							setValue={setValue}
							disabled={disabled}
							mobile={mobile}
							spacing={spacing}
							initialFilters={{ Beta: 'N' }}
						/>
					</Grid>}
				</Grid>
			</Panel>
			<Panel index={OS_SHARE} value={tab} boxProps={{ p: 0 }}>
				<Grid container spacing={spacing}>
					<Grid item xs={12}>
						<InputSharedOS
							shell={shell}
							setShowDetailsFor={setShowDetailsFor}
							value={value}
							setValue={setValue}
							disabled={disabled}
							spacing={spacing}/>
					</Grid>
				</Grid>
			</Panel>
		</>
	);

};

export default SourceDisplay;

SourceDisplay.propTypes = {
	disabled: PropTypes.bool,
	mobile: PropTypes.bool,
	setShowDetailsFor: PropTypes.func.isRequired,
	setValue: PropTypes.func.isRequired,
	shell: PropTypes.object.isRequired,
	spacing: PropTypes.number,
	tab: PropTypes.string.isRequired,
	value: PropTypes.any
};
