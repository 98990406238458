import React                 from 'react';
import { useTranslation }    from 'react-i18next';
import TableDefinition       from '../../core/data/table/TableDefinition';
import Table                 from '../../core/data/table/Table';
import Button                from '../../core/input/Button';
import VisibilityIcon        from '@material-ui/icons/Visibility';
import { Link }              from 'react-router-dom';
import { getOrderViewRoute } from '../../RouteController/Factory';
import DateUtil              from '../../core/data/Date/DateUtil';
import GetAppIcon            from '@material-ui/icons/GetApp';
import Grid                  from '@material-ui/core/Grid';
import useMediaQuery         from '@material-ui/core/useMediaQuery';
import OrderRowMobile        from './OrderRowMobile';
import { useOrders }         from '@karpeleslab/klb-react-services';

const OrdersTable = () => {
	const { t } = useTranslation();
	const [orders, fetch, loading] = useOrders();
	const isMobile = useMediaQuery(theme => theme.breakpoints.down('xs'));

	return (
		<Table
			initialFilters={{ Status: 'completed' }}
			title={t('order_history_title')}
			fetch={fetch}
			data={orders}
			search={false}
			loading={loading}
			paperProps={{ elevation: 0 }}
		>
			<TableDefinition label={t('history_invoice_number')} name='Invoice_Number' sortable>
				{order => {
					if (!isMobile) return order.Invoice_Number;
					return <OrderRowMobile order={order}/>;
				}}
			</TableDefinition>

			{!isMobile && <TableDefinition label={t('history_order_date')} name='Invoice_Date' sortable>
				{order => <DateUtil klbDateObj={order.Invoice_Date} format='LLL'/>}
			</TableDefinition>}

			{!isMobile && <TableDefinition label={t('history_paid_date')} name='Paid' sortable>
				{order => <DateUtil klbDateObj={order.Paid} format='LLL'/>}
			</TableDefinition>}

			{!isMobile && <TableDefinition label={t('history_status')} name='Status' sortable>
				{order => order.Status ?? '-'}
			</TableDefinition>}

			{!isMobile && <TableDefinition label={t('history_price')} name='Order__'>
				{order => order.Total.display ?? '-'}
			</TableDefinition>}

			{!isMobile && <TableDefinition align='right' label={t('tablecell_actions')} name='Order__'>
				{order =>
					<Grid container spacing={3} justify='flex-end'>
						<Grid item xs={12} lg={6}>
							<Button
								fullWidth
								componant='a'
								href={order.Invoice_Url}
								target='blank'
								variant='outlined'
								size='small'
								color='default'
								startIcon={<GetAppIcon/>}
							>
								{t('history_dl_pdf')}
							</Button>
						</Grid>
						<Grid item xs={12} lg={6}>
							<Button
								fullWidth
								component={Link}
								to={getOrderViewRoute(order.Order__)}
								variant='contained'
								size='small'
								color='primary'
								startIcon={<VisibilityIcon/>}
							>
								{t('view_btn')}
							</Button>
						</Grid>
					</Grid>
				}
			</TableDefinition>}

		</Table>
	);
};

export default OrdersTable;
