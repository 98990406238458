import React     from 'react';
import PropTypes from 'prop-types';

const OrderPaymentMethod = ({ order }) => {
	return (
		<span>
			{(order.Payment_Method && order.Payment_Class) && order.Payment_Method + ' - ' + order.Payment_Class}
			{(!order.Payment_Method && order.Payment_Class) && order.Payment_Class}
			{(order.Payment_Method && !order.Payment_Class) && order.Payment_Method}
		</span>
	);
};

export default OrderPaymentMethod;

OrderPaymentMethod.propTypes = {
	order: PropTypes.object.isRequired
};
