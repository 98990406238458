export const getShellsRoute = () => '/';
export const getShellViewRoute = id => `/shells/${id !== undefined ? id : ':shellId'}`;
export const getLoginRoute = () => '/login';
export const getUserSettingsRoute = () => '/user-settings';
export const getOrderViewRoute = id => `/orders/${id !== undefined ? id : ':orderId'}`;
export const getPartnershipRoute = () => '/partnership';
export const getSSHKeychainRoute = () => '/keychains';
export const getBillingRoute = () => '/billing';
export const getLinksRoute = () => '/links';
export const getSSHKeychainEditRoute = id => `/keychains/${id !== undefined ? id : ':keychainId'}`;
export const getCustomOsEditRoute = id => `/os/${id !== undefined ? id : ':osId'}`;
export const getCustomOsRoute = () => '/os';
export const getSwitchToModeRoute = mode => `/mode/${mode !== undefined ? mode : ':mode'}`;
export const getISOsRoute = () => '/iso';
export const getISORoute =  isoId => `/iso/${isoId !== undefined ? isoId : ':isoId'}`;
export const getAccountWarningRoute =  () => '/notifications';
