import PropTypes                   from 'prop-types';
import React                       from 'react';
import { Grid }                    from '@material-ui/core';
import PublicIcon                  from '@material-ui/icons/Public';
import DnsIcon                     from '@material-ui/icons/Dns';
import { Trans, useTranslation }   from 'react-i18next';
import PersonIcon                  from '@material-ui/icons/Person';
import { Title }                   from '../../core/typography/Title';
import TextWithIcon                from '../../core/typography/TextWithIcon';
import VpnKeyIcon                  from '@material-ui/icons/VpnKey';
import { Link }                    from 'react-router-dom';
import { getSSHKeychainEditRoute } from '../../RouteController/Factory';

const ShellConnection = ({ shell }) => {
	const { t } = useTranslation();

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Title>{t('shell_connection')}</Title>
			</Grid>
			<Grid item xs={12} md={4}>
				<TextWithIcon
					text={t('shell_info_shared_address', { host: shell.Host.IP })}
					startIcon={<PublicIcon/>}
				/>
			</Grid>
			<Grid item xs={12} md={4}>
				<TextWithIcon
					text={t('shell_ssh_port', { port: shell.Ssh_Port })}
					startIcon={<DnsIcon/>}
				/>

			</Grid>
			<Grid item xs={12} md={4}>
				<TextWithIcon
					text={t('shell_ssh_username', { username: shell.Username })}
					startIcon={<PersonIcon/>}
				/>
			</Grid>
			{shell.Keychain__ &&
			<Grid item xs={12} md={4}>
				<TextWithIcon
					text={
						<Trans i18nKey='shell_ssh_keychain' values={{ label: shell.Keychain.Label }}>
							sample
							<Link to={getSSHKeychainEditRoute(shell.Keychain__)}>
								sample
							</Link>
						</Trans>
					}
					startIcon={<VpnKeyIcon/>}
				/>
			</Grid>
			}
		</Grid>
	);
};

export default ShellConnection;

ShellConnection.propTypes = {
	shell: PropTypes.object.isRequired
};
