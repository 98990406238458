import React                 from 'react';
import Dialog                from '@material-ui/core/Dialog';
import DialogContentText     from '@material-ui/core/DialogContentText';
import DialogContent         from '@material-ui/core/DialogContent';
import { useTranslation }    from 'react-i18next';
import DialogTitle           from '@material-ui/core/DialogTitle';
import DialogActions         from '@material-ui/core/DialogActions';
import PropTypes             from 'prop-types';
import { useHistory }        from 'react-router';
import { getISOsRoute }      from '../../RouteController/Factory';
import Button                from '../../core/input/Button';
import RichAlertTrans        from '../../core/feedback/RichAlertTrans';
import { useShellISODelete } from '@karpeleslab/klb-react-services';

const ISODeleteDialog = ({ iso, open, setOpen }) => {
	const { t } = useTranslation();
	const [deleteISO, deleting] = useShellISODelete(iso.Shell_ISO__);
	const history = useHistory();

	const handleClose = () => {
		setOpen(false);
	};

	const handleDelete = e => {
		e.preventDefault();

		deleteISO()
			.then(handleClose)
			.then(() => history.push(getISOsRoute()));
	};

	return (
		<Dialog
			open={open}
			aria-labelledby={t('dialog_delete_iso_title')}
			aria-describedby={t('dialog_delete_iso_title')}
		>
			<DialogTitle id='delete-iso-title'>
				{t('dialog_delete_iso_title')}
			</DialogTitle>
			<DialogContent>
				<DialogContentText id='delete-iso-description'>
					<RichAlertTrans
						severity='warning'
						i18nKey='dialog_delete_iso_description'
						values={{ label: iso?.Filename }}
					/>
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button
					color='default'
					onClick={handleClose}
					disabled={deleting}
				>
					{t('close_btn')}
				</Button>
				<Button
					onClick={handleDelete}
					color='secondary'
					variant='outlined'
					disabled={deleting}
					loading={deleting}
				>
					{t('delete_btn')}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ISODeleteDialog;

ISODeleteDialog.propTypes = {
	iso: PropTypes.object,
	open: PropTypes.bool.isRequired,
	setOpen: PropTypes.func.isRequired
};
