import PropTypes           from 'prop-types';
import React, { useState } from 'react';
import Button              from '../../core/input/Button';
import { useTranslation }  from 'react-i18next';
import BugReportIcon       from '@material-ui/icons/BugReport';
import DebugDialog         from './DebugDialog';

const ShellDebugButton = ({ shell, ...rest }) => {
	const { t } = useTranslation();
	const [show, setShow] = useState(false);

	return (
		<>
			<Button
				variant='outlined'
				color='default'
				startIcon={<BugReportIcon/>}
				onClick={() => setShow(true)}
				{...rest}
			>
				{t('shell_debug_btn')}
			</Button>
			<DebugDialog setOpen={setShow} shell={shell} open={show}/>
		</>
	);
};

export default ShellDebugButton;

ShellDebugButton.propTypes = {
	shell: PropTypes.object.isRequired
};
