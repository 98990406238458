import React, { useContext } from 'react';

import styledComponents from 'styled-components';
import { styled }       from '@material-ui/core/styles';
import { LoginContext } from '@karpeleslab/klb-react-services';

const Div = styledComponents.div``;
export const OAuth2Wrapper = styled(Div)(style => {
	return {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		'& > *': {
			margin: style.theme.spacing(4),
		},
	};
});
export const OauthBtn = styled(Div)(style => {
	return {
		borderRadius: '50%',
		width: style.theme.spacing(7),
		height: style.theme.spacing(7),
		backgroundPosition: '50%',
		backgroundRepeat: 'no-repeat',
		backgroundAttachment: 'scroll',
		backgroundSize: '50%',
		overflow: 'hidden',
		textIndent: '-1000px',
		cursor: style.button ? 'pointer' : 'inherit',
		backgroundColor: style.backgroundColor,
		backgroundImage: `url(${style.logo})`,
		'&:hover': {
			opacity: style.button ? 0.4 : 1
		},
		'&.twitter': {
			backgroundSize: '60%'
		},
		'&.line': {
			backgroundSize: '70%'
		},
		'&.facebook': {
			backgroundSize: '30%'
		}
	};
});

const OauthBtnsBuilder = () => {
	const { oauthFields, onOauthClicked } = useContext(LoginContext);
	if (!oauthFields)
		return <></>;

	return (
		<OAuth2Wrapper>
			{
				oauthFields.map(
					(field) => (
						<OauthBtn
							key={field.id}
							button
							onClick={() => {
								onOauthClicked(field.id);
							}}
							alt={field.info.Name}
							className={field.info.Token_Name}
							backgroundColor={field.button['background-color']}
							logo={field.button['logo']}
						/>
					)
				)
			}
		</OAuth2Wrapper>
	);
};

export default OauthBtnsBuilder;

OauthBtnsBuilder.propTypes = {};
