import React, { useEffect } from 'react';
import { useTranslation }   from 'react-i18next';
import TextField            from '@material-ui/core/TextField';
import Grid                 from '@material-ui/core/Grid';
import PropTypes            from 'prop-types';

const Form = ({ data, setData, setIsValid, disabled = false }) => {
	const { t } = useTranslation();

	const createChangeHandler = key => e => {
		setData({ ...data, [key]: e.target.value });
	};

	const getValue = (key, def = '') => {
		if (!(key in (data ?? {}))) return def;
		return data[key];
	};

	useEffect(() => {
		if (!('Label' in (data ?? {}))) setIsValid(false);
		else setIsValid(true);
	}, [data, setIsValid]);

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<TextField
					variant="outlined"
					label={t('keychain_label')}
					helperText={t('keychain_label_helperText')}
					disabled={disabled}
					required
					fullWidth
					value={getValue('Label', '')}
					onChange={createChangeHandler('Label')}
				/>
			</Grid>
		</Grid>
	);
};

export default Form;

Form.propTypes = {
	data: PropTypes.object.isRequired,
	setData: PropTypes.func.isRequired,
	setIsValid: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
};
