import React, { useState }   from 'react';
import { useTranslation }    from 'react-i18next';
import DeleteDialog          from './DeleteDialog';
import Table                 from '../../../core/data/table/Table';
import TableDefinition       from '../../../core/data/table/TableDefinition';
import DateUtil              from '../../../core/data/Date/DateUtil';
import Button                from '../../../core/input/Button';
import moment                from 'moment';
import DeleteIcon            from '@material-ui/icons/DeleteForever';
import PropTypes             from 'prop-types';
import { Grid, IconButton }  from '@material-ui/core';
import Toolbar               from '../../../core/typography/Toolbar';
import AddIcon               from '@material-ui/icons/Add';
import CreateDialog          from './CreateDialog';
import { formatFingerprint } from '../../../../utils/crypt';
import useMediaQuery         from '@material-ui/core/useMediaQuery';
import { useKeychainKeys } from '@karpeleslab/klb-react-services';
import KeyHashes           from './KeyHashes';

const KeyTable = ({ keychain }) => {
	const isMobile = useMediaQuery(theme => theme.breakpoints.down('xs'));

	const [keys, fetch, loading] = useKeychainKeys(keychain.Keychain__);
	const { t } = useTranslation();

	const [selected, setSelected] = useState(null);
	const [showDelete, setShowDelete] = useState(false);
	const [showCreate, setShowCreate] = useState(false);

	const onSelected = key => {
		setSelected(key);
		setShowDelete(true);
	};

	const formatExpires = key => {
		const expires = key.Expires ? moment(parseInt(key.Expires.unixms)) : null;
		if (!expires) return t('keychain_key_expires_never');
		if (expires && expires > moment()) return expires.format('LLL');
		if (expires && expires <= moment()) return t('keychain_key_expires_expired', { date: expires.format('LLL') });
	};

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Toolbar title={t('keychains_keys_title')}>
					<Button
						onClick={() => setShowCreate(true)}
						size='small'
						variant='contained'
						color='primary'
						startIcon={<AddIcon/>}
					>
						{t('add_btn')}
					</Button>
				</Toolbar>
			</Grid>

			<Grid item xs={12}>
				<Table
					fetch={fetch}
					data={keys}
					search
					loading={loading}
					searchKey='Label'
					paperProps={{ elevation: 0 }}
				>
					<TableDefinition label={t('tablecell_key_label')} name='Label' sortable>
						{key => {
							if (!isMobile) return key.Label;
							return <>
								{key.Label}<br/>
								<small>{formatFingerprint(key.Fingerprint)}</small><br/>
								<Grid container justify='space-between'>
									{`${t('tablecell_key_expires')}: ${formatExpires(key)}`}<br/>
									<IconButton
										onClick={() => onSelected(key)}
										size='small'
										color='secondary'
									>
										<DeleteIcon/>
									</IconButton>
								</Grid>
							</>;
						}}
					</TableDefinition>

					{!isMobile &&
					<TableDefinition label={t('tablecell_key_hash')} name='Fingerprint'>
						{key => <KeyHashes hashes={key.Hash}/>}
					</TableDefinition>
					}

					{!isMobile &&
					<TableDefinition label={t('tablecell_key_expires')} name='Expires' sortable>
						{key => formatExpires(key)}
					</TableDefinition>
					}

					{!isMobile &&
					<TableDefinition label={t('tablecell_key_created')} name='Created' sortable>
						{key => <DateUtil klbDateObj={key.Created} format='LLL'/>}
					</TableDefinition>
					}
					{!isMobile &&
					<TableDefinition align='right' label={t('tablecell_actions')} name='Keychain__'>
						{key =>
							<Button
								onClick={() => onSelected(key)}
								variant='outlined'
								size='small'
								color='secondary'
								startIcon={<DeleteIcon/>}
							>
								{t('delete_btn')}
							</Button>
						}
					</TableDefinition>
					}
				</Table>
			</Grid>
			<DeleteDialog
				keychainKey={selected}
				open={showDelete}
				setOpen={setShowDelete}
				refreshing={loading}
				refreshList={fetch}
			/>
			<CreateDialog
				keychain={keychain}
				setOpen={setShowCreate}
				refreshing={loading}
				refresh={fetch}
				open={showCreate}
			/>
		</Grid>
	);
};

export default KeyTable;

KeyTable.propTypes = {
	keychain: PropTypes.object.isRequired
};
