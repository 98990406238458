import React              from 'react';
import { useTranslation } from 'react-i18next';
import { Link }           from 'react-router-dom';

import Grid       from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import PropTypes  from 'prop-types';
import Button     from '../../input/Button';


const Empty = ({ title, text = '', textI18Key, action = null, graphic = null }) => {
	const { t } = useTranslation();
	return (
		<Grid
			container
			spacing={3}
			direction="row"
			justify="center"
		>

			{graphic && <Grid item xs={4} md={4}>
				<img src={graphic} alt={title} width="100%"/>
			</Grid>}

			<Grid item xs={12} md={graphic ? 8 : 12}>
				<Grid container spacing={3} direction="column" justify="center">

					<Grid item xs={12}>
						<Typography variant="h5" color="textPrimary" component="h5">
							{title}
						</Typography>
					</Grid>

					<Grid item xs={12}>
						{textI18Key ? t(textI18Key) : text}
					</Grid>
					{action && <Grid item xs={12}>
						<Grid container justify='center'>
							<Button
								component={action.component ?? Link}
								variant="contained"
								color="primary"
								{...action}
							>
								{action.title}
							</Button>
						</Grid>
					</Grid>
					}
				</Grid>
			</Grid>
		</Grid>
	);
};

export default Empty;

Empty.propTypes = {
	graphic: PropTypes.string,
	text: PropTypes.any,
	title: PropTypes.string,
	textI18Key: PropTypes.string,
	action: PropTypes.object,
};
