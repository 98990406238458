import PropTypes     from 'prop-types';
import React         from 'react';
import Grid          from '@material-ui/core/Grid';
import CartSubTotals from './Prices/CartSubTotals';
import CartTotals    from './Prices/CartTotals';

const CartPrices = ({ cart }) => {

	return (
		<Grid container spacing={3} justify='space-between'>
			<Grid item xs={12} md={5}>
				<CartSubTotals subtotals={cart.subtotals}/>
			</Grid>

			<Grid item xs={12} md={5}>
				<CartTotals cart={cart}/>
			</Grid>
		</Grid>
	);
};

export default CartPrices;

CartPrices.propTypes = {
	cart: PropTypes.object.isRequired
};
