import PropTypes                   from 'prop-types';
import React, { useState }         from 'react';
import { useTranslation }          from 'react-i18next';
import { Typography }              from '@material-ui/core';
import TableDefinition             from '../../../core/data/table/TableDefinition';
import DateUtil                    from '../../../core/data/Date/DateUtil';
import Button                      from '../../../core/input/Button';
import Table                       from '../../../core/data/table/Table';
import RestoreIcon                 from '@material-ui/icons/Restore';
import moment                      from 'moment';
import Grid                        from '@material-ui/core/Grid';
import SnapshotRestoreDialog       from './SnapshotRestoreDialog';
import useMediaQuery               from '@material-ui/core/useMediaQuery';
import SnapshotTableMobileRow      from './SnapshotTableMobileRow';
import { useShellVolumeSnapshots } from '@karpeleslab/klb-react-services';

const SnapshotsTable = ({ shell, setShell }) => {
	const { t } = useTranslation();
	const [snapshots, fetch, loading] = useShellVolumeSnapshots(shell.Shell__);
	const isMobile = useMediaQuery(theme => theme.breakpoints.down('xs'));

	const [selected, setSelected] = useState(null);
	const [showRestore, setShowRestore] = useState(false);

	const onRestore = snapshot => {
		setSelected(snapshot);
		setShowRestore(true);
	};

	return <>
		<Table
			fetch={fetch}
			data={snapshots}
			search={false}
			loading={loading}
			paperProps={{ elevation: 0 }}
		>
			<TableDefinition label={t('tablecell_snapshot_id')} name='Snapshot_Id' sortable>
				{snapshot => {
					if (!isMobile) return snapshot.Snapshot_Id;
					return <SnapshotTableMobileRow snapshot={snapshot} onRestore={onRestore}/>;
				}}
			</TableDefinition>

			{!isMobile && <TableDefinition label={t('tablecell_snapshot_type')} name='Type' sortable>
				{snapshot => t(`shell_snapshot_type_${snapshot.Type}`)}
			</TableDefinition>}

			{!isMobile && <TableDefinition label={t('tablecell_snapshot_os')} name='Shell_Volume__'>
				{snapshot => snapshot.Shell_Volume.Shell_OS.Name}
			</TableDefinition>}

			{!isMobile && <TableDefinition label={t('tablecell_snapshot_datacenter')} name='Shell_Volume__'>
				{snapshot => snapshot.Shell_Volume.Shell_Datacenter ? `${snapshot.Shell_Volume.Shell_Datacenter.Location} - ${snapshot.Shell_Volume.Shell_Datacenter.Name}` : '-'}
			</TableDefinition>}

			{!isMobile && <TableDefinition label={t('tablecell_snapshot_date')} name='Created' sortable>
				{snapshot =>
					<Grid container direction='column'>
						<Grid item xs={12}>
							<Typography>
								<DateUtil klbDateObj={snapshot.Created} format='LLL'/>
							</Typography>
						</Grid>
						{snapshot.Created && (
							<Typography>
								{moment(parseInt(snapshot.Created.unixms)).fromNow()}
							</Typography>
						)}
					</Grid>

				}
			</TableDefinition>}

			{!isMobile && <TableDefinition align='right' label={t('tablecell_actions')} name='Snapshot__'>
				{snapshot =>
					<Button
						variant='contained'
						color='primary'
						onClick={() => onRestore(snapshot)}
						startIcon={<RestoreIcon/>}
					>
						{t('restore_btn')}
					</Button>
				}
			</TableDefinition>
			}
		</Table>
		<SnapshotRestoreDialog
			shell={shell}
			setShell={setShell}
			open={showRestore}
			setOpen={setShowRestore}
			snapshot={selected}
			refresh={fetch}
			refreshing={loading}
		/>
	</>;

};

export default SnapshotsTable;

SnapshotsTable.propTypes = {
	setShell: PropTypes.func.isRequired,
	shell: PropTypes.object.isRequired
};
