import React              from 'react';
import { useTranslation } from 'react-i18next';

import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import DialogActions     from '@material-ui/core/DialogActions';
import Dialog            from '@material-ui/core/Dialog';
import DialogContent     from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle       from '@material-ui/core/DialogTitle';
import Typography        from '@material-ui/core/Typography';

import PropTypes                  from 'prop-types';
import Button                     from '../../../core/input/Button';
import { useConsumerTokenUnlink } from '@karpeleslab/klb-react-services';

const DeleteDialog = ({ open, setOpen, token, onDeleted }) => {
	const { t } = useTranslation();
	const [unlinkToken, loading] = useConsumerTokenUnlink(token.OAuth2_Consumer_Token__);

	const handleDelete = () => unlinkToken().then(onDeleted);

	return (
		<Dialog
			open={open}
			aria-labelledby={t('confirm_delete_token_title')}
			aria-describedby={t('confirm_delete_token_title')}
			fullWidth
		>
			<DialogTitle id='canned-delete-title'>{t('confirm_delete_token_title')}</DialogTitle>
			<DialogContent>
				<DialogContentText id='canned-delete-description'>
					{t('confirm_delete_token_desc')}
					<br/>
					<Typography
						variant='body1'
						color='textPrimary'
						component='span'
					>
						{t('are_you_sure')}
					</Typography>
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button
					variant='contained'
					color='primary'
					autoFocus
					onClick={() => setOpen(false)}
					disabled={loading}
				>
					{t('back_btn')}
				</Button>
				<Button
					color='secondary'
					disabled={loading}
					loading={loading}
					endIcon={<DeleteForeverIcon/>}
					onClick={handleDelete}
				>
					{t('delete_btn')}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default DeleteDialog;


DeleteDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	setOpen: PropTypes.func.isRequired,
	token: PropTypes.object,
	onDeleted: PropTypes.func.isRequired,
};
