import React, { useContext }               from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import Grid                                from '@material-ui/core/Grid';
import Loader                              from './../layout/Loader/Loader';
import ButtonsBuilder                      from './ButtonsBuilder';
import FormBuilder                         from './FormBuilder';
import OauthBtnsBuilder                    from './OauthBtnsBuilder';
import PropTypes                           from 'prop-types';
import { Button }                          from '@material-ui/core';
import useMediaQuery                       from '@material-ui/core/useMediaQuery';
import { LoginContext }                    from '@karpeleslab/klb-react-services';


const Login = ({ TitleComponent = 'h2', ButtonComponent = Button, profilePicture = true }) => {
	const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

	const { loading, handleSubmit, formTitle } = useContext(LoginContext);

	return (
		<SwitchTransition>
			<CSSTransition
				key={!loading ? 'loaded' : 'loading'}
				addEndListener={(node, done) => node.addEventListener('transitionend', done, false)}
				classNames={isMobile ? 'u-transition--zoom' : 'u-transition--lateral'}
			>
				{
					!loading ? (
						<Grid container spacing={3}>
							<Grid item xs={12}>
								<TitleComponent>{formTitle}</TitleComponent>
							</Grid>
							<Grid item xs={12}>
								<form onSubmit={handleSubmit}>
									<FormBuilder profilePicture={profilePicture}/>
									<OauthBtnsBuilder/>
									<ButtonsBuilder ButtonComponent={ButtonComponent}/>
								</form>
							</Grid>
						</Grid>
					) : (
						<div className='c-loader__fullpage-wrapper'>
							<Loader/>
						</div>
					)
				}
			</CSSTransition>
		</SwitchTransition>
	);
};

export default Login;

Login.propTypes = {
	TitleComponent: PropTypes.node,
	ButtonComponent: PropTypes.any,
	profilePicture: PropTypes.bool,
};
