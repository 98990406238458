import React              from 'react';
import { useTranslation } from 'react-i18next';
import TextField          from '@material-ui/core/TextField';
import PropTypes          from 'prop-types';

export const SHELL_OS_PURPOSE_LIST = [
	'desktop',
	'server',
	'mobile',
	'unknown'
];

export const SHELL_OS_PURPOSE_LITE_LIST = [
	'desktop',
];

const OSPurposeSelector = ({ value, setValue, disabled = false, required = false, hideAll = false, list = SHELL_OS_PURPOSE_LIST, ...rest }) => {
	const { t } = useTranslation();

	return (
		<TextField
			label={t('shell_os_purpose_label')}
			value={!value ? 'all' : value}
			onChange={e => setValue(e.target.value === 'all' ? null : e.target.value)}
			select
			fullWidth
			disabled={disabled}
			required={required}
			variant='outlined'
			SelectProps={{
				native: true
			}}
			{...rest}
		>
			{!hideAll && <option value='all'>{t('shell_os_filter_all')}</option>}
			{list.map(p => <option key={p} value={p}>{t(`shell_os_filter_${p}`)}</option>)}
		</TextField>
	);
};

export default OSPurposeSelector;

OSPurposeSelector.propTypes = {
	disabled: PropTypes.bool,
	hideAll: PropTypes.bool,
	list: PropTypes.array,
	required: PropTypes.bool,
	setValue: PropTypes.func.isRequired,
	value: PropTypes.string
};
