import React     from 'react';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
import RichAlert from './RichAlert';

const RichAlertTrans = ({ i18nKey, values = {}, ...rest }) => {
	return (
		<RichAlert {...rest}>
			<Trans i18nKey={i18nKey} values={values}>
				sample<br/>
				<strong>sample</strong>
			</Trans>
		</RichAlert>
	);
};

export default RichAlertTrans;

RichAlertTrans.propTypes = {
	extra: PropTypes.object,
	i18nKey: PropTypes.string.isRequired,
	values: PropTypes.object
};
