import React, { createContext, useEffect, useState } from 'react';
import { useCookies }                                from 'react-cookie';
import PropTypes                                     from 'prop-types';
import useMediaQuery                                 from '@material-ui/core/useMediaQuery';
import { getCookiesOptions }                         from '../utils/misc';

export const defaultMenuContext = { route: null, open: true };
export const MenuContext = createContext(defaultMenuContext);

export const MenuContextContainer = ({ children }) => {
	const [cookies, setCookie] = useCookies(['menu']);

	const isMobile = useMediaQuery('(max-width:768px)', { noSsr: true });

	// Cookies will remember the state of the drawer, for the first time we default to open on large screen and close on small resolution
	if (!cookies.menu)
		setCookie('menu', (isMobile ? 'closed' : 'open'), getCookiesOptions());

	Object.assign(defaultMenuContext,
		{
			open: ((cookies.menu ?? (isMobile ? 'closed' : 'open')) === 'open')
		});

	const [menu, setMenu] = useState(defaultMenuContext);

	useEffect(() => {
		if (!cookies.menu === undefined) return;
		setCookie('menu', menu.open ? 'open' : 'closed', getCookiesOptions());
	}, [menu]); //eslint-disable-line

	return (
		<MenuContext.Provider value={[menu, setMenu]}>
			{children}
		</MenuContext.Provider>
	);
};

MenuContextContainer.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node
	]).isRequired
};
