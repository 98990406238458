import React, { useEffect, useRef, useState } from 'react';
import { useTranslation }                     from 'react-i18next';

import AvatarImage                                                       from './AvatarImage';
import defaultAvatar                                                     from './../../assets/img/default-avatar.svg';
import { AvatarBtn, AvatarBtnsWrapper, AvatarBtnWrapper, AvatarWrapper } from './avatarEditorStyles';
import PropTypes                                                         from 'prop-types';

const AvatarEditor = ({ field, fieldId, required = false, setAvatarImage }) => {
	const [state, setState] = useState({
		imgPath: null,
		width: 170,
		height: 170,
		scale: 1,
		preview: { img: null }
	});
	const editor = useRef(null);
	const croppieFileInput = useRef(null);
	const { t } = useTranslation();

	useEffect(() => {
		if (editor && editor.current)
			handleSetImage();
		// eslint-disable-next-line
	}, [editor]);

	const handleNewImage = e => setState({ ...state, imgPath: e.target.files[0] });

	const handleRemove = () => {
		setState({
			...state,
			imgPath: null,
			scale: 1
		});
		croppieFileInput.current.value = '';
		setAvatarImage(field.name, defaultAvatar, field.param, field.target);
	};

	const handleScale = e => {
		setState({ ...state, scale: parseFloat(e.target.value) });
		handleSetImage();
	};

	const handleSetImage = () => {
		editor.current.getImageScaledToCanvas().toBlob((blob) => {
			blob['name'] = 'avatar.png';
			setAvatarImage(field.name, blob, field.param, field.target);
		});
	};

	return (
		<AvatarWrapper>
			<div>
				<div>
					<AvatarImage
						editor={editor}
						handleSetImage={handleSetImage}
						handleScale={handleScale}
						state={state}
						defaultAvatar={defaultAvatar}
					/>
				</div>
			</div>
			<AvatarBtnsWrapper>
				<AvatarBtnWrapper>
					<AvatarBtn className='upload'>
						{t('profile_upload_image')}
						<input
							id={fieldId}
							name={field.name}
							ref={croppieFileInput}
							type="file"
							accept="image/png, image/jpeg, image/jpg, image/svg"
							onChange={handleNewImage}
							required={required}
						/>
					</AvatarBtn>
				</AvatarBtnWrapper>
				<AvatarBtnWrapper>
					<AvatarBtn onClick={handleRemove}>
						{t('profile_delete_image')}
					</AvatarBtn>
				</AvatarBtnWrapper>
			</AvatarBtnsWrapper>
		</AvatarWrapper>
	);
};

export default AvatarEditor;

AvatarEditor.propTypes = {
	field: PropTypes.object.isRequired,
	fieldId: PropTypes.string.isRequired,
	required: PropTypes.bool,
	setAvatarImage: PropTypes.func.isRequired,
};
