import PropTypes                            from 'prop-types';
import React, { useState }                  from 'react';
import { TableRow, Typography }             from '@material-ui/core';
import useMediaQuery                        from '@material-ui/core/useMediaQuery';
import TableCell                            from '@material-ui/core/TableCell';
import ShellUsage                           from '../Subscription/ShellUsage';
import Grid                                 from '@material-ui/core/Grid';
import ShellTableAction                     from './Action/ShellTableAction';
import ShellExpireButton                    from '../ShellExpireButton';
import Accordion                            from '@material-ui/core/Accordion';
import ExpandMoreIcon                       from '@material-ui/icons/ExpandMore';
import AccordionSummary                     from '@material-ui/core/AccordionSummary';
import AccordionDetails                     from '@material-ui/core/AccordionDetails';
import Chip                                 from '@material-ui/core/Chip';
import ShellPicture                         from '../ShellPicture/ShellPicture';
import ShellAction                          from '../ShellAction/ShellAction';
import { useTranslation }                   from 'react-i18next';
import { formatShellExpireDate, isExpired } from '../../../../utils/shell';
import ShellExpiredMessage                  from '../Subscription/ShellExpiredMessage';
import ModeCheck                            from '../../../layout/Mode/ModeCheck';

const ShellListRow = ({ data, children, loading, idx, createSetShellHandler, refresh, ...rest }) => {
	const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'), { noSsr: true });
	const isXSmall = useMediaQuery('(max-width:433px)', { noSsr: true });
	const [state, setState] = useState(JSON.parse(localStorage.getItem('shell-mobile-accordion-state') ?? '{}'));
	const { t } = useTranslation();

	if (!isMobile || loading) {
		return <TableRow {...rest}>
			{children}
		</TableRow>;
	}

	const storeState = data => {
		localStorage.setItem('shell-mobile-accordion-state', JSON.stringify(data));
		setState(data);
	};

	const changeHandler = (e, expended) => {
		const newState = {
			...JSON.parse(localStorage.getItem('shell-mobile-accordion-state') ?? '{}'),
			[data.Shell__]: expended
		};
		storeState(newState);
	};

	const isExpended = () => {
		return data.Shell__ in state && state[data.Shell__] === true;
	};

	return <TableRow>
		<TableCell>
			<Accordion elevation={0} onChange={changeHandler} expanded={isExpended()}>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon/>}
					aria-controls={`panel${idx}a-content`}
					id={`panel${idx}a-header`}
				>
					<Grid container>
						<Grid item xs={8}>
							<Typography>{data.Label}</Typography>

						</Grid>
						<Grid item xs={4} style={{ textAlign: 'right' }}>
							<Chip
								color='primary'
								label={t(`shell_badge_${data.Catalog_Product['Description.AuthorCode'].toLowerCase()}`)}
							/>
						</Grid>

					</Grid>

				</AccordionSummary>
				<AccordionDetails>
					<Grid container spacing={3}>
						{isExpired(data) && <ShellExpiredMessage shell={data}/>}
						{!isExpired(data) && <>
							<Grid item xs={12}>
								<ShellUsage shell={data} light/>
							</Grid>

							{!data.User_Billing__ && <Grid item xs={12}>
								{t('shell_expire_text', { date: formatShellExpireDate(data) })}
							</Grid>
							}
						</>
						}
						<Grid item xs={12}>
							<Grid
								container
								spacing={3}
								justify={isXSmall ? 'center' : 'space-between'}
								alignItems='flex-end'
							>
								<Grid item>
									<ShellPicture
										setShell={createSetShellHandler(idx)}
										shell={data}
										width={100}
										flagSize='small'
										stateSize='medium'
									/>
								</Grid>
								<Grid item style={{ width: isXSmall ? '100%' : 'unset' }}>
									<Grid container direction='column' spacing={3}>
										<ModeCheck advanced>
											<Grid item style={{ textAlign: 'left' }} xs={12}>
												<ShellAction
													disabled={isExpired(data)}
													shell={data}
													setShell={createSetShellHandler(idx)}
												/>
											</Grid>
										</ModeCheck>

										<Grid item xs={12}>
											<Grid container alignItems='baseline' justify='space-between'>
												<Grid item>
													<ShellExpireButton
														refresh={refresh}
														shell={data}
														light
													/>
												</Grid>
												<Grid item>
													<ShellTableAction
														refresh={refresh}
														setShell={createSetShellHandler(idx)}
														shell={data}
														justify='flex-start'
														light
													/>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								</Grid>

							</Grid>
						</Grid>

					</Grid>
				</AccordionDetails>
			</Accordion>
		</TableCell>
	</TableRow>;
};

export default ShellListRow;

ShellListRow.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
	createSetShellHandler: PropTypes.func,
	refresh: PropTypes.func,
	data: PropTypes.object.isRequired,
	idx: PropTypes.number,
	loading: PropTypes.bool
};
