import React, { useContext, useEffect, useState } from 'react';
import { useTranslation }                         from 'react-i18next';
import { Grid, TextField }                        from '@material-ui/core';
import RequestPasswordDialog                      from './RequestPasswordDialog';
import { UserContext }                            from '../../../context/UserContext';
import PropTypes                                  from 'prop-types';
import Button                                     from '../../core/input/Button';
import { useUserSetEmail }                        from '@karpeleslab/klb-react-services';

const ChangeEmail = ({ changingInfo = false }) => {
	const [user, setUser] = useContext(UserContext);
	const [email, setEmail] = useState(user.user.data.Email);
	const [requestPwdOpen, setRequestPwdOpen] = useState(false);
	const [saveEnabled, setSaveEnabled] = useState(false);
	const [setNewEmail, loadingNewEmail] = useUserSetEmail('@');
	const { t } = useTranslation();

	useEffect(() => {
		if (email !== user.user.data.Email && !loadingNewEmail) {
			setSaveEnabled(true);
			return;
		}
		setSaveEnabled(false);
	}, [email, user, loadingNewEmail]);

	const handleChangeEmail = pwd => {
		setNewEmail(email, pwd)
			.then(res => {
				if (res) {
					const tmp = { ...user };
					tmp.user.data = res;
					setUser(tmp);
				}
			});
	};

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<TextField
					variant='outlined'
					disabled={changingInfo}
					fullWidth label={t('profile_email')}
					value={email}
					type='email'
					onChange={(e) => setEmail(e.target.value)}
				/>
			</Grid>
			<Grid item xs={12}>
				<Grid container justify='flex-end'>
					<Button
						color='primary'
						variant='contained'
						disabled={!saveEnabled || changingInfo}
						onClick={() => {
							if (user.user.data.Has_Password)
								setRequestPwdOpen(true);
							else
								handleChangeEmail(null);
						}}
					>
						{t('save_btn')}
					</Button>

				</Grid>
			</Grid>
			{user.user.data.Has_Password &&
			<RequestPasswordDialog
				open={requestPwdOpen}
				setOpen={setRequestPwdOpen}
				closeCallback={handleChangeEmail}
			/>
			}
		</Grid>
	);
};

export default ChangeEmail;

ChangeEmail.propTypes = {
	changingInfo: PropTypes.bool,
};
