import React       from 'react';
import makeStyles  from '@material-ui/core/styles/makeStyles';
import Card        from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography  from '@material-ui/core/Typography';
import Skeleton    from '@material-ui/lab/Skeleton';
import PhoneIcon   from '@material-ui/icons/Phone';
import PropTypes   from 'prop-types';

const useStyles = makeStyles(() => ({
	card: {
		whiteSpace: 'pre-wrap',
		height: '100%'
	}
}));

const UserLocation = ({ title, address, elevation = 0 }) => {
	const classes = useStyles();

	return (
		<Card className={classes.card} elevation={elevation}>
			<CardContent>
				<Typography gutterBottom variant="h5" component="h2">{title}</Typography>

				{!address && <Skeleton height={100}/>}
				{
					address &&
					<Typography variant="body2" color="textSecondary" component="p">
						{address.Display.join('\n')}
					</Typography>
				}
				{
					(address && address.Contact_Phone) &&
					<Typography variant="body2" color="textSecondary" component="p">
						<PhoneIcon fontSize="small"/>
						{address.Contact_Phone}
					</Typography>
				}
			</CardContent>
		</Card>
	);
};

export default UserLocation;

UserLocation.propTypes = {
	address: PropTypes.bool,
	elevation: PropTypes.number,
	title: PropTypes.string
};
