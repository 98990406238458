import { useCallback, useState }                       from 'react';
import { rest }                                        from '@karpeleslab/klbfw';
import { getShellLicenseEndpoint, useApiErrorHandler } from '@karpeleslab/klb-react-services';

export const useShellHasLicense = (shellId) => {
	const [catchRedirect] = useApiErrorHandler();
	const [loading, setLoading] = useState(true);

	const fetch = useCallback(
		(licenseKey) => {
			setLoading(true);
			return rest(getShellLicenseEndpoint(licenseKey, shellId))
				.then(catchRedirect)
				.then(d => {
					return !!d.data;
				})
				.catch(() => {
					return false;
				})
				.finally(() => {
					setLoading(false);
				});
		}
		, [shellId]); //eslint-disable-line

	return [fetch, loading];
};
