import PropTypes            from 'prop-types';
import React                from 'react';
import { useTranslation }   from 'react-i18next';
import { useShellLicenses } from '@karpeleslab/klb-react-services';
import Table                from '../../../../core/data/table/Table';
import TableDefinition      from '../../../../core/data/table/TableDefinition';
import Empty                from './Empty';

const ShellLicenseTable = ({ shell }) => {
	const { t } = useTranslation();
	const [licenses, fetch, loading] = useShellLicenses(shell.Shell__);

	return (
		<Table
			fetch={fetch}
			data={licenses}
			search={false}
			loading={loading}
			emptyState={<Empty/>}
			paperProps={{ elevation: 0 }}
		>
			<TableDefinition label={t('tablecell_shell_license_name')} name='Shell_License__' sortable>
				{license => license.Catalog_Product['Basic.Name']}
			</TableDefinition>
		</Table>
	);
};

export default ShellLicenseTable;

ShellLicenseTable.propTypes = {
	shell: PropTypes.object.isRequired
};
