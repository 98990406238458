import PropTypes                      from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Grid }                       from '@material-ui/core';
import ShellOsSelector                from '../../../core/input/ShellOsSelector';
import OSPurposeSelector, {
	SHELL_OS_PURPOSE_LIST,
	SHELL_OS_PURPOSE_LITE_LIST
}                                     from '../../../core/input/OSPurposeSelector';
import { useIsMode }                  from '../../../../hooks/useIsMode';
import { ADVANCED_MODE }              from '../../../../context/ModeContext';
import { isShellKernel }              from '../../../../utils/misc';
import OsItemShellKernel              from './OSItemShellKernel';

const MobileOSSelector = ({ value, setValue, own = false, spacing = 2, initialSource = null }) => {
	const [source, setSource] = useState(initialSource);
	const isAdvanced = useIsMode(ADVANCED_MODE);

	useEffect(() => {
		if (value !== null) {
			setValue(null);
		}
		// eslint-disable-next-line
	}, [source]);

	return (
		<Grid container spacing={spacing}>
			<Grid item xs={12}>
				<OSPurposeSelector
					setValue={setSource}
					value={source}
					hideAll={!isAdvanced}
					list={isAdvanced ? SHELL_OS_PURPOSE_LIST : SHELL_OS_PURPOSE_LITE_LIST}
				/>
			</Grid>
			<Grid item xs={12}>
				<ShellOsSelector
					allowEmpty={false}
					setValue={setValue}
					value={value}
					own={own}
					purpose={source}
				/>
			</Grid>
			{(value && isShellKernel(value)) &&
			<Grid item xs={12}>
				<OsItemShellKernel detailed/>
			</Grid>
			}
		</Grid>
	);
};

export default MobileOSSelector;

MobileOSSelector.propTypes = {
	initialSource: PropTypes.string,
	own: PropTypes.bool,
	setValue: PropTypes.func.isRequired,
	spacing: PropTypes.number,
	value: PropTypes.object,
};
