import React, { useState } from 'react';
import { useTranslation }  from 'react-i18next';

import Empty                 from './Empty';
import TableDefinition       from '../../../../core/data/table/TableDefinition';
import { OauthBtn }          from '../../../../Login/OauthBtnsBuilder';
import DeleteForeverIcon     from '@material-ui/icons/DeleteForever';
import Button                from '../../../../core/input/Button';
import CoreTable             from '../../../../core/data/table/Table';
import DeleteDialog          from '../DeleteDialog';
import useMediaQuery         from '@material-ui/core/useMediaQuery';
import { useConsumerTokens } from '@karpeleslab/klb-react-services';

const Table = () => {
	const { t } = useTranslation();

	const [tokens, fetch, loading] = useConsumerTokens();
	const [selected, setSelected] = useState();
	const [showDelete, setShowDelete] = useState(false);
	const isMobile = useMediaQuery(theme => theme.breakpoints.down('xs'));

	const deleteHandler = token => {
		setSelected(token);
		setShowDelete(true);
	};

	return (
		<>
			<CoreTable
				fetch={fetch}
				data={tokens}
				search={false}
				loading={loading}
				paperProps={{ elevation: 0 }}
				emptyState={<Empty/>}
			>
				<TableDefinition label='' padding='checkbox' name='OAuth2_Consumer__' sortable>
					{token => <OauthBtn
						alt={token.OAuth2_Consumer.Name}
						className={token.OAuth2_Consumer.Token_Name}
						backgroundColor={
							token.OAuth2_Consumer.Button_Style['background-color']
						}
						logo={token.OAuth2_Consumer.Button_Style.logo}
					/>}
				</TableDefinition>

				{!isMobile && <TableDefinition label={t('tablecell_tokens_service')} name='Service' sortable/>}

				<TableDefinition label={t('tablecell_tokens_service')} name='Name' sortable/>

				<TableDefinition label={t('tablecell_actions')} name='OAuth2_Consumer__' align='right'>
					{token => <Button
						size='small'
						color='secondary'
						onClick={() => deleteHandler(token)}
						startIcon={<DeleteForeverIcon/>}
					>
						{t('delete_btn')}
					</Button>}
				</TableDefinition>
			</CoreTable>
			{selected && <DeleteDialog
				open={showDelete}
				setOpen={setShowDelete}
				token={selected}
				onDeleted={() => {
					fetch();
					setSelected(null);
					setShowDelete(false);
				}}
			/>
			}
		</>
	);
};

export default Table;
