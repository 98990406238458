import React, { useContext }               from 'react';
import { Redirect, Route }                 from 'react-router-dom';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

import MenuContextUpdater from './MenuContextUpdater';

import { UserContext }   from '../../../../context/UserContext';
import PropTypes         from 'prop-types';
import { getLoginRoute } from '../../../RouteController/Factory';
import { useLocation }   from 'react-router';
import ErrorBoundary     from '../ErrorBoundary';
import ModeCheck         from '../../../layout/Mode/ModeCheck';
import PageWrongMode     from '../../../../pages/System/PageWrongMode';


const RouteTransition = ({ forceHide = false, anyMode = false, lite = false, advanced = false, loginRequired = false, Component = null, children, path, ...etc }) => {
	const [user] = useContext(UserContext);
	const location = useLocation();

	if (loginRequired && !user.loggedIn) {
		const returnTo = `${location.pathname}${location.search}${location.hash}`;
		return <Redirect to={`${getLoginRoute()}${returnTo ? `?return_to=${encodeURI(returnTo)}` : ''}`}/>;
	}

	return (
		<SwitchTransition mode='out-in'>
			<CSSTransition
				key={
					path ||
					'default-key'
				}
				classNames='u-transition--lateral'
				addEndListener={(
					node,
					done
				) =>
					node.addEventListener(
						'transitionend',
						done,
						false
					)
				}
			>
				<Route
					path={path}
					{...etc}
				>
					<ErrorBoundary>
						<MenuContextUpdater/>
						<ModeCheck
							forceHide={forceHide}
							anyMode={anyMode}
							lite={lite}
							advanced={advanced}
							forbiddenComponent={
								<PageWrongMode
									expectedLite={lite}
									expectedAdvanced={advanced}
								/>
							}
						>
							{Component ? <Component/> : children}
						</ModeCheck>
					</ErrorBoundary>
				</Route>
			</CSSTransition>
		</SwitchTransition>
	);
};

export default RouteTransition;

RouteTransition.propTypes = {
	Component: PropTypes.any,
	advanced: PropTypes.bool,
	anyMode: PropTypes.bool,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	forceHide: PropTypes.bool,
	lite: PropTypes.bool,
	loginRequired: PropTypes.bool,
	path: PropTypes.string.isRequired
};
