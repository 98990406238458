import PropTypes            from 'prop-types';
import React                from 'react';
import Panel                from '../../../components/layout/Container/tab/Panel';
import { Grid }             from '@material-ui/core';
import RichAlert            from '../../../components/core/feedback/RichAlert';
import { Trans }            from 'react-i18next';
import ShellReinstallWizard from '../../../components/common/Shell/Reinstall/ShellReinstallWizard';

const ReinstallTab = ({ currentTab, shell, refresh }) => {
	return (
		<Panel value={currentTab} index={SHELL_TAB_REINSTALL}>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<RichAlert severity='info'>
						<Trans i18nKey='reinstall_desc'>
							sample <br/>
							<strong>sample</strong>
						</Trans>
					</RichAlert>
				</Grid>
				<Grid item xs={12}>
					<ShellReinstallWizard shell={shell} setShell={refresh}/>
				</Grid>
			</Grid>
		</Panel>
	);
};

export default ReinstallTab;
export const SHELL_TAB_REINSTALL = 3;

ReinstallTab.propTypes = {
	currentTab: PropTypes.number.isRequired,
	refresh: PropTypes.func.isRequired,
	shell: PropTypes.object.isRequired
};
