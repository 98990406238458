import React, { useContext, useState } from 'react';
import { useTranslation }              from 'react-i18next';
import { NavLink }                     from 'react-router-dom';

import AccountCircleIcon                                       from '@material-ui/icons/AccountCircle';
import ArrowDropUpIcon                                         from '@material-ui/icons/ArrowDropUp';
import ChevronLeftIcon                                         from '@material-ui/icons/ChevronLeft';
import Divider                                                 from '@material-ui/core/Divider';
import Drawer                                                  from '@material-ui/core/Drawer';
import HomeIcon                                                from '@material-ui/icons/Home';
import List                                                    from '@material-ui/core/List';
import ListItem                                                from '@material-ui/core/ListItem';
import ListItemIcon                                            from '@material-ui/core/ListItemIcon';
import ListItemText                                            from '@material-ui/core/ListItemText';
import Menu                                                    from '@material-ui/core/Menu';
import MenuIcon                                                from '@material-ui/icons/Menu';
import MenuItem                                                from '@material-ui/core/MenuItem';
import PowerSettingsNewIcon                                    from '@material-ui/icons/PowerSettingsNew';
import SettingsIcon                                            from '@material-ui/icons/Settings';
import Tooltip                                                 from '@material-ui/core/Tooltip';
import ContactSupportOutlinedIcon                              from '@material-ui/icons/ContactSupportOutlined';
import Graphic                                                 from '../../../assets/img/shells_logo_horizontal_white.svg';
import FacebookIcon                                            from '@material-ui/icons/Facebook';
import './Drawer.scss';
import { getLinksRoute, getShellsRoute, getUserSettingsRoute } from '../../RouteController/Factory';
import DrawerContent                                           from './DrawerContent';
import TwitterIcon                                             from '@material-ui/icons/Twitter';
import InstagramIcon                                           from '@material-ui/icons/Instagram';
import RedditIcon                                              from '@material-ui/icons/Reddit';
import { FaDiscord, FaProductHunt }                            from 'react-icons/fa';
import { IconButton }                                          from '@material-ui/core';
import LinkIcon                                                from '@material-ui/icons/Link';
import ThemeSwitch                                             from './../ThemeSwitch/ThemeSwitch';

import { UserContext } from '../../../context/UserContext';
import ListSubheader   from '@material-ui/core/ListSubheader';
import DrawerMode      from './DrawerMode';

export default function template(logOut, open, params, setOpen, wrapperRef) {
	const { t } = useTranslation();
	const [user] = useContext(UserContext);
	const [anchorEl, setAnchorEl] = useState(null);

	const handleUserSubmenuClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleUserSubmenuClose = () => {
		setAnchorEl(null);
	};

	return (
		<>

			{!open &&
			<div className={'c-drawer__menu-btn c-drawer__menu-btn_hack'}>
				<MenuIcon onClick={() => setOpen(!open)}/>
			</div>
			}


			<Drawer
				ref={wrapperRef}
				variant='permanent'
				className={`c-drawer c-drawer__root c-drawer--${open ? 'open' : 'close'}`}
				classes={{
					paper: `c-drawer--${open ? 'open' : 'close'} c-drawer__paper-root`
				}}
			>
				<List>
					<ListItem
						button
						color='inherit'
						onClick={() => setOpen(!open)}
						className={'c-drawer__menu-btn--wrapper'}
					>
						<ListItemIcon className={'c-drawer__menu-btn'}>
							{
								open ?
									<ChevronLeftIcon className={'c-drawer__icon'}/>
									:
									<MenuIcon className={'c-drawer__icon'}/>
							}
						</ListItemIcon>
						<div>
							<img src={Graphic} alt='Shells™' className={'c-drawer__logo'}/>
						</div>
					</ListItem>
					<ListItem color='inherit' disableGutters className={'media-links'}>
						<ListItemIcon className={'c-drawer__icon'}/>
						<ListItemText primary={<>
							<a href='https://www.facebook.com/shellsdotcom' rel='noopener noreferrer' target='_blank'>
								<IconButton size='small'><FacebookIcon style={{ fill: 'white' }}/></IconButton>
							</a>
							<a href='https://twitter.com/shellsdotcom' rel='noopener noreferrer' target='_blank'>
								<IconButton size='small'><TwitterIcon style={{ fill: 'white' }}/></IconButton>
							</a>
							<a href='https://instagram.com/shellscom' rel='noopener noreferrer' target='_blank'>
								<IconButton size='small'><InstagramIcon style={{ fill: 'white' }}/></IconButton>
							</a>
							<a href='https://www.reddit.com/r/shellscom' rel='noopener noreferrer' target='_blank'>
								<IconButton size='small'><RedditIcon style={{ fill: 'white' }}/></IconButton>
							</a>
							<a href='https://discord.gg/c9KQKj2MUU' rel='noopener noreferrer' target='_blank'>
								<IconButton size='small'><FaDiscord
									style={{ width: '23px', fill: 'white' }}/></IconButton>
							</a>
							<a
								href='https://www.producthunt.com/posts/shells'
								rel='noopener noreferrer'
								target='_blank'
							>
								<IconButton size='small'>
									<FaProductHunt style={{ width: '23px', fill: 'white' }}/>
								</IconButton>
							</a>
						</>} className={'c-drawer__text c-drawer__sns'}/>
					</ListItem>
				</List>
				<List>
					<Tooltip
						arrow
						title={open ? '' : t('navigation_home')}
						placement='right'
					>
						<ListItem
							component={NavLink}
							strict
							exact
							to={getShellsRoute()}
							activeStyle={{ backgroundColor: 'rgba(0, 0, 0, .3)' }}
							button
							color='inherit'
						>
							<ListItemIcon>
								<HomeIcon className={'c-drawer__icon'}/>
							</ListItemIcon>
							<ListItemText primary={t('navigation_home')} className={'c-drawer__text'}/>
						</ListItem>
					</Tooltip>
				</List>
				<Divider/>
				<DrawerContent/>
				<Divider/>
				<DrawerMode/>
				<Divider/>
				<List className={'c-drawer__bottom-links'}>
					<Tooltip
						arrow
						title={open ? '' : t('navigation_links')}
						placement='right'
					>
						<ListItem
							component={NavLink}
							button
							color='inherit'
							to={getLinksRoute()}
						>
							<ListItemIcon>
								<LinkIcon className={'c-drawer__icon'}/>
							</ListItemIcon>
							<ListItemText primary={t('navigation_links')} className={'c-drawer__text'}/>
						</ListItem>
					</Tooltip>
					<Tooltip
						arrow
						title={open ? '' : t('navigation_support')}
						placement='right'
					>
						<ListItem
							button
							component='a'
							href={process.env.REACT_APP_SUPPORT_SHELL_URL}
							color='inherit'
							target='_blank'
							rel='noopener noreferrer'
						>
							<ListItemIcon>
								<ContactSupportOutlinedIcon className={'c-drawer__icon'}/>
							</ListItemIcon>
							<ListItemText primary={t('navigation_support')} className={'c-drawer__text'}/>
						</ListItem>
					</Tooltip>
					<Tooltip
						arrow
						title={open ? '' : (user && user.user ? (user.user.data.Display_Name || user.user.data.Email) : 'User')}
						placement='right'
					>
						<ListItem
							button
							color='inherit'
							aria-controls='user-submenu'
							aria-haspopup='true'
							onClick={handleUserSubmenuClick}
						>
							<ListItemIcon>
								<AccountCircleIcon className={'c-drawer__icon'}/>
							</ListItemIcon>
							<ListItemText primary={t('navigation_account')} className={'c-drawer__text'}/>
							<ArrowDropUpIcon/>
						</ListItem>
					</Tooltip>
				</List>
				<Menu
					id='user-submenu'
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={handleUserSubmenuClose}
				>
					<ListSubheader className={'c-drawer__account-menu-fix'}>
						{user && user.user ? (user.user.data.Display_Name || user.user.data.Email) : t('navigation_account_user')}
					</ListSubheader>
					<Divider/>
					<MenuItem>
						<ThemeSwitch
							ButtonComponent={ListItemIcon}
							TextComponent={ListItemText}
						/>
					</MenuItem>
					<MenuItem
						component={NavLink}
						button
						color='inherit'
						onClick={handleUserSubmenuClose}
						to={getUserSettingsRoute()}
					>
						<ListItemIcon>
							<SettingsIcon/>
						</ListItemIcon>
						<ListItemText primary={t('navigation_settings')}/>
					</MenuItem>
					<MenuItem onClick={logOut}>
						<ListItemIcon>
							<PowerSettingsNewIcon/>
						</ListItemIcon>
						<ListItemText primary={t('navigation_logout')}/>
					</MenuItem>
				</Menu>
			</Drawer>
		</>
	);
}
