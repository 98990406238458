import PropTypes                 from 'prop-types';
import React                     from 'react';
import Dialog                    from '@material-ui/core/Dialog';
import DialogTitle               from '@material-ui/core/DialogTitle';
import DialogContent             from '@material-ui/core/DialogContent';
import DialogContentText         from '@material-ui/core/DialogContentText';
import DialogActions             from '@material-ui/core/DialogActions';
import { Trans, useTranslation } from 'react-i18next';
import RichAlert                 from '../../core/feedback/RichAlert';
import Button                    from '../../core/input/Button';


const ShellActionConfirmationDialog = ({ open, setOpen, actionName, action, loading }) => {
	const { t } = useTranslation();

	const handleClose = () => {
		setOpen(false);
	};

	const handleConfirm = () => {
		action(actionName)
			.finally(() => {
				handleClose();
			});
	};

	return (
		<Dialog
			open={open}
			aria-labelledby={t(`shell_action_${actionName}_title`)}
			aria-describedby={t(`shell_action_${actionName}_title`)}
		>
			<DialogTitle id="link-service-title">
				{t(`shell_action_${actionName}_title`)}
			</DialogTitle>
			<DialogContent>
				<DialogContentText id="link-service-description" component='div'>
					<RichAlert severity="warning">
						<Trans i18nKey={`shell_action_${actionName}_description`}>
							sample
							<br/>
							<strong>sample</strong>
						</Trans>
					</RichAlert>
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button
					variant="contained"
					color="default"
					autoFocus
					onClick={handleClose}
					disabled={loading}
				>
					{t('back_btn')}
				</Button>
				<Button
					variant="contained"
					color="primary"
					disabled={loading}
					loading={loading}
					onClick={handleConfirm}
				>
					{t(`${actionName}_btn`)}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ShellActionConfirmationDialog;

ShellActionConfirmationDialog.propTypes = {
	action: PropTypes.func,
	actionName: PropTypes.string,
	loading: PropTypes.bool.isRequired,
	open: PropTypes.bool.isRequired,
	setOpen: PropTypes.func.isRequired
};
