import PropTypes                 from 'prop-types';
import React                     from 'react';
import DialogContent             from '@material-ui/core/DialogContent';
import DialogActions             from '@material-ui/core/DialogActions';
import Grid                      from '@material-ui/core/Grid';
import RichAlert                 from '../../../../core/feedback/RichAlert';
import { Trans, useTranslation } from 'react-i18next';
import OtpActions                from '../OtpActions';
import Button                    from '../../../../core/input/Button';

const StepActivate = ({ otp, handleClose, refresh, setOtp }) => {
	const { t } = useTranslation();
	return (
		<>
			<DialogContent>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<RichAlert severity='info'>
							<Trans i18nKey='otp_activate_desc'>
								sample<br/>
								<strong>sample</strong>
							</Trans>
						</RichAlert>
					</Grid>
					<Grid item xs={12}>
						<OtpActions otp={otp} refresh={otp => {
							refresh();
							setOtp(otp);
						}}/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button color='default' onClick={handleClose}>{t('close_btn')}</Button>
			</DialogActions>
		</>
	);
};

export default StepActivate;

StepActivate.propTypes = {
	handleClose: PropTypes.func.isRequired,
	otp: PropTypes.object.isRequired,
	refresh: PropTypes.func.isRequired,
	setOtp: PropTypes.func.isRequired
};
