import React, { forwardRef } from 'react';
import ReactButton           from '@material-ui/core/Button';
import CircularProgress      from '@material-ui/core/CircularProgress';
import PropTypes             from 'prop-types';
import withStyles            from '@material-ui/core/styles/withStyles';

const Button = forwardRef(({ loading = false, children, ...rest }, ref) => {
	if (loading) {
		rest.disabled = true;
		rest.endIcon = <CircularProgress thickness={2} size={16}/>;
	}

	return (
		<ReactButton {...rest} ref={ref}>
			{children}
		</ReactButton>
	);
});


Button.displayName = 'Button';
Button.propTypes = {
	loading: PropTypes.bool,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node
	]),
};


export default withStyles(() => {
	return {
		root: {
			borderRadius: '0px',
			boxShadow: 'none',
			'&:hover': {
				boxShadow: 'none'
			},
			'&:disabled': {
				border: '1px solid rgba(0, 0, 0, 0.12)'
			}
		},
		containedPrimary: {
			color: 'white',
			border: '1px solid #72ba3e',
			background: '#72ba3e',
			'&:hover': {
				background: '#ffffff',
				color: '#72ba3e'
			}
		}
	};
})(Button);
