import React, { createContext, useCallback, useEffect, useState } from 'react';
import PropTypes                                                  from 'prop-types';
import { getHostname }                                            from '@karpeleslab/klbfw';
import { useCookies }                                             from 'react-cookie';
import { getCookiesOptions }                                      from '../utils/misc';

export const LITE_MODE = 'LITE_MODE';
export const ADVANCED_MODE = 'ADVANCED_MODE';

export const defaultModeContext = { mode: LITE_MODE };
export const ModeContext = createContext(defaultModeContext);

export const ModeContainer = ({ children }) => {
	const [modeContext, setModeContext] = useState(defaultModeContext);
	const [cookies, setCookies] = useCookies(['mode']);
	const setMode = useCallback(mode => {
		setModeContext({ mode: mode });
	}, [setModeContext]);

	useEffect(() => {
		let mode = LITE_MODE;
		switch (getHostname()) {
			// Set here the mode based on the hostname (for school/business mode)
			default:
				mode = cookies.mode ? cookies.mode : LITE_MODE;
				break;
		}

		setMode(mode);
		setCookies('mode', mode, getCookiesOptions());
	}, [setMode]); //eslint-disable-line

	return (
		<ModeContext.Provider value={[modeContext.mode, setMode]}>
			{children}
		</ModeContext.Provider>
	);
};

ModeContainer.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node
	]).isRequired
};
