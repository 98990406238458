import PropTypes                      from 'prop-types';
import React, { useEffect, useState } from 'react';
import { IconButton }                 from '@material-ui/core';
import TextWithIcon                   from '../../../core/typography/TextWithIcon';
import LoopIcon                       from '@material-ui/icons/Loop';

const KeyHashes = ({ hashes }) => {
	const [idx, setIdx] = useState(0);

	useEffect(() => {
		setIdx(0);
	}, [hashes]);

	const handleClick = () => {
		setIdx(idx + 1 >= hashes.length ? 0 : idx + 1);
	};

	if (hashes.length < 1) return <></>;
	return (
		<TextWithIcon
			text={hashes[idx]}
			startIcon={
				<IconButton onClick={handleClick} size='small'>
					<LoopIcon/>
				</IconButton>
			}
		/>
	);
};

export default KeyHashes;

KeyHashes.propTypes = {
	hashes: PropTypes.array.isRequired
};
