import PropTypes                      from 'prop-types';
import React, { useEffect, useState } from 'react';
import { IconButton }                 from '@material-ui/core';
import Tooltip                        from '@material-ui/core/Tooltip';
import { useTranslation }             from 'react-i18next';
import { useShellReboot }             from '@karpeleslab/klb-react-services';
import ShellActionConfirmationDialog  from '../ShellActionConfirmationDialog';
import { SHELL_REBOOT_ENABLE_STATES } from '../../../../hooks/useShells';
import ReplayIcon                     from '@material-ui/icons/Replay';

const ShellActionReboot = ({ setShell, shell, setLoading, disabled = false }) => {
	const { t } = useTranslation();
	const [reboot, rebooting] = useShellReboot(shell.Shell__);
	const [showConfirm, setShowConfirm] = useState(false);

	useEffect(() => {
		setLoading(rebooting);
	}, [rebooting]);

	const rebootHandler = () => {
		return reboot().then(() => setShell({ ...shell, State: 'running' }));
	};

	return (
		<>
			<Tooltip arrow title={t('shell_action_reboot')} placement='bottom'>
				<span>
					<IconButton
						aria-label={t('shell_action_reboot')}
						disabled={!SHELL_REBOOT_ENABLE_STATES.includes(shell.State) || rebooting || disabled}
						onClick={() => setShowConfirm(true)}
					>
						<ReplayIcon/>
					</IconButton>
				</span>
			</Tooltip>
			<ShellActionConfirmationDialog
				actionName='reboot'
				action={rebootHandler}
				open={showConfirm}
				setOpen={setShowConfirm}
				loading={rebooting}
			/>
		</>
	);
};

export default ShellActionReboot;

ShellActionReboot.propTypes = {
	disabled: PropTypes.bool,
	setLoading: PropTypes.func.isRequired,
	setShell: PropTypes.func.isRequired,
	shell: PropTypes.object.isRequired
};
