import { useCallback }                            from 'react';
import { rest }                                   from '@karpeleslab/klbfw';
import { getShellOsEndpoint, useApiErrorHandler } from '@karpeleslab/klb-react-services';

export const ICON_VARIATION = 'strip&scale_crop=40x40&format=png';

export const useFetchOperatingSystem = () => {
	const [catchRedirect] = useApiErrorHandler();

	const fetch = useCallback(
		(osId, params) => {
			return rest(getShellOsEndpoint(osId), 'GET', params)
				.then(catchRedirect)
				.then(d => d);
		}
		, []); //eslint-disable-line

	return [fetch];
};
