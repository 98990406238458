import moment from 'moment';

export const formatShellExpireDate = (shell) => {
	if (!shell.Created || !shell.Expires.iso) {
		return '';
	}
	return moment(shell.Expires.iso).format('ll');
};


export const isExpired = (shell) => {
	if (shell.Status === 'expired') return true;
	return !!(shell.Expires && moment(parseInt(shell.Expires.unixms)) < moment());
};

export const isShellValid = (shell) => {
	if (shell.Timer_Enabled && shell.Timer_Balance <= 0) return false;
	return !isExpired(shell);
};

export const isShellCanBeStarted = (shell) => {
	return isShellValid(shell) && shell.State !== 'preparing';
};

export const getPrice = (item, forceShowMonthly, isCustom, unit) => {
	let p = item[forceShowMonthly ? 'Price.Monthly' : 'Price'];
	if (isCustom) {
		p = '$' + p.value_disp * unit;
	} else {
		p = p.display;
	}

	return p;
};

export const isCustomPlan = (plan) => {
	return plan['Description.AuthorCode'] === 'custom';
};

export const isYearly = (plan) => {
	return plan['Basic.ServiceLifetime'] === '1y';
};

export const isSamePlanType = (p1, p2) => {
	return p1['Description.AuthorCode'] === p2['Description.AuthorCode'];
};
