import React, { useEffect, useState }       from 'react';
import Table                                from '@material-ui/core/Table';
import TableBody                            from '@material-ui/core/TableBody';
import TableRow                             from '@material-ui/core/TableRow';
import TableCell             from '@material-ui/core/TableCell';
import ShellPicture          from '../../../Shell/ShellPicture/ShellPicture';
import { getShellViewRoute } from '../../../../RouteController/Factory';
import { formatShellExpireDate, isExpired } from '../../../../../utils/shell';
import ShellExpiredMessage                  from '../../../Shell/Subscription/ShellExpiredMessage';
import { Grid }                             from '@material-ui/core';
import ShellUsage                           from '../../../Shell/Subscription/ShellUsage';
import TableContainer                       from '@material-ui/core/TableContainer';
import { useShellProductChangeWarning }     from '@karpeleslab/klb-react-services';
import { useTranslation }                   from 'react-i18next';
import Loader                               from '../../../../layout/Loader/Loader';
import { Link }                             from 'react-router-dom';

const ConcernedShellTable = () => {
	const { t } = useTranslation();
	const [fetch] = useShellProductChangeWarning();
	const [shells, setShells] = useState([]);

	useEffect(() => {
		fetch().then(setShells);
	}, [fetch, setShells]);

	if (shells.length < 1) return <Loader/>;

	return (
		<TableContainer>
			<Table>
				<TableBody>
					{shells.map(s =>
						<TableRow key={s.Shell__}>
							<TableCell>
								<ShellPicture shell={s} disabled width={100}/>
							</TableCell>
							<TableCell>
								<Link to={getShellViewRoute(s.Shell__)}>
									{s.Label}
								</Link>
							</TableCell>
							<TableCell>
								{isExpired(s) && <ShellExpiredMessage shell={s}/> }
								{!isExpired(s) &&
								<Grid container spacing={3} justify='space-between' alignItems='center'>
									<Grid item>
										<ShellUsage shell={s}/>
									</Grid>
									<Grid item>
										{!s.User_Billing__ && t('shell_expire_text', { date: formatShellExpireDate(s) })}
									</Grid>
								</Grid>}
							</TableCell>
						</TableRow>
					)}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default ConcernedShellTable;
