import PropTypes  from 'prop-types';
import React      from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
	root: {
		display: '-webkit-box',
		'-webkit-line-clamp': props => props.row,
		'-webkit-box-orient': 'vertical',
		overflow: 'hidden',
	}
}));

const Ellipsis = ({ children, row = 2 }) => {
	const classes = useStyles({ row: row });
	return (
		<div className={classes.root}>
			{children}
		</div>
	);
};

export default Ellipsis;

Ellipsis.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node
	]),
	row: PropTypes.number
};
