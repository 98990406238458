import withStyles from '@material-ui/core/styles/withStyles';
import Badge      from '@material-ui/core/Badge';
import { Chip }   from '@material-ui/core';

export const PlanBadge = withStyles(() => ({
	badge: {
		bottom: '0',
		right: 'unset',
		left: '50%',
		transform: 'translate(-50%, -50%)',
	},
}))(Badge);


export const PlanChip = withStyles(() => ({
	root: {
		height: '20px',
	},
	label: {
		fontSize: '0.75rem',
		fontWeight: '500',
		lineHeight: 1
	}
}))(Chip);
