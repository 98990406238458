import React, { useContext } from 'react';

import Drawer          from './Drawer/Drawer';
import Helmet          from 'react-helmet';
import RouteController from '../RouteController/RouteController';

import { UserContext } from '../../context/UserContext';
import { MenuContext } from '../../context/MenuContext';

import './Layout.scss';
import AccountWarnings from './AccountWarnings/AccountWarnings';

const Layout = () => {
	const [user] = useContext(UserContext);
	const [menu] = useContext(MenuContext);

	return (
		<>
			<Helmet titleTemplate={'%s - Shells Control Panel'}>
				{ /* https://devhints.io/html-meta */}
			</Helmet>
			{user && user.loggedIn ? <Drawer/> : ''}
			{user && user.loggedIn ? <AccountWarnings/> : ''}
			<div className={(user && user.loggedIn) ? `c-layout__wrapper ${menu.open ? 'c-layout__wrapper--menu-open' : ''}` : ''}>
				<RouteController/>
			</div>
		</>
	);
};

export default Layout;
