import React, { useContext, useEffect } from 'react';
import { useParams }                    from 'react-router-dom';

import { MenuContext } from '../../../../context/MenuContext';

export default function MenuContextUpdater() {
	const params = useParams();
	const [, setMenu] = useContext(MenuContext);

	useEffect(() => {
		setMenu(prevState => ({ ...prevState, route: params }));
	}, [setMenu]); // eslint-disable-line

	return <></>;
}
