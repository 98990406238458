import PropTypes                    from 'prop-types';
import React                        from 'react';
import TableCell                    from '@material-ui/core/TableCell';
import TableRow                     from '@material-ui/core/TableRow';
import { IconButton }               from '@material-ui/core';
import DeleteForeverOutlinedIcon    from '@material-ui/icons/DeleteForeverOutlined';
import { useCatalogCartItemRemove } from '@karpeleslab/klb-react-services';

const CartItem = ({ cartItem, refreshCart }) => {
	const [remove, removing] = useCatalogCartItemRemove(cartItem.key);

	const handleDelete = () => {
		remove().then(refreshCart);
	};

	return (
		<TableRow>
			<TableCell>{cartItem.data['Basic.Name']}</TableCell>
			<TableCell>{cartItem.quantity}</TableCell>
			<TableCell>{cartItem.price.raw.display}</TableCell>
			<TableCell>{`${cartItem.price.tax_rate}%`}</TableCell>
			<TableCell>{cartItem.price.tax.display}</TableCell>
			<TableCell align='right'>
				<IconButton
					onClick={handleDelete}
					size='small'
					disabled={removing}
					color='secondary'
				>
					<DeleteForeverOutlinedIcon/>
				</IconButton>
			</TableCell>
		</TableRow>
	);
};

export default CartItem;

CartItem.propTypes = {
	cartItem: PropTypes.object.isRequired,
	refreshCart: PropTypes.func.isRequired
};
