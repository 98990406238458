import React                    from 'react';
import { useTranslation }       from 'react-i18next';
import TableDefinition          from '../../core/data/table/TableDefinition';
import Table                    from '../../core/data/table/Table';
import Button                   from '../../core/input/Button';
import VisibilityIcon           from '@material-ui/icons/Visibility';
import { Link }                 from 'react-router-dom';
import { getCustomOsEditRoute } from '../../RouteController/Factory';
import Grid                     from '@material-ui/core/Grid';
import { ICON_VARIATION }       from '../../../hooks/useOperatingSystems';
import DateUtil                 from '../../core/data/Date/DateUtil';
import { useOperatingSystems }  from '@karpeleslab/klb-react-services';
import Avatar                   from '@material-ui/core/Avatar';
import defaultIcon              from '../../../assets/img/shells_icon_white.svg';
import useMediaQuery            from '@material-ui/core/useMediaQuery';

const OperatingSystemTable = () => {
	const { t } = useTranslation();
	const [os, fetch, loading] = useOperatingSystems();
	const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

	return (
		<Table
			initialFilters={{
				image_variation: [ICON_VARIATION]
			}} // Visible N Will fetch only custom operating system of the user
			title={t('custom_os_title')}
			fetch={fetch}
			data={os}
			search={false}
			loading={loading}
			paperProps={{ elevation: 0 }}
		>
			<TableDefinition label={t('custom_os_icon')} name='Shell_OS__'>
				{os => <Avatar
					variant='square'
					src={os.Media_Image ? os.Media_Image.Variation[ICON_VARIATION] : defaultIcon}
				/>}
			</TableDefinition>
			<TableDefinition label={t('custom_os_name')} name='Name'/>

			<TableDefinition label={t('custom_os_public')} name='Public'>
				{os => t(`custom_os_public_${os.Public.toLowerCase()}`)}
			</TableDefinition>

			{!isMobile && <TableDefinition label={t('custom_os_family')} name='Family'>
				{os => t(`custom_os_family_${os.Family.toLowerCase()}`)}
			</TableDefinition>}

			{!isMobile && <TableDefinition label={t('custom_os_date')} name='Created'>
				{os => <DateUtil klbDateObj={os.Created} format='LLL'/>}
			</TableDefinition>}

			<TableDefinition align='right' label={t('tablecell_actions')} name='Shell_OS__'>
				{os =>
					<Grid container spacing={3} justify='flex-end'>
						<Grid item>
							<Button
								component={Link}
								to={getCustomOsEditRoute(os.Shell_OS__)}
								variant='contained'
								size='small'
								color='primary'
								startIcon={<VisibilityIcon/>}
							>
								{t('view_btn')}
							</Button>
						</Grid>
					</Grid>
				}
			</TableDefinition>


		</Table>
	);
};

export default OperatingSystemTable;
