import PropTypes                 from 'prop-types';
import React, { useState }       from 'react';
import TextField                 from '@material-ui/core/TextField';
import { Trans, useTranslation } from 'react-i18next';
import InputAdornment            from '@material-ui/core/InputAdornment';
import CircularProgress          from '@material-ui/core/CircularProgress';
import Grid                      from '@material-ui/core/Grid';
import Alert                     from '@material-ui/lab/Alert';
import HelpOutlineIcon           from '@material-ui/icons/HelpOutline';
import Tooltip                   from '@material-ui/core/Tooltip';

const Affiliate = ({ affiliate }) => {
	const { t } = useTranslation();
	const [referral, setReferral] = useState('');

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Alert severity='info'>{t(`partnership_rate_${affiliate.Type.toLowerCase()}`)}</Alert>
			</Grid>
			<Grid item xs={12} md={8}>
				<TextField
					label={t('account_affiliate_link_lbl')}
					helperText={t('account_affiliate_link_helperText')}
					autoFocus
					value={
						`${process.env.REACT_APP_SHELL_URL}/?_a=${affiliate.Code}${referral.trim() ? `&_b=${encodeURIComponent(referral.trim())}` : ''}`
					}
					variant='outlined'
					editable='false'
					fullWidth
					InputProps={!affiliate ?
						{
							endAdornment:
								(<InputAdornment position='end'>
									<CircularProgress thickness={1}/>
								</InputAdornment>)
						} : undefined
					}
				/>
			</Grid>
			<Grid item xs={12} md={4}>
				<TextField
					label={t('account_affiliate_surfer_label')}
					value={referral}
					onChange={e => setReferral(e.target.value)}
					variant='outlined'
					fullWidth
					inputProps={{
						maxLength: 64,
					}}
					InputProps={
						{
							endAdornment:
								(<InputAdornment position='end'>
									<Tooltip
										arrow
										title={
											<Trans i18nKey='account_affiliate_surfer_helperText'>
												sample<br/>
												<strong>sample</strong>
											</Trans>
										}
										placement='top'
									>
										<HelpOutlineIcon/>
									</Tooltip>
								</InputAdornment>)
						}
					}
				/>
			</Grid>
		</Grid>
	);
};

export default Affiliate;

Affiliate.propTypes = {
	affiliate: PropTypes.object.isRequired
};
