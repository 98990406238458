// dirty
import makeStyles from '@material-ui/core/styles/makeStyles';
import Alert      from '@material-ui/lab/Alert';
import PropTypes  from 'prop-types';
import React      from 'react';

const useStyles = makeStyles(() => {
	return {
		fixAlert: {
			'& > .MuiAlert-message': {
				display: 'block',
			},
			overflowWrap: 'anywhere'
		}
	};
});

const RichAlert = ({ children, ...rest }) => {
	const classes = useStyles();
	return (
		<Alert {...rest} className={classes.fixAlert}>
			{children}
		</Alert>
	);
};

export default RichAlert;

RichAlert.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node
	]).isRequired
};
