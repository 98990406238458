import React, { useCallback }            from 'react';
import { FUNDING, PayPalScriptProvider } from '@paypal/react-paypal-js';
import Buttons                           from './Buttons';
import PropTypes                         from 'prop-types';
import { useSetSnackbar }                from '../../../../hooks/useSnackbar';
import { useTranslation }                from 'react-i18next';

const Paypal = ({ clientId, order, onApproved, style = {}, disabled = false, onOrderCanceled = undefined, fundingSource = FUNDING.PAYPAL }) => {
	const { t } = useTranslation();
	const setSnackbar = useSetSnackbar();

	const handleLoadFailed = useCallback(() => {
		setSnackbar(t('paypal_load_failed_alert'), 'error');
	}, []);

	const handlePaymentCanceled = useCallback(() => {
		if (onOrderCanceled) onOrderCanceled();
	}, []);

	const handlePaymentError = useCallback(() => {
		setSnackbar(t('paypal_error_alert'), 'error');
		if (onOrderCanceled) onOrderCanceled();
	}, []);

	return (
		<PayPalScriptProvider options={{ 'client-id': clientId }}>
			<Buttons
				disabled={disabled}
				style={style}
				fundingSource={fundingSource}
				order={order}
				onLoadFailed={handleLoadFailed}
				onCancel={handlePaymentCanceled}
				onError={handlePaymentError}
				onApproved={onApproved}
			/>
		</PayPalScriptProvider>
	);
};

export default Paypal;

Paypal.propTypes = {
	clientId: PropTypes.string.isRequired,
	disabled: PropTypes.bool,
	fundingSource: PropTypes.oneOf([Object.values(FUNDING)]),
	onApproved: PropTypes.func.isRequired,
	onOrderCanceled: PropTypes.func,
	order: PropTypes.object.isRequired,
	style: PropTypes.object
};
