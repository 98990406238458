import PropTypes             from 'prop-types';
import React, { useState }   from 'react';
import LinearProgress        from '../../core/feedback/LinearProgress';
import DialogTitle           from '@material-ui/core/DialogTitle';
import DialogContent         from '@material-ui/core/DialogContent';
import Grid                  from '@material-ui/core/Grid';
import FormControl           from '@material-ui/core/FormControl';
import InputLabel            from '@material-ui/core/InputLabel';
import Input                 from '@material-ui/core/Input';
import FormHelperText        from '@material-ui/core/FormHelperText';
import DialogActions         from '@material-ui/core/DialogActions';
import Button                from '../../core/input/Button';
import Dialog                from '@material-ui/core/Dialog';
import { useTranslation }    from 'react-i18next';
import uuid                  from '../../../utils/uuid';
import { useShellISOUpload } from '@karpeleslab/klb-react-services';
import { useHistory }        from 'react-router';
import { getISORoute }       from '../../RouteController/Factory';
import RichAlertTrans        from '../../core/feedback/RichAlertTrans';

const IsoCreateDialog = ({ open, setOpen }) => {
	const { t } = useTranslation();

	const history = useHistory();

	const [create, creating, progress] = useShellISOUpload();

	const [file, setFile] = useState(null);
	const [fileKey, setFileKey] = useState(uuid());

	const handleClose = () => {
		setOpen(false);
	};

	const reset = () => {
		setFileKey(uuid());
		setFile(null);
	};

	const handleFile = e => {
		if (e.target.files.length > 0)
			setFile(e.target.files[0]);
	};

	const handleCreate = () => {
		create(file).then((iso) => history.push(getISORoute(iso.Shell_ISO__)));
	};

	return (
		<Dialog
			open={open}
			aria-labelledby={t('dialog_create_iso_title')}
			aria-describedby={t('dialog_create_iso_title')}
			onEnter={() => reset()}
			maxWidth='lg'
			fullWidth
		>
			<form onSubmit={handleCreate}>
				<DialogTitle id='create-os'>
					{t('dialog_create_iso_title')}
				</DialogTitle>
				<DialogContent>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<RichAlertTrans severity='info' i18nKey='dialog_create_iso_desc'/>
						</Grid>
						<Grid item xs={12}>
							<FormControl fullWidth required disabled={creating}>
								<InputLabel
									shrink={true}
									htmlFor='iso-file'>
									{t('iso_file_label')}
								</InputLabel>
								<Input
									inputProps={
										{
											accept: '.iso'
										}
									}
									key={fileKey}
									disabled={creating}
									onChange={handleFile}
									id='iso-file'
									type='file'
								/>
								<FormHelperText>{t('custom_os_file_helperText')}</FormHelperText>
							</FormControl>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					{creating && <LinearProgress variant='determinate' value={progress * 100}/>}
					<Button color='default' onClick={handleClose} disabled={creating}>
						{t('close_btn')}
					</Button>
					<Button
						onClick={handleCreate}
						color='primary'
						type='submit'
						variant='contained'
						disabled={creating || !file}
						loading={creating}
					>
						{t('create_btn')}
					</Button>
				</DialogActions>
			</form>
		</Dialog>
	);
};

export default IsoCreateDialog;

IsoCreateDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	setOpen: PropTypes.func.isRequired
};
