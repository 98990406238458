import PropTypes from 'prop-types';
import React    from 'react';
import CartItem from './CartItem';

const CartItems = ({ items, refreshCart }) => {
	return (
		<>
			{items.map(product => <CartItem key={product.key} cartItem={product} refreshCart={refreshCart}/>)}
		</>
	);
};

export default CartItems;

CartItems.propTypes = {
	items: PropTypes.array.isRequired,
	refreshCart: PropTypes.func.isRequired
};
