import PropTypes                                  from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { UserContext }                            from '../../../../context/UserContext';
import { Grid }                                   from '@material-ui/core';
import Button                                     from '../../../core/input/Button';
import { useTranslation }                         from 'react-i18next';
import {
	useCatalogCartCreateOrder,
	useUserLocationCreate,
	useUserSetDefaultLocation
}                                                 from '@karpeleslab/klb-react-services';
import Form                                       from '../../User/Location/Form';
import { Title }                                  from '../../../core/typography/Title';


const CreateOrder = ({ setOrder, setCreating, setDialogActions = null }) => {
	const { t } = useTranslation();

	const [userContext] = useContext(UserContext);
	const [createOrder, creatingOrder] = useCatalogCartCreateOrder();
	const [createLocation, creatingLocation] = useUserLocationCreate('@');
	const [setDefaultLocation, settingDefaultLocation] = useUserSetDefaultLocation('@');

	const [location, setLocation] = useState({});
	const [locationValid, setLocationValid] = useState(false);

	useEffect(() => {
		if (!setCreating) return;
		setCreating(creatingOrder || creatingLocation || settingDefaultLocation);
	}, [setCreating, creatingOrder, creatingLocation, settingDefaultLocation]);

	useEffect(() => {
		if (!setDialogActions) return;
		if (userContext.user.data.Default_Billing_User_Location__) {
			setDialogActions((
				<Button
					onClick={handleCreateOrder}
					color='primary'
					variant='contained'
					disabled={creatingOrder}
					loading={creatingOrder}
				>
					{t('create_order_btn')}
				</Button>
			));
		} else {
			setDialogActions((
				<Button
					onClick={handleCreateOrderWithLocation}
					color='primary'
					variant='contained'
					disabled={creatingLocation || settingDefaultLocation || creatingOrder || !locationValid}
					loading={creatingLocation || settingDefaultLocation || creatingOrder}
				>
					{t('create_order_btn')}
				</Button>
			));
		}

	}, [setDialogActions, creatingLocation, settingDefaultLocation, creatingOrder, locationValid]);

	const handleCreateOrder = () => {
		createOrder().then(setOrder);
	};

	const handleCreateOrderWithLocation = e => {
		e.preventDefault();

		createLocation(location, { snackMessageToken: null })
			.then(location => setDefaultLocation(location.User_Location__, 'Billing', { snackMessageToken: null }))
			.then(handleCreateOrder);
	};

	if (userContext.user.data.Default_Billing_User_Location__) {
		// Button is added to the dialog with useEffect above
		if (setDialogActions) return <></>;

		return (
			<Grid container spacing={3} justify='flex-end'>
				<Grid item>
					<Button
						onClick={handleCreateOrder}
						color='primary'
						variant='contained'
						disabled={creatingOrder}
						loading={creatingOrder}
					>
						{t('create_order_btn')}
					</Button>
				</Grid>
			</Grid>
		);
	}

	return (
		<form onSubmit={handleCreateOrderWithLocation}>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Title>{t('cart_billing_location_create')}</Title>
				</Grid>
				<Grid item xs={12}>
					<Form
						setData={setLocation}
						setIsValid={setLocationValid}
						data={location}
						disabled={creatingLocation || settingDefaultLocation || creatingOrder}
					/>
				</Grid>
				{!setDialogActions && <Grid item xs={12}>
					<Grid container spacing={3} justify='flex-end'>
						<Grid item>
							<Button
								type='submit'
								color='primary'
								variant='contained'
								disabled={creatingLocation || settingDefaultLocation || creatingOrder || !locationValid}
								loading={creatingLocation || settingDefaultLocation || creatingOrder}
							>
								{t('create_order_btn')}
							</Button>
						</Grid>
					</Grid>
				</Grid>}
			</Grid>
		</form>
	);
};

export default CreateOrder;

CreateOrder.propTypes = {
	setOrder: PropTypes.func.isRequired,
	setCreating: PropTypes.func,
	setDialogActions: PropTypes.func // Used when the component is used in a dialog in order to integrate the action into the dialog actions
};
