import React                       from 'react';
import { useTranslation }          from 'react-i18next';
import Table                       from '../../core/data/table/Table';
import TableDefinition             from '../../core/data/table/TableDefinition';
import DateUtil                    from '../../core/data/Date/DateUtil';
import Button                      from '../../core/input/Button';
import { getSSHKeychainEditRoute } from '../../RouteController/Factory';
import EditIcon                    from '@material-ui/icons/Edit';
import { Link }                    from 'react-router-dom';
import useMediaQuery               from '@material-ui/core/useMediaQuery';
import { useKeychains }            from '@karpeleslab/klb-react-services';

const KeychainTable = () => {
	const [keychains, fetch, loading] = useKeychains();
	const { t } = useTranslation();
	const isMobile = useMediaQuery(theme => theme.breakpoints.down('xs'));

	return (
		<Table
			fetch={fetch}
			data={keychains}
			search
			loading={loading}
			searchKey='Label'
			emptyText={t('keychains_empty')}
			paperProps={{ elevation: 0 }}
		>
			<TableDefinition label={t('tablecell_keychain_label')} name='Label' sortable/>
			{!isMobile && <TableDefinition label={t('tablecell_keychain_date')} name='Created' sortable>
				{keychain => <DateUtil klbDateObj={keychain.Created} format='LLL'/>}
			</TableDefinition>}

			<TableDefinition align='right' label={t('tablecell_actions')} name='Keychain__'>
				{keychain =>
					<Button
						size={isMobile ? 'small' : undefined}
						variant='contained'
						component={Link}
						to={getSSHKeychainEditRoute(keychain.Keychain__)}
						color='primary'
						startIcon={<EditIcon/>}
					>
						{t('edit_btn')}
					</Button>
				}
			</TableDefinition>
		</Table>
	);
};

export default KeychainTable;
