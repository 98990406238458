import PropTypes             from 'prop-types';
import React, { useState }   from 'react';
import IconButton            from '@material-ui/core/IconButton';
import MoreVertIcon          from '@material-ui/icons/MoreVert';
import MoreHorizIcon         from '@material-ui/icons/MoreHoriz';
import Menu                  from '@material-ui/core/Menu';
import MenuItem              from '@material-ui/core/MenuItem';
import { NavLink }           from 'react-router-dom';
import { getShellViewRoute } from '../../../../RouteController/Factory';
import ListItemIcon          from '@material-ui/core/ListItemIcon';
import InfoOutlinedIcon      from '@material-ui/icons/InfoOutlined';
import ListItemText          from '@material-ui/core/ListItemText';
import { useTranslation }    from 'react-i18next';
import AutorenewIcon         from '@material-ui/icons/Autorenew';
import NewOrderDialog        from '../../../Order/order/NewOrderDialog';
import useMediaQuery         from '@material-ui/core/useMediaQuery';

const ActionMenu = ({ shell, refresh }) => {
	const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'), { noSsr: true });
	const needHorizontal = useMediaQuery('(max-width:1666px)', { noSsr: true });


	const { t } = useTranslation();
	const [menuAnchor, setMenuAnchor] = React.useState(null);

	const [showOrder, setShowOrder] = useState(false);

	const handleRenew = () => {
		setShowOrder(true);
	};

	const handleComplete = () => {
		refresh();
		closeMenu();
	};

	const openMenu = (event) => {
		setMenuAnchor(event.currentTarget);
	};

	const closeMenu = () => {
		setMenuAnchor(null);
	};

	return (
		<>
			<IconButton onClick={openMenu}>
				{(!isMobile && needHorizontal) ? <MoreHorizIcon/> : <MoreVertIcon/>}
			</IconButton>
			<Menu
				anchorEl={menuAnchor}
				keepMounted
				open={Boolean(menuAnchor)}
				onClose={closeMenu}
			>
				<MenuItem
					component={NavLink}
					button
					color='inherit'
					onClick={closeMenu}
					to={getShellViewRoute(shell.Shell__)}
				>
					<ListItemIcon>
						<InfoOutlinedIcon fontSize='small'/>
					</ListItemIcon>
					<ListItemText primary={t('edit_btn')}/>
				</MenuItem>
				{!shell.User_Billing__ &&
				<MenuItem onClick={handleRenew} disabled={showOrder}>
					<ListItemIcon>
						<AutorenewIcon fontSize='small'/>
					</ListItemIcon>
					<ListItemText
						primary={t('renew_btn')}
					/>

				</MenuItem>
				}
			</Menu>
			{
				showOrder &&
				<NewOrderDialog
					onClose={closeMenu}
					setOpen={setShowOrder}
					request={`${shell.Catalog_Product__},shell=${shell.Shell__},mode=renew`}
					open={showOrder}
					onComplete={handleComplete}
				/>
			}
		</>
	);
};

export default ActionMenu;

ActionMenu.propTypes = {
	refresh: PropTypes.func.isRequired,
	shell: PropTypes.object.isRequired
};
