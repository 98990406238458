import PropTypes                      from 'prop-types';
import React, { useEffect, useState } from 'react';
import Dialog                         from '@material-ui/core/Dialog';
import DialogTitle                    from '@material-ui/core/DialogTitle';
import DialogContent                  from '@material-ui/core/DialogContent';
import DialogContentText              from '@material-ui/core/DialogContentText';
import Grid                           from '@material-ui/core/Grid';
import DialogActions                  from '@material-ui/core/DialogActions';
import { Trans, useTranslation }      from 'react-i18next';
import FormControl                    from '@material-ui/core/FormControl';
import InputLabel                     from '@material-ui/core/InputLabel';
import Input                          from '@material-ui/core/Input';
import FormHelperText                 from '@material-ui/core/FormHelperText';
import Tab                            from '@material-ui/core/Tab';
import Tabs                           from '@material-ui/core/Tabs';
import useMediaQuery                  from '@material-ui/core/useMediaQuery';
import uuid                           from '../../../../utils/uuid';
import RichAlertTrans                 from '../../../core/feedback/RichAlertTrans';
import Panel                          from '../../../layout/Container/tab/Panel';
import ShellSelector                  from '../../../core/input/ShellSelector';
import Button                         from '../../../core/input/Button';
import {
	useOperatingSystemAddImageFromFile,
	useOperatingSystemAddImageFromShell
}                                     from '@karpeleslab/klb-react-services';
import LinearProgress                 from '../../../core/feedback/LinearProgress';
import RichAlert                      from '../../../core/feedback/RichAlert';
import { fileExtensionIs }            from '../../../../utils/misc';

const FROM_SHELL_TAB = 0;
const FROM_FILE_TAB = 1;

const AddVersionDialog = ({ open, setOpen, os, refresh, canAddNewVersion = true }) => {
	const { t } = useTranslation();

	const [createFromFile, creatingFromFile, progress] = useOperatingSystemAddImageFromFile(os?.Shell_OS__);
	const [createFromShell, creatingFromShell] = useOperatingSystemAddImageFromShell(os?.Shell_OS__);

	const [tab, setTab] = useState(FROM_SHELL_TAB);

	const [file, setFile] = useState(null);
	const [fileKey, setFileKey] = useState(uuid());
	const [shell, setShell] = useState();
	// This will control, the input to display it red if the file is not a .shells,
	// We default to true, because when there is no selected file we don't want to have the 'red' error outline to the input
	const [isFileValid, setIsFileValid] = useState(true);

	const [saveEnabled, setSaveEnabled] = useState(false);

	const isMobile = useMediaQuery(theme => theme.breakpoints.down('xs'));

	const handleTabChange = (event, newValue) => {
		setTab(newValue);
	};

	const reset = () => {
		setTab(FROM_SHELL_TAB);
		setShell(undefined);
		setFileKey(uuid());
		setFile(null);
		setIsFileValid(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleFile = e => {
		if (e.target.files.length > 0)
			setFile(e.target.files[0]);
	};

	const handleCreate = e => {
		e.preventDefault();

		let promise;
		if (tab === FROM_FILE_TAB) {
			promise = createFromFile(file);
		} else {
			promise = createFromShell(shell.Shell_Volume__);
		}

		promise.then(() => refresh()).then(handleClose);
	};

	useEffect(() => {
		if (!file) setIsFileValid(true); // No file so we default to true
		else setIsFileValid(fileExtensionIs(file, 'shells'));
	}, [file, setIsFileValid]);

	useEffect(() => {
		if (
			(tab === FROM_SHELL_TAB && !shell) ||
			(tab === FROM_FILE_TAB && (!file || !isFileValid))
		) {
			setSaveEnabled(false);
			return;
		}

		setSaveEnabled(true);
	}, [setSaveEnabled, file, tab, shell, isFileValid]);

	return (
		<Dialog
			open={open}
			aria-labelledby={t('dialog_os_add_version_title')}
			aria-describedby={t('dialog_os_add_version_title')}
			onEnter={() => reset()}
			maxWidth='lg'
			fullWidth
		>
			<form onSubmit={handleCreate}>
				<DialogTitle id='create-os'>
					{t('dialog_os_add_version_title')}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id='create-os-description'>
						<RichAlertTrans severity='info' i18nKey='dialog_os_add_version_description'/>
					</DialogContentText>
					<Grid container spacing={2}>
						{!canAddNewVersion &&
						<Grid item xs={12}>
							<RichAlertTrans severity='warning' i18nKey='dialog_os_add_version_warning'/>
						</Grid>
						}
						{canAddNewVersion && <Grid item xs={12}>
							<Tabs
								variant='scrollable'
								scrollButtons={isMobile ? 'on' : 'auto'}
								value={tab}
								indicatorColor='primary'
								textColor='primary'
								onChange={handleTabChange}
							>
								<Tab
									label={t('shell_create_os_shell')}
									disabled={creatingFromFile || creatingFromShell}
								/>
								<Tab
									label={t('shell_create_os_file')}
									disabled={creatingFromFile || creatingFromShell}
								/>

							</Tabs>
						</Grid>}
						{canAddNewVersion && <Grid item xs={12}>
							<Panel index={FROM_FILE_TAB} value={tab} boxProps={{ p: 0 }}>
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<RichAlert severity='info'>
											<Trans i18nKey='dialog_create_os_file_description'>
												sample<br/>
												<a
													href={`${process.env.REACT_APP_SHELL_URL}/tutorial/Shells-com-OS-Creation-Tutorial`}
													target='_blank'
													rel='noopener noreferrer'
												>
													sample
												</a>
											</Trans>
										</RichAlert>
									</Grid>
									<Grid item xs={12}>
										<FormControl
											fullWidth
											required
											disabled={creatingFromFile || creatingFromShell}
										>
											<InputLabel
												shrink={true}
												htmlFor='os-file'>
												{t('custom_os_file_label')}
											</InputLabel>
											<Input
												inputProps={
													{
														accept: '.shells'
													}
												}
												key={fileKey}
												disabled={creatingFromFile || creatingFromShell}
												onChange={handleFile}
												id='os-file'
												type='file'
												error={!isFileValid}
											/>
											<FormHelperText
												error={!isFileValid}
											>
												{t(isFileValid ? 'custom_os_file_helperText' : 'custom_os_file_error_helperText')}
											</FormHelperText>
										</FormControl>
									</Grid>
								</Grid>
							</Panel>
							<Panel index={FROM_SHELL_TAB} value={tab} boxProps={{ p: 0 }}>
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<RichAlertTrans i18nKey='custom_os_from_shell_info' severity='info'/>
									</Grid>
									<Grid item xs={12}>
										<ShellSelector
											volumeRequired
											idOnly={false}
											fullWidth
											setValue={setShell}
											value={shell}
											disabled={creatingFromFile || creatingFromShell}
											required
										/>
									</Grid>
								</Grid>
							</Panel>
						</Grid>}
					</Grid>
				</DialogContent>
				<DialogActions>
					{creatingFromFile && <LinearProgress variant='determinate' value={progress * 100}/>}
					<Button color='default' onClick={handleClose} disabled={creatingFromFile || creatingFromShell}>
						{t('close_btn')}
					</Button>
					{canAddNewVersion && <Button
						onClick={handleCreate}
						color='primary'
						type='submit'
						variant='contained'
						disabled={creatingFromFile || creatingFromShell || !saveEnabled}
						loading={creatingFromFile || creatingFromShell}
					>
						{t('create_btn')}
					</Button>
					}
				</DialogActions>
			</form>
		</Dialog>
	);
};

export default AddVersionDialog;

AddVersionDialog.propTypes = {
	canAddNewVersion: PropTypes.bool,
	open: PropTypes.bool.isRequired,
	os: PropTypes.object.isRequired,
	refresh: PropTypes.func.isRequired,
	setOpen: PropTypes.func.isRequired
};
