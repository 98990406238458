import React              from 'react';
import makeStyles         from '@material-ui/core/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import CardContent        from '@material-ui/core/CardContent';
import Typography         from '@material-ui/core/Typography';
import Grid               from '@material-ui/core/Grid';
import Skeleton           from '@material-ui/lab/Skeleton';
import Card               from '@material-ui/core/Card';
import OrderPaymentMethod from './OrderPaymentMethod';
import PropTypes          from 'prop-types';


const useStyles = makeStyles(() => ({
	card: {
		whiteSpace: 'pre-wrap',
		height: '100%'
	}
}));


const OrderPaymentInfo = ({ order, elevation = 0 }) => {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<Card className={classes.card} elevation={elevation}>
			<CardContent>
				<Typography
					gutterBottom
					variant="h5"
					component="h2"
				>
					{t('order_payment_info')}
				</Typography>


				<Grid
					container
					direction="row"
					justify="center"
					alignItems="center"
				>
					<Grid item xs={12}>
						<Typography variant="body1" color="textPrimary" component="p">
							{t('order_payment_method')}:</Typography>
					</Grid>

					<Grid item xs={12}>
						{
							order &&
							<Typography variant="body2" color="textSecondary" component="p">
								<OrderPaymentMethod order={order}/>
							</Typography>
						}
						{!order && <Skeleton/>}
					</Grid>


					{(order && order.Payment_Last4) &&
					<>
						<Grid item xs={12}>
							<Typography variant="body1" color="textPrimary" component="p">
								{t('order_payment_card')}:</Typography>
						</Grid>

						<Grid item xs={12}>
							<Typography variant="body2" color="textSecondary" component="p">
								{`${order.Payment_Method}⋯${order.Payment_Last4}`}
							</Typography>
						</Grid>
					</>
					}
				</Grid>

			</CardContent>
		</Card>
	);
};

export default OrderPaymentInfo;

OrderPaymentInfo.propTypes = {
	elevation: PropTypes.number,
	order: PropTypes.object
};
