import PropTypes                      from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation }             from 'react-i18next';
import TextField                      from '@material-ui/core/TextField';
import InputAdornment                 from '@material-ui/core/InputAdornment';
import CircularProgress               from '@material-ui/core/CircularProgress';
import { useShells }                  from '@karpeleslab/klb-react-services';

const ShellSelector = ({ value, setValue, idOnly = true, volumeRequired = false, ...rest }) => {
	const { t } = useTranslation();
	const [shells, fetch, loading] = useShells();
	const [idToShellMap, setIdToShellMap] = useState();

	useEffect(() => {
		fetch({}, { result_per_page: 100, page_no: 1 });
	}, []); // eslint-disable-line

	useEffect(() => {
		if (idOnly || !shells) return;
		const map = {};
		shells.data.forEach(s => map[s.Shell__] = s);
		setIdToShellMap(map);
	}, [shells, setIdToShellMap, idOnly]);

	return (
		<TextField
			label={t('shell_selector_label')}
			helperText={t('shell_selector_helperText')}
			value={idOnly ? value : (value ? value.Shell__ : undefined)}
			onChange={e => setValue(idOnly ? e.target.value : idToShellMap[e.target.value])}
			select
			fullWidth
			InputLabelProps={{ shrink: !!value }}
			variant='outlined'
			InputProps={loading ?
				{
					endAdornment:
						(<InputAdornment position='end'>
							<CircularProgress size={30} thickness={1}/>
						</InputAdornment>)
				} : undefined
			}
			SelectProps={{
				native: true,
			}}
			{...rest}
		>
			<option/>

			{(shells && !loading) && shells.data.map(
				shell => <option
					disabled={volumeRequired && shell.Shell_Volume__ == null}
					key={shell.Shell__}
					value={shell.Shell__}>
					{shell.Label}
				</option>
			)}
		</TextField>
	);
};

export default ShellSelector;

ShellSelector.propTypes = {
	idOnly: PropTypes.bool,
	setValue: PropTypes.func.isRequired,
	value: PropTypes.string,
	volumeRequired: PropTypes.bool
};
