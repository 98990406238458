import PropTypes                      from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useUserWalletAutos }         from '@karpeleslab/klb-react-services';
import Grid                           from '@material-ui/core/Grid';
import { Skeleton }                   from '@material-ui/lab';
import { useTranslation }             from 'react-i18next';
import Button                         from '../../../core/input/Button';
import AffiliatePayoutDetailsDialog   from '../AffiliatePayoutDetailsDialog';

const WalletTablePayout = ({ wallet }) => {
	const { t } = useTranslation();

	const [list, fetch, loading] = useUserWalletAutos(wallet.User_Wallet__);
	const [showPayoutDetails, setShowPayoutDetails] = useState(false);
	const [selected, setSelected] = useState(null);

	useEffect(() => {
		// We are not making a table but directly displaying everything
		// There will be usually 0 or 1 User Wallet Auto per wallet, so querying 100 per page is far enough
		fetch({ results_per_page: 100 });
		// eslint-disable-next-line
	}, []);

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				{(loading || !list) && <Skeleton/>}
				{(!loading && !!list && list.data.length < 1) && t('affiliate_no_user_wallet_payout')}
				{(!loading && !!list && list.data.length > 0) &&
				<Grid container spacing={3} justify='space-around'>
					{list.data.map(userWalletAuto =>
						(
							<Grid item key={userWalletAuto.User_Wallet_Auto__}>
								<Button
									onClick={() => {
										setSelected(userWalletAuto.Money_Bank_Account__);
										setShowPayoutDetails(true);
									}}
									variant='outlined'
									color='primary'
								>
									{userWalletAuto.Money_Bank_Account.Label}
								</Button>
							</Grid>
						)
					)}
				</Grid>
				}
			</Grid>
			{/*Not really optimized as this component is called in a Table, so if will include several time the dialog,
			 but the table itself will contains 1 entries most of the time*/}
			{selected && <AffiliatePayoutDetailsDialog
				setOpen={setShowPayoutDetails}
				moneyBankAccountId={selected}
				open={showPayoutDetails}
			/>}
		</Grid>
	);
};

export default WalletTablePayout;

WalletTablePayout.propTypes = {
	wallet: PropTypes.object.isRequired
};
