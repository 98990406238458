import React              from 'react';
import { useTranslation } from 'react-i18next';
import TextField          from '@material-ui/core/TextField';
import PropTypes          from 'prop-types';

const OSBootSelector = ({ value, setValue, disabled = false, required = false, ...rest }) => {
	const { t } = useTranslation();

	return (
		<TextField
			label={t('custom_os_boot_label')}
			helperText={t('custom_os_boot_helperText')}
			value={value}
			onChange={e => setValue(e.target.value)}
			select
			fullWidth
			InputLabelProps={{ shrink: !!value }}
			disabled={disabled}
			required={required}
			variant='outlined'
			SelectProps={{
				native: true
			}}
			{...rest}
		>
			<option/>
			<option value='guest-linux'>{t('custom_os_family_guest-linux')}</option>
			<option value='bios'>{t('custom_os_family_bios')}</option>
			<option value='efi'>{t('custom_os_family_efi')}</option>
		</TextField>
	);
};

export default OSBootSelector;

OSBootSelector.propTypes = {
	disabled: PropTypes.bool,
	required: PropTypes.bool,
	setValue: PropTypes.func.isRequired,
	value: PropTypes.string
};
