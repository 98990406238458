import React, { useContext, useEffect, useState } from 'react';
import { useTranslation }                         from 'react-i18next';
import { Grid, TextField }                        from '@material-ui/core';
import { Title }                                  from '../../core/typography/Title';
import { UserContext }                            from '../../../context/UserContext';
import Button                                     from '../../core/input/Button';
import { useUserProfileUpdate }                   from '@karpeleslab/klb-react-services';

const Information = () => {
	const { t } = useTranslation();
	const [saveEnabled, setSaveEnabled] = useState(false);
	const [user, setUser] = useContext(UserContext);
	const [username, setUsername] = useState(user.user.data.Profile.Display_Name);
	const [updateProfile, changingInfo] = useUserProfileUpdate('@');


	useEffect(() => {
		if (username !== user.user.data.Profile.Display_Name) {
			setSaveEnabled(true);
			return;
		}

		setSaveEnabled(false);
	}, [username, user]);

	const handleSave = () => {
		let p = {
			Display_Name: username
		};

		updateProfile(p)
			.then(res => {
				if (res) {
					const tmp = { ...user };
					tmp.user.data.Profile = res;
					setUser(tmp);
				}
			});
	};

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Title>{t('profile_information')}</Title>
			</Grid>
			<Grid item xs={12}>
				<TextField
					variant='outlined'
					fullWidth label={t('profile_username')}
					value={username}
					disabled={changingInfo}
					onChange={(e) => setUsername(e.target.value)}
				/>
			</Grid>

			<Grid item xs={12}>
				<Grid container justify='flex-end'>
					<Button
						color='primary'
						variant='contained'
						disabled={!saveEnabled || changingInfo}
						onClick={handleSave}
					>
						{t('save_btn')}
					</Button>

				</Grid>
			</Grid>
		</Grid>
	);
};

export default Information;
