import PropTypes                    from 'prop-types';
import React, { useState }          from 'react';
import { useTranslation }           from 'react-i18next';
import { Typography }               from '@material-ui/core';
import Box                          from '@material-ui/core/Box';
import { CATALOG_PRODUCT_SHELL_IP } from '../../../hooks/useShells';
import Loader                       from '../../layout/Loader/Loader';
import NotFound                     from '../../core/utils/NotFound/NotFound';
import Grid                         from '@material-ui/core/Grid';
import Button                       from '../../core/input/Button';
import { useCatalogProduct }        from '@karpeleslab/klb-react-services';
import NewOrderDialog               from '../Order/order/NewOrderDialog';

const ShellIpPlan = ({ shell, refresh }) => {
	const { t } = useTranslation();
	const [plan] = useCatalogProduct(CATALOG_PRODUCT_SHELL_IP);
	const [showOrder, setShowOrder] = useState(false);

	if (!plan) return <Loader/>;
	if (!plan.data) return <NotFound/>;

	const handleComplete = () => {
		refresh();
	};

	const handleOrderCreate = () => {
		setShowOrder(true);
	};

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Box m={2} borderColor='grey.500' border={1} textAlign='center' p={2}>
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<Typography variant='h5'>{plan.data['Basic.Name']}</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography variant='subtitle2'>
								{plan.data['Price.Price'].display}
								<span>/mo</span>
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Button
								disabled={showOrder}
								color='primary'
								onClick={handleOrderCreate}
								variant='outlined'
							>
								{t('add_ip_btn')}
							</Button>
						</Grid>
					</Grid>
				</Box>
			</Grid>
			{
				showOrder &&
				<NewOrderDialog
					setOpen={setShowOrder}
					request={`${plan.data.Catalog_Product__},shell=${shell.Shell__}`}
					open={showOrder}
					onComplete={handleComplete}
				/>
			}
		</Grid>
	);
};


export default ShellIpPlan;

ShellIpPlan.propTypes = {
	shell: PropTypes.object.isRequired,
	refresh: PropTypes.func.isRequired
};
