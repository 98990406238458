import React, { useEffect, useRef, useState } from 'react';
import makeStyles                             from '@material-ui/core/styles/makeStyles';
import TableHead                              from '@material-ui/core/TableHead';
import TableRow                               from '@material-ui/core/TableRow';
import TableCell                              from '@material-ui/core/TableCell';
import SortableCell                           from './SortableCell';
import TableBody                              from '@material-ui/core/TableBody';
import TableFooter                            from '@material-ui/core/TableFooter';
import { Table as MUITable }                  from '@material-ui/core';
import TablePagination                        from '@material-ui/core/TablePagination';
import TablePaginationActions                 from '@material-ui/core/TablePagination/TablePaginationActions';
import TableContainer                         from '@material-ui/core/TableContainer';
import { Skeleton }                           from '@material-ui/lab';
import queryString                            from 'query-string';
import { useHistory, useLocation }            from 'react-router-dom';
import { useTranslation }                     from 'react-i18next';
import Paper                                  from '@material-ui/core/Paper';
import Grid                                   from '@material-ui/core/Grid';
import TableToolbar                           from './TableToolbar';
import PropTypes                              from 'prop-types';

const useStyles = makeStyles(theme => ({
	cTable: {
		'& a:not([role="button"])': {
			color: `${theme.palette.text.primary} !important`
		}
	}
}));

const Table = ({ title, data, loading = false, fetch, children, search = true, searchKey, initialFilters = {}, paperProps = {}, searchCriteria = [], extra = [], emptyState = null, emptyText = null, RowComponent = TableRow, rowProps = {}, toolbarProps = {}, ...etc }) => {
	const history = useHistory();
	const location = useLocation();
	const { t } = useTranslation();
	const classes = useStyles();

	const [paging, setPaging] = useState({});
	const [filters, setFilters] = useState(initialFilters);
	const [hasFilters, setHasFilters] = useState(false);

	const displayData = () => {
		const list = loading ?
			[...Array(paging.results_per_page ?? 10).keys()] :
			(data ? data.data ?? [] : []);

		if (list.length < 1) { // empty
			return (<TableRow>
				<TableCell colSpan={(Array.isArray(children) ? children : [children]).length} align='center'>
					{emptyText ?? t('table_empty_text')}
				</TableCell>
			</TableRow>);
		}

		return list.map((d, rowIdx) =>
			<RowComponent key={`row-${rowIdx}`} data={d} idx={rowIdx} {...rowProps} loading={loading}>
				{(Array.isArray(children) ? children : [children]).map(
					(child, cellIdx) => {
						if (!child)
							return null;

						// eslint-disable-next-line
						const { name, label, sortable, children, ...cellProps } = child.props;

						return <TableCell key={`row-${rowIdx}-cell-${cellIdx}`} {...(cellProps ?? {})}>
							{loading && <Skeleton/>}
							{!loading && (children ?
								children(d, rowIdx, cellIdx) :
								d[name])
							}
						</TableCell>;
					}
				)}
			</RowComponent>
		);
	};

	const changePageHandler = (e, newPage) => {
		// The pagination component start at 0;...
		if (loading) return;
		handleFiltersAndPagingChange(null, { ...paging, page_no: parseInt(newPage) + 1 });
	};

	const changeResultPerPageHandler = e => {
		if (loading) return;
		handleFiltersAndPagingChange(null, { ...paging, results_per_page: e.target.value });
	};

	const changeFiltersHandler = filters => {
		if (loading) return;
		const currentParams = queryString.parse(location.search);
		const params = {};
		// We remove all existing filters
		Object.keys(currentParams ?? {}).forEach(pK => {
			if (pK.startsWith('f-')) return;
			params[pK] = currentParams[pK];
		});

		Object.keys(filters ?? {}).forEach(key => {
			if (key === searchKey && '$prefix' in filters[key])
				params[`f-${key}`] = filters[key]['$prefix'];
			else
				params[`f-${key}`] = filters[key];
		});


		history.push(location.pathname + '?' + queryString.stringify(params));
	};


	const handleFiltersAndPagingChange = (filters, paging) => {
		if (loading) return;

		let params = queryString.parse(location.search);

		if (filters !== null) { // We have some filter changes
			// We remove all existing filters
			Object.keys({ ...(params ?? {}) }).forEach(pK => {
				if (!pK.startsWith('f-')) return;
				delete params[pK];
			});

			// We add the new filters
			Object.keys(filters ?? {}).forEach(key => {
				if (key === searchKey && '$prefix' in filters[key])
					params[`f-${key}`] = filters[key]['$prefix'];
				else
					params[`f-${key}`] = filters[key];
			});

		}

		if (paging !== null) {
			params = { ...params, ...paging }; // we replace the paging information
		}

		history.push(location.pathname + '?' + queryString.stringify(params));
	};

	const sortHandler = (property, dir) => {
		let p = { ...filters };
		// only 1 sort
		p.sort = {};
		p.sort[property] = dir;
		setFilters(p);
	};

	const pagingChangeHandler = params => {
		const pNo = 'page_no' in params ? parseInt(params.page_no) : 1;
		const perPage = 'results_per_page' in params ? parseInt(params.results_per_page) : 10;
		let newPaging = null;
		if (paging.page_no !== pNo)
			newPaging = { ...(newPaging ?? {}), page_no: pNo };
		if (paging.results_per_page !== perPage)
			newPaging = { ...(newPaging ?? {}), results_per_page: perPage };

		if (newPaging) {
			setPaging({ ...paging, ...newPaging });
		}
	};

	const filtersChangeHandler = params => {
		// We need to compare the filters set in the url and the one we have.
		// We keep the sort filters, and the initial filters
		// We refresh only if a change occurred
		const filtersInUrl = {};
		let refreshNeeded = false;

		// We extract the filters in the url, all the table filter will begin by f-
		// We also use this to detect if an existing filter as changed
		// With this we can detect if filters has been added to the url, or changed
		Object.keys(params).forEach(k => {
			if (!k.startsWith('f-')) return;
			const cleaned = k.replace('f-', '');
			filtersInUrl[cleaned] = cleaned === searchKey ? { '$prefix': params[k] } : params[k];
			// Detect changes
			if (!(k in filters)) {
				refreshNeeded = true;
				return;
			}
			if (cleaned === searchKey && filters[k]['$prefix'] !== params[k]['$prefix'])
				refreshNeeded = true;
			else if (cleaned !== searchKey && filters[k] !== params[k]) refreshNeeded = true;
		});

		//We need now to detect a filters has been removed from the url
		Object.keys(filters).forEach(k => {
			if (k in initialFilters) return; // this is an initial filters we don't touch it
			if (k === 'sort') return;  // We don't handle sort filter here

			// There is a filter in the filters list but not in the url,
			// this means a filter has been deleted, we need to update the filter list
			if (!(k in filtersInUrl)) refreshNeeded = true;
		});

		if (refreshNeeded)
			setFilters({ ...initialFilters, ...(filters.sort ?? {}), ...filtersInUrl });
	};

	useEffect(() => {
		let hasFilter = false;
		Object.keys(filters ?? {}).forEach(key => {
			if (key in (initialFilters ?? {}) || key === 'sort') return;
			hasFilter = true;
		});

		setHasFilters(hasFilter);
	}, [filters, setHasFilters]);

	useEffect(() => {
		const params = queryString.parse(location.search);

		// Change paging
		pagingChangeHandler(params);

		// Change filters
		filtersChangeHandler(params);


		// eslint-disable-next-line
	}, [location]);

	const firstUpdate = useRef(true);

	// Detect filter or paging changes
	useEffect(() => {
		// Do not fetch on first render
		// The system will get the paging info from the url that will trigger the initial fetch
		if (firstUpdate.current) {
			firstUpdate.current = false;
			return;
		}

		fetch(filters, paging);
		// eslint-disable-next-line
	}, [filters, paging]);

	return (
		<Paper {...paperProps}>
			{(loading || (data ? data.data ?? [] : []).length > 0 || (!emptyState || hasFilters)) &&
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<TableToolbar
						toolbarProps={toolbarProps}
						extra={extra}
						title={title}
						loading={loading}
						search={search}
						searchKey={searchKey}
						filters={filters}
						setFilters={setFilters}
						changeFiltersHandler={changeFiltersHandler}
						changePageHandler={changePageHandler}
						changeFiltersAndPaging={handleFiltersAndPagingChange}
						searchCriteria={searchCriteria}
					/>
				</Grid>

				<Grid item xs={12}>
					<TableContainer className={classes.cTable}>
						<MUITable {...etc}>
							<TableHead>
								<TableRow>
									{(Array.isArray(children) ? children : [children]).map(
										(child, idx) => {
											if (!child)
												return null;

											const { name, label, sortable, headerAlign, align, ...cellProps } = child.props;

											const finalAlign = headerAlign ?? (align ?? undefined);

											return <TableCell key={idx} align={finalAlign} {...(cellProps ?? {})}>
												{!sortable ? label :
													<SortableCell
														sort={filters.sort}
														onClick={sortHandler}
														field={name}
														label={label}
													/>
												}
											</TableCell>;
										})}
								</TableRow>
							</TableHead>
							<TableBody>
								{displayData()}
							</TableBody>
							<TableFooter>
								<TableRow>
									<TablePagination
										rowsPerPageOptions={[5, 10, 25]}
										colSpan={(Array.isArray(children) ? children : [children]).filter(c => !!c).length}
										count={(data && data.paging) ? parseInt(data.paging.count ?? 0) : 10}
										rowsPerPage={parseInt(paging.results_per_page ?? 10)}
										page={paging ? parseInt(paging.page_no ?? 1) - 1 : 0}
										labelRowsPerPage={t('rows_per_page')}
										SelectProps={{
											inputProps: { 'aria-label': t('rows_per_page') },
											native: true,
										}}
										labelDisplayedRows={
											({ from, to, count }) => t(count !== -1 ? 'table_paging' : 'table_paging_more', {
												from: from,
												to: to,
												count: count
											})
										}
										onChangePage={changePageHandler}
										onChangeRowsPerPage={changeResultPerPageHandler}
										ActionsComponent={TablePaginationActions}
									/>
								</TableRow>
							</TableFooter>
						</MUITable>
					</TableContainer>
				</Grid>
			</Grid>
			}
			{(emptyState && !loading && (data ? data.data ?? [] : []).length < 1 && !hasFilters) && emptyState}
		</Paper>
	);
};

export default Table;

Table.propTypes = {
	RowComponent: PropTypes.any,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	data: PropTypes.object,
	emptyState: PropTypes.any,
	emptyText: PropTypes.string,
	extra: PropTypes.array,
	fetch: PropTypes.func,
	initialFilters: PropTypes.object,
	loading: PropTypes.bool,
	paperProps: PropTypes.object,
	rowProps: PropTypes.object,
	search: PropTypes.bool,
	searchCriteria: PropTypes.array,
	searchKey: PropTypes.string,
	title: PropTypes.string,
	toolbarProps: PropTypes.object
};
