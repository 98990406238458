import React              from 'react';
import { useTranslation } from 'react-i18next';
import { useUserWallets } from '@karpeleslab/klb-react-services';
import TableDefinition    from '../../../core/data/table/TableDefinition';
import Table              from '../../../core/data/table/Table';
import WalletTablePayout  from './WalletTablePayout';

const WalletsTable = (props) => {
	const { t } = useTranslation();
	const [wallets, fetch, loading] = useUserWallets('@');

	return (
		<Table
			title={t('user_wallets_title')}
			fetch={fetch}
			data={wallets}
			search={false}
			loading={loading}
			paperProps={{ elevation: 0 }}
			{...props}
		>
			<TableDefinition label={t('tablecell_wallet_currency')} name='Currency__' sortable/>
			<TableDefinition label={t('tablecell_wallet_payout')} name='User_Wallet__' align='center'>
				{wallet => <WalletTablePayout wallet={wallet}/>}
			</TableDefinition>
			<TableDefinition label={t('tablecell_wallet_balance')} name='Balance' align='right'>
				{wallet => wallet.Balance.display}
			</TableDefinition>
		</Table>
	);
};

export default WalletsTable;
