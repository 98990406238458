import React              from 'react';
import PageContainer      from '../../components/layout/Container/PageContainer';
import { Grid }           from '@material-ui/core';
import Toolbar            from '../../components/core/typography/Toolbar';
import { useTranslation } from 'react-i18next';
import AddIcon            from '@material-ui/icons/Add';
import Button             from '../../components/core/input/Button';
import ShellsTable        from '../../components/common/Shell/Table/ShellsTable';
import useMediaQuery      from '@material-ui/core/useMediaQuery';

const ShellList = () => {
	const { t } = useTranslation();
	const isMobile = useMediaQuery(theme => theme.breakpoints.down('xs'));

	return (
		<PageContainer>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Toolbar
						titleItemProps={isMobile ? { xs: 12 } : {}}
						justify={isMobile ? 'center' : 'space-between'}
						title={t('shell_list_title')}
						pageTitle
						alignItems='center'
					>
						<Button
							component='a'
							href={`${process.env.REACT_APP_SHELL_URL}/join`}
							target='_blank'
							rel='noopener noreferrer'
							endIcon={<AddIcon/>}
							variant='contained'
							color='primary'
						>
							{t('order_new_shell_btn')}
						</Button>
					</Toolbar>
				</Grid>

				<Grid item xs={12}>
					<ShellsTable/>
				</Grid>
			</Grid>
		</PageContainer>
	);
};

export default ShellList;
