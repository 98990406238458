import React, { useEffect, useState } from 'react';
import Dialog                         from '@material-ui/core/Dialog';
import DialogContentText              from '@material-ui/core/DialogContentText';
import DialogContent                  from '@material-ui/core/DialogContent';
import { useTranslation }             from 'react-i18next';
import DialogTitle                    from '@material-ui/core/DialogTitle';
import DialogActions                  from '@material-ui/core/DialogActions';
import Form                           from './Form';
import moment                         from 'moment';
import Button                         from '../../../core/input/Button';
import Grid                           from '@material-ui/core/Grid';
import PropTypes                      from 'prop-types';
import { useKeychainKeyCreate }       from '@karpeleslab/klb-react-services';
import uuid                           from '../../../../utils/uuid';

const CreateDialog = ({ keychain, open, setOpen, refresh, refreshing }) => {
	const { t } = useTranslation();
	const [saveEnabled, setSaveEnabled] = useState(false);
	const [data, setData] = useState({});
	const [create, creating] = useKeychainKeyCreate(keychain.Keychain__);
	const [isValid, setIsValid] = useState(false);
	const [isChecking, setIsChecking] = useState(false);
	const [formKey, setFormKey] = useState(uuid()); // Because form as an internal state, use the key to reinitialize it.

	useEffect(() => {
		setSaveEnabled(isValid && !isChecking);
	}, [isValid, isChecking, setSaveEnabled]);

	const handleClose = () => {
		setOpen(false);
	};

	const handleCreate = e => {
		e.preventDefault();
		let expires = null;

		if (data.Expires && data.Expires instanceof moment) {
			expires = '@' + data.Expires.unix();
		}

		create(data.Label.trim(), data.Data.trim(), expires)
			.then(() => refresh())
			.then(handleClose);
	};

	return (
		<Dialog
			open={open}
			aria-labelledby={t('dialog_create_keychain_key_title')}
			aria-describedby={t('dialog_create_keychain_key_title')}
			onEnter={() => {
				setFormKey(uuid());
				setData({});
			}}
		>
			<form onSubmit={handleCreate}>
				<DialogTitle id='create-keychain-key-title'>
					{t('dialog_create_keychain_key_title')}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id='create-keychain-key-description'>
						{t('account_keychains_description')}
					</DialogContentText>
					<Grid container spacin={2}>
						<Grid item xs={12}>
							<Form
								key={formKey}
								data={data}
								setData={setData}
								setIsValid={setIsValid}
								setChecking={setIsChecking}
								disabled={creating || refreshing}
							/>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button
						color='default'
						onClick={handleClose}
						disabled={creating || refreshing}
					>
						{t('close_btn')}
					</Button>
					<Button
						onClick={handleCreate}
						color='primary'
						type='submit'
						variant='contained'
						disabled={creating || !saveEnabled || refreshing}
						loading={creating || refreshing || isChecking}
					>
						{t('add_btn')}
					</Button>
				</DialogActions>
			</form>
		</Dialog>
	);
};

export default CreateDialog;

CreateDialog.propTypes = {
	keychain: PropTypes.object.isRequired,
	open: PropTypes.bool.isRequired,
	refresh: PropTypes.func.isRequired,
	refreshing: PropTypes.bool.isRequired,
	setOpen: PropTypes.func.isRequired
};
