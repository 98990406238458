import React, { useEffect, useState } from 'react';
import { useTranslation }             from 'react-i18next';

import Box               from '@material-ui/core/Box';
import CircularProgress  from '@material-ui/core/CircularProgress';
import ClearIcon         from '@material-ui/icons/Clear';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Grid              from '@material-ui/core/Grid';
import { IconButton }    from '@material-ui/core';
import InputAdornment    from '@material-ui/core/InputAdornment';
import MenuItem          from '@material-ui/core/MenuItem';
import SearchIcon        from '@material-ui/icons/Search';
import TextField         from '@material-ui/core/TextField';
import Toolbar           from '../../typography/Toolbar';
import PropTypes         from 'prop-types';
import Button            from '../../input/Button';

const TableToolbar = (
	{
		title,
		loading,
		search,
		searchKey,
		filters,
		changeFiltersAndPaging,
		searchCriteria = [], // [{ field: string, value: ''|[value1, value2, ...] }]
		extra = [], //Array of extra element that will be append at the right of the search icon (used to add button etc)
		toolbarProps = {}
	}
) => {
	const { t } = useTranslation();
	const [showSearch, setShowSearch] = useState(false);
	const [searchText, setSearchText] = useState('');
	const [showCriteria, setShowCriteria] = useState(false);
	const [currentCriterias, setCurrentCriterias] = useState([]);

	useEffect(() => {
		if (searchKey in filters) {
			setShowSearch(true);

			if (searchText !== filters[searchKey])
				setSearchText(filters[searchKey]['$prefix']);
		}
		// eslint-disable-next-line
	}, [filters]);

	const handleAdvancedSearch = () => {
		const params = {};
		currentCriterias.forEach(c => {
			if (c.value.length > 0)
				params[c.field] = c.value;
		});
		changeFiltersAndPaging(params, { page_no: 1 });
	};

	const handleSearch = () => {
		changeFiltersAndPaging({ [searchKey]: { '$prefix': searchText } }, { page_no: 1 });
	};

	const handleClear = () => {
		setSearchText('');
		setShowSearch(false);
		changeFiltersAndPaging({}, { page_no: 1 });
	};

	const handleSearchKeyPress = e => {
		if (e.key !== 'Enter') return;
		handleSearch();
	};

	const handleSearchKeyUp = e => {
		if (e.key !== 'Escape') return;
		setShowSearch(false);
		handleClear();
	};

	const newCriteria = (e) => {
		setCurrentCriterias([...currentCriterias, {
			field: e.target.value,
			value: ''
		}]);
	};

	const unsetCriteria = field => {
		const idx = currentCriterias.findIndex(c => c.field === field);
		if (idx < 0)
			return;

		const tmp = [...currentCriterias];
		tmp.splice(idx, 1);
		setCurrentCriterias(tmp);
	};

	const updateCriteria = (field, value) => {
		const idx = currentCriterias.findIndex(c => c.field === field);
		if (idx < 0)
			return;

		const tmp = [...currentCriterias];
		tmp[idx] = { field, value };
		setCurrentCriterias(tmp);
	};

	return (
		<>
			<Toolbar
				toolbarProps={toolbarProps}
				title={showSearch ?
					<TextField
						autoFocus
						value={searchText}
						onChange={e => setSearchText(e.target.value)}
						onKeyPress={handleSearchKeyPress}
						onKeyUp={handleSearchKeyUp}
						InputProps={
							{
								endAdornment: (
									<InputAdornment position='end'>
										<IconButton onClick={handleClear} size='small'>
											<ClearIcon/>
										</IconButton>
									</InputAdornment>
								),
								startAdornment: (
									<InputAdornment position='start'>
										<IconButton onClick={() => handleSearch()} size='small'>
											<SearchIcon/>
										</IconButton>
									</InputAdornment>)
							}
						}
					/> :
					title}
				endAdornment={
					loading ?
						<CircularProgress size={24} style={{ marginLeft: 15, position: 'relative', top: 4 }}/> :
						null
				}
			>
				<Grid container spacing={3}>
					{/* remove 'false' in the following condition to display the advanced search */}
					{searchCriteria.length > 0 && false &&
					<Grid item>
						<Button onClick={() => setShowCriteria(!showCriteria)}>
							{t('toggle_advanced_search', { state: showCriteria ? 'Hide' : 'Show' })}
						</Button>
					</Grid>}
					{search &&
					<Grid item>
						<IconButton onClick={() => setShowSearch(!showSearch)}>
							<SearchIcon/>
						</IconButton>
					</Grid>}

					{extra && extra.map((e, idx) => <Grid item key={`table-extra-${idx}`}>{e}</Grid>)}
				</Grid>

			</Toolbar>
			{showCriteria &&
			<Box p={4}>
				<Grid container spacing={3} alignItems='center'>
					<Grid item xs={12}>
						{searchCriteria.filter(option => currentCriterias.findIndex(c => c.field === option.field) < 0).length > 0 &&
						<TextField
							select
							label={t('label_add_criteria')}
							onChange={newCriteria}
							value={''}
							variant='outlined'
							fullWidth
						>
							{searchCriteria.filter(option => currentCriterias.findIndex(c => c.field === option.field) < 0).map(option => (
								<MenuItem key={option.field} value={option.field}>
									{t(`label_criteria_${option.field.replace('.', '_')}`)}
								</MenuItem>
							))}
						</TextField>
						}
					</Grid>
					{
						currentCriterias.map(c => (
							<Grid item key={c.field}>
								<TextField
									label={t(`label_criteria_${c.field.replace('.', '_')}`)}
									onChange={e => updateCriteria(c.field, e.target.value)}
									value={c.value}
									variant='outlined'
									fullWidth
									InputProps={{
										endAdornment:
											<InputAdornment position='end'>
												<IconButton
													onClick={() => unsetCriteria(c.field)}
												>
													<DeleteForeverIcon/>
												</IconButton>
											</InputAdornment>
									}}
								/>
							</Grid>
						))
					}
					{
						currentCriterias.length > 0 &&
						<Grid item>
							<Button
								variant='contained'
								color='primary'
								disabled={currentCriterias.filter(c => c.value.length > 0).length <= 0}
								onClick={handleAdvancedSearch}
							>
								{t('search')}
							</Button>
						</Grid>
					}
				</Grid>
			</Box>
			}
		</>
	);
};

export default TableToolbar;

TableToolbar.propTypes = {
	changeFiltersAndPaging: PropTypes.func.isRequired,
	extra: PropTypes.array,
	filters: PropTypes.object.isRequired,
	loading: PropTypes.bool,
	search: PropTypes.bool,
	searchCriteria: PropTypes.array,
	searchKey: PropTypes.string,
	title: PropTypes.string,
	toolbarProps: PropTypes.object
};
