import PropTypes                                   from 'prop-types';
import React, { useEffect, useState }              from 'react';
import { Grid }                                    from '@material-ui/core';
import RichAlertTrans                              from '../../../core/feedback/RichAlertTrans';
import TextField                                   from '@material-ui/core/TextField';
import { validate }                                from '../../../../utils/uuid';
import { useTranslation }                          from 'react-i18next';
import { ICON_VARIATION, useFetchOperatingSystem } from '../../../../hooks/useOperatingSystems';
import InputAdornment                              from '@material-ui/core/InputAdornment';
import CircularProgress                            from '@material-ui/core/CircularProgress';
import OsItem                                      from './OSItem';

const InputSharedOS = ({ shell, value, setValue, setShowDetailsFor, disabled = false, spacing = 3 }) => {
	const { t } = useTranslation();
	const [shareInput, setShareInput] = useState('');
	const [fetch] = useFetchOperatingSystem();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);

	useEffect(() => {
		if (!validate(shareInput, 'shlos')) return;
		setLoading(true);
		const params = { image_variation: [ICON_VARIATION] };
		fetch(shareInput, params)
			.then(os => {
				if (os.data.Ready !== 'Y') {
					setError(true);
					return;
				}

				if (os.data.Visible !== 'Y' && os.data.Public !== 'Y') {
					setError(true);
					return;
				}

				setValue(os.data);
				setError(false);
			})
			.catch(() => setError(true))
			.finally(() => setLoading(false));
	}, [shareInput, setLoading, fetch]);

	return (
		<Grid container spacing={spacing}>
			<Grid item xs={12}>
				<RichAlertTrans
					severity='warning'
					i18nKey='shell_os_tab_share_info'
				/>
			</Grid>
			<Grid item xs={12} md={value ? 8 : 12}>
				<TextField
					required
					disabled={disabled || loading}
					variant='outlined'
					label={t('shell_os_shared_label')}
					helperText={t('shell_os_shared_helperText')}
					placeholder={t('shell_os_shared_placeholder')}
					error={!validate(shareInput, 'shlos')}
					fullWidth
					value={shareInput}
					onChange={e => setShareInput(e.target.value)}
					InputProps={
						{
							endAdornment: (
								loading ?
									<InputAdornment position='end'>
										<CircularProgress thickness={1}/>
									</InputAdornment>
									: null
							),
						}
					}
				/>
			</Grid>
			{value &&
			<Grid item xs={12} md={4}>
				<OsItem
					shell={shell}
					setSelected={
						() => {
						}
					}
					loading={loading}
					disabled={disabled}
					os={loading ? null : value}
					setShowDetailsFor={setShowDetailsFor}
					selected={true}
				/>
			</Grid>
			}
			{(shareInput && error) &&
			<Grid item xs={12}>
				<RichAlertTrans
					severity='error'
					i18nKey='shell_os_tab_share_error'
				/>
			</Grid>
			}
		</Grid>
	);
};

export default InputSharedOS;

InputSharedOS.propTypes = {
	disabled: PropTypes.bool,
	setShowDetailsFor: PropTypes.func.isRequired,
	setValue: PropTypes.func.isRequired,
	shell: PropTypes.object.isRequired,
	spacing: PropTypes.number,
	value: PropTypes.object
};
