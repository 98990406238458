import PropTypes                      from 'prop-types';
import React, { useEffect, useState } from 'react';
import Grid                           from '@material-ui/core/Grid';
import YearSelector                   from '../../core/input/YearSelector';
import Button                         from '../../core/input/Button';
import { useTranslation }             from 'react-i18next';
import { useAffiliateExportOrder }    from '@karpeleslab/klb-react-services';
import { rest }                       from '@karpeleslab/klbfw';
import { useSetSnackbar }             from '../../../hooks/useSnackbar';

const OrdersExport = ({ affiliate }) => {
	const [year, setYear] = useState(new Date().getFullYear());
	const [generating, setGenerating] = useState(false);
	const [generate] = useAffiliateExportOrder(affiliate.Affiliate__);
	const [interval, setIntervalVar] = useState(null);
	const setSnackbar = useSetSnackbar();

	const { t } = useTranslation();

	const handleExport = () => {
		setGenerating(true);
		generate().then(j => {
			// This is dirty AF but we don't have realtime management stuff to handle the job so we pull it every 5 sec;
			// One day maybe once we will take this we will clean that
			const interval = setInterval(() => {
				rest(`Job/${j.Job__}`)
					.then(d => d.data)
					.then(j => {
						if (!j.Finished) {
							return;
						}
						clearInterval(interval);
						setIntervalVar(null);
						setGenerating(false);
						setSnackbar(t('affiliate_export_order_success'), 'success');
					});
			}, 5000);
		});
	};

	useEffect(() => {
		if (!interval) return;
		return () => clearInterval(interval);
	}, [interval]);

	return (
		<Grid container spacing={3}>
			<Grid item>
				<Button
					onClick={handleExport}
					color='primary'
					variant='contained'
					disabled={generating}
					loading={generating}
				>
					{t('affiliate_order_export_btn')}
				</Button>
			</Grid>
			<Grid item>
				<YearSelector
					size='small'
					setValue={setYear}
					value={year}
					interval={['2020']}
					disabled={generating}
				/>
			</Grid>

		</Grid>
	);
};

export default OrdersExport;

OrdersExport.propTypes = {
	affiliate: PropTypes.object.isRequired
};
