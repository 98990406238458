import React              from 'react';
import { useTranslation } from 'react-i18next';
import TextField          from '@material-ui/core/TextField';
import PropTypes          from 'prop-types';

const OSFamilySelector = ({ value, setValue, disabled = false, required = false, ...rest }) => {
	const { t } = useTranslation();

	return (
		<TextField
			label={t('custom_os_family_label')}
			helperText={t('custom_os_family_helperText')}
			value={value}
			onChange={e => setValue(e.target.value)}
			select
			fullWidth
			InputLabelProps={{ shrink: !!value }}
			disabled={disabled}
			required={required}
			variant='outlined'
			SelectProps={{
				native: true
			}}
			{...rest}
		>
			<option/>
			<option value='linux'>{t('custom_os_family_linux')}</option>
			<option value='windows'>{t('custom_os_family_windows')}</option>
			<option value='macos'>{t('custom_os_family_macos')}</option>
			<option value='android'>{t('custom_os_family_android')}</option>
		</TextField>
	);
};

export default OSFamilySelector;

OSFamilySelector.propTypes = {
	disabled: PropTypes.bool,
	required: PropTypes.bool,
	setValue: PropTypes.func.isRequired,
	value: PropTypes.string
};
