import PropTypes                         from 'prop-types';
import React                             from 'react';
import Dialog                            from '@material-ui/core/Dialog';
import DialogContentText                 from '@material-ui/core/DialogContentText';
import DialogContent                     from '@material-ui/core/DialogContent';
import { Trans, useTranslation }         from 'react-i18next';
import DialogTitle                       from '@material-ui/core/DialogTitle';
import DialogActions                     from '@material-ui/core/DialogActions';
import RichAlert                         from '../../../core/feedback/RichAlert';
import Button                            from '../../../core/input/Button';
import { useShellVolumeSnapshotRestore } from '@karpeleslab/klb-react-services';
import moment                            from 'moment';

const SnapshotRestoreDialog = ({ open, setOpen, snapshot, shell, setShell, refresh, refreshing }) => {
	const { t } = useTranslation();
	const [restore, restoring] = useShellVolumeSnapshotRestore(shell.Shell__);

	const handleClose = () => {
		setOpen(false);
	};

	const handleRestore = e => {
		e.preventDefault();
		restore(snapshot.Shell_Volume_Snapshot__)
			.then(shell => setShell(shell))
			.then(() => refresh())
			.then(handleClose);
	};

	return (
		<Dialog
			open={open}
			aria-labelledby={t('dialog_restore_snapshot_title')}
			aria-describedby={t('dialog_restore_snapshot_title')}
		>
			<DialogTitle id='restore-snapshot-title'>
				{t('dialog_restore_snapshot_title')}
			</DialogTitle>
			<DialogContent>
				<DialogContentText id='restore-snapshot-description'>
					<RichAlert severity='info'>
						<Trans
							i18nKey='dialog_restore_snapshot_description'
							values={{ date: snapshot ? moment(snapshot.Created).format('LLL') : null }}
						>
							sample
							<br/>
							<strong>sample</strong>
						</Trans>
					</RichAlert>
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button color='default' onClick={handleClose} disabled={restoring || refreshing}>
					{t('close_btn')}
				</Button>
				<Button
					onClick={handleRestore}
					color='primary'
					type='submit'
					variant='contained'
					disabled={restoring || refreshing}
					loading={restoring || refreshing}
				>
					{t('restore_btn')}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default SnapshotRestoreDialog;

SnapshotRestoreDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	refresh: PropTypes.func.isRequired,
	refreshing: PropTypes.bool.isRequired,
	setOpen: PropTypes.func.isRequired,
	setShell: PropTypes.func.isRequired,
	shell: PropTypes.object.isRequired,
	snapshot: PropTypes.object
};
