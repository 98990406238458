import React              from 'react';
import { useTranslation } from 'react-i18next';
import { Grid }           from '@material-ui/core';
import PageTitle          from '../../components/core/typography/PageTitle';
import OrdersTable        from '../../components/common/Order/OrdersTable';
import PageContainer      from '../../components/layout/Container/PageContainer';
import { Title }          from '../../components/core/typography/Title';
import Billing            from './Billing';

const PaymentInfo = () => {
	const { t } = useTranslation();

	return (
		<PageContainer>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<PageTitle title={t('billing_title')}/>
				</Grid>
				<Grid item xs={12}>
					<Billing/>
				</Grid>
				<Grid item xs={12}>
					<Title>{t('order_history_title')}</Title>
				</Grid>
				<Grid item xs={12}>
					<OrdersTable/>
				</Grid>
			</Grid>
		</PageContainer>
	);
};

export default PaymentInfo;
