import React              from 'react';
import { Grid }           from '@material-ui/core';
import PageTitle          from '../../components/core/typography/PageTitle';
import PageContainer      from '../../components/layout/Container/PageContainer';
import { useTranslation } from 'react-i18next';
import SolutionsForYou    from '../../components/common/Links/SolutionsForYou';
import SolutionsForThem   from '../../components/common/Links/SolutionsForThem';
import CommunityHelp      from '../../components/common/Links/CommunityHelp';
import Legal              from '../../components/common/Links/Legal';
import Navigation         from '../../components/common/Links/Navigation';
import About              from '../../components/common/Links/About';

const Links = () => {
	const { t } = useTranslation();

	return (
		<PageContainer>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<PageTitle title={t('links_title')}/>
				</Grid>
				<Grid item xs={12} md={6}>
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<SolutionsForYou/>
						</Grid>
						<Grid item xs={12}>
							<SolutionsForThem/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12} md={6}>
					<Grid container spacing={3}>
						<Grid item xs={12} md={6}>
							<About/>
						</Grid>
						<Grid item xs={12} md={6}>
							<CommunityHelp/>
						</Grid>

						<Grid item xs={12} md={6}>
							<Navigation/>
						</Grid>
						<Grid item xs={12} md={6}>
							<Legal/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12} style={{ textAlign: 'center' }}>
					{t('footer_copyright', { year: (new Date()).getFullYear() })}
				</Grid>
			</Grid>
		</PageContainer>
	);
};

export default Links;
