import PropTypes                    from 'prop-types';
import React                        from 'react';
import { SHELL_STOP_ENABLE_STATES } from '../../../../hooks/useShells';
import PowerSettingsNewIcon         from '@material-ui/icons/PowerSettingsNew';
import SettingsIcon                 from '@material-ui/icons/Settings';
import ReportIcon                   from '@material-ui/icons/Report';
import { isExpired, isShellValid }  from '../../../../utils/shell';
import { useTheme }                 from '@material-ui/core';
import makeStyles                   from '@material-ui/core/styles/makeStyles';
import Tooltip                      from '@material-ui/core/Tooltip';
import { useTranslation }           from 'react-i18next';


const useStyles = makeStyles(() => {
	return {
		osIcon: {
			'&:hover':
				{
					cursor: 'pointer',
					opacity: '0.7',
				}
		}
	};
});

const ShellStateIcon = ({ shell, size = 'medium' }) => {
	const classes = useStyles();
	const theme = useTheme();
	const { t } = useTranslation();
	let component = null;
	let title = '';

	if (!isShellValid(shell)) {
		component = <ReportIcon
			fontSize={size}
			style={{ color: theme.palette.warning.main }}
		/>;

		title = isExpired(shell) ? t('shell_expired_tooltip') : t('shell_time_allowance_tooltip');
	} else if (SHELL_STOP_ENABLE_STATES.includes(shell.State)) {
		component = <PowerSettingsNewIcon
			fontSize={size}
			color='primary'
			className={classes.osIcon}
		/>;
		title = t(`shell_status_${shell.State}`);
	} else if (shell.State !== 'preparing') {
		component = <PowerSettingsNewIcon
			color='secondary'
			fontSize={size}
			className={classes.osIcon}/>;
		title = t('shell_status_shutdown');
	} else {
		component = <SettingsIcon
			fontSize={size}
			color='primary'
		/>;
		title = t('shell_preparing_tooltip');

	}

	return (
		<Tooltip
			title={title}
			placement='down'
			enterDelay={1000}
		>
			{component}
		</Tooltip>);
};

export default ShellStateIcon;

ShellStateIcon.propTypes = {
	shell: PropTypes.object.isRequired,
	size: PropTypes.string
};
