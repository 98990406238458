import React, { useEffect } from 'react';
import { useCookies }       from 'react-cookie';

import CssBaseline                       from '@material-ui/core/CssBaseline';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import useMediaQuery                     from '@material-ui/core/useMediaQuery';

import PropTypes             from 'prop-types';
import lightBlue             from '@material-ui/core/colors/lightBlue';
import { getCookiesOptions } from '../../../utils/misc';

export default function MuiTheme({ children }) {
	const [cookies, setCookie] = useCookies(['theme']);
	let prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
	if (!cookies.theme)
		setCookie('theme', prefersDarkMode ? 'dark' : 'light', getCookiesOptions());

	// Update cookies expiration
	useEffect(() => {
		setCookie('theme', cookies.theme ?? 'light', getCookiesOptions());
	}, []);

	const theme = React.useMemo(
		() =>
			createMuiTheme({
				palette: {
					type: cookies.theme,
					primary: {
						light: '#72BA3E',
						main: '#72BA3E',
						dark: '#72BA3E'
					}
				},
				overrides: {
					MuiPickersModal: {
						dialogAction: {
							color: lightBlue['400'],
						}
					},
					MuiButton: {
						textPrimary: {
							color: cookies.theme === 'light' ? '#000' : '#fff',
						},
					},
					MuiBadge: {
						colorPrimary: {
							color: 'white',
						},
					},
					MuiChip: {
						colorPrimary: {
							color: 'white',
						},
					},
					MuiStepIcon: {
						text: {
							fill: 'white',
						},
					},
				},
			}),
		[cookies],
	);

	return (
		<ThemeProvider theme={theme}>
			<CssBaseline/>
			{children}
		</ThemeProvider>
	);
}

MuiTheme.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node
	]).isRequired
};
