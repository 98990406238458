import PropTypes                         from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import { useTranslation }                from 'react-i18next';
import { Grid }                          from '@material-ui/core';
import { Title }                         from '../../../core/typography/Title';
import OrderTable                        from './OrderTable';
import OrderPayments                     from './Payments/OrderPayments';
import OrderProcessingAlert              from './OrderProcessingAlert';
import RichAlertTrans                    from '../../../core/feedback/RichAlertTrans';

const OrderWithPayment = ({ order, refresh, onComplete, setDialogActions = null }) => {
	const { t } = useTranslation();

	const detectComplete = useCallback(newOrder => {
		if (order.Status !== newOrder.Status &&
			newOrder.Status === 'completed' &&
			onComplete) {
			onComplete(newOrder);
		}

		refresh(newOrder);
	}, [order]);

	useEffect(() => {
		// This will remove the pay action button once the order is paid
		if (!order.Paid || !setDialogActions) return;
		setDialogActions(null);
	}, [order, setDialogActions]);

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Title>
					{t('order_details')}
				</Title>
			</Grid>

			{(order.Paid && order.Status !== 'completed') &&
			<Grid item xs={12}>
				<OrderProcessingAlert refresh={detectComplete}/>
			</Grid>
			}

			{(order.Status === 'completed') &&
			<Grid item xs={12}>
				<RichAlertTrans i18nKey='order_completed'/>
			</Grid>
			}

			<Grid item xs={12}>
				<OrderTable order={order} elevation={0}/>
			</Grid>

			{(!order.Paid && order.Status !== 'completed') &&
			<Grid item xs={12}>
				<OrderPayments order={order} refresh={detectComplete} setDialogActions={setDialogActions}/>
			</Grid>
			}
		</Grid>
	);
};

export default OrderWithPayment;

OrderWithPayment.propTypes = {
	order: PropTypes.object.isRequired,
	refresh: PropTypes.func.isRequired,
	onComplete: PropTypes.func,
	setDialogActions: PropTypes.func // Used when the component is used in a dialog in order to integrate the action into the dialog actions
};
