import React                from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useTranslation }   from 'react-i18next';
import Box                  from '@material-ui/core/Box';
import Link                 from './Link';

const SolutionsForYou = () => {
	const { t } = useTranslation();
	return (
		<Grid container spacing={1} direction='column'>
			<Grid item xs={12}>
				<Box my={2}>
					<Typography variant='h4'>{t('footer_nav_why_shells_header_1')}</Typography>
				</Box>
			</Grid>
			<Grid item xs={12}>
				<Link href={`${process.env.REACT_APP_SHELL_URL}/DaaS`}>
					{t('footer_nav_solutions_for_you_t12')}
				</Link>
			</Grid>
			<Grid item xs={12}>
				<Link href={`${process.env.REACT_APP_SHELL_URL}/linux-cloud-desktop`}>
					{t('footer_nav_solutions_for_you_t13')}
				</Link>
			</Grid>
			<Grid item xs={12}>
				<Link href={`${process.env.REACT_APP_SHELL_URL}/browser-in-the-cloud`}>
					{t('footer_nav_solutions_for_you_t14')}
				</Link>
			</Grid>
			<Grid item xs={12}>
				<Link href={`${process.env.REACT_APP_SHELL_URL}/virtual-box`}>
					{t('footer_nav_solutions_for_you_t15')}
				</Link>
			</Grid>
			<Grid item xs={12}>
				<Link href={`${process.env.REACT_APP_SHELL_URL}/windows-subsystem`}>
					{t('footer_nav_solutions_for_you_t16')}
				</Link>
			</Grid>
			<Grid item xs={12}>
				<Link href={`${process.env.REACT_APP_SHELL_URL}/vmware`}>
					{t('footer_nav_solutions_for_you_t17')}
				</Link>
			</Grid>
			<Grid item xs={12}>
				<Link href={`${process.env.REACT_APP_SHELL_URL}/lp/vms`}>
					{t('footer_nav_solutions_for_you_t2')}
				</Link>
			</Grid>
			<Grid item xs={12}>
				<Link href={`${process.env.REACT_APP_SHELL_URL}/lp/ide`}>
					{t('footer_nav_solutions_for_you_t3')}
				</Link>
			</Grid>
			<Grid item xs={12}>
				<Link href={`${process.env.REACT_APP_SHELL_URL}/lp/grade`}>
					{t('footer_nav_solutions_for_you_t4')}
				</Link>
			</Grid>
			<Grid item xs={12}>
				<Link href={`${process.env.REACT_APP_SHELL_URL}/lp/cloud`}>
					{t('footer_nav_solutions_for_you_t8')}
				</Link>
			</Grid>
			<Grid item xs={12}>
				<Link href={`${process.env.REACT_APP_SHELL_URL}/lp/irc`}>
					{t('footer_nav_solutions_for_you_t10')}
				</Link>
			</Grid>
			<Grid item xs={12}>
				<Link href={`${process.env.REACT_APP_SHELL_URL}/cloudmusic`}>
					{t('footer_nav_solutions_for_you_t0')}
				</Link>
			</Grid>
		</Grid>
	);
};

export default SolutionsForYou;
