import PropTypes               from 'prop-types';
import React                   from 'react';
import { Grid }                from '@material-ui/core';
import Button                  from '../../../../core/input/Button';
import VisibilityIcon          from '@material-ui/icons/Visibility';
import { useTranslation }      from 'react-i18next';
import ShellOpenWrapper        from '../../ShellOpenWrapper';
import ActionMenu              from './ActionMenu';
import { isShellCanBeStarted } from '../../../../../utils/shell';

const ShellTableAction = ({ shell, setShell, refresh, justify = 'flex-end', light = false }) => {
	const { t } = useTranslation();


	return (
		<Grid container spacing={3} justify={justify} alignItems='center'>
			{!light && <Grid item>
				<ShellOpenWrapper
					setShell={setShell}
					shell={shell}
				>
					<Button
						variant='outlined'
						size='small'
						disabled={!isShellCanBeStarted(shell)}
						color='primary'
						startIcon={<VisibilityIcon/>}
					>
						{t('view_btn')}
					</Button>
				</ShellOpenWrapper>
			</Grid>}

			<Grid item>
				<ActionMenu refresh={refresh} shell={shell}/>
			</Grid>
		</Grid>
	);
};

export default ShellTableAction;

ShellTableAction.propTypes = {
	justify: PropTypes.string,
	light: PropTypes.bool,
	setShell: PropTypes.func.isRequired,
	refresh: PropTypes.func.isRequired,
	shell: PropTypes.object.isRequired
};
