import React              from 'react';
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import SetStatus from './../SetStatus/SetStatus';

export default function NotFound() {
	const { t } = useTranslation();
	return (
		<SetStatus statusCode={404}>
			<Grid container direction="column" align="center" justify="center">
				<Grid item>
					<h1>{t('error_content_not_found_title')}</h1>
				</Grid>
				<Grid item>
					<p>{t('error_content_not_found_description')}</p>
				</Grid>
			</Grid>
		</SetStatus>
	);
}
