import PropTypes           from 'prop-types';
import React, { useState } from 'react';
import { useTranslation }  from 'react-i18next';

import Dialog            from '@material-ui/core/Dialog';
import DialogContent     from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle       from '@material-ui/core/DialogTitle';
import Grid              from '@material-ui/core/Grid';
import Step              from '@material-ui/core/Step';
import StepLabel         from '@material-ui/core/StepLabel';
import Stepper           from '@material-ui/core/Stepper';
import Panel             from '../../../../layout/Container/tab/Panel';
import StepCreate        from './StepCreate';
import StepAssociate     from './StepAssociate';
import StepActivate      from './StepActivate';

export const TAB_CREATE = 0;
export const TAB_ASSOCIATE = 1;
export const TAB_ACTIVATE = 2;

const AddDialog = ({ open, setOpen, refresh }) => {
	const { t } = useTranslation();
	const [step, setStep] = useState(TAB_CREATE);
	const [otp, setOtp] = useState(null);
	const [loading, setLoading] = useState(false);

	const handleClose = () => setOpen(false);


	const init = () => {
		setStep(TAB_CREATE);
		setOtp(null);
	};

	return (
		<Dialog
			open={open}
			aria-labelledby={t('add_otp_title')}
			aria-describedby={t('link_service')}
			fullWidth
			maxWidth='lg'
			onEnter={() => {
				init();
			}}
		>
			<DialogTitle id='add-otp-title'>{t('add_otp_title')}</DialogTitle>
			<DialogContent>
				<DialogContentText id='add-otp-desc'>
					{t('add_otp_description')}
				</DialogContentText>
				<Grid container spacing={2} alignItems='baseline'>
					<Grid item xs={12}>
						<Stepper activeStep={step}>
							<Step disabled={loading}>
								<StepLabel>{t('step_otp_config')}</StepLabel>
							</Step>
							<Step disabled={loading}>
								<StepLabel>{t('step_otp_associate')}</StepLabel>
							</Step>
							<Step disabled={loading}>
								<StepLabel>{t('step_otp_activate')}</StepLabel>
							</Step>
						</Stepper>
					</Grid>
				</Grid>
			</DialogContent>
			<Panel index={step} value={TAB_CREATE}>
				<StepCreate handleClose={handleClose} setOtp={setOtp} setStep={setStep} setLoading={setLoading}/>
			</Panel>
			<Panel index={step} value={TAB_ASSOCIATE}>
				{otp && <StepAssociate
					otp={otp}
					refresh={refresh}
					handleClose={handleClose}
					setOtp={setOtp}
					setStep={setStep}
					setLoading={setLoading}
				/>}
			</Panel>
			<Panel index={step} value={TAB_ACTIVATE}>
				{otp && <StepActivate
					otp={otp}
					refresh={refresh}
					setOtp={setOtp}
					handleClose={handleClose}
					setLoading={setLoading}
				/>}
			</Panel>
		</Dialog>
	);
};

export default AddDialog;

AddDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	refresh: PropTypes.func.isRequired,
	setOpen: PropTypes.func.isRequired
};
