import React      from 'react';
import Typography from '@material-ui/core/Typography';
import Box        from '@material-ui/core/Box';
import PropTypes  from 'prop-types';

const Panel = ({ children, value, index, boxProps = { p: 3 }, ...other }) => {
	return (
		<Typography
			component='div'
			role='tabpanel'
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}
		>
			{value === index && <Box {...boxProps}>{children}</Box>}
		</Typography>
	);
};

export default Panel;

Panel.propTypes = {
	boxProps: PropTypes.object,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired
};
