import React, { useContext, useEffect, useState } from 'react';
import PageContainer                              from '../../components/layout/Container/PageContainer';
import Helmet                                 from 'react-helmet';
import { Grid }                               from '@material-ui/core';
import EmptyState                             from '../../components/core/feedback/State/Empty';
import { Trans, useTranslation }              from 'react-i18next';
import Graphic                                from '../../assets/img/modeGraphic.svg';
import { ModeContext }                        from '../../context/ModeContext';
import { useHistory, useLocation, useParams } from 'react-router';
import { getShellsRoute }                     from '../../components/RouteController/Factory';
import { useSetSnackbar }                     from '../../hooks/useSnackbar';
import { useCookies }                         from 'react-cookie';
import queryString                            from 'query-string';
import { getCookiesOptions }                  from '../../utils/misc';

const SwitchMode = () => {
	const [, setCookie] = useCookies(['mode']);
	const { mode } = useParams();
	const { t } = useTranslation();
	const [, setMode] = useContext(ModeContext);
	const history = useHistory();
	const setSnackbar = useSetSnackbar();
	const [returnTo, setReturnTo] = useState(getShellsRoute());
	const location = useLocation();


	useEffect(() => {
		const params = queryString.parse(location.search);
		if (!params.to) setReturnTo(getShellsRoute());
		else setReturnTo(decodeURIComponent(params.to));

	}, [location, setReturnTo]);
	return (
		<PageContainer>
			<Helmet>
				{ /* https://devhints.io/html-meta */}
				<title>{t('mode_switch_title', { mode: t(`${mode.toLowerCase()}_label`) })}</title>
			</Helmet>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<EmptyState
						title={t('mode_switch_title', { mode: t(`${mode.toLowerCase()}_label`) })}
						text={
							<Trans i18nKey={`switch_to_${mode.toLowerCase()}_desc`}>
								sample<br/>
								<strong>sample</strong>
								<ul>
									<li>sample</li>
								</ul>

							</Trans>
						}
						graphic={Graphic}
						action={{
							title: t('mode_change_btn', { mode: t(`${mode.toLowerCase()}_label`) }),
							onClick: e => {
								e.preventDefault();
								setCookie('mode', mode.toUpperCase(), getCookiesOptions());
								setMode(mode.toUpperCase());
								setSnackbar(t('switched_to_mode', { mode: t(`${mode.toLowerCase()}_label`) }), 'success');
								history.push(returnTo);
							},
						}}
					/>
				</Grid>
			</Grid>
		</PageContainer>
	);
};

export default SwitchMode;
