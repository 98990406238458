import PropTypes            from 'prop-types';
import React                from 'react';
import { Grid, Typography } from '@material-ui/core';
import Chip                 from '@material-ui/core/Chip';
import { useTranslation }   from 'react-i18next';
import { Title }            from '../../core/typography/Title';

const IsoInfo = ({ iso }) => {
	const { t } = useTranslation();

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Title>{t('iso_info_filename')}</Title>
			</Grid>
			<Grid item xs={12}>
				<Typography>
					{iso.Filename}
				</Typography>
			</Grid>
			<Grid item xs={12}>
				<Title>{t('iso_info_hash')}</Title>
			</Grid>
			<Grid item xs={12}>
				<Typography>
					{
						<Chip
							color={iso.Hash ? 'default' : 'primary'}
							variant='outlined'
							size='small'
							label={iso.Hash ? iso.Hash : t('iso_hash_pending')}
						/>
					}
				</Typography>
			</Grid>
		</Grid>
	);
};

export default IsoInfo;

IsoInfo.propTypes = {
	iso: PropTypes.object.isRequired
};
