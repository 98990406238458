import PropTypes                   from 'prop-types';
import React                       from 'react';
import makeStyles                  from '@material-ui/core/styles/makeStyles';
import ShellOpenWrapper            from '../ShellOpenWrapper';
import Flag                        from '../../../core/data/Flag/Flag';
import { useHistory }              from 'react-router';
import { getShellViewRoute }       from '../../../RouteController/Factory';
import { isShellCanBeStarted }     from '../../../../utils/shell';
import Grid                        from '@material-ui/core/Grid';
import defaultIcon                 from '../../../../assets/img/shells_icon_white.svg';
import ShellStateIcon              from './ShellStateIcon';
import { useTranslation }          from 'react-i18next';
import Tooltip                     from '@material-ui/core/Tooltip';
import { SHELL_OS_ICON_VARIATION } from '../../../../hooks/useShells';
import Img                         from '../../../core/data/Image/Img';


const useStyles = makeStyles(() => {
	return {
		osIcon: {
			'&:hover':
				{
					cursor: 'pointer',
					opacity: '0.7',
				}
		}
	};
});

const ShellPicture = ({ shell, setShell, flagSize = 'medium', disabled = false, stateSize = undefined, ...rest }) => {
	const classes = useStyles();
	const { t } = useTranslation();

	const history = useHistory();

	const getPicture = () => {
		if (!shell || !shell.OS || !shell.OS.Media_Image)
			return defaultIcon; // Todo, replace with an appropriate image

		if (SHELL_OS_ICON_VARIATION in shell.OS.Media_Image)
			return shell.OS.Media_Image[SHELL_OS_ICON_VARIATION];

		return shell.OS.Media_Image.Url;
	};

	const onOSSet = shell => {
		if (shell.State === 'preparing') {
			// We need to force to go the shell page to display the message
			history.push(getShellViewRoute(shell.Shell__));
		} else {
			window.open(`${process.env.REACT_APP_VIEW_SHELL_URL}/?id=${shell.Shell__}`);
		}
	};

	return (
		<ShellOpenWrapper
			callback={onOSSet}
			setShell={setShell}
			shell={shell}
			disabled={!isShellCanBeStarted(shell) || disabled}
		>
			<Grid container spacing={2}>
				<Grid item xs={10}>
					<Grid container direction='column' alignItems='center' spacing={2}>
						<Grid item>
							<Tooltip
								title={shell.OS ? shell.OS.Name : t('shell_no_os')}
								placement='down'
								enterDelay={1000}
							>
								<Img
									className={isShellCanBeStarted(shell) ? classes.osIcon : ''}
									src={getPicture()}
									title={shell.OS ? shell.OS.Name : t('shell_no_os')}
									alt={shell.OS ? shell.OS.Name : ''}
									{...rest}
								/>
							</Tooltip>
						</Grid>
					</Grid>

				</Grid>
				<Grid item xs={2}>
					<Grid
						container
						direction='column'
						alignItems='center'
						justify='space-between'
						style={{ height: '100%' }}
					>
						<Grid item>
							<Tooltip
								enterDelay={1000}
								title={`${shell.Shell_Datacenter.Location} - ${shell.Shell_Datacenter.Name}`}
								placement='down'
							>
								<Flag
									code={shell.Shell_Datacenter.Country__.toLowerCase()}
									size={flagSize}
									alt={`${shell.Shell_Datacenter.Location} - ${shell.Shell_Datacenter.Name}`}
								/>
							</Tooltip>
						</Grid>
						<Grid item>
							<ShellStateIcon shell={shell} size={stateSize ?? flagSize}/>
						</Grid>
					</Grid>

				</Grid>
			</Grid>
		</ShellOpenWrapper>
	);
};

export default ShellPicture;

ShellPicture.propTypes = {
	disabled: PropTypes.bool,
	flagSize: PropTypes.string,
	setShell: PropTypes.func,
	shell: PropTypes.object,
	stateSize: PropTypes.any
};
