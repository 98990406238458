import PropTypes                                   from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { Avatar, Grid }                            from '@material-ui/core';
import { useTranslation }                          from 'react-i18next';
import uuid                                        from '../../../utils/uuid';
import FormControl                                 from '@material-ui/core/FormControl';
import InputLabel                                  from '@material-ui/core/InputLabel';
import Input                                       from '@material-ui/core/Input';
import FormHelperText                              from '@material-ui/core/FormHelperText';
import Button                                      from './Button';
import defaultPicture                              from '../../../assets/img/shells_icon_white.svg';
import { useSetSnackbar }                          from '../../../hooks/useSnackbar';

const Picture = ({ value, setValue, defaultValue, label, disabled, pictureEditedChanged, square = false, required = false, reverseDisplay = false, helperText, minWidth = null, minHeight = null, previewStyle = {} }) => {
	const { t } = useTranslation();
	const setSnackbar = useSetSnackbar();
	const [pictureSrc, setPictureSrc] = useState(defaultPicture);
	const [pictureEdited, _setPictureEdited] = useState(false);
	const [pictureKey, setPictureKey] = useState(uuid());

	// eslint-disable-next-line no-undef
	const setPictureEdited = useCallback((v) => {
		_setPictureEdited(v);
		if (pictureEditedChanged)
			pictureEditedChanged(v);
	}, [_setPictureEdited, pictureEditedChanged]);

	// Update img src tag
	useEffect(() => {
		const isSame = value === defaultValue || (value && 'Media_Image__' in value && defaultValue && value.Media_Image__ === defaultValue.Media_Image__);

		if (value === null || 'Media_Image__' in value) {
			if (defaultValue && 'Url' in defaultValue)
				setPictureSrc(defaultValue.Url);
			else {
				if (!defaultValue && value && 'Url' in value) {
					setPictureSrc(value.Url);
				} else
					setPictureSrc(defaultPicture);
			}

			if (isSame) {
				setPictureEdited(false);
				setPictureKey(uuid());
			}

			return;
		}

		const fr = new FileReader();
		fr.onload = function () {
			const img = new Image();

			img.onload = function () {
				let error = null;
				if ((minWidth && minHeight) && (img.width < minWidth || img.height < minHeight)) {
					error = ['picture_chooser_invalid_size', { minHeight: minHeight, minWidth: minWidth }];
				} else if (minWidth && img.width < minWidth) {
					error = ['picture_chooser_invalid_size_width', { minWidth: minWidth }];
				} else if (minHeight && img.height < minHeight) {
					error = ['picture_chooser_invalid_size_height', { minHeight: minHeight }];
				} else if (square && img.width !== img.height) {
					error = ['picture_chooser_invalid_square'];
				}

				if (error) {
					setSnackbar(t(...error), 'error');
					onRevertPicture();
					return;
				}

				setPictureSrc(fr.result);
			};

			img.src = fr.result; // is the data URL because called with readAsDataURL

		};
		fr.readAsDataURL(value);

		// eslint-disable-next-line
	}, [value, defaultValue, setPictureEdited, minWidth, minHeight]);

	const onPictureChange = (e) => {
		if (e.target.files.length < 1) {
			setValue(defaultValue);
		} else {
			setValue(e.target.files[0]);
		}

		setPictureEdited(true);
	};

	const onRevertPicture = () => {
		setValue(defaultValue);
		setPictureEdited(false);
		setPictureKey(uuid());
	};

	return (
		<Grid container spacing={3} alignItems='center' direction={reverseDisplay ? 'row-reverse' : 'row'}>
			<Grid item xs={4}>
				<Avatar
					variant='square'
					src={pictureSrc}
					alt={label}
					style={{ width: '150px', height: '150px', ...previewStyle }}
				/>
			</Grid>
			<Grid item xs={8}>
				<FormControl fullWidth disabled={disabled}>
					<InputLabel shrink={true} htmlFor='picture-chooser'>{label}</InputLabel>
					<Input
						required={required}
						disabled={disabled}
						key={pictureKey}
						onChange={onPictureChange}
						inputProps={
							{
								accept: 'image/png, image/jpeg, image/jpg, image/svg'
							}
						}
						id='picture-chooser'
						type='file'
					/>
					{helperText && <FormHelperText>{helperText}</FormHelperText>}
				</FormControl>
				{pictureEdited &&
				<Button color='secondary' variant='text' onClick={onRevertPicture} disabled={disabled}>
					{t('cancel_edit_picture')}
				</Button>}
			</Grid>
		</Grid>
	);
};

export default Picture;

Picture.propTypes = {
	defaultValue: PropTypes.any,
	disabled: PropTypes.any,
	helperText: PropTypes.any,
	label: PropTypes.any,
	minHeight: PropTypes.number,
	minWidth: PropTypes.number,
	pictureEditedChanged: PropTypes.any,
	previewStyle: PropTypes.object,
	required: PropTypes.bool,
	reverseDisplay: PropTypes.bool,
	setValue: PropTypes.func.isRequired,
	square: PropTypes.bool,
	value: PropTypes.any
};
