import React, { useEffect } from 'react';
import { useTranslation }   from 'react-i18next';
import TextField            from '@material-ui/core/TextField';
import InputAdornment       from '@material-ui/core/InputAdornment';
import CircularProgress     from '@material-ui/core/CircularProgress';
import PropTypes            from 'prop-types';
import { useShellISOs }     from '@karpeleslab/klb-react-services';

const ShellISOSelector = ({ value, setValue, label = null, allowEmpty = false, helperText = null, disabled = false, required = false, ...rest }) => {
	const { t } = useTranslation();
	const [isos, fetch, loading] = useShellISOs();

	useEffect(() => {
		fetch({ Status: 'valid' }, { results_per_page: 100 });
	}, [fetch]);

	return (
		<TextField
			label={label ?? t('shell_iso_selector_label')}
			helperText={helperText ?? t('shell_iso_selector_helperText')}
			value={value}
			onChange={e => setValue(e.target.value)}
			select
			fullWidth
			InputLabelProps={{ shrink: !!value }}
			disabled={disabled || !isos}
			required={required}
			variant='outlined'
			InputProps={loading ?
				{
					endAdornment:
						(<InputAdornment position='end'>
							<CircularProgress size={30} thickness={1}/>
						</InputAdornment>)
				} : undefined
			}
			SelectProps={{
				native: true
			}}
			{...rest}
		>
			{allowEmpty &&
			<option value='none'>

			</option>
			}
			{isos && isos.data.map(
				d => <option
					key={d.Shell_ISO__}
					value={d.Shell_ISO__}
				>
					{d.Filename}
				</option>
			)}
		</TextField>
	);
};

export default ShellISOSelector;

ShellISOSelector.propTypes = {
	allowEmpty: PropTypes.bool,
	disabled: PropTypes.bool,
	helperText: PropTypes.string,
	label: PropTypes.string,
	required: PropTypes.bool,
	setValue: PropTypes.func.isRequired,
	value: PropTypes.string.isRequired
};
