import React                   from 'react';
import { Grid }                from '@material-ui/core';
import { useTranslation }      from 'react-i18next';
import PageContainer           from '../../components/layout/Container/PageContainer';
import Toolbar                 from '../../components/core/typography/Toolbar';
import { useParams }           from 'react-router';
import PageLoader              from '../../components/layout/Loader/PageLoader';
import UpdateForm              from '../../components/common/Keychain/UpdateForm';
import KeyTable                from '../../components/common/Keychain/Key/KeyTable';
import PageNotFound            from '../System/PageNotFound';
import { getSSHKeychainRoute } from '../../components/RouteController/Factory';
import { useKeychain }         from '@karpeleslab/klb-react-services';

const KeychainEdit = () => {
	const { t } = useTranslation();
	const { keychainId } = useParams();
	const [keychain, refresh] = useKeychain(keychainId);

	if (!keychain) return <PageLoader/>;
	if (!keychain.data) return <PageNotFound/>;

	return (
		<PageContainer>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Toolbar
						title={t('keychain', { name: keychain.data.Label })}
						pageTitle
						pageTitleProps={{
							backButton: getSSHKeychainRoute()
						}}
					/>
				</Grid>

				<Grid item xs={12}>
					<UpdateForm keychain={keychain.data} setKeychain={refresh}/>
				</Grid>

				<Grid item xs={12}>
					<KeyTable keychain={keychain.data}/>
				</Grid>
			</Grid>
		</PageContainer>
	);
};

export default KeychainEdit;
