import React                     from 'react';
import PropTypes                 from 'prop-types';
import { useFirstPaymentMethod } from '../../hooks/useUserBilling';
import NotFound                  from '../../components/core/feedback/State/NotFound';
import { Grid }                  from '@material-ui/core';
import UpdateLocationForm        from '../../components/common/User/Billing/UpdateLocationForm';
import UpdatePaymentMethodForm   from '../../components/common/User/Billing/UpdatePaymentMethodForm';
import { useUserLocation }       from '@karpeleslab/klb-react-services';
import Loader                    from '../../components/layout/Loader/Loader';

const UpdateBillingWrapper = ({ billing, setBilling }) => {
	const [location, refreshLocation] = useUserLocation(billing.User_Location__);
	const [method, refreshMethod] = useFirstPaymentMethod(billing.User_Billing__);

	if (!location || !method)
		return <Loader/>;

	if (!method.data || !location.data)
		return <NotFound/>;

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<UpdateLocationForm
					setBilling={setBilling}
					billing={billing}
					location={location.data}
					setLocation={refreshLocation}
				/>
			</Grid>

			<Grid item xs={12}>
				<UpdatePaymentMethodForm
					location={location.data}
					method={method.data}
					setMethod={refreshMethod}
				/>
			</Grid>
		</Grid>
	);
};

export default UpdateBillingWrapper;

UpdateBillingWrapper.propTypes = {
	billing: PropTypes.object.isRequired,
	setBilling: PropTypes.func.isRequired
};
