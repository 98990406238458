import PropTypes from 'prop-types';
import React     from 'react';
import Grid      from '@material-ui/core/Grid';

const TextWithIcon = ({ startIcon, endIcon, text }) => {
	return (
		<Grid container direction="row" alignItems="center" spacing={2}>
			{startIcon && <Grid item>
				{startIcon}
			</Grid>}
			<Grid item>
				{text}
			</Grid>
			{endIcon && <Grid item>
				{endIcon}
			</Grid>}
		</Grid>
	);
};

export default TextWithIcon;

TextWithIcon.propTypes = {
	endIcon: PropTypes.element,
	startIcon: PropTypes.element,
	text: PropTypes.string.isRequired
};
