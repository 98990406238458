import React, { useState }    from 'react';
import { useTranslation }     from 'react-i18next';
import { useParams }          from 'react-router';
import PageLoader             from '../../components/layout/Loader/PageLoader';
import PageNotFound           from '../System/PageNotFound';
import PageContainer          from '../../components/layout/Container/PageContainer';
import { Grid }               from '@material-ui/core';
import Toolbar                from '../../components/core/typography/Toolbar';
import { getCustomOsRoute }   from '../../components/RouteController/Factory';
import OperatingSystemShare   from '../../components/common/OperatingSystem/OperatingSystemShare';
import EditForm               from '../../components/common/OperatingSystem/EditForm';
import OSImageTable           from '../../components/common/OperatingSystem/Image/OSImageTable';
import { useOperatingSystem } from '@karpeleslab/klb-react-services';
import Button                 from '../../components/core/input/Button';
import DeleteDialog           from '../../components/common/OperatingSystem/DeleteDialog';

const CustomOsEdit = () => {
	const { t } = useTranslation();
	const { osId } = useParams();
	const [os, refresh] = useOperatingSystem(osId);
	const [showDelete, setShowDelete] = useState(false);

	if (!os) return <PageLoader/>;
	if (!os.data) return <PageNotFound/>;

	return (
		<PageContainer>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Toolbar
						title={t('os_title', { name: os.data.Name })}
						pageTitle
						pageTitleProps={{
							backButton: getCustomOsRoute()
						}}
					/>
				</Grid>

				<Grid item xs={12}>
					<OperatingSystemShare os={os.data}/>
				</Grid>

				<Grid item xs={12}>
					<EditForm
						refresh={refresh}
						os={os.data}
					/>
				</Grid>

				<Grid item xs={12}>
					<OSImageTable os={os.data}/>
				</Grid>
				{os.data.Is_Deletable &&
				<Grid item xs={12}>
					<Button
						color='secondary'
						variant='contained'
						onClick={() => setShowDelete(true)}
					>
						{t('delete_btn')}
					</Button>
				</Grid>
				}
			</Grid>
			{os.data.Is_Deletable && <DeleteDialog setOpen={setShowDelete} open={showDelete} os={os.data}/>}
		</PageContainer>
	);
};

export default CustomOsEdit;
