import PropTypes          from 'prop-types';
import React              from 'react';
import { TableBody }      from '@material-ui/core';
import Table              from '@material-ui/core/Table';
import TableContainer     from '@material-ui/core/TableContainer';
import { useTranslation } from 'react-i18next';
import TableRow           from '@material-ui/core/TableRow';
import TableCell          from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import { Title } from '../../../../core/typography/Title';

const CartSubTotals = ({ subtotals }) => {
	const { t } = useTranslation();

	return (
		<TableContainer>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell align='center' colSpan={2}>
							<Title>
								{t('cart_sub_totals')}
							</Title>
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>

					<TableRow>
						<TableCell>{t('cart_sub_total_cart')}</TableCell>
						<TableCell align='right'>{subtotals.regular.display}</TableCell>
					</TableRow>

					<TableRow>
						<TableCell>{t('cart_sub_total_shipping')}</TableCell>
						<TableCell align='right'>{subtotals.shipping.display}</TableCell>
					</TableRow>

					<TableRow>
						<TableCell>{t('cart_sub_total_discount')}</TableCell>
						<TableCell align='right'>{subtotals.discount.display}</TableCell>
					</TableRow>

					<TableRow>
						<TableCell>{t('cart_sub_total_other_tax')}</TableCell>
						<TableCell align='right'>{subtotals.tax.display}</TableCell>
					</TableRow>
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default CartSubTotals;

CartSubTotals.propTypes = {
	subtotals: PropTypes.object.isRequired
};
