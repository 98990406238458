import PropTypes                      from 'prop-types';
import React, { useEffect, useState } from 'react';
import Panel                          from '../../../../layout/Container/tab/Panel';
import Bitpay                         from './Methods/Bitpay';
import Free                           from './Methods/Free';
import OnFile                         from './Methods/OnFile';
import Wallet                         from './Methods/Wallet';
import Stripe                         from './Methods/Stripe';
import Paypal                         from './Methods/Paypal';

const PaymentMethodContent = ({ order, selectedPaymentMethod, method, methodData, setPaying, refreshOrder, setDialogActions = null }) => {
	const [component, setComponent] = useState(null);

	useEffect(() => {
		switch (method) {
			case 'Bitpay':
				setComponent(
					<Bitpay
						order={order}
						setPaying={setPaying}
						refreshOrder={refreshOrder}
						settings={methodData}
						setDialogActions={setDialogActions}
					/>);
				break;
			case 'Free':
				setComponent(
					<Free
						order={order}
						setPaying={setPaying}
						refreshOrder={refreshOrder}
						settings={methodData}
						setDialogActions={setDialogActions}
					/>
				);
				break;
			case 'OnFile':
				setComponent(
					<OnFile
						order={order}
						setPaying={setPaying}
						refreshOrder={refreshOrder}
						settings={methodData}
						setDialogActions={setDialogActions}
					/>
				);
				break;
			case 'Wallet':
				setComponent(
					<Wallet
						order={order}
						setPaying={setPaying}
						refreshOrder={refreshOrder}
						settings={methodData}
						setDialogActions={setDialogActions}
					/>);
				break;
			case 'Stripe':
				setComponent(
					<Stripe
						order={order}
						setPaying={setPaying}
						refreshOrder={refreshOrder}
						settings={methodData}
						setDialogActions={setDialogActions}
					/>
				);
				break;
			case 'Paypal':
				setComponent(
					<Paypal
						order={order}
						setPaying={setPaying}
						refreshOrder={refreshOrder}
						settings={methodData}
						setDialogActions={setDialogActions}
					/>
				);
				break;
			default:
				return <></>;
		}

	}, [setComponent, methodData]);

	if (!component) return <></>;

	return (
		<Panel index={method} value={selectedPaymentMethod} boxProps={{ p: 0 }}>
			{component}
		</Panel>
	);
};

export default PaymentMethodContent;

PaymentMethodContent.propTypes = {
	method: PropTypes.string.isRequired,
	methodData: PropTypes.object.isRequired,
	order: PropTypes.object.isRequired,
	selectedPaymentMethod: PropTypes.string.isRequired,
	setPaying: PropTypes.func.isRequired,
	refreshOrder: PropTypes.func.isRequired,
	setDialogActions: PropTypes.func // Used when the component is used in a dialog in order to integrate the action into the dialog actions
};
