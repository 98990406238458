import React, { useState } from 'react';
import { useTranslation }  from 'react-i18next';

import AddDialog from './AddDialog';
import AddIcon   from '@material-ui/icons/Add';
import Alert     from '@material-ui/lab/Alert';
import Grid      from '@material-ui/core/Grid';
import Table     from './table/Table';
import Toolbar   from './../../../core/typography/Toolbar';
import Button    from '../../../core/input/Button';

const OAuth = () => {
	const { t } = useTranslation();
	const [addDialogOpened, setAddDialogOpened] = useState(false);

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Toolbar title={t('profile_oauth')} disableGutters={true}>
					<Button
						endIcon={<AddIcon/>}
						variant='contained'
						color='primary'
						onClick={() => setAddDialogOpened(true)}
					>
						{t('add_btn')}
					</Button>
				</Toolbar>
			</Grid>
			<Grid item xs={12}>
				<Alert severity='info'>
					{t('profile_oauth_desc')}
				</Alert>
			</Grid>
			<Grid item xs={12}>
				<Table/>
			</Grid>
			<AddDialog open={addDialogOpened} setOpen={setAddDialogOpened}/>
		</Grid>
	);
};

export default OAuth; 
