import React               from 'react';
import { Grid }            from '@material-ui/core';
import Toolbar             from '../../components/core/typography/Toolbar';
import PageContainer       from '../../components/layout/Container/PageContainer';
import { useTranslation }  from 'react-i18next';
import { useParams }       from 'react-router';
import PageLoader          from '../../components/layout/Loader/PageLoader';
import OrderInfo           from '../../components/common/Order/order/OrderInfo';
import OrderPaymentInfo    from '../../components/common/Order/order/OrderPaymentInfo';
import UserLocation        from '../../components/common/User/Location/UserLocation';
import PageNotFound        from '../System/PageNotFound';
import { useOrder }        from '@karpeleslab/klb-react-services';
import { getBillingRoute } from '../../components/RouteController/Factory';
import OrderWithPayment    from '../../components/common/Order/order/OrderWithPayment';


const ViewOrder = () => {
	const { t } = useTranslation();
	const { orderId } = useParams();
	const [order, refresh] = useOrder(orderId);

	if (!order) return <PageLoader/>;
	if (!order.data) return <PageNotFound/>;

	return (
		<PageContainer>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Toolbar
						title={t('order_title', { number: order.data.Invoice_Number })}
						pageTitle
						pageTitleProps={{
							backButton: getBillingRoute()
						}}
					/>
				</Grid>

				<Grid item lg={3} md={6} xs={12}>
					<OrderInfo order={order.data}/>
				</Grid>

				<Grid item lg={3} md={6} xs={12}>
					<UserLocation title={t('order_billing_address')} address={order.data.Billing_User_Location}/>
				</Grid>

				<Grid item lg={3} md={6} xs={12}>
					<UserLocation title={t('order_shipping_address')} address={order.data.Shipping_User_Location}/>
				</Grid>

				<Grid item lg={3} md={6} xs={12}>
					<OrderPaymentInfo order={order.data}/>
				</Grid>

				<Grid item xs={12}>
					<OrderWithPayment
						order={order.data}
						refresh={refresh}
					/>
				</Grid>
			</Grid>
		</PageContainer>
	);
};

export default ViewOrder;
