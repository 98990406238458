import PropTypes          from 'prop-types';
import React              from 'react';
import Alert              from '@material-ui/lab/Alert';
import AlertTitle         from '@material-ui/lab/AlertTitle';
import { useTranslation } from 'react-i18next';

const TextWarning = ({ warning }) => {
	const { t } = useTranslation();
	return (
		<Alert severity='info'>
			<AlertTitle>{t('warning_global_title')}</AlertTitle>
			<div dangerouslySetInnerHTML={{ __html: warning.Data }}/>
		</Alert>
	);
};

export default TextWarning;

TextWarning.propTypes = {
	warning: PropTypes.object.isRequired
};
