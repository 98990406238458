import PropTypes                   from 'prop-types';
import React, { useState }         from 'react';
import { Grid }                    from '@material-ui/core';
import SourceTabs, { OS_OFFICIAL } from './SourceTabs';
import SourceDisplay               from './SourceDisplay';
import useMediaQuery               from '@material-ui/core/useMediaQuery';
import OSDetailsDialog             from './OSDetailsDialog';


const OSSelector = ({ shell, value, setValue, disabled = false, spacing = 3 }) => {
	const [tab, setTab] = useState(OS_OFFICIAL);
	const isMobile = useMediaQuery(theme => theme.breakpoints.down('xs'), { noSsr: true });
	const [showDetailsFor, setShowDetailsFor] = useState(null);

	return (
		<Grid container spacing={spacing}>
			<Grid item xs={12}>
				<SourceTabs
					setValue={setValue}
					setTab={setTab}
					tab={tab}
					disabled={disabled}
				/>
			</Grid>
			<Grid item xs={12}>
				<SourceDisplay
					shell={shell}
					setShowDetailsFor={setShowDetailsFor}
					mobile={isMobile}
					disabled={disabled}
					value={value}
					setValue={setValue}
					tab={tab}
					spacing={spacing}
				/>
			</Grid>
			{showDetailsFor &&
			<OSDetailsDialog shell={shell} setShowDetailsFor={setShowDetailsFor} os={showDetailsFor}/>
			}
		</Grid>
	);
};

export default OSSelector;

OSSelector.propTypes = {
	disabled: PropTypes.bool,
	setValue: PropTypes.func.isRequired,
	shell: PropTypes.object.isRequired,
	spacing: PropTypes.number,
	value: PropTypes.any
};
