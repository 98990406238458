import React         from 'react';
import PageContainer from '../Container/PageContainer';
import Loader        from './Loader';
import PropTypes     from 'prop-types';

const PageLoader = ({ message = '' }) => {
	return (
		<PageContainer>
			<Loader message={message}/>
		</PageContainer>
	);
};

export default PageLoader;

PageLoader.propTypes = {
	message: PropTypes.string
};
