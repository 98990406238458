import PropTypes          from 'prop-types';
import React              from 'react';
import { useTranslation } from 'react-i18next';
import Typography         from '@material-ui/core/Typography';
import { Grid }           from '@material-ui/core';

const AffiliatePayoutDetailsPaypal = ({ bankAccount }) => {
	const { t } = useTranslation();
	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Typography color='textSecondary' gutterBottom>
					{t('partnership_transfer_paypal_title')}
				</Typography>
				<Typography variant='h5' component='h2'>
					{bankAccount.Meta.email}
				</Typography>
			</Grid>
		</Grid>
	);
};

export default AffiliatePayoutDetailsPaypal;

AffiliatePayoutDetailsPaypal.propTypes = {
	bankAccount: PropTypes.object.isRequired
};
