import PropTypes                                                      from 'prop-types';
import React, { useEffect }                                           from 'react';
import { useCatalogCart, useCatalogCartProcess, useCatalogCartReset } from '@karpeleslab/klb-react-services';
import Loader                                                         from '../../layout/Loader/Loader';
import { Grid }                                                       from '@material-ui/core';
import EmptyCart                                                      from './Cart/EmptyCart';
import Table                                                          from '@material-ui/core/Table';
import TableBody                                                      from '@material-ui/core/TableBody';
import TableContainer                                                 from '@material-ui/core/TableContainer';
import { useTranslation }                                             from 'react-i18next';
import CartHeader                                                     from './Cart/CartHeader';
import CartItems                                                      from './Cart/CartItems';
import CartPrices                                                     from './Cart/CartPrices';
import CartAddCoupon                                                  from './Cart/CartAddCoupon';
import { Title }                                                      from '../../core/typography/Title';
import CreateOrder                                                    from './Cart/CreateOrder';

const Cart = ({ request = null, setOrder, setCreating, setDialogActions  = null }) => {
	const [cart, refresh, loadingCart] = useCatalogCart();
	const [processQuery, processingCart] = useCatalogCartProcess();
	const [resetCart, resettingCart] = useCatalogCartReset();
	const { t } = useTranslation();

	useEffect(() => {
		if (!request) return;

		resetCart()
			.then(() => processQuery(request, { snackMessageToken: null }))
			.then(newCart => refresh(newCart));

	}, [request, refresh, resetCart]);


	if (!cart || loadingCart || processingCart || resettingCart) {
		return <Loader/>;
	}

	if (cart.data.products.length < 1) {
		return <EmptyCart/>;
	}

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Title>
					{t('cart_title')}
				</Title>
			</Grid>
			<Grid item xs={12}>
				<TableContainer>
					<Table>
						<CartHeader/>
						<TableBody><CartItems items={cart.data.products} refreshCart={refresh}/></TableBody>
					</Table>
				</TableContainer>
			</Grid>

			<Grid item xs={12}>
				<CartAddCoupon refresh={refresh}/>
			</Grid>

			<Grid item xs={12}>
				<CartPrices cart={cart.data}/>
			</Grid>
			{setOrder &&
			<Grid item xs={12}>
				<CreateOrder
					setCreating={setCreating}
					setOrder={setOrder}
					setDialogActions={setDialogActions}
				/>
			</Grid>
			}
		</Grid>
	);
};

export default Cart;

Cart.propTypes = {
	request: PropTypes.string,
	setCreating: PropTypes.func,
	setOrder: PropTypes.func,
	setDialogActions: PropTypes.func // Used when the component is used in a dialog in order to integrate the action into the dialog actions
};
