import React              from 'react';
import { useTranslation } from 'react-i18next';
import EmptyState         from './Empty';
import Graphic            from './../../../../assets/img/404.svg';

const NotFound = () => {
	const { t } = useTranslation();
	return (
		<EmptyState
			title={t('page_notfound_title')}
			textI18Key='page_notfound_text'
			graphic={Graphic}
		/>
	);
};

export default NotFound;
