import PropTypes          from 'prop-types';
import React              from 'react';
import Grid               from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import DateUtil           from '../../../core/data/Date/DateUtil';
import RestoreIcon        from '@material-ui/icons/Restore';
import Button             from '../../../core/input/Button';
import moment             from 'moment';

const SnapshotTableMobileRow = ({ snapshot, onRestore }) => {
	const { t } = useTranslation();

	return (
		<Grid container spacing={1}>
			<Grid item xs={6}>{snapshot.Snapshot_Id}</Grid>
			<Grid item xs={6}>{t(`shell_snapshot_type_${snapshot.Type}`)}</Grid>
			<Grid item xs={6}>{t('tablecell_snapshot_date')}</Grid>
			<Grid item xs={6}>
				<Grid container direction='column'>
					<Grid item xs={12}>
						<DateUtil klbDateObj={snapshot.Created} format='LL'/>
					</Grid>
					{snapshot.Created && moment(parseInt(snapshot.Created.unixms)).fromNow()}
				</Grid>
			</Grid>
			<Grid item xs={6}>{t('tablecell_snapshot_os')}</Grid>
			<Grid item xs={6}>{snapshot.Shell_Volume.Shell_OS.Name}</Grid>
			<Grid item xs={6}>{t('tablecell_snapshot_datacenter')}</Grid>
			<Grid item xs={6}>{
				snapshot.Shell_Volume.Shell_Datacenter ? `${snapshot.Shell_Volume.Shell_Datacenter.Location} - ${snapshot.Shell_Volume.Shell_Datacenter.Name}` : ''
			}
			</Grid>
			<Grid item xs={12}>
				<Button
					size="small"
					variant="contained"
					color="primary"
					onClick={() => onRestore(snapshot)}
					startIcon={<RestoreIcon/>}
				>
					{t('restore_btn')}
				</Button>
			</Grid>
		</Grid>
	);
};

export default SnapshotTableMobileRow;

SnapshotTableMobileRow.propTypes = {
	onRestore: PropTypes.func.isRequired,
	snapshot: PropTypes.object.isRequired
};
