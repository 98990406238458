import PropTypes               from 'prop-types';
import React                   from 'react';
import DialogTitle             from '@material-ui/core/DialogTitle';
import DialogContent           from '@material-ui/core/DialogContent';
import DialogContentText       from '@material-ui/core/DialogContentText';
import Dialog                  from '@material-ui/core/Dialog';
import { useTranslation }      from 'react-i18next';
import RichAlertTrans          from '../../../../core/feedback/RichAlertTrans';
import { useFirstUserBilling } from '../../../../../hooks/useUserBilling';
import Loader                  from '../../../../layout/Loader/Loader';
import AutoRenewCreate         from './AutoRenewCreate';
import { isExpired }           from '../../../../../utils/shell';
import AutoRenewSet            from './AutoRenewSet';

const ShellSetAutoRenewDialog = ({ open, setOpen, shell, setShell }) => {
	const { t } = useTranslation();
	const [billing, refresh] = useFirstUserBilling('@');

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<Dialog
			fullWidth
			maxWidth='lg'
			open={open}
			aria-labelledby={t('activate_auto_renew_title')}
			aria-describedby={t('activate_auto_renew_title')}
			onEnter={() => {
				refresh();
			}}
		>
			<DialogTitle id='activate-auto-renew-title'>
				{t('activate_auto_renew_title')}
			</DialogTitle>
			<DialogContent>
				<DialogContentText id='activate-auto-renew-desc'>
					<RichAlertTrans i18nKey='activate_auto_renew_desc' severity='info'/>
				</DialogContentText>

				{(!!billing && isExpired(shell)) &&
				<DialogContentText id='activate-auto-renew-warning-desc'>
					<RichAlertTrans i18nKey='activate_auto_renew_expire_warning' severity='warning'/>
				</DialogContentText>
				}

			</DialogContent>
			{!billing && <Loader/>}
			{(billing && !!billing.data) && <AutoRenewSet
				handleClose={handleClose}
				setShell={setShell}
				shell={shell}
				billing={billing.data}
			/>}
			{(billing && !billing.data) && <AutoRenewCreate
				shell={shell}
				setShell={setShell}
				handleClose={handleClose}
			/>}
		</Dialog>
	);
};

export default ShellSetAutoRenewDialog;

ShellSetAutoRenewDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	setOpen: PropTypes.func.isRequired,
	setShell: PropTypes.func.isRequired,
	shell: PropTypes.object.isRequired
};
