import PropTypes                         from 'prop-types';
import React, { cloneElement, useState } from 'react';
import ShellSetInitialOSDialog           from './ShellSetInitialOSDialog';

const ShellOpenWrapper = ({ shell, setShell, children, disabled = false, callback }) => {
	const [showSelectOs, setShowSelectOs] = useState(false);

	const openShell = s => {
		if (callback) callback(s);
		else window.open(`${process.env.REACT_APP_VIEW_SHELL_URL}/?id=${shell.Shell__}`);
	};

	const onClickHandler = () => {
		if (disabled) return;
		if (shell.Shell_OS__)
			openShell(shell);
		else
			setShowSelectOs(true);

	};
	return (
		<>
			{cloneElement(children, { onClick: onClickHandler })}
			{shell && <ShellSetInitialOSDialog
				onComplete={openShell}
				setOpen={setShowSelectOs}
				setShell={setShell}
				shell={shell}
				open={showSelectOs}
			/>}
		</>
	);
};

export default ShellOpenWrapper;

ShellOpenWrapper.propTypes = {
	callback: PropTypes.func,
	children: PropTypes.node.isRequired,
	disabled: PropTypes.bool,
	setShell: PropTypes.func.isRequired,
	shell: PropTypes.object.isRequired
};
