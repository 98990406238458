import { useContext, useRef } from 'react';
import { UserContext }        from '../../../context/UserContext';
import { MenuContext }        from '../../../context/MenuContext';
import template               from './Drawer.template';
import { useUserLogout }      from '@karpeleslab/klb-react-services';

const Drawer = () => {
	const [menu, setMenu] = useContext(MenuContext);
	const [, setUser] = useContext(UserContext);
	const wrapperRef = useRef(null);
	const [logout] = useUserLogout();

	const logOut = async () => {
		const res = logout();
		setUser({
			loggedIn: false,
			user: {}
		});
		return res;
	};

	const onOpen = open => setMenu(m => ({ ...m, open }));

	return template(logOut, menu.open, menu.route, onOpen, wrapperRef);
};

export default Drawer;
