import PropTypes                      from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Grid }                       from '@material-ui/core';
import TextField                      from '@material-ui/core/TextField';
import Button                         from '../../core/input/Button';
import { useTranslation }             from 'react-i18next';
import ShellCancelDialog              from './Subscription/ShellCancelDialog';
import { useShellUpdate }             from '@karpeleslab/klb-react-services';
import ShellSetAutoRenewDialog        from './Subscription/AutoRenewDialog/ShellSetAutoRenewlDialog';
import useMediaQuery                  from '@material-ui/core/useMediaQuery';

const ShellUpdateForm = ({ shell, refresh }) => {
	const { t } = useTranslation();

	const [label, setLabel] = useState(shell.Label);
	const [saveEnabled, setSaveEnabled] = useState(false);
	const [showCancel, setShowCancel] = useState(false);
	const [showAutoRenew, setShowAutoRenew] = useState(false);

	const [update, updating] = useShellUpdate(shell.Shell__);

	const isMobile = useMediaQuery(theme => theme.breakpoints.down('xs'));

	useEffect(() => {
		setLabel(shell.Label);
	}, [setLabel, shell]);

	useEffect(() => {
		setSaveEnabled(label.trim() && label.trim() !== shell.Label);
	}, [shell, label, setSaveEnabled]);

	const saveHandler = () => {
		update({ Label: label.trim() })
			.then(d => refresh(d));
	};

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<TextField
					variant='outlined'
					label={t('shell_label')}
					helperText={t('shell_label_helperText')}
					disabled={updating}
					required
					fullWidth
					value={label}
					onChange={e => setLabel(e.target.value)}
				/>
			</Grid>

			<Grid item xs={12}>
				<Grid
					container
					direction={isMobile ? 'column-reverse' : 'row'}
					justify={isMobile ? 'center' : 'space-between'}
					spacing={3}
				>
					{shell.User_Billing__ &&
					<Grid item xs={isMobile ? 12 : undefined}>
						<Button
							fullWidth={isMobile}
							color='secondary'
							variant='outlined'
							onClick={() => setShowCancel(true)}
							loading={updating}
							disabled={updating}
						>
							{t('cancel_subscription_btn')}
						</Button>
					</Grid>
					}
					{!shell.User_Billing__ &&
					<Grid item xs={isMobile ? 12 : undefined}>
						<Button
							fullWidth={isMobile}
							color='primary'
							variant='contained'
							onClick={() => setShowAutoRenew(true)}
							loading={updating}
							disabled={updating}
						>
							{t('auto_renew_subscription_btn')}
						</Button>
					</Grid>
					}
					<Grid item xs={isMobile ? 12 : undefined}>
						<Button
							fullWidth={isMobile}
							color='primary'
							variant='contained'
							onClick={saveHandler}
							loading={updating}
							disabled={!saveEnabled || updating}
						>
							{t('save_btn')}
						</Button>
					</Grid>
				</Grid>
			</Grid>
			<ShellCancelDialog
				setOpen={setShowCancel}
				setShell={refresh}
				shell={shell}
				open={showCancel}
			/>
			<ShellSetAutoRenewDialog
				setOpen={setShowAutoRenew}
				setShell={refresh}
				shell={shell}
				open={showAutoRenew}
			/>
		</Grid>
	);
};

export default ShellUpdateForm;

ShellUpdateForm.propTypes = {
	refresh: PropTypes.func.isRequired,
	shell: PropTypes.object.isRequired
};
