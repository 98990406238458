import makeStyles from '@material-ui/core/styles/makeStyles';
import { fade }   from '@material-ui/core/styles/colorManipulator';

export const useStyles = makeStyles(theme => {
	return {
		root: {
			position: 'relative',
			height: '100%'
		},
		actionArea: {
			height: '100%'
		},
		media: {
			margin: theme.spacing(2),
			marginLeft: 'auto',
			marginRight: 'auto'
		},
		mediaLoading: {
			height: '75px',
			width: '75px',
			margin: theme.spacing(0),
			marginLeft: 'auto',
			marginRight: 'auto'
		},
		selected: {
			borderColor: theme.palette.primary.light,
			backgroundColor: fade(theme.palette.primary.light, 0.18),
		},
		selectedTitle: {
			fontWeight: 'bold',
			color: theme.palette.primary.dark,
		},
		description: {
			fontSize: '0.75rem'
		},
		content: {
			paddingTop: theme.spacing(1),
		},
		detailsBtb: {
			position: 'absolute',
			top: 0,
			right: 0,
			zIndex: 1,
		},
		ribbon: {
			backgroundColor: 'transparent',
			position: 'absolute',
			color: theme.palette.secondary.light,
			border: `1px solid ${theme.palette.secondary.light}`,
			width: 150,
			zIndex: 3,
			textAlign: 'center',
			textTransform: 'uppercase',
			padding: 5,
			[theme.breakpoints.down('sm')]: {
				padding: 2,
			},
			'&::before': {
				position: 'absolute',
				zIndex: -1,
				content: '',
				display: 'block',
				border: `5px solid ${theme.palette.secondary.light}`,
			},
			'&::after': {
				position: 'absolute',
				zIndex: -1,
				content: '',
				display: 'block',
				border: `5px solid ${theme.palette.secondary.dark}`,
			},
			transform: 'rotate(-45deg)',
			top: theme.spacing(2),
			marginLeft: -40,
		},
	};
});
