import React      from 'react';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes  from 'prop-types';

const styles = makeStyles(() => ({
	title: {
		flex: '1 1 100%',
	},
}));
export const Title = ({ children }) => {
	const classes = styles();
	return (
		<Typography variant="h6" className={classes.title} gutterBottom>
			{children}
		</Typography>
	);
};

Title.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node
	])
};
