import PropTypes                 from 'prop-types';
import React                     from 'react';
import { Grid }                  from '@material-ui/core';
import Typography                from '@material-ui/core/Typography';
import LinearProgress            from '@material-ui/core/LinearProgress';
import { useTranslation }        from 'react-i18next';
import moment                    from 'moment';
import { useHumanTimeFormatter } from '../../../../hooks/useUtils';
import AllInclusiveOutlinedIcon  from '@material-ui/icons/AllInclusiveOutlined';

const ShellUsage = ({ shell, light = false }) => {
	const { t } = useTranslation();

	const formatTime = useHumanTimeFormatter();

	if (!shell.Timer_Enabled)
		return light ?
			'' :
			<Grid container direction='row' alignItems='center' spacing={1}>
				<Grid item>
					<AllInclusiveOutlinedIcon/>
				</Grid>
				<Grid item>
					{t('shell_unlimited_usage')}
				</Grid>
			</Grid>;


	return (
		(
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Typography variant="body2">
						{t('shell_timer_remaining', {
							time: formatTime(shell.Timer_Balance)
						})}
					</Typography>
					{!light && <LinearProgress
						variant="determinate"
						value={(shell.Timer_Balance * 100) / shell.Timer_Allowance}
					/>}
					{!light && <Typography variant="body2">
						{t('shell_timer_next_reset', {
							date: shell.Timer_Next_Reset
								? moment(parseInt(shell.Timer_Next_Reset.unixms)).format('LL')
								: 'N/A'
						})}
					</Typography>}
				</Grid>
			</Grid>
		)
	);
};

export default ShellUsage;

ShellUsage.propTypes = {
	light: PropTypes.bool,
	shell: PropTypes.object.isRequired
};
