import React, { createContext, memo, useContext, useEffect, useState } from 'react';
import PropTypes                                                       from 'prop-types';
import { RestContext }                                                 from '@karpeleslab/klb-react-services';
import { useTranslation }                                              from 'react-i18next';

export const SnackbarValueContext = createContext({});
export const SnackbarSetContext = createContext(() => {
});

const SnackbarProvider = memo(({ setSnackbar, children }) => {
	const handleSnackbarSet = (message, type = 'default') => {
		setSnackbar({ message, type });
	};

	return (
		<SnackbarSetContext.Provider value={handleSnackbarSet}>
			{children}
		</SnackbarSetContext.Provider>
	);
});

SnackbarProvider.displayName = 'SnackbarProvider';
SnackbarProvider.propTypes = {
	setSnackbar: PropTypes.func.isRequired,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node
	]).isRequired
};

export const SnackbarContainer = ({ children }) => {
	const { t } = useTranslation();
	const [snackbar, setSnackbar] = useState({
		message: '',
		type: 'default'
	});

	const { setSnackMessageCallback } = useContext(RestContext);

	useEffect(() => {
		setSnackMessageCallback((message, type, i18n) => {
			// Ignore this message as now a proper modal is shown when the user's session (PR 162)
			if (message === 'error_login_required') return;
			setSnackbar({ message: i18n ? t(message) : message, type });
		});
	}, [setSnackbar, setSnackMessageCallback]);

	return (
		<SnackbarValueContext.Provider value={snackbar}>
			<SnackbarProvider setSnackbar={setSnackbar}>
				{children}
			</SnackbarProvider>
		</SnackbarValueContext.Provider>
	);
};


SnackbarContainer.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node
	]).isRequired
};
