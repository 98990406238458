import React, { useEffect, useState } from 'react';
import { useTranslation }             from 'react-i18next';
import Form                           from './Form';
import { deepCopy }                   from '../../../utils/misc';
import Button                         from '../../core/input/Button';
import Grid                           from '@material-ui/core/Grid';
import PropTypes                      from 'prop-types';
import { useKeychainUpdate }          from '@karpeleslab/klb-react-services';


const UpdateForm = ({ keychain, setKeychain }) => {
	const { t } = useTranslation();
	const [data, setData] = useState(deepCopy(keychain));
	const [isValid, setIsValid] = useState(false);
	const [saveEnabled, setSaveEnabled] = useState(false);
	const [update, updating] = useKeychainUpdate(keychain.Keychain__);

	useEffect(() => {
		setData(deepCopy(keychain));
	}, [keychain, setData]);

	useEffect(() => {
		if (!isValid) {
			setSaveEnabled(false);
			return;
		}

		setSaveEnabled(keychain.Label !== (data.Label ?? '').trim());
	}, [isValid, data, keychain, setSaveEnabled]);

	const saveHandler = () => {
		update(data).then(setKeychain);
	};

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>{t('keychain_update_title')}</Grid>
			<Grid item xs={12}>
				<Form
					disabled={updating}
					data={data}
					setData={setData}
					setIsValid={setIsValid}
				/>
			</Grid>

			<Grid item xs={12}>
				<Grid container justify='flex-end'>
					<Button
						color='primary'
						variant='contained'
						onClick={saveHandler}
						loading={updating}
						disabled={!saveEnabled || updating}
					>
						{t('save_btn')}
					</Button>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default UpdateForm;

UpdateForm.propTypes = {
	keychain: PropTypes.object.isRequired,
	setKeychain: PropTypes.func.isRequired
};
