import PropTypes              from 'prop-types';
import React, { useCallback } from 'react';
import RichAlertTrans         from '../../../core/feedback/RichAlertTrans';
import { useAutoRefresher }   from '../../../../hooks/useAutoRefresher';
import { useSetSnackbar }     from '../../../../hooks/useSnackbar';
import { useTranslation }     from 'react-i18next';

const OrderProcessingAlert = ({ refresh }) => {
	const setSnackbar = useSetSnackbar();
	const { t } = useTranslation();

	const refresher = useCallback(() => {
		refresh().then(o => {
			if (o.data.Status !== 'completed') return;
			setSnackbar(t('order_completed_success'), 'success');
		});
	}, []);

	useAutoRefresher(refresher, 60 * 1000);

	return (
		<RichAlertTrans i18nKey='order_processing' severity='info'/>
	);
};

export default OrderProcessingAlert;

OrderProcessingAlert.propTypes = {
	refresh: PropTypes.func.isRequired
};
