import React, { useState } from 'react';
import { useTranslation }  from 'react-i18next';
import { useParams }       from 'react-router';
import { useShellISO }     from '@karpeleslab/klb-react-services';
import PageLoader          from '../../components/layout/Loader/PageLoader';
import PageNotFound        from '../System/PageNotFound';
import { Grid }            from '@material-ui/core';
import Toolbar             from '../../components/core/typography/Toolbar';
import { getISOsRoute }    from '../../components/RouteController/Factory';
import PageContainer       from '../../components/layout/Container/PageContainer';
import IsoInfo             from '../../components/common/ISO/ISOInfo';
import IsoMountTable       from '../../components/common/ISO/IsoMountTable';
import Button              from '../../components/core/input/Button';
import ISODeleteDialog     from '../../components/common/ISO/ISODeleteDialog';

const IsoEdit = () => {
	const { t } = useTranslation();
	const { isoId } = useParams();
	const [iso] = useShellISO(isoId);
	const [showDelete, setShowDelete] = useState(false);

	if (!iso) return <PageLoader/>;
	if (!iso.data) return <PageNotFound/>;

	return (
		<PageContainer>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Toolbar
						title={t('iso_title')}
						pageTitle
						pageTitleProps={{
							backButton: getISOsRoute()
						}}
					/>
				</Grid>
				<Grid item xs={12}>
					<IsoInfo iso={iso.data}/>
				</Grid>
				<Grid item xs={12}>
					<IsoMountTable
						filterId={iso.data.Shell_ISO__}
						forShells={false}
					/>
				</Grid>
				{!iso.data.Is_Mounted &&
				<Grid item xs={12}>
					<Button
						color='secondary'
						variant='outlined'
						onClick={() => setShowDelete(true)}
					>
						{t('delete_btn')}
					</Button>
				</Grid>
				}
				{!iso.data.Is_Mounted && <ISODeleteDialog setOpen={setShowDelete} open={showDelete} iso={iso.data}/>}
			</Grid>
		</PageContainer>
	);
};

export default IsoEdit;
