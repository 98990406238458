import React                    from 'react';
import Dialog                   from '@material-ui/core/Dialog';
import DialogContentText        from '@material-ui/core/DialogContentText';
import DialogContent            from '@material-ui/core/DialogContent';
import { useTranslation }       from 'react-i18next';
import DialogTitle              from '@material-ui/core/DialogTitle';
import DialogActions            from '@material-ui/core/DialogActions';
import RichAlert                from '../../../core/feedback/RichAlert';
import Button                   from '../../../core/input/Button';
import PropTypes                from 'prop-types';
import { useKeychainKeyDelete } from '@karpeleslab/klb-react-services';

const DeleteDialog = ({ keychainKey, open, setOpen, refreshList, refreshing }) => {
	const { t } = useTranslation();
	const [deleteKey, deleting] = useKeychainKeyDelete(keychainKey?.Keychain_Key__);

	const handleClose = () => {
		setOpen(false);
	};

	const handleDelete = e => {
		e.preventDefault();

		deleteKey()
			.then(() => refreshList())
			.then(handleClose);
	};

	return (
		<Dialog
			open={open}
			aria-labelledby={t('dialog_delete_keychain_key_title')}
			aria-describedby={t('dialog_delete_keychain_key_title')}
		>
			<DialogTitle id='delete-keychain-key-title'>
				{t('dialog_delete_keychain_key_title')}
			</DialogTitle>
			<DialogContent>
				<DialogContentText id='delete-keychain-key-description'>
					<RichAlert severity='warning'>
						{t('dialog_delete_keychain_key_description', {
							label: keychainKey?.Label,
							fingerprint: keychainKey?.Fingerprint
						})}
					</RichAlert>
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button
					color='default'
					onClick={handleClose}
					disabled={deleting || refreshing}
				>
					{t('close_btn')}
				</Button>
				<Button
					onClick={handleDelete}
					color='secondary'
					variant='outlined'
					disabled={deleting || refreshing}
					loading={deleting || refreshing}
				>
					{t('delete_btn')}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default DeleteDialog;

DeleteDialog.propTypes = {
	keychainKey: PropTypes.object,
	open: PropTypes.bool.isRequired,
	refreshList: PropTypes.func.isRequired,
	refreshing: PropTypes.bool.isRequired,
	setOpen: PropTypes.func.isRequired
};
