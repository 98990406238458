import React, { useCallback }               from 'react';
import { useTranslation }                   from 'react-i18next';
import TableDefinition                      from '../../../core/data/table/TableDefinition';
import Table                                from '../../../core/data/table/Table';
import EmptyList                            from './EmptyList';
import ShellPicture                         from '../ShellPicture/ShellPicture';
import { Grid }                             from '@material-ui/core';
import ShellAction                          from '../ShellAction/ShellAction';
import ShellUsage                           from '../Subscription/ShellUsage';
import useMediaQuery                        from '@material-ui/core/useMediaQuery';
import ShellListRow                         from './ShellListRow';
import ShellTableAction                     from './Action/ShellTableAction';
import { formatShellExpireDate, isExpired } from '../../../../utils/shell';
import ShellExpiredMessage                  from '../Subscription/ShellExpiredMessage';
import { ADVANCED_MODE }                    from '../../../../context/ModeContext';
import { useIsMode }                        from '../../../../hooks/useIsMode';
import { useShells }                        from '@karpeleslab/klb-react-services';
import { useAutoRefresher }                 from '../../../../hooks/useAutoRefresher';
import { PlanChip }                         from '../ShellPicture/PlanBadge';
import { SHELL_OS_ICON_VARIATION }          from '../../../../hooks/useShells';

const ShellsTable = () => {
	const { t } = useTranslation();
	const [shells, fetch, loading, setShell] = useShells();

	const refresher = useCallback(() => {
		fetch(null, null, { silent: true });
	}, [fetch]);


	useAutoRefresher(refresher);

	const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
	const isAdvanced = useIsMode(ADVANCED_MODE);

	const createSetShellHandler = idx => shell => {
		setShell(idx, shell);
	};

	const tableDefStyle = {
		width: isMobile ? '100%' : '150px',
		...(isMobile ? { borderBottom: 'unset', padding: '0px' } : {})
	};

	return (
		<Table
			title={t('shell_list_title')}
			fetch={fetch}
			data={shells}
			search={true}
			loading={loading}
			paperProps={{ elevation: 0 }}
			emptyText={t('shell_list_empty_filter')}
			emptyState={<EmptyList/>}
			initialFilters={
				{
					Status: { '$in': ['valid', 'expired'] },
					sort: { Label: 'ASC', Created: 'ASC' },
					image_variation: [SHELL_OS_ICON_VARIATION]
				}
			}
			searchKey='Label'
			rowProps={{
				refresh: refresher,
				createSetShellHandler: createSetShellHandler
			}}
			RowComponent={ShellListRow}
		>
			<TableDefinition
				label=''
				name='Shell__'
				size='small'
				style={tableDefStyle}
			>
				{(shell, idx) => <Grid container spacing={3} justify={isMobile ? 'space-between' : undefined}>
					<Grid item xs={5} md={12}>
						<ShellPicture
							flagSize='small'
							stateSize='medium'
							setShell={createSetShellHandler(idx)}
							shell={shell}
							width={75}
						/>
					</Grid>
				</Grid>
				}
			</TableDefinition>

			{(!isMobile && isAdvanced) && <TableDefinition
				label={t('tablecell_shell_quick_actions')}
				align='center'
				name='Shell__'
			>
				{(shell, idx) =>
					<ShellAction
						disabled={isExpired(shell)}
						shell={shell}
						setShell={createSetShellHandler(idx)}
					/>
				}
			</TableDefinition>
			}

			{!isMobile && <TableDefinition align='left' label={t('tablecell_shell_label')} name='Label' sortable>
				{(shell) => <Grid container spacing={1}>
					<Grid item xs={12}>
						{shell.Label}
					</Grid>
					<Grid item xs={12}>
						<PlanChip
							label={t(`shell_badge_${shell.Catalog_Product['Description.AuthorCode'].toLowerCase()}`)}
							size='small'
							color='primary'
						/>
					</Grid>
				</Grid>
				}
			</TableDefinition>}

			{!isMobile &&
			<TableDefinition label={t('tablecell_shell_subscription')} headerAlign='center' name='Shell__'>
				{shell => {
					if (isExpired(shell))
						return <ShellExpiredMessage shell={shell} refresh={refresher} allowShortMode/>;
					else
						return <Grid container spacing={3} justify='space-between' alignItems='center'>
							<Grid item>
								<ShellUsage shell={shell}/>
							</Grid>
							<Grid item>
								{!shell.User_Billing__ && t('shell_expire_text', { date: formatShellExpireDate(shell) })}
							</Grid>
						</Grid>;
				}
				}
			</TableDefinition>}

			{!isMobile && <TableDefinition align='center' label={t('tablecell_actions')} name='Keychain__'>
				{(shell, idx) => <ShellTableAction
					refresh={refresher}
					justify='center'
					shell={shell}
					setShell={createSetShellHandler(idx)}
				/>}
			</TableDefinition>
			}
		</Table>
	);
};

export default ShellsTable;
