import React              from 'react';
import { useTranslation } from 'react-i18next';

import ChangeEmail from './ChangeEmail';
import { Grid }    from '@material-ui/core';
import { Title }   from '../../core/typography/Title';

const Account = () => {
	const { t } = useTranslation();

	return (
		<Grid container spacing={3} alignItems="center">
			<Grid item xs={12}>
				<Title>{t('profile_account')}</Title>
			</Grid>
			<Grid item xs={12}>
				<ChangeEmail/>
			</Grid>
		</Grid>
	);
};

export default Account;
