import PropTypes                                     from 'prop-types';
import React, { useEffect, useState }                from 'react';
import Loader                                        from '../../../layout/Loader/Loader';
import { Grid }                                      from '@material-ui/core';
import { Title }                                     from '../../../core/typography/Title';
import { useTranslation }                            from 'react-i18next';
import RichAlert                                     from '../../../core/feedback/RichAlert';
import TextField                                     from '@material-ui/core/TextField';
import Button                                        from '../../../core/input/Button';
import { useSetSnackbar }                            from '../../../../hooks/useSnackbar';
import { useShellReconfigure, useTwoStepValidation } from '@karpeleslab/klb-react-services';

const StepValidation = ({ shell, data, onComplete, onFail }) => {
	const { t } = useTranslation();
	const [twoStepInfo, setTwoStepInfo] = useState();
	const [reconfigure, reconfiguring] = useShellReconfigure(shell.Shell__);
	const [validate, validating] = useTwoStepValidation();
	const [code, setCode] = useState('');
	const setSnackbar = useSetSnackbar();

	useEffect(() => {
		const hasKeychainChanged = shell.Keychain__ !== (data.keychain === 'none' ? null : data.keychain);
		reconfigure({
			erase: (data.erase ?? false) ||
				data.os.Shell_OS__ !== shell.Shell_OS__ ||
				hasKeychainChanged,
			Shell_OS__: !data.os ? null : data.os.Shell_OS__,
			Shell_Datacenter__: data.datacenter === 'none' ? null : data.datacenter,
			Keychain__: data.keychain === 'none' ? '' : (data.keychain ?? null),
		}).then(session => {
			if (session && session.done) {
				setSnackbar(t('shell_reinstall_done'), 'success');
				onComplete(shell);
				return;
			}

			setTwoStepInfo(session);
		}).catch(onFail);
	}, [setTwoStepInfo]);// eslint-disable-line

	const handleCodeSubmit = () => {
		validate(twoStepInfo, code)
			.then(newShell => {
				setSnackbar(t('shell_reinstall_done'), 'success');
				onComplete(newShell);
			});
	};

	if (reconfiguring || !twoStepInfo || validating)
		return <Loader/>;

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Title>{t('shell_reinstall_validation_title')}</Title>
			</Grid>
			<Grid item xs={12}>
				<RichAlert severity='info'>
					{t('shell_reinstall_validation_desc')}
				</RichAlert>
			</Grid>
			<Grid item xs={12}>
				<TextField
					variant='outlined'
					label={t('shell_reinstall_validation_code')}
					helperText={t('shell_reinstall_validation_code_helperText')}
					error={!new RegExp(/\d{6}$/).test(code)}
					required
					fullWidth
					value={code}
					onChange={e => setCode(e.target.value)}
				/>
			</Grid>
			<Grid item xs={12}>
				<Grid container justify='flex-end'>
					<Button
						color='primary'
						variant='contained'
						onClick={handleCodeSubmit}
						disabled={!new RegExp(/\d{6}$/).test(code)}
					>
						{t('confirm_btn')}
					</Button>
				</Grid>
			</Grid>
		</Grid>
	);
};


export default StepValidation;

StepValidation.propTypes = {
	data: PropTypes.object.isRequired,
	onComplete: PropTypes.func.isRequired,
	onFail: PropTypes.func.isRequired,
	shell: PropTypes.object.isRequired
};
