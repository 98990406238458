import PropTypes              from 'prop-types';
import React                  from 'react';
import DialogTitle            from '@material-ui/core/DialogTitle';
import DialogContent          from '@material-ui/core/DialogContent';
import DialogActions          from '@material-ui/core/DialogActions';
import Button                 from '../../core/input/Button';
import Dialog                 from '@material-ui/core/Dialog';
import { useTranslation }     from 'react-i18next';
import AffiliatePayoutDetails from './AffiliatePayoutDetails';

const AffiliatePayoutDetailsDialog = ({ open, setOpen, moneyBankAccountId }) => {
	const { t } = useTranslation();

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<Dialog
			open={open}
			aria-labelledby={t('dialog_payout_details_title')}
			aria-describedby={t('dialog_payout_details_title')}
		>
			<DialogTitle id='create-os'>
				{t('dialog_payout_details_title')}
			</DialogTitle>
			<DialogContent>
				<AffiliatePayoutDetails bankAccountId={moneyBankAccountId}/>
			</DialogContent>
			<DialogActions>
				<Button color='default' onClick={handleClose}>
					{t('close_btn')}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default AffiliatePayoutDetailsDialog;

AffiliatePayoutDetailsDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	setOpen: PropTypes.func.isRequired,
	moneyBankAccountId: PropTypes.string.isRequired
};
