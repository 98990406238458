import PropTypes                                       from 'prop-types';
import React, { useEffect, useState }                  from 'react';
import { deepCopy }                                    from '../../../../utils/misc';
import { Grid }                                        from '@material-ui/core';
import { Title }                                       from '../../../core/typography/Title';
import Form                                            from '../Location/Form';
import Button                                          from '../../../core/input/Button';
import { useTranslation }                              from 'react-i18next';
import { useUserBillingUpdate, useUserLocationUpdate } from '@karpeleslab/klb-react-services';


const UpdateLocationForm = ({ billing, setBilling, location, setLocation }) => {
	const { t } = useTranslation();
	const [formValid, setFormValid] = useState(false);
	const [saveEnabled, setSaveEnabled] = useState(false);
	const [data, setData] = useState(deepCopy(location));
	const [updateLocation, updatingLocation] = useUserLocationUpdate(location.User_Location__);
	const [updateBilling, updatingBilling] = useUserBillingUpdate(billing.User_Billing__);

	useEffect(() => {
		setData(deepCopy(location));
	}, [location, setData]);

	useEffect(() => {
		if (formValid && (
			location.First_Name !== (data.First_Name ?? '').trim() ||
			location.Last_Name !== (data.Last_Name ?? '').trim() ||
			location.Zip !== (data.Zip ?? '').trim() ||
			location.Country__ !== (data.Country__ ?? '').trim()
		))
			setSaveEnabled(true);
		else

			setSaveEnabled(false);
	}, [location, data, formValid, setSaveEnabled]);

	const saveHandler = () => {
		const toSave = {
			First_Name: data.First_Name.trim(),
			Last_Name: data.Last_Name.trim(),
			Zip: data.Zip.trim(),
			Country__: data.Country__,
		};
		updateLocation(toSave)
			.then(location => {
				setLocation(location);
				return location;
			})
			.then(location => {
				return updateBilling({ User_Location__: location.User_Location__ });
			})
			.then(billing => {
				setBilling(billing);
			});
	};

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Title>{t('billing_user_location_update_title')}</Title>
			</Grid>

			<Grid item xs={12}>
				<Form
					setData={setData}
					setIsValid={setFormValid}
					data={data}
					disabled={updatingLocation || updatingBilling}
				/>
			</Grid>

			<Grid item xs={12}>
				<Grid container justify='flex-end'>
					<Button
						color='primary'
						variant='contained'
						onClick={saveHandler}
						loading={updatingLocation || updatingBilling}
						disabled={!saveEnabled || updatingLocation || updatingBilling}
					>
						{t('save_btn')}
					</Button>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default UpdateLocationForm;

UpdateLocationForm.propTypes = {
	billing: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
	setBilling: PropTypes.func.isRequired,
	setLocation: PropTypes.func.isRequired
};
