import PropTypes          from 'prop-types';
import React              from 'react';
import { useTranslation } from 'react-i18next';

import Grid      from '@material-ui/core/Grid';
import SetStatus from './../SetStatus/SetStatus';

export default function ErrorPage({ errorID }) {
	const { t } = useTranslation();
	return (
		<SetStatus statusCode={500}>
			<Grid container direction='column' align='center' justify='center'>
				<Grid item>
					<h1>{t('error_fatal_title')}</h1>
				</Grid>
				<Grid item>
					<p>{t('error_fatal_description')}</p>
				</Grid>
				{errorID && <>
					<Grid item>
						<p>{t('error_fatal_error_id')}</p>
					</Grid>
					<Grid item>
						<p><strong>{errorID}</strong></p>
					</Grid>
				</>
				}
			</Grid>
		</SetStatus>
	);
}

ErrorPage.propTypes = {
	errorID: PropTypes.string
};
