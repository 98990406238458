import PropTypes          from 'prop-types';
import React              from 'react';
import { useTranslation } from 'react-i18next';
import Typography         from '@material-ui/core/Typography';
import { Skeleton }       from '@material-ui/lab';
import { useMoneySwift }  from '@karpeleslab/klb-react-services';
import { Grid }           from '@material-ui/core';

const AffiliatePayoutDetailsPaypalTransfer = ({ bankAccount }) => {
	const { t } = useTranslation();
	const [details] = useMoneySwift(bankAccount.Meta.swift ?? bankAccount.Meta.aba, !!bankAccount.Meta.aba);

	if (!details || !details.data) {
		return <Skeleton/>;
	}

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Typography color='textSecondary' gutterBottom>
					{t('partnership_transfer_bank_title')}
				</Typography>
				<Typography variant='h5' component='h2'>
					{bankAccount.Meta.account_number}
				</Typography>
			</Grid>
			<Grid item xs={6}>
				<Typography color='textSecondary'>
					{t('partnership_bank_info_name', { value: bankAccount.Meta.name })}
				</Typography>
			</Grid>
			<Grid item xs={6}>
				<Typography color='textSecondary'>
					{
						t(`partnership_bank_info_${bankAccount.Meta.swift ? 'swift' : 'aba'}`,
							{ value: bankAccount.Meta.swift ?? bankAccount.Meta.aba }
						)
					}
				</Typography>
			</Grid>
			<Grid item xs={6}>
				<Typography color='textSecondary'>
					{t('partnership_bank_institution', {
						value: details.data.Institution_Name,
						country: details.data.Iso_Country_Code
					})}
				</Typography>
			</Grid>
		</Grid>
	);
};

export default AffiliatePayoutDetailsPaypalTransfer;

AffiliatePayoutDetailsPaypalTransfer.propTypes = {
	bankAccount: PropTypes.object.isRequired
};
