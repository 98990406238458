import React     from 'react';
import PropTypes from 'prop-types';

const TableDefinition = () => {
	return (<></>);
};

export default TableDefinition;

TableDefinition.propTypes = {
	name: PropTypes.string.isRequired,
	label: PropTypes.string,
	sortable: PropTypes.bool,
};
