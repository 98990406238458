import PropTypes                from 'prop-types';
import React, { useState }      from 'react';
import { useTwoStepValidation } from '@karpeleslab/klb-react-services';
import DialogTitle              from '@material-ui/core/DialogTitle';
import DialogContent            from '@material-ui/core/DialogContent';
import DialogContentText        from '@material-ui/core/DialogContentText';
import Grid                     from '@material-ui/core/Grid';
import DialogActions            from '@material-ui/core/DialogActions';
import Button                   from '../../../core/input/Button';
import Dialog                   from '@material-ui/core/Dialog';
import { useTranslation }       from 'react-i18next';
import RichAlertTrans           from '../../../core/feedback/RichAlertTrans';
import TextField                from '@material-ui/core/TextField';

const TwoStepDialog = ({ open, setOpen, session, onComplete, extra = null, snackMessageToken = null }) => {
	const [validate, validating] = useTwoStepValidation();
	const [code, setCode] = useState('');
	const { t } = useTranslation();

	const reset = () => setCode('');
	const handleClose = () => setOpen(false);

	const handleValidate = (e) => {
		e.preventDefault();

		const p = {};
		if (snackMessageToken) p['snackMessageToken'] = snackMessageToken;
		validate(session, code, p).then(d => {
			if (onComplete) onComplete(d);
		});
	};

	return (
		<Dialog
			open={open}
			aria-labelledby={t('dialog_two_step_title')}
			aria-describedby={t('dialog_two_step_title')}
			onEnter={reset}
		>
			<form onSubmit={handleValidate}>
				<DialogTitle id='two-step-title'>
					{t('dialog_two_step_title')}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id='two-step-description'>
						<RichAlertTrans i18nKey='dialog_two_step_desc' severity='info'/>
					</DialogContentText>
					<Grid container spacin={2}>
						{extra && <Grid item xs={12}>
						</Grid>}
						<Grid item xs={12}>
							<TextField
								variant='outlined'
								label={t('two_step_validation_code')}
								helperText={t('two_step_validation_code_helperText')}
								error={!new RegExp(/\d{6}$/).test(code)}
								required
								fullWidth
								value={code}
								disabled={validating}
								onChange={e => setCode(e.target.value)}
							/>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button color='default' onClick={handleClose} disabled={validating}>
						{t('close_btn')}
					</Button>
					<Button
						color='primary'
						type='submit'
						variant='contained'
						disabled={validating || !new RegExp(/\d{6}$/).test(code)}
						loading={validating}
					>
						{t('confirm_btn')}
					</Button>
				</DialogActions>
			</form>
		</Dialog>
	);
};

export default TwoStepDialog;

TwoStepDialog.propTypes = {
	onComplete: PropTypes.func,
	open: PropTypes.bool.isRequired,
	setOpen: PropTypes.func.isRequired,
	snackMessageToken: PropTypes.string,
	session: PropTypes.string.isRequired,
	extra: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node
	])
};
