import React              from 'react';
import { useTranslation } from 'react-i18next';
import makeStyles         from '@material-ui/core/styles/makeStyles';
import Card               from '@material-ui/core/Card';
import CardContent        from '@material-ui/core/CardContent';
import Typography         from '@material-ui/core/Typography';
import Grid               from '@material-ui/core/Grid';
import Skeleton           from '@material-ui/lab/Skeleton';
import PropTypes          from 'prop-types';
import DateUtil           from '../../../core/data/Date/DateUtil';

const useStyles = makeStyles(() => ({
	card: {
		whiteSpace: 'pre-wrap',
		height: '100%'
	}
}));


const OrderInfo = ({ order, elevation = 0 }) => {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<Card className={classes.card} elevation={elevation}>
			<CardContent>
				<Typography
					gutterBottom
					variant="h5"
					component="h2"
				>
					{t('details')}
				</Typography>


				<Grid
					container
					direction="row"
					justify="center"
					alignItems="center"
				>
					<Grid item xs={12}>
						<Typography variant="body1" color="textPrimary" component="p">
							{t('order_payment_status')}:</Typography>
					</Grid>

					<Grid item xs={12}>
						{
							order &&
							<Typography variant="body2" color="textSecondary" component="p">
								{t('order_status_' + order.Status)}
							</Typography>
						}
						{!order && <Skeleton/>}
					</Grid>


					<Grid item xs={12}>
						<Typography variant="body1" color="textPrimary" component="p">
							{t('label_order_date')}:</Typography>
					</Grid>

					<Grid item xs={12}>
						{
							order && <DateUtil klbDateObj={order.Created} format='LLL'/>}
						{!order && <Skeleton/>}
					</Grid>


					<Grid item xs={12}>
						<Typography variant="body1" color="textPrimary" component="p">
							{t('label_paid_date')}:</Typography>
					</Grid>


					<Grid item xs={12}>
						{
							order && <DateUtil klbDateObj={order.Paid} format='LLL'/>
						}
						{!order && <Skeleton/>}
					</Grid>

				</Grid>

			</CardContent>
		</Card>
	);
};

export default OrderInfo;

OrderInfo.propTypes = {
	elevation: PropTypes.number,
	order: PropTypes.number
};
