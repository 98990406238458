import PropTypes      from 'prop-types';
import React          from 'react';
import Panel          from '../../../components/layout/Container/tab/Panel';
import { Grid }       from '@material-ui/core';
import RichAlert      from '../../../components/core/feedback/RichAlert';
import { Trans }      from 'react-i18next';
import SnapshotsTable from '../../../components/common/Shell/Snapshots/SnapshotsTable';

const SnapshotsTab = ({ currentTab, shell, refresh }) => {
	return (
		<Panel value={currentTab} index={SHELL_TAB_SNAPSHOTS}>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<RichAlert severity='info'>
						<Trans i18nKey='snapshots_desc'>
							sample <br/>
							<strong>sample</strong>
						</Trans>
					</RichAlert>
				</Grid>
				<Grid item xs={12}>
					<SnapshotsTable shell={shell} setShell={refresh}/>
				</Grid>
			</Grid>
		</Panel>
	);
};

export default SnapshotsTab;
export const SHELL_TAB_SNAPSHOTS = 2;

SnapshotsTab.propTypes = {
	currentTab: PropTypes.number.isRequired,
	refresh: PropTypes.func.isRequired,
	shell: PropTypes.object.isRequired
};
