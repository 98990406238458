import PropTypes                   from 'prop-types';
import React, { useState }         from 'react';
import DialogTitle                 from '@material-ui/core/DialogTitle';
import DialogContent               from '@material-ui/core/DialogContent';
import DialogContentText           from '@material-ui/core/DialogContentText';
import Grid                        from '@material-ui/core/Grid';
import DialogActions               from '@material-ui/core/DialogActions';
import Button                      from '../../core/input/Button';
import Dialog                      from '@material-ui/core/Dialog';
import { useTranslation }          from 'react-i18next';
import BugReportIcon               from '@material-ui/icons/BugReport';
import ClipboardCopyTextField      from '../../core/input/ClipboardCopyTextField';
import { useMetaObjectLinkAccess } from '@karpeleslab/klb-react-services';
import RichAlertTrans              from '../../core/feedback/RichAlertTrans';

const DebugDialog = ({ open, setOpen, shell }) => {
	const { t } = useTranslation();

	const [getShareLink, loadingLink] = useMetaObjectLinkAccess(shell.Shell__);
	const [link, setLink] = useState();

	const handleClose = () => {
		setOpen(false);
	};

	const handleGet = () => {
		getShareLink('A', '+24 hour').then(setLink);
	};

	return (
		<Dialog
			disableEnforceFocus
			fullWidth
			maxWidth='md'
			open={open}
			aria-labelledby={t('shell_debug')}
			aria-describedby={t('shell_debug')}
		>

			<DialogTitle id='shell-debug-title'>
				{t('shell_debug')}
			</DialogTitle>
			<DialogContent>
				<DialogContentText id='shell-debug-description'>
					<RichAlertTrans i18nKey='shell_debug_description' severity='warning'/>
				</DialogContentText>
				<Grid container spacing={2} justify='center'>
					<Grid item xs={12} style={{ textAlign: 'center' }}>
						{!link && <Button
							variant='contained'
							color='primary'
							startIcon={<BugReportIcon/>}
							loading={loadingLink}
							onClick={handleGet}

						>
							{t('shell_debug_btn')}
						</Button>
						}
						{link &&
						<ClipboardCopyTextField
							fullWidth
							label={t('shell_debug_identifier')}
							value={link.Link}
							variant='outlined'
							disabled
						/>
						}
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button color='default' onClick={handleClose} disabled={loadingLink}>
					{t('close_btn')}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default DebugDialog;

DebugDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	setOpen: PropTypes.func.isRequired,
	shell: PropTypes.object.isRequired
};
