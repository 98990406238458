import PropTypes            from 'prop-types';
import React, { useEffect } from 'react';
import { useOrderProcess }  from '@karpeleslab/klb-react-services';
import { Grid }             from '@material-ui/core';
import { useTranslation }   from 'react-i18next';
import Alert                from '@material-ui/lab/Alert';
import Button               from '../../../../../core/input/Button';

const Wallet = ({ order, settings, setPaying, refreshOrder, setDialogActions = null }) => {
	const { t } = useTranslation();

	const [processOrder, processingOrder] = useOrderProcess(order.Order__);

	useEffect(() => {
		setPaying(processingOrder);
	}, [processingOrder]);

	const handleProcess = () => {
		processOrder(settings.session, settings.method, {}, { snackMessageToken: 'order_paid' })
			.then(d => refreshOrder(d.order));
	};

	useEffect(() => {
		if (!setDialogActions) return;

		setDialogActions((
			<Button
				onClick={handleProcess}
				variant='contained'
				color='primary'
				disabled={processingOrder}
				loading={processingOrder}
			>
				{t('wallet_checkout_btn')}
			</Button>
		));
	}, [setDialogActions, processingOrder]);

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Alert severity='info'>
					{t('wallet_balance', { balance: settings.fields.Wallet.Balance.display })}
				</Alert>
			</Grid>
			{!setDialogActions && <Grid item xs={12}>
				<Grid container spacing={3} justify='center'>
					<Grid item>
						<Button
							onClick={handleProcess}
							variant='contained'
							color='primary'
							disabled={processingOrder}
							loading={processingOrder}
						>
							{t('wallet_checkout_btn')}
						</Button>
					</Grid>
				</Grid>
			</Grid>
			}
		</Grid>
	);
};

export default Wallet;

Wallet.propTypes = {
	refreshOrder: PropTypes.func.isRequired,
	setPaying: PropTypes.func.isRequired,
	settings: PropTypes.object.isRequired,
	order: PropTypes.object.isRequired,
	setDialogActions: PropTypes.func // Used when the component is used in a dialog in order to integrate the action into the dialog actions
};
