import PropTypes             from 'prop-types';
import React                 from 'react';
import Alert                 from '@material-ui/lab/Alert';
import AlertTitle            from '@material-ui/lab/AlertTitle';
import { Trans }             from 'react-i18next';
import { Link }              from 'react-router-dom';
import { getShellViewRoute } from '../../../../RouteController/Factory';

const ShellWarning = ({ warning }) => {
	return (
		<Alert severity='info'>
			<AlertTitle>
				<Trans
					i18nKey='warning_concerning_shell'
					values={{ name: warning.MetaObject.Label }}
				>
					sample
					<Link to={getShellViewRoute(warning.MetaObject.Shell__)}>sample</Link>
				</Trans>
			</AlertTitle>
			<div dangerouslySetInnerHTML={{ __html: warning.Data }}/>
		</Alert>
	);
};

export default ShellWarning;

ShellWarning.propTypes = {
	warning: PropTypes.object.isRequired
};
