import React                from 'react';
import { useTranslation }   from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';
import Box                  from '@material-ui/core/Box';
import Link                 from './Link';

const Navigation = () => {
	const { t } = useTranslation();
	return (
		<Grid container spacing={1} direction='column'>
			<Grid item xs={12}>
				<Box my={2}>
					<Typography variant='h4'>{t('footer_nav_nav')}</Typography>
				</Box>
			</Grid>
			<Grid item xs={12}>
				<Link href={`${process.env.REACT_APP_SHELL_URL}/os`}>
					{t('footer_link_os')}
				</Link>
			</Grid>
			<Grid item xs={12}>
				<Link href={`${process.env.REACT_APP_SHELL_URL}/download`}>
					{t('footer_link_download')}
				</Link>
			</Grid>
			<Grid item xs={12}>
				<Link href={`${process.env.REACT_APP_SHELL_URL}/page/affiliates`}>
					{t('footer_link_affiliates')}
				</Link>
			</Grid>
			<Grid item xs={12}>
				<Link href={`${process.env.REACT_APP_SHELL_URL}/contact`}>
					{t('footer_link_contact')}
				</Link>
			</Grid>
			<Grid item xs={12}>
				<Link href={`${process.env.REACT_APP_SHELL_URL}/network`}>
					{t('footer_link_network')}
				</Link>
			</Grid>
		</Grid>
	);
};

export default Navigation;
