import React                    from 'react';
import PropTypes                from 'prop-types';
import { SnackbarContainer }    from './context/SnackbarContext';
import { UserContainer }        from './context/UserContext';
import { RestContextContainer } from '@karpeleslab/klb-react-services';
import { MenuContextContainer } from './context/MenuContext';

const AppContextContainers = ({ children }) => {
	return (
		<RestContextContainer>
			<SnackbarContainer>
				<UserContainer>
					<MenuContextContainer>
						{children}
					</MenuContextContainer>
				</UserContainer>
			</SnackbarContainer>
		</RestContextContainer>
	);
};

export default AppContextContainers;

AppContextContainers.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};
