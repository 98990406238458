import React, { useContext }               from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import Loader                              from './../layout/Loader/Loader';
import { UserContext }                     from '../../context/UserContext';
import Routes                              from './Routes';

const RouteController = () => {
	const [user] = useContext(UserContext);

	return (
		<SwitchTransition>
			<CSSTransition
				key={(!user || !user.loading) ? 'loaded' : 'loading'}
				addEndListener={(node, done) => node.addEventListener('transitionend', done, false)}
				classNames="u-transition--vertical"
			>
				{
					(!user || !user.loading) ? <Routes/> :
						(
							<div className="c-loader__fullpage-wrapper">
								<Loader/>
							</div>
						)
				}
			</CSSTransition>
		</SwitchTransition>
	);
};

export default RouteController;
