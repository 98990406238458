import PropTypes                 from 'prop-types';
import React, { useState }       from 'react';
import { Trans, useTranslation } from 'react-i18next';
import TableContainer            from '@material-ui/core/TableContainer';
import Table                     from '@material-ui/core/Table';
import TableHead                 from '@material-ui/core/TableHead';
import TableCell                 from '@material-ui/core/TableCell';
import TableRow                  from '@material-ui/core/TableRow';
import TableBody                 from '@material-ui/core/TableBody';
import DeleteIcon                from '@material-ui/icons/DeleteForever';
import Button                    from '../../core/input/Button';
import DeleteIpDialog            from './DeleteIpDialog';
import { Grid }                  from '@material-ui/core';
import RichAlert                 from '../../core/feedback/RichAlert';
import ShellIpPlan               from './ShellIpPlan';
import useMediaQuery             from '@material-ui/core/useMediaQuery';
import IconButton                from '@material-ui/core/IconButton';
import { Title }                 from '../../core/typography/Title';

const ShellIpTable = ({ shell, setShell }) => {
	const { t } = useTranslation();
	const [selected, setSelected] = useState(null);
	const [showDeleteIp, setShowDeleteIp] = useState(false);
	const isMobile = useMediaQuery(theme => theme.breakpoints.down('xs'));

	if (shell.IPs.length < 1) {
		return <Grid container spacing={3}>
			<Grid item xs={12}>
				<RichAlert severity='info'>
					<Trans i18nKey='shell_ip_address_info'>
						sample<br/>
						<strong>sample</strong>
					</Trans>
				</RichAlert>
			</Grid>
			<Grid item xs={12}>
				<ShellIpPlan shell={shell} refresh={setShell}/>
			</Grid>
		</Grid>;
	}

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Title>{t('shell_ips')}</Title>
			</Grid>
			<Grid item xs={12}>
				<TableContainer>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell> {t('tablecell_ip_address')}</TableCell>
								<TableCell>{t('tablecell_ip_address_type')}</TableCell>
								<TableCell align='right'>{t('tablecell_actions')}</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{shell.IPs.map(ip => (
								<TableRow key={ip.Shell_IP__}>
									<TableCell>{ip.IP}</TableCell>
									<TableCell>{ip.Type}</TableCell>
									<TableCell align='right'>
										{(isMobile && ip.Type !== 'anycast') && <IconButton
											onClick={() => {
												setSelected(ip);
												setShowDeleteIp(true);
											}}
											variant='outlined'
											size='small'
											color='secondary'
										>
											<DeleteIcon/>
										</IconButton>}

										{(!isMobile && ip.Type !== 'anycast') && <Button
											onClick={() => {
												setSelected(ip);
												setShowDeleteIp(true);
											}}
											variant='outlined'
											size='small'
											color='secondary'
											startIcon={<DeleteIcon/>}
										>
											{t('delete_btn')}
										</Button>
										}
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
					<DeleteIpDialog
						shell={shell}
						shellIp={selected}
						open={showDeleteIp}
						setOpen={setShowDeleteIp}
						setShell={setShell}
					/>
				</TableContainer>
			</Grid>
		</Grid>
	);
};

export default ShellIpTable;

ShellIpTable.propTypes = {
	setShell: PropTypes.func.isRequired,
	shell: PropTypes.object.isRequired
};
