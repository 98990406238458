import PropTypes                    from 'prop-types';
import React, { useEffect }         from 'react';
import Tab                          from '@material-ui/core/Tab';
import { SHELL_TAB_SETTINGS }       from './SettingsTab';
import { SHELL_TAB_ADMINISTRATION } from './AdministrationTab';
import { isExpired }                from '../../../utils/shell';
import { SHELL_TAB_SNAPSHOTS }     from './SnapshotsTab';
import { SHELL_TAB_REINSTALL }     from './ReinstallTab';
import { SHELL_TAB_ISO }           from './ISOTab';
import Tabs                        from '@material-ui/core/Tabs';
import { getShellViewRoute }       from '../../../components/RouteController/Factory';
import queryString                 from 'query-string';
import { useHistory, useLocation } from 'react-router';
import useMediaQuery               from '@material-ui/core/useMediaQuery';
import { useTranslation }          from 'react-i18next';
import { useIsMode }               from '../../../hooks/useIsMode';
import { ADVANCED_MODE }           from '../../../context/ModeContext';
import { SHELL_TAB_SUPPORT }       from './SupportTab';

const ShellTabs = ({ shell, value, setValue }) => {
	const history = useHistory();
	const location = useLocation();
	const { t } = useTranslation();

	const isMobile = useMediaQuery('(max-width:608px)', { noSsr: true });
	const isAdvanced = useIsMode(ADVANCED_MODE);

	const handleTabChange = (event, newValue) => {
		history.push(`${getShellViewRoute(shell.Shell__)}?t=${newValue}`);
	};

	useEffect(() => {
		const currentParams = queryString.parse(location.search);
		const index = parseInt(currentParams.t ?? 0) ?? 0;

		setValue(index);
	}, [location]); //eslint-disable-line

	useEffect(() => {
		// Reinstall tab disabled when shell is expired
		const currentParams = queryString.parse(location.search);
		const index = parseInt(currentParams.t ?? 0) ?? 0;

		if (index === SHELL_TAB_REINSTALL && isExpired(shell)) {
			history.push(`${getShellViewRoute(shell.Shell__)}?t=${SHELL_TAB_SETTINGS}`);
		}
	}, [shell, location]);

	return (
		<Tabs
			variant='scrollable'
			scrollButtons={isMobile ? 'on' : 'auto'}
			value={value}
			indicatorColor='primary'
			textColor='primary'
			onChange={handleTabChange}
		>
			<Tab label={t('shell_tab_settings')} value={SHELL_TAB_SETTINGS}/>
			<Tab label={t('shell_tab_administration')} value={SHELL_TAB_ADMINISTRATION}/>
			<Tab
				label={t('shell_tab_snapshots')}
				disabled={isExpired(shell)}
				value={SHELL_TAB_SNAPSHOTS}
			/>
			<Tab
				label={t('shell_tab_reinstall')}
				disabled={isExpired(shell)}
				value={SHELL_TAB_REINSTALL}
			/>
			{(isAdvanced && 0) &&
			<Tab label={t('shell_tab_iso')} disabled={isExpired(shell)} value={SHELL_TAB_ISO}/>
			}
			<Tab label={t('shell_tab_support')} value={SHELL_TAB_SUPPORT}/>
		</Tabs>
	);
};

export default ShellTabs;

ShellTabs.propTypes = {
	setValue: PropTypes.func.isRequired,
	shell: PropTypes.object.isRequired,
	value: PropTypes.number.isRequired
};
