import React              from 'react';
import { useTranslation } from 'react-i18next';

import Account       from './../../components/common/profile/Account';
import Grid          from '@material-ui/core/Grid';
import Helmet        from 'react-helmet';
import Information   from './../../components/common/profile/Information';
import OAuth         from './../../components/common/profile/OAuth/OAuth';
import Security      from './../../components/common/profile/Security';
import PageContainer from '../../components/layout/Container/PageContainer';
import OtpTable      from '../../components/common/profile/OTP/table/OtpTable';
import ModeCheck     from '../../components/layout/Mode/ModeCheck';

const Profile = () => {
	const { t } = useTranslation();

	return (
		<PageContainer>
			<Helmet>
				<title>{t('navigation_settings')}</title>
			</Helmet>
			<Grid container spacing={3}>
				{ /* TBD add helmet */}
				<Grid item xs={12}>
					<Account/>
				</Grid>

				<Grid item xs={12}>
					<Information/>
				</Grid>

				<Grid item xs={12}>
					<Security/>
				</Grid>
				<Grid item xs={12}>
					<OAuth/>
				</Grid>
				<ModeCheck anyMode>
					<Grid item xs={12}>
						<OtpTable/>
					</Grid>
				</ModeCheck>
			</Grid>
		</PageContainer>
	);
};

export default Profile;
