import React, { useState } from 'react';
import { useTranslation }  from 'react-i18next';

import Empty             from './Empty';
import TableDefinition   from '../../../../core/data/table/TableDefinition';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Button            from '../../../../core/input/Button';
import CoreTable         from '../../../../core/data/table/Table';
import { Grid }          from '@material-ui/core';
import Toolbar           from '../../../../core/typography/Toolbar';
import AddIcon           from '@material-ui/icons/Add';
import Alert             from '@material-ui/lab/Alert';
import AddDialog         from '../AddDialog/AddDialog';
import OtpActions        from '../OtpActions';
import DeleteDialog      from '../DeleteDialog';
import { useUserOTPs }   from '@karpeleslab/klb-react-services';

const OtpTable = () => {
	const { t } = useTranslation();

	const [tokens, fetch, loading, setOtp] = useUserOTPs();
	const [showCreate, setShowCreate] = useState(false);
	const [showDelete, setShowDelete] = useState(false);
	const [selected, setSelected] = useState(null);

	const createSetOtpHandler = idx => otp => {
		setOtp(idx, otp);
	};

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Toolbar title={t('profile_otp')} disableGutters={true}>
					<Button
						endIcon={<AddIcon/>}
						variant='contained'
						color='primary'
						onClick={() => setShowCreate(true)}
					>
						{t('add_btn')}
					</Button>
				</Toolbar>
			</Grid>
			<Grid item xs={12}>
				<Alert severity='info'>
					{t('profile_otp_desc')}
				</Alert>
			</Grid>

			<Grid item xs={12}>
				<CoreTable
					fetch={fetch}
					data={tokens}
					search={false}
					loading={loading}
					paperProps={{ elevation: 0 }}
					emptyState={<Empty/>}
				>

					<TableDefinition label={t('tablecell_otp_name')} name='Name' sortable/>
					<TableDefinition label={t('tablecell_otp_usage')} name='User_Otp__'>
						{(otp, idx) => <OtpActions refresh={createSetOtpHandler(idx)} otp={otp}/>}
					</TableDefinition>

					<TableDefinition label={t('tablecell_actions')} name='User_Otp__' align='right'>
						{otp =>
							(otp.Actions ?? []).length < 1 ? <Button
								size='small'
								color='secondary'
								startIcon={<DeleteForeverIcon/>}
								onClick={() => {
									setSelected(otp);
									setShowDelete(true);
								}}
							>
								{t('delete_btn')}
							</Button> : <></>
						}
					</TableDefinition>
				</CoreTable>
			</Grid>
			<AddDialog
				setOpen={setShowCreate}
				open={showCreate}
				refresh={fetch}
			/>
			{selected && <DeleteDialog
				otp={selected}
				open={showDelete}
				setOpen={setShowDelete}
				refreshing={loading}
				refreshList={fetch}
			/>
			}
		</Grid>
	);
};

export default OtpTable;
