import PropTypes     from 'prop-types';
import React         from 'react';
import { Grid }      from '@material-ui/core';
import IsoMountTable from '../../ISO/IsoMountTable';

const ShellIso = ({ shell }) => {
	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<IsoMountTable filterId={shell.Shell__} forShells={true}/>
			</Grid>
		</Grid>
	);
};

export default ShellIso;

ShellIso.propTypes = {
	shell: PropTypes.object.isRequired
};
