import React, { useState } from 'react';
import { Grid }            from '@material-ui/core';
import Button              from '../../components/core/input/Button';
import { useTranslation }  from 'react-i18next';
import AddIcon             from '@material-ui/icons/Add';
import CreateDialog        from '../../components/common/Keychain/CreateDialog';
import KeychainTable       from '../../components/common/Keychain/KeychainTable';
import PageContainer       from '../../components/layout/Container/PageContainer';
import Toolbar             from '../../components/core/typography/Toolbar';

const Keychains = () => {
	const { t } = useTranslation();
	const [showCreate, setShowCreate] = useState(false);

	return (
		<PageContainer>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Toolbar
						title={t('account_keychains_title')}
						pageTitle
					>
						<Button
							onClick={() => setShowCreate(true)}
							size="small"
							variant="contained"
							color="primary"
							startIcon={<AddIcon/>}
						>
							{t('create_btn')}
						</Button>
					</Toolbar>
				</Grid>
				<Grid item xs={12}>
					<p>{t('account_keychains_description')}</p>
				</Grid>
				<Grid item xs={12}>
					<KeychainTable/>
				</Grid>
				<CreateDialog open={showCreate} setOpen={setShowCreate}/>
			</Grid>
		</PageContainer>
	);
};

export default Keychains;
