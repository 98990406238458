import { useEffect }   from 'react';
import { useLocation } from 'react-router';

const ScrollToTop = () => {
	const { pathname } = useLocation();

	useEffect(() => {
		if (typeof window !== 'undefined')
			setTimeout(() => window.scrollTo(0, 0), 500); // timeout to match the CSS transition between pages.
	}, [pathname]);

	return null;
};

export default ScrollToTop;
