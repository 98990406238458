import PropTypes       from 'prop-types';
import React           from 'react';
import ShellUpdateForm from '../../../components/common/Shell/ShellUpdateForm';
import Panel           from '../../../components/layout/Container/tab/Panel';
import { Grid }        from '@material-ui/core';
import ModeCheck       from '../../../components/layout/Mode/ModeCheck';
import ShellTransfer   from '../../../components/common/Shell/Transfer/ShellTransfer';

const SettingsTab = ({ currentTab, shell, refresh }) => {
	return (
		<Panel value={currentTab} index={SHELL_TAB_SETTINGS}>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<ShellUpdateForm refresh={refresh} shell={shell}/>
				</Grid>
				<ModeCheck advanced>
					<Grid item xs={12}>
						<ShellTransfer shell={shell}/>
					</Grid>
				</ModeCheck>
			</Grid>
		</Panel>
	);
};

export default SettingsTab;
export const SHELL_TAB_SETTINGS = 0;

SettingsTab.propTypes = {
	currentTab: PropTypes.number.isRequired,
	refresh: PropTypes.func.isRequired,
	shell: PropTypes.object.isRequired
};
