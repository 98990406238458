import PropTypes      from 'prop-types';
import React          from 'react';
import Panel          from '../../../components/layout/Container/tab/Panel';
import { Grid }       from '@material-ui/core';
import RichAlertTrans from '../../../components/core/feedback/RichAlertTrans';
import ShellIso       from '../../../components/common/Shell/ISO/ShellISO';
import ModeCheck      from '../../../components/layout/Mode/ModeCheck';

const ISOTab = ({ currentTab, shell }) => {
	return (
		<ModeCheck advanced forceHide>
			<Panel value={currentTab} index={SHELL_TAB_ISO}>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<RichAlertTrans i18nKey='shell_tab_iso_info' severity='info'/>
					</Grid>
					<Grid item xs={12}>
						<ShellIso shell={shell}/>
					</Grid>
				</Grid>
			</Panel>
		</ModeCheck>
	);
};

export default ISOTab;
export const SHELL_TAB_ISO = 4;

ISOTab.propTypes = {
	currentTab: PropTypes.number.isRequired,
	refresh: PropTypes.func.isRequired,
	shell: PropTypes.object.isRequired
};
