import PropTypes                      from 'prop-types';
import React                          from 'react';
import DialogTitle                    from '@material-ui/core/DialogTitle';
import DialogContent                  from '@material-ui/core/DialogContent';
import DialogContentText              from '@material-ui/core/DialogContentText';
import RichAlert                      from '../../../core/feedback/RichAlert';
import DialogActions                  from '@material-ui/core/DialogActions';
import Button                         from '../../../core/input/Button';
import Dialog                         from '@material-ui/core/Dialog';
import { Trans, useTranslation }      from 'react-i18next';
import { useShellCancelSubscription } from '@karpeleslab/klb-react-services';

const ShellCancelDialog = ({ open, setOpen, shell, setShell }) => {
	const { t } = useTranslation();
	const [cancel, cancelling] = useShellCancelSubscription(shell.Shell__);

	const handleClose = () => {
		setOpen(false);
	};

	const handleCancel = () => {
		cancel()
			.then(setShell)
			.then(handleClose);
	};

	return (
		<Dialog
			open={open}
			aria-labelledby={t('confirm_cancel_shell_title')}
			aria-describedby={t('confirm_cancel_shell_title')}
		>
			<DialogTitle id='cancel-shell-title'>
				{t('confirm_cancel_shell_title')}
			</DialogTitle>
			<DialogContent>
				<DialogContentText id='cancel-shell-description'>
					<RichAlert severity='warning'>
						<Trans i18nKey='confirm_cancel_shell_desc'>
							sample<br/>
							<strong>sample</strong>
						</Trans>
					</RichAlert>
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button
					color='default'
					onClick={handleClose}
					disabled={cancelling}
				>
					{t('close_btn')}
				</Button>
				<Button
					onClick={handleCancel}
					color='secondary'
					variant='outlined'
					disabled={cancelling}
					loading={cancelling}
				>
					{t('cancel_subscription_btn')}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ShellCancelDialog;

ShellCancelDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	setOpen: PropTypes.func.isRequired,
	setShell: PropTypes.func.isRequired,
	shell: PropTypes.object.isRequired
};
