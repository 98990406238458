import PropTypes          from 'prop-types';
import React              from 'react';
import Card               from '@material-ui/core/Card';
import CardActionArea     from '@material-ui/core/CardActionArea';
import { ICON_VARIATION } from '../../../../hooks/useOperatingSystems';
import defaultIcon        from '../../../../assets/img/shells_icon_white.svg';
import { useTranslation } from 'react-i18next';
import CardContent        from '@material-ui/core/CardContent';
import Typography         from '@material-ui/core/Typography';
import Ellipsis           from '../../../core/typography/Ellipsis';
import { Box, Grid }      from '@material-ui/core';
import { Skeleton }       from '@material-ui/lab';
import { useStyles }      from './OSItem.style';
import IconButton         from '@material-ui/core/IconButton';
import OsItemLicense      from './OSItemLicense';
import InfoIcon           from '@material-ui/icons/Info';
import Img                from '../../../core/data/Image/Img';
import { isShellKernel }  from '../../../../utils/misc';
import OsItemShellKernel  from './OSItemShellKernel';

const OsItem = ({ shell, setSelected, setShowDetailsFor, selected = false, disabled = false, loading = true, os = null }) => {
	const { t } = useTranslation();
	const classes = useStyles();

	const handleClick = () => {
		if (loading || selected) return;
		setSelected(os);
	};

	const handleDetailsClick = () => {
		setShowDetailsFor(os);
	};

	return (
		<Card variant='outlined' className={`${classes.root} ${selected ? classes.selected : ''}`}>
			{(!loading && os.Beta === 'Y') &&
			<div className={classes.ribbon}>
				<span className={classes.span}>
					{t('shell_os_beta_mark')}
				</span>
			</div>
			}
			<div className={classes.detailsBtb}>
				{(!loading && (!!os.Description || !!os.Short_Description)) &&
				<IconButton disabled={disabled} onClick={handleDetailsClick}>
					<InfoIcon/>
				</IconButton>
				}
			</div>
			<CardActionArea disabled={disabled} onClick={handleClick} className={classes.actionArea}>
				<CardContent className={classes.content}>
					<Grid
						container
						spacing={0}
						alignItems='stretch'
						direction='column'
					>
						<Grid item>
							{!loading && <Img
								width={40}
								alt={os.Name}
								centered
								className={classes.media}
								src={os.Media_Image ? os.Media_Image.Variation[ICON_VARIATION] : defaultIcon}
							/>
							}
							{loading && <Skeleton className={classes.mediaLoading}/>}
						</Grid>

						<Grid item>
							{!loading &&
							<Typography
								gutterBottom
								variant='subtitle2'
								align='center'
								className={`${selected ? classes.selectedTitle : ''}`}
							>
								{os.Name}
							</Typography>}
							{loading && <Skeleton/>}
						</Grid>
						<Grid item>
							{loading && <Skeleton/>}
							{!loading && <Ellipsis>
								<Typography
									gutterBottom
									variant='body2'
									align='center'
									color='textSecondary'
									className={classes.description}
								>
									{os.Short_Description}
								</Typography>
							</Ellipsis>
							}
						</Grid>
						{((!loading && (os.Catalog_Product__ || isShellKernel(os)))) &&
						<Grid item>
							<Box pt={2} style={{ textAlign: 'center' }}>
								{os.Catalog_Product__ && <OsItemLicense os={os} shell={shell}/>}
								{isShellKernel(os) && <OsItemShellKernel/>}
							</Box>
						</Grid>
						}
					</Grid>

				</CardContent>
			</CardActionArea>
		</Card>
	);
};

export default OsItem;

OsItem.propTypes = {
	disabled: PropTypes.bool,
	loading: PropTypes.bool,
	os: PropTypes.object,
	selected: PropTypes.bool,
	setSelected: PropTypes.func.isRequired,
	setShowDetailsFor: PropTypes.func.isRequired,
	shell: PropTypes.object.isRequired
};
