import PropTypes                                 from 'prop-types';
import React, { useContext }                     from 'react';
import { ADVANCED_MODE, LITE_MODE, ModeContext } from '../../../context/ModeContext';

const ModeCheck = ({ children, forceHide = false, anyMode = false, lite = false, advanced = false, forbiddenComponent = null }) => {
	const [mode] = useContext(ModeContext);

	if (!forceHide && (anyMode || lite && mode === LITE_MODE || advanced && mode === ADVANCED_MODE))
		return children;

	if (forbiddenComponent) return forbiddenComponent;
	return <></>;
};

export default ModeCheck;

ModeCheck.propTypes = {
	advanced: PropTypes.bool,
	anyMode: PropTypes.bool,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
	forbiddenComponent: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	forceHide: PropTypes.bool,
	lite: PropTypes.bool
};
