import React                     from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Empty                     from '../../../core/feedback/State/Empty';
import Graphic                   from '../../../../assets/img/shells_logo_vertical_white.svg';
import AddIcon                   from '@material-ui/icons/Add';

const EmptyList = () => {
	const { t } = useTranslation();

	return (
		<Empty
			title={t('shell_list_empty_title')}
			text={
				<Trans i18nKey='shell_list_empty_desc'>
					sample<br/>
					<strong>sample</strong>
				</Trans>
			}
			graphic={Graphic}
			action={
				{
					component: 'a',
					title: t('order_new_shell_btn'),
					rel: 'noopener noreferrer',
					endIcon: <AddIcon/>,
					target: '_blank',
					href: `${process.env.REACT_APP_SHELL_URL}/join`
				}
			}
		/>
	);
};

export default EmptyList;
