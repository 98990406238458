import PropTypes          from 'prop-types';
import React              from 'react';
import { useTranslation } from 'react-i18next';
import Tab                from '@material-ui/core/Tab';
import Tabs               from '@material-ui/core/Tabs';
import AlbumIcon          from '@material-ui/icons/Album';
import SettingsIcon       from '@material-ui/icons/Settings';
import ShareIcon          from '@material-ui/icons/Share';
import { useIsMode }      from '../../../../hooks/useIsMode';
import { ADVANCED_MODE }  from '../../../../context/ModeContext';

export const OS_OFFICIAL = 'official';
export const OS_PRIVATE = 'private';
export const OS_SHARE = 'share';

const SourceTabs = ({ tab, setTab, setValue, disabled = false }) => {
	const { t } = useTranslation();
	const isAdvanced = useIsMode(ADVANCED_MODE);

	const handleTabChange = (event, newValue) => {
		setTab(newValue);
		setValue(null);
	};

	return (
		<Tabs
			variant='scrollable'
			scrollButtons={'on'}
			value={tab}
			indicatorColor='primary'
			textColor='primary'
			onChange={handleTabChange}
		>
			<Tab icon={<AlbumIcon/>} value={OS_OFFICIAL} label={t('shell_os_tab_official')} disabled={disabled}/>

			{isAdvanced &&
			<Tab icon={<SettingsIcon/>} value={OS_PRIVATE} label={t('shell_os_tab_my')} disabled={disabled}/>}

			{isAdvanced &&
			<Tab icon={<ShareIcon/>} value={OS_SHARE} label={t('shell_os_tab_share')} disabled={disabled}/>}
		</Tabs>
	);
};

export default SourceTabs;

SourceTabs.propTypes = {
	disabled: PropTypes.bool,
	setTab: PropTypes.func.isRequired,
	setValue: PropTypes.func.isRequired,
	tab: PropTypes.any
};
