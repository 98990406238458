import PropTypes          from 'prop-types';
import React              from 'react';
import Dialog             from '@material-ui/core/Dialog';
import DialogContentText  from '@material-ui/core/DialogContentText';
import DialogContent      from '@material-ui/core/DialogContent';
import { useTranslation } from 'react-i18next';
import DialogTitle        from '@material-ui/core/DialogTitle';
import DialogActions      from '@material-ui/core/DialogActions';

import RichAlert            from '../../core/feedback/RichAlert';
import Button               from '../../core/input/Button';
import { useShellDeleteIp } from '@karpeleslab/klb-react-services';

const DeleteIpDialog = ({ shell, shellIp, open, setOpen, setShell }) => {
	const { t } = useTranslation();
	const [deleteIp, deleting] = useShellDeleteIp(shell.Shell__);

	const handleClose = () => {
		setOpen(false);
	};

	const handleDelete = e => {
		e.preventDefault();

		deleteIp(shellIp)
			.then(d => setShell(d))
			.then(handleClose);
	};

	return (
		<Dialog
			open={open}
			aria-labelledby={t('dialog_delete_ip_title')}
			aria-describedby={t('dialog_delete_ip_title')}
		>
			<DialogTitle id='delete-ip-title'>
				{t('dialog_delete_ip_title')}
			</DialogTitle>
			<DialogContent>
				<DialogContentText id='delete-ip-description'>
					<RichAlert severity='warning'>{t('dialog_delete_ip_desc')}</RichAlert>
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button
					color='default'
					onClick={handleClose}
					disabled={deleting}
				>
					{t('close_btn')}
				</Button>
				<Button
					onClick={handleDelete}
					color='secondary'
					variant='outlined'
					disabled={deleting}
					loading={deleting}
				>
					{t('delete_btn')}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default DeleteIpDialog;

DeleteIpDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	setShell: PropTypes.func.isRequired,
	setOpen: PropTypes.func.isRequired,
	shell: PropTypes.object.isRequired,
	shellIp: PropTypes.object
};
