import PropTypes          from 'prop-types';
import React              from 'react';
import { useTranslation } from 'react-i18next';
import Dialog             from '@material-ui/core/Dialog';
import DialogTitle        from '@material-ui/core/DialogTitle';
import DialogContent      from '@material-ui/core/DialogContent';
import Grid               from '@material-ui/core/Grid';
import DialogActions      from '@material-ui/core/DialogActions';
import Button             from '../../../core/input/Button';
import { Typography }     from '@material-ui/core';
import Avatar             from '@material-ui/core/Avatar';
import { ICON_VARIATION } from '../../../../hooks/useOperatingSystems';
import defaultIcon        from '../../../../assets/img/shells_icon_white.svg';
import OsItemLicense      from './OSItemLicense';
import { isShellKernel }  from '../../../../utils/misc';
import OsItemShellKernel  from './OSItemShellKernel';

const OSDetailsDialog = ({ shell, os, setShowDetailsFor }) => {
	const { t } = useTranslation();

	const handleClose = () => {
		setShowDetailsFor(null);
	};

	return (
		<Dialog
			open={!!os}
			aria-labelledby={os.Name}
			aria-describedby={os.Name}
		>
			<DialogTitle id='os-details-title'>
				{os.Name + (os.Beta === 'Y' ? ` (${t('shell_os_beta_mark')})` : '')}
			</DialogTitle>
			<DialogContent>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Grid container spacing={2} justify='center' alignItems='center'>
							<Grid item>
								<Avatar
									variant='square'
									src={os.Media_Image ? os.Media_Image.Variation[ICON_VARIATION] : defaultIcon}
								/>
							</Grid>
						</Grid>
					</Grid>
					{isShellKernel(os) &&
					<Grid item xs={12}>
						<OsItemShellKernel/>
					</Grid>}
					{os.Short_Description &&
					<Grid item xs={12}>
						<Typography variant='body1' color='textSecondary'>
							{os.Short_Description}
						</Typography>
					</Grid>
					}

					{os.Catalog_Product__ &&
					<Grid item xs={12}>
						<OsItemLicense shell={shell} os={os} detailed/>
					</Grid>
					}

					{os.Description &&
					<Grid item xs={12}>
						<Typography variant='body1'>
							{os.Description}
						</Typography>
					</Grid>
					}
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button color='default' onClick={handleClose}>
					{t('close_btn')}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default OSDetailsDialog;

OSDetailsDialog.propTypes = {
	os: PropTypes.object.isRequired,
	setShowDetailsFor: PropTypes.func.isRequired,
	shell: PropTypes.object.isRequired
};
