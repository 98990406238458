import PropTypes                      from 'prop-types';
import React                          from 'react';
import Skeleton                       from '@material-ui/lab/Skeleton';
import AffiliatePayoutDetailsPaypal   from './AffiliatePayoutDetailsPaypal';
import AffiliatePayoutDetailsTransfer from './AffiliatePayoutDetailsTransfer';
import { useMoneyBankAccount }        from '@karpeleslab/klb-react-services';

const AffiliatePayoutDetails = ({ bankAccountId }) => {
	const [bankAccount] = useMoneyBankAccount(bankAccountId);

	if (!bankAccount || !bankAccount.data)
		return <Skeleton/>;

	if (bankAccount.data.Meta.transfer_type === 'paypal')
		return <AffiliatePayoutDetailsPaypal bankAccount={bankAccount.data}/>;

	return <AffiliatePayoutDetailsTransfer bankAccount={bankAccount.data}/>;
};

export default AffiliatePayoutDetails;

AffiliatePayoutDetails.propTypes = {
	bankAccountId: PropTypes.string.isRequired
};
