import PropTypes                                                from 'prop-types';
import React, { useEffect, useState }                           from 'react';
import DialogTitle                                              from '@material-ui/core/DialogTitle';
import DialogContent                                            from '@material-ui/core/DialogContent';
import DialogActions                                            from '@material-ui/core/DialogActions';
import Button                                                   from '../../core/input/Button';
import Dialog                                                   from '@material-ui/core/Dialog';
import { Trans, useTranslation }                                from 'react-i18next';
import { Grid, Typography }                                     from '@material-ui/core';
import { useShellSetInitialOS }                                 from '@karpeleslab/klb-react-services';
import OSSelector                                               from '../OperatingSystem/Selector/OSSelector';
import ModeCheck                                                from '../../layout/Mode/ModeCheck';
import SSHKeyWarning                                            from './Reinstall/SSHKeyWarning';
import ShellKeychainSelector                                    from '../../core/input/ShellKeychainSelector';
import { useShellHasLicense }                                   from '../../../hooks/useShellLicense';
import SelectOsLicenseInfo                                      from './License/SelectOSLicenseInfo';
import NewOrderDialog                                           from '../Order/order/NewOrderDialog';
import ShellWindowsOsWarning, { isShellWindowsOSWarningNeeded } from './ShellWindowsOSWarning';

const ShellSetInitialOSDialog = ({ open, setOpen, shell, setShell, onComplete }) => {
	const { t } = useTranslation();
	const [doSetOs, loading] = useShellSetInitialOS(shell.Shell__);
	const [os, setOs] = useState(null);
	const [keychain, setKeychain] = useState('none');
	const [hasLicense, setHasLicense] = useState(false);
	const [fetch, loadingLicense] = useShellHasLicense(shell.Shell__);
	const [saveEnabled, setSaveEnabled] = useState(false);

	// Used when an order is needed
	const [orderRequest, setOrderRequest] = useState(null);
	const [showOrder, setShowOrder] = useState(false);

	useEffect(() => {
		if (!os || !os.Catalog_Product__) {
			setHasLicense(true);
			return;
		}

		// Used to reset the state of the selected license
		setHasLicense(false);

		fetch(os.Catalog_Product['Shell.LicenseType']).then(setHasLicense);
	}, [setHasLicense, os]);

	useEffect(() => {
		if (!os || os === 'none') {
			setSaveEnabled(false);
			return;
		}

		// Check for license
		if (os.Catalog_Product) {
			if (loadingLicense || !hasLicense) {
				setSaveEnabled(false);
				return;
			}
		}

		// We need to require an keychain has been set only when the OS is a server
		if (os.Purpose !== 'server') {
			setSaveEnabled(true);
			return;
		}

		setSaveEnabled(!!keychain && keychain !== 'none');

	}, [os, keychain, setSaveEnabled, loadingLicense, hasLicense]);

	const handleClose = () => {
		setOpen(false);
	};

	const onOrderComplete = () => {
		setShowOrder(false);
		setOrderRequest(null);

		fetch(os.Catalog_Product['Shell.LicenseType']).then(setHasLicense);
	};

	const handleBuyLicense = e => {
		e.preventDefault();
		const productId = os.Catalog_Product__;
		const shellId = shell.Shell__;

		const urlParams = `${productId},shell=${shellId}`;

		setOrderRequest(urlParams);
		setShowOrder(true);
	};

	const handleConfirm = () => {
		const k = keychain === 'none' ? null : keychain;

		doSetOs(os.Shell_OS__, k)
			.then(shell => {
				setShell(shell);
				return shell;
			})
			.then(onComplete)
			.then(handleClose);
	};

	return (
		<>
			<Dialog
				open={open}
				aria-labelledby={t('shell_initial_config_title')}
				aria-describedby={t('shell_initial_config_title')}
				maxWidth='xl'
				fullWidth
			>
				<DialogTitle id='initial-os-shell-title'>
					<Trans i18nKey='shell_initial_config_desc'>
						sample<br/>
						<strong>sample</strong>
					</Trans>
				</DialogTitle>
				<DialogContent>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<Typography variant='h4'>
								{t('shell_os_selector_label')}
							</Typography>
						</Grid>
						<Grid item xs={12}>
							{
								<OSSelector
									shell={shell}
									required
									setValue={setOs}
									value={os}
									disabled={loading}
									spacing={2}
								/>
							}
						</Grid>
						
						{isShellWindowsOSWarningNeeded(shell, os) &&
						<Grid item xs={12}>
							<ShellWindowsOsWarning/>
						</Grid>
						}

						{(os && os.Catalog_Product__) &&
						<Grid item xs={12}>
							<SelectOsLicenseInfo
								loadingLicense={loadingLicense}
								hasLicense={hasLicense}
								os={os}
								handleBuyLicense={handleBuyLicense}
							/>
						</Grid>}

						<ModeCheck advanced>
							<Grid item xs={12}>
								<Typography variant='h4'>
									{t('shell_initial_config_ssh_title')}
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<SSHKeyWarning
									os={os}
									keychain={keychain}
								/>
							</Grid>
							<Grid item xs={12}>
								<ShellKeychainSelector
									value={keychain}
									setValue={setKeychain}
								/>
							</Grid>
						</ModeCheck>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button
						color='default'
						onClick={handleClose}
						disabled={loading}
					>
						{t('close_btn')}
					</Button>
					<Button
						onClick={handleConfirm}
						color='primary'
						variant='contained'
						disabled={loading || !saveEnabled}
						loading={loading}
					>
						{t('save_btn')}
					</Button>
				</DialogActions>
			</Dialog>
			{orderRequest &&
			<NewOrderDialog
				setOpen={setShowOrder}
				request={orderRequest}
				open={showOrder}
				onComplete={onOrderComplete}
			/>
			}
		</>
	);
};

export default ShellSetInitialOSDialog;

ShellSetInitialOSDialog.propTypes = {
	onComplete: PropTypes.func.isRequired,
	open: PropTypes.bool.isRequired,
	setOpen: PropTypes.func.isRequired,
	setShell: PropTypes.func.isRequired,
	shell: PropTypes.object.isRequired
};
