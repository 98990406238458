import PropTypes            from 'prop-types';
import React, { useEffect } from 'react';
import { useTranslation }   from 'react-i18next';
import { useOrderProcess }  from '@karpeleslab/klb-react-services';
import Grid                 from '@material-ui/core/Grid';
import { Typography }       from '@material-ui/core';
import Button               from '../../../../../core/input/Button';

const Free = ({ order, settings, setPaying, refreshOrder, setDialogActions = null }) => {
	const { t } = useTranslation();

	const [processOrder, processingOrder] = useOrderProcess(order.Order__);

	useEffect(() => {
		setPaying(processingOrder);
	}, [processingOrder]);

	const handleProcess = () => {
		processOrder(settings.session, settings.method, {}, { snackMessageToken: 'order_paid' })
			.then(d => refreshOrder(d.order));
	};

	useEffect(() => {
		if (!setDialogActions) return;
		setDialogActions((
			<Button
				variant='contained'
				color='primary'
				onClick={handleProcess}
				disabled={processingOrder}
				loading={processingOrder}
			>
				{t('free_order_btn')}
			</Button>
		));

	}, [setDialogActions, processingOrder]);

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Typography>{t('free_order_text')}</Typography>
			</Grid>
			{!setDialogActions && <Grid xs={12}>
				<Grid container spacing={3} justify='center'>
					<Grid item>
						<Button
							variant='contained'
							color='primary'
							onClick={handleProcess}
							disabled={processingOrder}
							loading={processingOrder}
						>
							{t('free_order_btn')}
						</Button>
					</Grid>
				</Grid>
			</Grid>
			}
		</Grid>
	);
};

export default Free;

Free.propTypes = {
	refreshOrder: PropTypes.func.isRequired,
	setPaying: PropTypes.func.isRequired,
	settings: PropTypes.object.isRequired,
	order: PropTypes.object.isRequired,
	setDialogActions: PropTypes.func // Used when the component is used in a dialog in order to integrate the action into the dialog actions
};
