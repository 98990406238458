import PropTypes                      from 'prop-types';
import React, { useEffect, useState } from 'react';
import Grid                           from '@material-ui/core/Grid';
import { deepCopy }                   from '../../../utils/misc';
import Form                           from './Form';
import Button                         from '../../core/input/Button';
import { useTranslation }             from 'react-i18next';
import { getLocale }                  from '@karpeleslab/klbfw';
import { useUpdateOperatingSystem }   from '@karpeleslab/klb-react-services';

const EditForm = ({ os, refresh }) => {
	const [data, setData] = useState(deepCopy(os));
	const [isValid, setIsValid] = useState(false);
	const [saveEnabled, setSaveEnabled] = useState(false);
	const { t } = useTranslation();
	const [update, updating] = useUpdateOperatingSystem(os.Shell_OS__);

	useEffect(() => {
		setData(deepCopy(os));
	}, [os, setData]);

	const saveHandler = () => {
		// We need to format the name
		// because on the backend it use multilingual input that is not supported yet on the frontend
		const icon = data['New_Media_Image'] ?? null;
		const cpy = deepCopy(data);
		cpy['Name'] = { [getLocale()]: cpy.Name.trim() };
		cpy['Description'] = { [getLocale()]: (cpy.Description ?? '').trim() };
		cpy['Short_Description'] = { [getLocale()]: (cpy.Short_Description ?? '').trim() };
		update(cpy, icon).then(refresh);
	};

	useEffect(() => {
		if (
			(!!data['New_Media_Image'] && data['New_Media_Image'] instanceof File) ||
			data['Public'] !== os['Public'] ||
			data['Name'] !== os['Name'] ||
			data['Description'] !== os['Description'] ||
			data['Short_Description'] !== os['Short_Description'] ||
			data['Family'] !== os['Family'] ||
			data['Boot'] !== os['Boot'] ||
			data['CPU'] !== os['CPU'] ||
			data['Purpose'] !== os['Purpose'] ||
			data['Beta'] !== os['Beta']
		)
			setSaveEnabled(true);
		else
			setSaveEnabled(false);
	}, [data, os, setSaveEnabled]);

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Form
					disabled={updating}
					data={data}
					setData={setData}
					setIsValid={setIsValid}
				/>
			</Grid>
			<Grid item xs={12}>
				<Grid container justify='flex-end'>
					<Button
						color='primary'
						variant='contained'
						onClick={saveHandler}
						loading={updating}
						disabled={!isValid || updating || !saveEnabled}
					>
						{t('save_btn')}
					</Button>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default EditForm;

EditForm.propTypes = {
	os: PropTypes.object.isRequired,
	refresh: PropTypes.func.isRequired
};
