import React, { useEffect, useState } from 'react';
import { useTranslation }             from 'react-i18next';

import Dialog            from '@material-ui/core/Dialog';
import DialogActions     from '@material-ui/core/DialogActions';
import DialogContent     from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle       from '@material-ui/core/DialogTitle';
import TextField         from '@material-ui/core/TextField';
import PropTypes         from 'prop-types';
import Button            from '../../core/input/Button';

const RequestPasswordDialog = ({ open, setOpen, closeCallback }) => {
	const { t } = useTranslation();
	const [pwd, setPwd] = useState('');
	const handleUnlock = () => {
		setOpen(false);
		closeCallback(pwd);
	};

	useEffect(() => {
		setPwd('');
	}, [open]);

	return (
		<Dialog
			open={open}
			aria-labelledby="Security password"
			aria-describedby="Security password"
		>
			<DialogTitle id="security-password-title">
				{t('security_password_required_title')}
			</DialogTitle>
			<DialogContent>
				<DialogContentText id="security-password-description">
					{t('security_password_required_description')}
				</DialogContentText>
				<TextField
					label={t('label_current_password')}
					fullWidth
					value={pwd}
					onChange={(e) => setPwd(e.target.value)}
					variant="outlined"
					type='password'
				/>
			</DialogContent>
			<DialogActions>
				<Button
					color="primary"
					autoFocus
					onClick={() => setOpen(false)}
				>
					{t('back_btn')}
				</Button>
				<Button
					variant='contained'
					type='submit'
					color="primary"
					onClick={handleUnlock}
				>
					{t('unlock_btn')}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default RequestPasswordDialog;

RequestPasswordDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	setOpen: PropTypes.func.isRequired,
	closeCallback: PropTypes.func.isRequired
};
