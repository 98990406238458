import React         from 'react';
import PageContainer from '../../components/layout/Container/PageContainer';
import NotFound      from '../../components/core/feedback/State/NotFound';
import Helmet        from 'react-helmet';
import { useTranslation } from 'react-i18next';

const PageNotFound = () => {
	const { t } = useTranslation();

	return (
		<PageContainer>
			<Helmet>
				{ /* https://devhints.io/html-meta */}
				<title>{t('page_notfound_title')}</title>
			</Helmet>
			<NotFound/>
		</PageContainer>
	);
};

export default PageNotFound;
