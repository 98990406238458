import PropTypes                 from 'prop-types';
import React                     from 'react';
import { useFirstPaymentMethod } from '../../../../../hooks/useUserBilling';
import DialogContent             from '@material-ui/core/DialogContent';
import Button                    from '../../../../core/input/Button';
import DialogActions             from '@material-ui/core/DialogActions';
import { useTranslation }        from 'react-i18next';
import { useShellSetBilling }    from '@karpeleslab/klb-react-services';
import { Grid }                  from '@material-ui/core';
import PaymentMethod             from '../../../User/Billing/PaymentMethod';
import Alert                     from '@material-ui/lab/Alert';
import Loader                    from '../../../../layout/Loader/Loader';
import Typography                from '@material-ui/core/Typography';

const AutoRenewSet = ({ billing, shell, setShell, handleClose }) => {
	const { t } = useTranslation();
	const [method] = useFirstPaymentMethod(billing.User_Billing__);
	const [activate, activating] = useShellSetBilling(shell.Shell__);

	const handleActivate = () => {
		activate(billing.User_Billing__)
			.then(setShell)
			.then(handleClose);
	};

	return (
		<>
			<DialogContent>
				{(!method || !method.data) && <Loader/>}
				{(method && method.data) && <Grid container spacing={2}>
					<Grid item xs={12}>
						<Typography variant='subtitle2'>{t('activate_auto_renew_billing')}</Typography>
					</Grid>
					<Grid item xs={12}>
						<Alert severity='info'>
							<PaymentMethod method={method.data}/>
						</Alert>
					</Grid>
				</Grid>
				}
			</DialogContent>
			<DialogActions>
				<Button color='default' onClick={handleClose} disabled={activating}>
					{t('close_btn')}
				</Button>
				<Button
					onClick={handleActivate}
					color='primary'
					type='submit'
					variant='contained'
					disabled={activating || !method || !method.data}
					loading={activating || !method || !method.data}
				>
					{t('activate_auto_renew_btn')}
				</Button>
			</DialogActions>
		</>
	);
};

export default AutoRenewSet;

AutoRenewSet.propTypes = {
	billing: PropTypes.object.isRequired,
	handleClose: PropTypes.func.isRequired,
	setShell: PropTypes.func.isRequired,
	shell: PropTypes.object.isRequired
};
