import React                from 'react';
import Dialog               from '@material-ui/core/Dialog';
import DialogContentText    from '@material-ui/core/DialogContentText';
import DialogContent        from '@material-ui/core/DialogContent';
import { useTranslation }   from 'react-i18next';
import DialogTitle          from '@material-ui/core/DialogTitle';
import DialogActions        from '@material-ui/core/DialogActions';
import RichAlert            from '../../../core/feedback/RichAlert';
import Button               from '../../../core/input/Button';
import PropTypes            from 'prop-types';
import { useUserOTPDelete } from '@karpeleslab/klb-react-services';

const DeleteDialog = ({ otp, open, setOpen, refreshList, refreshing }) => {
	const { t } = useTranslation();
	const [deleteKey, deleting] = useUserOTPDelete(otp.User_Otp__);

	const handleClose = () => {
		setOpen(false);
	};

	const handleDelete = e => {
		e.preventDefault();

		deleteKey()
			.then(() => refreshList())
			.then(handleClose);
	};

	return (
		<Dialog
			open={open}
			aria-labelledby={t('dialog_delete_opt_title')}
			aria-describedby={t('dialog_delete_opt_title')}
		>
			<DialogTitle id='delete-otp-title'>
				{t('dialog_delete_opt_title')}
			</DialogTitle>
			<DialogContent>
				<DialogContentText id='delete-otp-description'>
					<RichAlert severity='warning'>
						{t('dialog_delete_opt_desc', {
							name: otp?.Name,
						})}
					</RichAlert>
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button
					color='default'
					onClick={handleClose}
					disabled={deleting || refreshing}
				>
					{t('close_btn')}
				</Button>
				<Button
					onClick={handleDelete}
					color='secondary'
					variant='outlined'
					disabled={deleting || refreshing}
					loading={deleting || refreshing}
				>
					{t('delete_btn')}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default DeleteDialog;

DeleteDialog.propTypes = {
	otp: PropTypes.object,
	open: PropTypes.bool.isRequired,
	refreshList: PropTypes.func.isRequired,
	refreshing: PropTypes.bool.isRequired,
	setOpen: PropTypes.func.isRequired
};
