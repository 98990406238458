import React, { useEffect, useState } from 'react';
import { useTranslation }             from 'react-i18next';
import TextField                      from '@material-ui/core/TextField';
import InputAdornment                 from '@material-ui/core/InputAdornment';
import CircularProgress               from '@material-ui/core/CircularProgress';
import PropTypes                      from 'prop-types';
import { ICON_VARIATION }             from '../../../hooks/useOperatingSystems';
import { useOperatingSystems }        from '@karpeleslab/klb-react-services';

const ShellOsSelector = ({ value, setValue, own = false, label = null, allowEmpty = false, emptyText = null, helperText = null, disabled = false, required = false, purpose = null, ...rest }) => {
	const { t } = useTranslation();
	const [os, fetch, loading] = useOperatingSystems();
	const [idToOsMap, setIdToOsMap] = useState();

	const [releasedOS, setReleasedOS] = useState([]);
	const [betaOs, setBetaOS] = useState([]);
	const [licenseOs, setLicenseOs] = useState([]);

	useEffect(() => {
		const params = { Ready: 'Y', Visible: own ? 'N' : 'Y', image_variation: [ICON_VARIATION] };
		if (purpose) params.Purpose = purpose;

		fetch(params, { results_per_page: 100 });
	}, [purpose]); // eslint-disable-line

	useEffect(() => {
		if (!os) return;
		const map = {};
		const beta = [];
		const license = [];
		const nonBeta = [];
		os.data.forEach(s => {
			if (s.Catalog_Product__) license.push(s);
			else if (s.Beta === 'Y') beta.push(s);
			else nonBeta.push(s);
			map[s.Shell_OS__] = s;
		});
		if (value === null) {
			const first = nonBeta[0] || beta[0];
			if (first) {
				setValue(first);
			}
		}
		setBetaOS(beta);
		setReleasedOS(nonBeta);
		setLicenseOs(license);
		setIdToOsMap(map);
	}, [os, setIdToOsMap, setBetaOS, setReleasedOS, setLicenseOs]);

	return (
		<TextField
			label={label ?? t('shell_os_selector_label')}
			helperText={helperText ?? t('shell_os_selector_helperText')}
			value={value ? (typeof value === 'object' ? value.Shell_OS__ : (value ?? 'none')) : 'none'}
			onChange={e => setValue(idToOsMap[e.target.value] ?? 'none')}
			select
			fullWidth
			disabled={disabled || !os}
			required={required}
			variant='outlined'
			InputProps={loading ?
				{
					endAdornment:
						(<InputAdornment position='end'>
							<CircularProgress size={30} thickness={1}/>
						</InputAdornment>)
				} : undefined
			}
			SelectProps={{
				native: true
			}}
			{...rest}
		>
			{(os && os.data.length < 1 && !loading) && <option disabled> {t('shell_os_filter_no_result_short')}</option>}
			{(os && os.data.length > 0 && allowEmpty) &&
			<option value='none'>
				{emptyText === null ? t('shell_os_selector_default') : emptyText}
			</option>
			}

			{(releasedOS.length > 0 && !loading) &&
			<optgroup label={t('shell_os_selector_released')}>
				{releasedOS.map(
					os => <option
						disabled={os.Ready === 'N'}
						key={os.Shell_OS__}
						value={os.Shell_OS__}
					>
						{os.Name}
					</option>)}
			</optgroup>
			}

			{(licenseOs.length > 0 && !loading) &&
			<optgroup label={t('shell_os_selector_license')}>
				{licenseOs.map(
					os => <option
						disabled={os.Ready === 'N'}
						key={os.Shell_OS__}
						value={os.Shell_OS__}
					>
						{os.Name}
					</option>)}
			</optgroup>
			}

			{(betaOs.length > 0 && !loading) &&
			<optgroup label={t('shell_os_selector_beta')}>
				{betaOs.map(
					os => <option
						disabled={os.Ready === 'N'}
						key={os.Shell_OS__}
						value={os.Shell_OS__}
					>
						{os.Name}
					</option>)}
			</optgroup>
			}

		</TextField>
	);
};

export default ShellOsSelector;

ShellOsSelector.propTypes = {
	allowEmpty: PropTypes.bool,
	disabled: PropTypes.bool,
	emptyText: PropTypes.any,
	helperText: PropTypes.string,
	label: PropTypes.string,
	own: PropTypes.bool,
	purpose: PropTypes.string,
	required: PropTypes.bool,
	setValue: PropTypes.func.isRequired,
	value: PropTypes.object
};
