import React, { createContext, useContext, useEffect, useState } from 'react';
import { useRest }                                               from '@karpeleslab/react-klbfw-hooks';
import PropTypes                                                 from 'prop-types';
import { getUserEndpoint, RestContext }                          from '@karpeleslab/klb-react-services';
import ExpiredModal                                              from '../components/layout/ExpiredModal/ExpiredModal';

export const defaultUserContext = {
	loggedIn: false,
	loading: true,
	showExpiredModal: false,
	user: {
		Display_Name: null,
		Email: null,
		Profile: {
			Media_Image: {
				Url: null
			}
		}
	}
};

export const UserContext = createContext(defaultUserContext);

export const UserContainer = ({ children }) => {
	const [user, setUser] = useState(defaultUserContext);

	const [loggedUser] = useRest(getUserEndpoint('@'));

	const { setLogoutUserCallback } = useContext(RestContext);

	useEffect(() => {
		setLogoutUserCallback(() => {
			setUser(prev => ({ ...prev, showExpiredModal: true }));
		});
	}, [setUser, setLogoutUserCallback]);

	useEffect(() => {
		if (!loggedUser)
			return;

		let userCtx = {};
		if (loggedUser.result === 'success')
			userCtx = { loggedIn: true, user: loggedUser };

		setUser({ ...userCtx, loading: false });

	}, [loggedUser]);

	return (
		<UserContext.Provider value={[user, setUser]}>
			{children}
			<ExpiredModal open={user.showExpiredModal}/>
		</UserContext.Provider>
	);
};

UserContainer.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node
	]).isRequired
};
