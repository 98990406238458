import PropTypes                                                from 'prop-types';
import React, { useEffect, useState }                           from 'react';
import { Grid }                                                 from '@material-ui/core';
import { Trans, useTranslation }                                from 'react-i18next';
import { Title }                                                from '../../../core/typography/Title';
import FormControlLabel                                         from '@material-ui/core/FormControlLabel';
import Checkbox                                                 from '@material-ui/core/Checkbox';
import ShellKeychainSelector                                    from '../../../core/input/ShellKeychainSelector';
import Button                                                   from '../../../core/input/Button';
import RichAlert                                                from '../../../core/feedback/RichAlert';
import ShellDatacenterSelector                                  from '../../../core/input/ShellDatacenterSelector';
import Link                                                     from '../../Links/Link';
import ModeCheck                                                from '../../../layout/Mode/ModeCheck';
import OSSelector                                               from '../../OperatingSystem/Selector/OSSelector';
import SSHKeyWarning                                            from './SSHKeyWarning';
import { useShellHasLicense }                                   from '../../../../hooks/useShellLicense';
import SelectOsLicenseInfo                                      from '../License/SelectOSLicenseInfo';
import NewOrderDialog                                           from '../../Order/order/NewOrderDialog';
import ShellWindowsOsWarning, { isShellWindowsOSWarningNeeded } from '../ShellWindowsOSWarning';

const StepSettings = ({ data, setData, shell, next }) => {
	const { t } = useTranslation();
	const [nextEnabled, setNextEnabled] = useState(false);
	const [changeOSToggle, setChangeOSToggle] = useState(false);

	// Related to OS license
	const [hasLicense, setHasLicense] = useState(false);
	const [fetchLicense, loadingLicense] = useShellHasLicense(shell.Shell__);

	// Used when an order is needed
	const [orderRequest, setOrderRequest] = useState(null);
	const [showOrder, setShowOrder] = useState(false);

	const createChangeHandler = (key, property = 'value') => e => {
		setData({ ...data, [key]: (property ? e.target[property] : e) });
	};

	const getValue = (key, def = '') => {
		if (!(key in (data ?? {}))) return def;
		return data[key];
	};

	useEffect(() => {
		const os = getValue('os', null);
		if (!os || !os.Catalog_Product__) {
			setHasLicense(true);
			return;
		}

		// Used to reset the state of the selected license
		setHasLicense(false);

		fetchLicense(os.Catalog_Product['Shell.LicenseType']).then(setHasLicense);
	}, [setHasLicense, data, fetchLicense]);

	useEffect(() => {

		// We must select an OS
		if (!getValue('os', null)) {
			setNextEnabled(false);
			return;
		}

		const os = getValue('os');

		// Check for license
		if (os.Catalog_Product) {
			if (loadingLicense || !hasLicense) {
				setNextEnabled(false);
				return;
			}
		}

		if (os.Purpose === 'server') {
			const keychain = getValue('keychain', 'none');
			if (!keychain || keychain === 'none') {
				setNextEnabled(false);
				return;
			}
		}

		if (getValue('os').Shell_OS__ !== (shell.Shell_OS__) ||
			getValue('datacenter', 'none') !== (shell.Shell_Datacenter__ ?? 'datacenter') ||
			getValue('keychain', 'none') !== (shell.Keychain__ ?? 'none') ||
			getValue('erase', false)
		) {
			setNextEnabled(true);
			return;
		}

		setNextEnabled(false);

	}, [data, setNextEnabled, loadingLicense, hasLicense]);

	const onOrderComplete = () => {
		setShowOrder(false);
		setOrderRequest(null);

		const os = getValue('os', null);
		if (!os) return;
		fetchLicense(os.Catalog_Product['Shell.LicenseType']).then(setHasLicense);
	};

	const handleBuyLicense = e => {
		e.preventDefault();
		const os = getValue('os', null);
		if (!os) return;
		const productId = os.Catalog_Product__;
		const shellId = shell.Shell__;

		const urlParams = `${productId},shell=${shellId}`;

		setOrderRequest(urlParams);
		setShowOrder(true);
	};

	const erase = getValue('erase', false) ||
		(
			getValue('os') ?
				getValue('os', {}).Shell_OS__ !== shell.Shell_OS__ :
				false
		) ||
		getValue('datacenter') !== (shell.Shell_Datacenter__ ?? 'datacenter') ||
		getValue('keychain') !== (shell.Keychain__ ?? 'none');

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Title>{t('shell_reinstall_erase')}</Title>
			</Grid>
			{erase &&
			<Grid item xs={12}>
				<RichAlert severity='warning'>
					<Trans i18nKey='shell_reinstall_erase_warning'>
						sample<br/>
						<strong>sample</strong>
					</Trans>
				</RichAlert>
			</Grid>
			}
			<Grid item xs={12}>
				<FormControlLabel
					control={
						<Checkbox
							checked={erase}
							onChange={createChangeHandler('erase', 'checked')}
							color='primary'
						/>
					}
					label={t('shell_reinstall_erase_text')}
				/>
			</Grid>
			<Grid item xs={12}>
				<Title>{t('shell_reinstall_os')}</Title>
			</Grid>
			<Grid item xs={12}>
				{(!changeOSToggle && shell.Shell_OS__ !== null) &&
				<Grid container spacing={3}>
					<Grid item>
						{shell.OS.Name}
					</Grid>
					<Grid item>
						<Button
							size='small'
							color='primary'
							variant='outlined'
							onClick={() => setChangeOSToggle(true)}
						>
							{t('change_btn')}
						</Button>
					</Grid>
				</Grid>
				}
				{(changeOSToggle || shell.Shell_OS__ === null) &&
				<OSSelector
					shell={shell}
					setValue={createChangeHandler('os', null)}
					value={getValue('os', null)}
					required
				/>
				}
			</Grid>

			{(getValue('os', null) && getValue('os', null).Catalog_Product__) &&
			<Grid item xs={12}>
				<SelectOsLicenseInfo
					loadingLicense={loadingLicense}
					hasLicense={hasLicense}
					os={getValue('os', null)}
					handleBuyLicense={handleBuyLicense}
				/>
			</Grid>}

			{isShellWindowsOSWarningNeeded(shell, getValue('os', null)) &&
			<Grid item xs={12}>
				<ShellWindowsOsWarning/>
			</Grid>
			}

			<ModeCheck advanced>
				<Grid item xs={12}>
					<Title>{t('shell_reinstall_keychain')}</Title>
				</Grid>
				<Grid item xs={12}>
					<SSHKeyWarning
						os={getValue('os', null)}
						keychain={getValue('keychain') ?? 'none'}
					/>
				</Grid>
				<Grid item xs={12}>
					<ShellKeychainSelector
						value={getValue('keychain') ?? 'none'}
						setValue={createChangeHandler('keychain', null)}
					/>
				</Grid>
				<Grid item xs={12}>
					<Title>{t('shell_reinstall_datacenter')}</Title>
				</Grid>
				{shell.IPs.length > 0 &&
				<Grid item xs={12}>
					<RichAlert severity='info'>
						<Trans i18nKey='shell_reinstall_datacenter_ip_warning'>
							sample<br/>
							<strong>sample</strong>
							<Link href={`${process.env.REACT_APP_SHELL_URL}/contact`}>
								sample
							</Link>
						</Trans>
					</RichAlert>
				</Grid>
				}
				<Grid item xs={12}>
					<ShellDatacenterSelector
						disabled={shell.IPs.length > 0}
						value={getValue('datacenter')}
						setValue={createChangeHandler('datacenter', null)}
					/>
				</Grid>
			</ModeCheck>
			<Grid item xs={12}>
				<Grid container justify='flex-end'>
					<Button
						color='primary'
						variant='contained'
						onClick={next}
						disabled={!nextEnabled}
					>
						{t('next_btn')}
					</Button>
				</Grid>
			</Grid>

			{orderRequest &&
			<NewOrderDialog
				setOpen={setShowOrder}
				request={orderRequest}
				open={showOrder}
				onComplete={onOrderComplete}
			/>
			}
		</Grid>
	);
};

export default StepSettings;

StepSettings.propTypes = {
	data: PropTypes.object.isRequired,
	next: PropTypes.func.isRequired,
	setData: PropTypes.func.isRequired,
	shell: PropTypes.object.isRequired
};
