import React, { useEffect, useState } from 'react';
import PageContainer                  from '../../components/layout/Container/PageContainer';
import { useShellAccountWarnings }    from '@karpeleslab/klb-react-services';
import PageLoader                     from '../../components/layout/Loader/PageLoader';
import PageTitle                      from '../../components/core/typography/PageTitle';
import { Grid }                       from '@material-ui/core';
import { useTranslation }             from 'react-i18next';
import TextWarnings                   from '../../components/common/Account/Warning/Text/TextWarnings';
import UserWarnings                   from '../../components/common/Account/Warning/User/UserWarnings';
import ShellWarnings                  from '../../components/common/Account/Warning/Shell/ShellWarnings';
import ProductWarnings                from '../../components/common/Account/Warning/Product/ProductWarnings';
import OsWarnings                     from '../../components/common/Account/Warning/Os/OsWarnings';
import DatacenterWarnings             from '../../components/common/Account/Warning/Datacenter/DatacenterWarnings';

const Warning = () => {
	const { t } = useTranslation();

	const [fetch, loading] = useShellAccountWarnings();
	const [textWarnings, setTextWarnings] = useState([]);
	const [productWarnings, setProductWarnings] = useState([]);
	const [userWarnings, setUserWarnings] = useState([]);
	const [shellWarnings, setShellWarnings] = useState([]);
	const [osWarnings, setOsWarnings] = useState([]);
	const [datacenterWarnings, setDatacenterWarnings] = useState([]);

	useEffect(() => {
		fetch()
			.then(warnings => {
				setTextWarnings(warnings.filter(w => w.Type === 'text'));
				setProductWarnings(warnings.filter(w => w.Type === 'product'));
				setUserWarnings(warnings.filter(w => w.Type === 'user'));
				setShellWarnings(warnings.filter(w => w.Type === 'shell'));
				setOsWarnings(warnings.filter(w => w.Type === 'shell_os'));
				setDatacenterWarnings(warnings.filter(w => w.Type === 'datacenter'));
			});
	}, [fetch, setTextWarnings, setProductWarnings, setUserWarnings, setShellWarnings, setOsWarnings, setDatacenterWarnings]);

	if (loading) return <PageLoader/>;

	return (
		<PageContainer>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<PageTitle title={t('account_notification_title')}/>
				</Grid>

				<Grid item xs={12}>
					<TextWarnings warnings={textWarnings}/>
				</Grid>

				<Grid item xs={12}>
					<UserWarnings warnings={userWarnings}/>
				</Grid>

				<Grid item xs={12}>
					<ShellWarnings warnings={shellWarnings}/>
				</Grid>

				<Grid item xs={12}>
					<OsWarnings warnings={osWarnings}/>
				</Grid>

				<Grid item xs={12}>
					<DatacenterWarnings warnings={datacenterWarnings}/>
				</Grid>

				<Grid item xs={12}>
					<ProductWarnings warnings={productWarnings}/>
				</Grid>
			</Grid>
		</PageContainer>
	);
};

export default Warning;
