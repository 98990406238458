import PropTypes         from 'prop-types';
import React             from 'react';
import RichAlert         from '../../../core/feedback/RichAlert';
import { Trans }         from 'react-i18next';
import moment            from 'moment';
import { Grid }          from '@material-ui/core';
import ShellExpireButton from '../ShellExpireButton';
import useMediaQuery     from '@material-ui/core/useMediaQuery';

const ShellExpiredMessage = ({ shell, refresh, allowShortMode = false }) => {
	const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
	const isSmall = useMediaQuery(theme => theme.breakpoints.down('md'));

	// This is an edge case where the normal alert message wouldn't have space to be displayed
	if (!isMobile && isSmall && allowShortMode) {
		return <Grid container spacing={3}>
			<Grid item xs={12}>
				<RichAlert
					severity='error'
				>
					<Trans
						i18nKey='shell_expired_notification_short'
						values={
							{ date: moment(parseInt(shell.Expires.unixms)).add(30, 'days').format('LLL') }
						}
					>
						sample<br/>
						<strong>sample</strong>
					</Trans>
				</RichAlert>
			</Grid>
			{refresh ?
				<Grid item xs={12}>
					<ShellExpireButton light variant='contained' refresh={refresh} shell={shell}/>
				</Grid> : undefined}
		</Grid>;
	}

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<RichAlert
					severity='error'
					action={refresh ?
						<ShellExpireButton light variant='contained' refresh={refresh} shell={shell}/> : undefined}
				>
					<Trans
						i18nKey='shell_expired_notification'
						values={
							{ date: moment(parseInt(shell.Expires.unixms)).add(30, 'days').format('LLL') }
						}
					>
						sample<br/>
						<strong>sample</strong>
					</Trans>
				</RichAlert>
			</Grid>
		</Grid>
	);
};

export default ShellExpiredMessage;

ShellExpiredMessage.propTypes = {
	allowShortMode: PropTypes.bool,
	refresh: PropTypes.func,
	shell: PropTypes.object.isRequired
};
