import './polyfills';

import React from 'react';
import App   from './App';

import i18n                 from 'i18next';
import { initReactI18next } from 'react-i18next';
import { getLocale, rest }  from '@karpeleslab/klbfw';
import { Backend }          from '@karpeleslab/i18next-klb-backend';
import { run }              from '@karpeleslab/react-klbfw-hooks';

import './assets/styles/main.scss';

let i18nOpt = {
	lng: getLocale(),
	initImmediate: false,
	load: 'currentOnly',
	interpolation: { escapeValue: false },
	react: { useSuspense: false }
};

global.rest = rest;

run(
	<App/>,
	[i18n.use(Backend).use(initReactI18next).init(i18nOpt)]
);
