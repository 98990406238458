import PropTypes                       from 'prop-types';
import React, {useContext, useEffect, useState} from 'react';
import DialogContent                   from '@material-ui/core/DialogContent';
import DialogActions                   from '@material-ui/core/DialogActions';
import { useShellCreateAndSetBilling } from '@karpeleslab/klb-react-services';
import { Grid }                        from '@material-ui/core';
import Form                            from '../../../User/Location/Form';
import { Title }                       from '../../../../core/typography/Title';
import { useTranslation }              from 'react-i18next';
import Stripe                          from '../../../../core/input/Stripe/Stripe';
import Alert                           from '@material-ui/lab/Alert';
import Button                          from '../../../../core/input/Button';
import {UserContext} 				   from '../../../../../context/UserContext';

const AutoRenewCreate = ({ shell, setShell, handleClose }) => {
	const { t } = useTranslation();

	const [create] = useShellCreateAndSetBilling(shell.Shell__);
	const [saveEnabled, setSaveEnabled] = useState(false);
	const [creating, setCreating] = useState(false);

	const [locationFormValid, setLocationFormValid] = useState(false);
	const [locationData, setLocationData] = useState({});

	const [stripe, setStripe] = useState(null);
	const [stripeIntent, setStripeIntent] = useState(null);
	const [stripeElements, setStripeElements] = useState(null);
	const [stripeValid, setStripeValid] = useState(false);
	const [error, setError] = useState(null);
	const [userContext] = useContext(UserContext);


	const onStripeChange = e => {
		setStripeValid(e.complete === true);
	};

	const handleCreate = async () => {
		if (!stripe || !stripeElements)
			return; // stripe not loaded yet

		setCreating(true);
		
		const result = await stripe.confirmSetup({
			elements: stripeElements,
			redirect: 'if_required',
			confirmParams: {
				payment_method_data: {
					billing_details: {
						name: `${locationData.First_Name} ${locationData.Last_Name}`,
						email: userContext.user.data.Email,
						address: {
							country: locationData.Country__,
							postal_code: locationData.Zip,
							state: locationData.Province ? locationData.Province : '',
							city: locationData.City ? locationData.City : '',
							line1: locationData.Address ? locationData.Address : '',
							line2: locationData.Address2 ? locationData.Address2 : '',
						}
					},
				},
			}
		});

		if (result.error) {
			if (result.error.type === 'card_error' || result.error.type === 'validation_error') {
				setError(result.error.message);
			} else {
				setError(t('unexpected_error'));
			}

			setCreating(false);
			return;
		}

		create(locationData, {
			billingLabel: 'Shells',
			billingMethod: 'Stripe',
			billingMethodData: { stripe_intent: stripeIntent.stripe_intent }
		})
			.then(setShell)
			.then(handleClose)
			.finally(() => setCreating(false));
	};

	useEffect(() => {
		setSaveEnabled(locationFormValid && stripeValid);
	}, [locationFormValid, stripeValid, setSaveEnabled]);

	return (
		<>
			<DialogContent>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Title>{t('billing_user_location_create_title')}</Title>
					</Grid>
					<Grid item xs={12}>
						<Form
							setData={setLocationData}
							setIsValid={setLocationFormValid}
							data={locationData}
							disabled={creating}
						/>
					</Grid>
					<Grid item xs={12}>
						<Title>{t('billing_method_create_title')}</Title>
					</Grid>

					{error && <Grid item xs={12}>
						<Alert severity='error'>
							{error}
						</Alert>
					</Grid>
					}

					<Grid item xs={12}>
						<Stripe
							spacing={2}
							setIntent={setStripeIntent}
							setStripeElements={setStripeElements}
							setStripe={setStripe}
							onChange={onStripeChange}
							disabled={creating}
						/>
					</Grid>

				</Grid>
			</DialogContent>
			<DialogActions>
				<Button color='default' onClick={handleClose} disabled={creating}>
					{t('close_btn')}
				</Button>
				<Button
					onClick={handleCreate}
					color='primary'
					type='submit'
					variant='contained'
					disabled={creating || !saveEnabled}
					loading={creating}
				>
					{t('activate_auto_renew_btn')}
				</Button>
			</DialogActions>

		</>
	);
};

export default AutoRenewCreate;

AutoRenewCreate.propTypes = {
	handleClose: PropTypes.func.isRequired,
	setShell: PropTypes.func.isRequired,
	shell: PropTypes.object.isRequired
};
