import React         from 'react';
import ListItem      from '@material-ui/core/ListItem';
import ListItemIcon  from '@material-ui/core/ListItemIcon';
import ListItemText  from '@material-ui/core/ListItemText';
import Tooltip       from '@material-ui/core/Tooltip';
import { NavLink }   from 'react-router-dom';
import PropTypes     from 'prop-types';
import ModeCheck     from '../Mode/ModeCheck';
import { matchPath } from 'react-router';

const DrawerItem = ({ title, tooltip, Icon, onClick, to, extraActive = [], toProps = {}, forceHide = false, anyMode = false, liteMode = false, advancedMode = false }) => {
	return (
		<ModeCheck advanced={advancedMode} anyMode={anyMode} lite={liteMode} forceHide={forceHide}>
			<Tooltip
				arrow
				title={tooltip}
				placement='right'
			>
				<div>
					<ListItem
						button
						color='inherit'
						component={to ? NavLink : 'div'}
						{...(to ? {
							to: to,
							activeStyle: { backgroundColor: 'rgba(0, 0, 0, .3)' },
							isActive: (match, location) => {
								if (match) return true;
								for (let i = 0; i < extraActive.length; i++) {
									if (matchPath(location.pathname, extraActive[i])) return true;
								}
								return false;
							},
							...toProps
						} : {})}

						onClick={onClick ? onClick : undefined}
					>
						{Icon && <ListItemIcon>
							<Icon className={'c-drawer__icon'}/>
						</ListItemIcon>}
						<ListItemText primary={title} className={'c-drawer__text'}/>
					</ListItem>
				</div>
			</Tooltip>
		</ModeCheck>
	);
};

export default DrawerItem;

DrawerItem.propTypes = {
	Icon: PropTypes.any.isRequired,
	advancedMode: PropTypes.bool,
	anyMode: PropTypes.bool,
	extraActive: PropTypes.array,
	forceHide: PropTypes.bool,
	liteMode: PropTypes.bool,
	onClick: PropTypes.func,
	title: PropTypes.string.isRequired,
	to: PropTypes.string,
	toProps: PropTypes.object,
	tooltip: PropTypes.string.isRequired
};
