import PropTypes                                             from 'prop-types';
import React                                                 from 'react';
import { Grid, Radio }                                       from '@material-ui/core';
import FormControlLabel                                      from '@material-ui/core/FormControlLabel';
import Checkbox                                              from '@material-ui/core/Checkbox';
import { useTranslation }                                    from 'react-i18next';
import FormLabel                                             from '@material-ui/core/FormLabel';
import FormControl                                           from '@material-ui/core/FormControl';
import { SHELL_OS_PURPOSE_LIST, SHELL_OS_PURPOSE_LITE_LIST } from '../../../core/input/OSPurposeSelector';
import { useIsMode }                                         from '../../../../hooks/useIsMode';
import { ADVANCED_MODE }                                     from '../../../../context/ModeContext';

const OsFilters = ({ filters, setFilters, spacing, disabled = false }) => {
	const { t } = useTranslation();
	const isAdvanced = useIsMode(ADVANCED_MODE);

	const handleBeta = e => {
		const checked = e.target.checked;
		const cpy = { ...filters };
		checked ? delete cpy.Beta : cpy.Beta = 'N';
		setFilters(cpy);
	};

	const handleChange = e => {
		const checked = e.target.checked;
		const value = e.target.value;
		const cpy = { ...filters };
		if (!checked) return;

		if (value === 'all') {
			delete cpy.Purpose;
		} else {
			cpy.Purpose = value;
		}

		setFilters(cpy);
	};


	return (
		<FormControl>
			<Grid container spacing={spacing} alignItems='center'>
				<Grid item>
					<FormLabel>{t('shell_os_filter_title')}</FormLabel>
				</Grid>

				{isAdvanced && <Grid item>
					<FormControlLabel
						disabled={disabled}
						control={
							<Radio
								color='primary'
								name='filter-group'
								value='all'
								checked={!('Purpose' in filters)}
								onChange={handleChange}
							/>
						}
						label={t('shell_os_filter_all')}
					/>
				</Grid>
				}

				{
					(isAdvanced ? SHELL_OS_PURPOSE_LIST : SHELL_OS_PURPOSE_LITE_LIST).map(
						purpose => {
							return (
								<Grid item key={purpose}>
									<FormControlLabel
										disabled={disabled}
										control={
											<Radio
												color='primary'
												name='filter-group'
												value={purpose}
												checked={purpose === filters.Purpose}
												onChange={handleChange}
											/>
										}
										label={t(`shell_os_filter_${purpose}`)}
									/>
								</Grid>
							);
						}
					)}

				<Grid item>
					<FormControlLabel
						disabled={disabled}
						control={<Checkbox
							color='primary'
							name='Beta'
							checked={!('Beta' in filters)}
							onChange={handleBeta}/>
						}
						label={t('shell_os_filter_beta')}
					/>
				</Grid>
			</Grid>
		</FormControl>
	);
};

export default OsFilters;

OsFilters.propTypes = {
	disabled: PropTypes.bool,
	filters: PropTypes.object.isRequired,
	setFilters: PropTypes.func.isRequired,
	spacing: PropTypes.number.isRequired
};
