import PropTypes          from 'prop-types';
import React              from 'react';
import { useTranslation } from 'react-i18next';
import { Grid }           from '@material-ui/core';
import Plan               from './Plan';
import AlertTitle         from '@material-ui/lab/AlertTitle';
import Alert              from '@material-ui/lab/Alert';
import ForwardIcon        from '@material-ui/icons/Forward';
import DateUtil           from '../../../../core/data/Date/DateUtil';

const ProductWarning = ({ warning }) => {
	const { t } = useTranslation();

	return (
		<Grid container spacing={3} alignItems='center'>
			{warning.Data &&
			<Grid item xs={12} style={{height:'100%'}}>
				<Alert severity='info'>
					<AlertTitle>{t('warning_product_title')}</AlertTitle>
					<div dangerouslySetInnerHTML={{ __html: warning.Data }}/>
				</Alert>
			</Grid>}
			<Grid item xs={12} md={5}>
				<Plan product={warning.MetaObject}/>
			</Grid>

			{warning.Target_MetaObject__ &&
			<Grid item xs={12} md={2} style={{height:'100%'}}>
				<Grid container alignItems='center' direction='column'>
					<Grid item>
						<ForwardIcon/>
					</Grid>
					<Grid item>
						{t(warning.Expires ? 'warning_will_change_the' : 'warning_will_change_to')}
					</Grid>
					{warning.Expires &&
					<Grid item>
						<strong><DateUtil klbDateObj={warning.Expires} format='LLL'/></strong>
					</Grid>
					}
				</Grid>

			</Grid>}
			{warning.Target_MetaObject__ &&
			<Grid item xs={12} md={5} style={{height:'100%'}}>
				<Plan product={warning.Target_MetaObject} from={warning.MetaObject}/>
			</Grid>
			}

		</Grid>
	);
};

export default ProductWarning;

ProductWarning.propTypes = {
	warning: PropTypes.object.isRequired
};
