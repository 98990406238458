import PropTypes                                          from 'prop-types';
import React, { useEffect }                               from 'react';
import { FUNDING, PayPalButtons, usePayPalScriptReducer } from '@paypal/react-paypal-js';
import CircularProgress                                   from '@material-ui/core/CircularProgress';
import { useTranslation }                                 from 'react-i18next';

const Buttons = ({ order, onApproved, onError = undefined, disabled = false, onCancel = undefined, onLoadFailed, fundingSource = FUNDING.PAYPAL }) => {
	const { t } = useTranslation();

	const [{ isPending, isRejected }] = usePayPalScriptReducer();


	useEffect(() => {
		if (!isRejected) return;
		onLoadFailed();
	}, [isRejected]);

	if (isPending) return <CircularProgress/>;
	if (isRejected) return t('paypal_load_failed_label');

	return (
		<PayPalButtons
			disabled={disabled}
			fundingSource={fundingSource}
			createOrder={order ? (data, actions) => actions.order.create(order) : undefined}
			onApprove={(data) => {
				onApproved(data);
				//return actions.order.capture().then(onComplete);
			}}
			onCancel={onCancel}
			onError={onError}
		/>
	);
};

export default Buttons;

Buttons.propTypes = {
	disabled: PropTypes.bool,
	fundingSource: PropTypes.oneOf([Object.values(FUNDING)]),
	onCancel: PropTypes.func,
	onApproved: PropTypes.func.isRequired,
	onError: PropTypes.func,
	onLoadFailed: PropTypes.func,
	order: PropTypes.object.isRequired
};
