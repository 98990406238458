import React                     from 'react';
import { Trans, useTranslation } from 'react-i18next';
import EmptyState                from '../../../core/feedback/State/Empty';
import Graphic                   from '../../../../assets/img/404.svg';

const NotFound = () => {
	const { t } = useTranslation();
	return (
		<EmptyState
			title={t('billing_notfound_title')}
			text={<Trans i18nKey='billing_notfound_text'>
				sample<br/>
				<strong>sample</strong>
			</Trans>}
			graphic={Graphic}
		/>
	);
};

export default NotFound;
