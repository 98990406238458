import React, { useEffect, useState } from 'react';
import List                           from '@material-ui/core/List';
import AllInclusiveIcon               from '@material-ui/icons/AllInclusive';
import SpaIcon                        from '@material-ui/icons/Spa';
import { getSwitchToModeRoute }       from '../../RouteController/Factory';
import DrawerItem                     from './DrawerItem';
import { useTranslation }             from 'react-i18next';
import { ADVANCED_MODE, LITE_MODE }   from '../../../context/ModeContext';
import { useLocation }                from 'react-router';

const DrawerMode = () => {
	const { t } = useTranslation();
	const location = useLocation();
	const [returnTo, setReturnTo] = useState('');


	useEffect(() => {
		setReturnTo(encodeURIComponent(`${location.pathname}${location.search}${location.hash}`));
	}, [location, setReturnTo]);

	return (
		<List>
			<DrawerItem
				liteMode
				Icon={AllInclusiveIcon}
				tooltip={t('mode_switch_advanced')}
				title={t('mode_switch_advanced')}
				to={`${getSwitchToModeRoute(ADVANCED_MODE.toLowerCase())}?to=${returnTo}`}
				toProps={{
					exact: false
				}}
			/>
			<DrawerItem
				advancedMode
				Icon={SpaIcon}
				tooltip={t('mode_switch_lite')}
				title={t('mode_switch_lite')}
				to={`${getSwitchToModeRoute(LITE_MODE.toLowerCase())}?to=${returnTo}`}
				toProps={{
					exact: false
				}}
			/>
		</List>
	);
};

export default DrawerMode;
