import React, { useState }         from 'react';
import { useTranslation }          from 'react-i18next';
import { useParams }               from 'react-router';
import PageLoader                  from '../../components/layout/Loader/PageLoader';
import PageNotFound                from '../System/PageNotFound';
import PageContainer               from '../../components/layout/Container/PageContainer';
import { Box, Grid }               from '@material-ui/core';
import PageTitle                   from '../../components/core/typography/PageTitle';
import { getShellsRoute }          from '../../components/RouteController/Factory';
import ShellPicture                from '../../components/common/Shell/ShellPicture/ShellPicture';
import ShellAction                 from '../../components/common/Shell/ShellAction/ShellAction';
import ShellUsage                  from '../../components/common/Shell/Subscription/ShellUsage';
import ShellExpireButton           from '../../components/common/Shell/ShellExpireButton';
import ShellInfo                   from '../../components/common/Shell/ShellInfo';
import ShellUpgradeButton          from '../../components/common/Shell/ShellUpgradeButton';
import useMediaQuery               from '@material-ui/core/useMediaQuery';
import PreparingRefresh            from '../../components/common/Shell/PreparingRefresh';
import { isExpired }               from '../../utils/shell';
import ShellExpiredMessage         from '../../components/common/Shell/Subscription/ShellExpiredMessage';
import ModeCheck                                                from '../../components/layout/Mode/ModeCheck';
import { useShell }                                             from '@karpeleslab/klb-react-services';
import { useAutoRefresher }                                     from '../../hooks/useAutoRefresher';
import { SHELL_OS_ICON_VARIATION }                              from '../../hooks/useShells';
import SettingsTab                                              from './Shell/SettingsTab';
import AdministrationTab                                        from './Shell/AdministrationTab';
import SnapshotsTab                                             from './Shell/SnapshotsTab';
import ReinstallTab                                             from './Shell/ReinstallTab';
import ISOTab                                                   from './Shell/ISOTab';
import ShellTabs                                                from './Shell/ShellTabs';
import SupportTab                                               from './Shell/SupportTab';
import ShellWindowsOsWarning, { isShellWindowsOSWarningNeeded } from '../../components/common/Shell/ShellWindowsOSWarning';

const Shell = () => {
	const { t } = useTranslation();
	const { shellId } = useParams();
	const [params] = useState({ image_variation: [SHELL_OS_ICON_VARIATION] });
	const [shell, refresh] = useShell(shellId, params);
	useAutoRefresher(refresh);

	const [tabIndex, setTabIndex] = useState(0);

	const isMobile = useMediaQuery('(max-width:608px)', { noSsr: true });
	const isSmall = useMediaQuery(theme => theme.breakpoints.down('md'), { noSsr: true });

	if (!shell) return <PageLoader/>;
	if (!shell.data) return <PageNotFound/>;

	return (
		<PageContainer>
			<Grid
				container
				spacing={3}
				direction={isMobile ? 'column' : 'row'}
				alignItems={isMobile ? 'center' : undefined}
			>
				<Grid item xs={12}>
					<PageTitle
						title={t('shell_title', { name: shell.data.Label })}
						backButton={getShellsRoute()}
					/>
				</Grid>

				{isExpired(shell.data) &&

				<Grid item xs={12}>
					<ShellExpiredMessage
						shell={shell.data}
						refresh={isMobile ? undefined : refresh}/>
				</Grid>
				}

				{isShellWindowsOSWarningNeeded(shell.data, shell.data.OS) &&
				<Grid item xs={12}>
					<ShellWindowsOsWarning/>
				</Grid>
				}

				<Grid item xs={12}>
					<PreparingRefresh refresh={refresh} shell={shell.data}/>
				</Grid>

				<Grid item>
					<Grid container spacing={3} direction='column' alignItems='center'>
						<Grid item xs={12}>
							<ShellPicture
								flagSize={isMobile ? 'small' : 'medium'}
								stateSize={isMobile ? 'medium' : 'large'}
								setShell={refresh}
								shell={shell.data}
								style={{ maxWidth: '285px' }}
								width={isMobile ? '100px' : (isSmall ? '150px' : '285px')}
							/>
						</Grid>
						<ModeCheck advanced>
							<Grid item xs={12}>
								<ShellAction setShell={refresh} shell={shell.data}/>
							</Grid>
						</ModeCheck>
					</Grid>
				</Grid>

				<Grid item>
					<ShellInfo shell={shell.data}/>
				</Grid>

				<Grid item>
					<Box pt={2}>
						<Grid container spacing={3}>
							<Grid item xs={12}>
								<ShellUsage shell={shell.data}/>
							</Grid>
							<Grid item xs={12} md={6} lg={12}>
								<ShellExpireButton
									shell={shell.data}
									refresh={refresh}
								/>
							</Grid>
							{!isExpired(shell.data) &&
							<Grid item xs={12} md={6} lg={12}>
								<ShellUpgradeButton shell={shell.data} refresh={refresh}/>
							</Grid>
							}
						</Grid>
					</Box>
				</Grid>

				<Grid item xs={12}>
					<ShellTabs value={tabIndex} setValue={setTabIndex} shell={shell.data}/>
				</Grid>

				<Grid item xs={12}>
					<SettingsTab currentTab={tabIndex} refresh={refresh} shell={shell.data}/>
					<AdministrationTab currentTab={tabIndex} refresh={refresh} shell={shell.data}/>
					<SnapshotsTab currentTab={tabIndex} refresh={refresh} shell={shell.data}/>
					<ReinstallTab currentTab={tabIndex} refresh={refresh} shell={shell.data}/>
					<ISOTab currentTab={tabIndex} refresh={refresh} shell={shell.data}/>
					<SupportTab currentTab={tabIndex} refresh={refresh} shell={shell.data}/>
				</Grid>
			</Grid>
		</PageContainer>
	);
};

export default Shell;
