import PropTypes                      from 'prop-types';
import React, { useEffect, useState } from 'react';
import Grid                           from '@material-ui/core/Grid';
import TextField                      from '@material-ui/core/TextField';
import { useTranslation }             from 'react-i18next';
import DialogContent                  from '@material-ui/core/DialogContent';
import DialogActions                  from '@material-ui/core/DialogActions';
import Button                         from '../../../../core/input/Button';
import { useUserOTPCreate }           from '@karpeleslab/klb-react-services';
import { TAB_ASSOCIATE }              from './AddDialog';

const StepCreate = ({ handleClose, setStep, setOtp, setLoading }) => {
	const { t } = useTranslation();
	const [otpName, setOtpName] = useState();
	const [create, creating] = useUserOTPCreate();

	useEffect(() => {
		setLoading(creating);
	}, [creating]);

	const handleCreate = () => {
		create(otpName.trim())
			.then(res => {
				setOtp(res);
				setStep(TAB_ASSOCIATE);
			});
	};

	return (
		<>
			<DialogContent>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<TextField
							label={t('otp_name_label')}
							helperText={t('otp_name_helperText')}
							fullWidth
							value={otpName}
							onChange={(e) => setOtpName(e.target.value)}
							variant='outlined'
							required
							disabled={creating}
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button color='default' onClick={handleClose}>{t('close_btn')}</Button>
				<Button
					loading={creating}
					disabled={creating || !(otpName ?? '').trim()}
					variant='contained'
					type='submit'
					color='primary'
					onClick={handleCreate}
				>
					{t('otp_step_create_btn')}
				</Button>

			</DialogActions>
		</>
	);
};

export default StepCreate;

StepCreate.propTypes = {
	handleClose: PropTypes.func.isRequired,
	setLoading: PropTypes.func.isRequired,
	setOtp: PropTypes.func.isRequired,
	setStep: PropTypes.func.isRequired
};
