import PropTypes                        from 'prop-types';
import React, { useCallback, useState } from 'react';
import { Grid }                         from '@material-ui/core';
import { Title }                        from '../../../core/typography/Title';
import { useTranslation }               from 'react-i18next';
import { useHistory }                   from 'react-router';
import { getShellsRoute }               from '../../../RouteController/Factory';
import RichAlertTrans                   from '../../../core/feedback/RichAlertTrans';
import TextField                        from '@material-ui/core/TextField';
import Button                           from '../../../core/input/Button';
import useMediaQuery                    from '@material-ui/core/useMediaQuery';
import { useShellTransfer }             from '@karpeleslab/klb-react-services';
import TwoStepDialog                    from '../../Account/TwoStep/TwoStepDialog';

const ShellTransfer = ({ shell }) => {
	const { t } = useTranslation();

	const [transfer, transferring] = useShellTransfer(shell.Shell__);

	const [showTwoStep, setShowTwoStep] = useState(false);
	const [session, setSession] = useState();
	const [email, setEmail] = useState();
	const history = useHistory();
	const isMobile = useMediaQuery(theme => theme.breakpoints.down('xs'));

	const onComplete = useCallback(() => {
		history.push(getShellsRoute());
	}, []);

	const handleSubmit = e => {
		e.preventDefault();
		transfer(email).then((session) => {
			setSession(session);
			setShowTwoStep(true);
		});
	};

	return (
		<>
			<form onSubmit={handleSubmit}>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<Title>{t('shell_transfer_title')}</Title>
					</Grid>
					<Grid item xs={12}>
						<RichAlertTrans i18nKey='shell_transfer_description' severity='info'/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							type='email'
							variant='outlined'
							label={t('shell_transfer_email')}
							helperText={t('shell_transfer_email_helperText')}
							required
							fullWidth
							value={email}
							disabled={showTwoStep || transferring}
							onChange={e => setEmail(e.target.value)}
						/>
					</Grid>
					<Grid item xs={12}>
						<Grid
							container
							justify={isMobile ? 'center' : 'flex-end'}
							spacing={3}
						>
							<Grid item>
								<Button
									color='primary'
									type='submit'
									variant='contained'
									disabled={showTwoStep || !email}
									loading={showTwoStep || transferring}
								>
									{t('transfer_btn')}
								</Button>
							</Grid>
						</Grid>

					</Grid>


				</Grid>
			</form>
			<TwoStepDialog
				open={showTwoStep}
				setOpen={setShowTwoStep}
				session={session}
				onComplete={onComplete}
				snackMessageToken='shell_transfer_done'
				extra={<RichAlertTrans i18nKey='shell_transfer_warn' severity='warning'/>}
			/>
		</>
	);
};

export default ShellTransfer;

ShellTransfer.propTypes = {
	shell: PropTypes.object.isRequired
};
