import PropTypes                 from 'prop-types';
import React                     from 'react';
import { useTranslation }        from 'react-i18next';
import Table                     from '../../core/data/table/Table';
import TableDefinition           from '../../core/data/table/TableDefinition';
import DateUtil                  from '../../core/data/Date/DateUtil';
import { useMoneyBankWithdraws } from '@karpeleslab/klb-react-services';

const PendingPayout = ({ affiliate, statuses = [] }) => {
	const { t } = useTranslation();
	const [withdraw, fetch, loading] = useMoneyBankWithdraws();

	return (
		<Table
			title={t('partnership_pending_payout_title')}
			initialFilters={{ Status: statuses }}
			fetch={fetch}
			data={withdraw}
			search={false}
			loading={loading}
			paperProps={{ elevation: 0 }}
			toolbarProps={{ style: { paddingLeft: 0 } }}
		>
			<TableDefinition label={t('money_withdraw_requested')} name='Invoice_Date'>
				{w => <DateUtil klbDateObj={w.Requested} format='LLL'/>}
			</TableDefinition>

			<TableDefinition label={t('money_withdraw_target')} name='Label'>
				{() => affiliate.Money_Bank_Account ? affiliate.Money_Bank_Account.Label : '-'}
			</TableDefinition>

			<TableDefinition label={t('money_withdraw_status')} name='Status'>
				{w => t(`partnership_payout_status_${w.Status.toLocaleLowerCase()}`)}
			</TableDefinition>

			<TableDefinition label={t('money_withdraw_amount')} name='Order__' align='right'>
				{w => w.Amount.display ?? '-'}
			</TableDefinition>
		</Table>
	);
};

export default PendingPayout;

PendingPayout.propTypes = {
	affiliate: PropTypes.object.isRequired,
	statuses: PropTypes.array
};
