import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { ICON_VARIATION } from '../../../../hooks/useOperatingSystems';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Grid, Typography } from '@material-ui/core';
import TablePaginationActions from '@material-ui/core/TablePagination/TablePaginationActions';
import TablePagination from '@material-ui/core/TablePagination';
import { useOperatingSystems } from '@karpeleslab/klb-react-services';
import OsItem from './OSItem';
import OsFilters from './OSFilters';
import useDimensions from 'react-cool-dimensions';
import useTheme from '@material-ui/core/styles/useTheme';

const OsList = ({ shell, value, setValue, setShowDetailsFor, own = false, disabled = false, spacing = 3, initialFilters = { Beta: 'N' } }) => {
	const { t } = useTranslation();
	const [os, fetch] = useOperatingSystems();
	const [loading, setLoading] = useState(true);
	const [filters, setFilters] = useState({
		Ready: 'Y',
		image_variation: [ICON_VARIATION],
		...initialFilters
	});
	const [paging, setPaging] = useState({ results_per_page: 12, page_no: 1 });
	const theme = useTheme();
	const [perRow, setPerRow] = useState(6);

	const { observe } = useDimensions({
		breakpoints: {
			'2': theme.breakpoints.values.xs,
			'3': theme.breakpoints.values.sm,
			'4': theme.breakpoints.values.md,
			'6': theme.breakpoints.values.lg
		},
		updateOnBreakpointChange: true,
		onResize: ({ currentBreakpoint }) => {
			setPerRow(parseInt(currentBreakpoint));
		},
	});

	useEffect(() => {
		const params = { ...filters };
		params.Visible = own ? 'N' : 'Y';

		setLoading(true);
		setValue(null);
		fetch(params, paging).then(() => setLoading(false));
	}, [own, filters, paging, setLoading]);

	const changePageHandler = (e, newPage) => {
		setPaging({ ...paging, page_no: parseInt(newPage) + 1 });
	};

	if (!loading && !os) return <CircularProgress/>;

	return (
		<Grid container spacing={spacing} ref={observe}>
			<Grid item xs={12}>
				<OsFilters
					setFilters={setFilters}
					filters={filters}
					spacing={spacing}
					disabled={loading || disabled}
				/>
			</Grid>

			{
				(
					(!loading && os) ? os.data : [...Array(4).keys()]
				).map(
					o => {
						return (
							<Grid
								item
								xs={12 / perRow}
								key={loading ? o : o.Shell_OS__}
								style={{ height: 'auto' }}
							>
								<OsItem
									shell={shell}
									setShowDetailsFor={setShowDetailsFor}
									loading={loading}
									disabled={disabled}
									os={loading ? null : o}
									setSelected={() => setValue(o)}
									selected={loading ? false : (value ? value.Shell_OS__ === o.Shell_OS__ : false)}
								/>
							</Grid>
						);
					}
				)
			}
			{(!loading && os && os.data.length < 1) &&
			<Grid item xs={12}>
				<Typography align='center' variant='h5'>
					{t('shell_os_filter_no_result')}
				</Typography>
			</Grid>
			}
			{(!loading && os && os.paging.page_max > 1) &&
			<Grid item xs={12}>
				<TablePagination
					component='div'
					rowsPerPageOptions={[]}
					count={os.paging.count}
					rowsPerPage={paging.results_per_page}
					page={paging.page_no - 1}
					labelRowsPerPage={t('rows_per_page')}
					labelDisplayedRows={
						({ from, to, count }) => t(count !== -1 ? 'table_paging' : 'table_paging_more', {
							from: from,
							to: to,
							count: count
						})
					}
					onChangePage={changePageHandler}
					ActionsComponent={TablePaginationActions}
				/>
			</Grid>
			}
		</Grid>
	);
};

export default OsList;

OsList.propTypes = {
	disabled: PropTypes.bool,
	initialFilters: PropTypes.object,
	own: PropTypes.bool,
	setShowDetailsFor: PropTypes.func.isRequired,
	setValue: PropTypes.func.isRequired,
	shell: PropTypes.object.isRequired,
	spacing: PropTypes.number,
	value: PropTypes.any
};
