import PropTypes                      from 'prop-types';
import { useTranslation }             from 'react-i18next';
import React, { useEffect, useState } from 'react';
import Table                        from '../../../core/data/table/Table';
import TableDefinition              from '../../../core/data/table/TableDefinition';
import DateUtil                     from '../../../core/data/Date/DateUtil';
import AddIcon                      from '@material-ui/icons/Add';
import Button                       from '../../../core/input/Button';
import AddVersionDialog             from './AddVersionDialog';
import moment                       from 'moment';
import { Grid }                     from '@material-ui/core';
import RichAlertTrans               from '../../../core/feedback/RichAlertTrans';
import Chip                         from '@material-ui/core/Chip';
import { getShellViewRoute }        from '../../../RouteController/Factory';
import { useOperatingSystemImages } from '@karpeleslab/klb-react-services';

const OSImageTable = ({ os }) => {
	const { t } = useTranslation();
	const [images, fetch, loading] = useOperatingSystemImages(os.Shell_OS__);
	const [showCreate, setShowCreate] = useState(false);


	const [canAddNewVersion, setCanAddNewVersion] = useState(false);
	const [hasValidImage, setHasValidImage] = useState(false);

	// The Image disk version is formatted as follow: YYYYMMDD (UTC)
	// It should be unique in the database, it means we can only add 1 Disk Image per UTC day
	useEffect(() => {
		if (!images) return;
		const today = moment.utc().format('YYYYMMDD');

		let canAdd = true;
		let hasValid = false;
		for (let i = 0; i < (images.data ?? []).length; i++) {
			if (images.data[i].Version === today) {
				canAdd = false;
			}
			if (images.data[i].Status === 'valid') hasValid = true;
		}
		setCanAddNewVersion(canAdd);
		setHasValidImage(hasValid);
	}, [images]);

	return (
		<Grid container spacing={3}>
			{!hasValidImage && <Grid item xs={12}>
				<RichAlertTrans i18nKey='custom_os_no_valid_image' severity='warning'/>
			</Grid>
			}
			<Grid item xs={12}>
				<Table
					extra={
						[
							(<Button
								key='add-os-image'
								onClick={() => setShowCreate(true)}
								size="small"
								variant="contained"
								color="primary"
								startIcon={<AddIcon/>}
							>
								{t('add_os_image_version_btn')}
							</Button>)
						]
					}
					title={t('custom_os_image_title')}
					fetch={fetch}
					data={images}
					search={false}
					loading={loading}
					paperProps={{ elevation: 0 }}
				>
					<TableDefinition label={t('custom_os_image_name')} name='Filename'>
						{image => {
							if (image.Source !== 'volume' || !image.Shell_Volume) return image.Filename;
							if (!image.Shell_Volume.Shell)
								return <Chip size="small" label={t('custom_os_image_from_volume')}/>;
							return <Chip
								size="small"
								label={t('custom_os_image_from_shell', { name: image.Shell_Volume.Shell.Label })}
								component="a"
								href={getShellViewRoute(image.Shell_Volume.Shell.Shell__)}
								clickable
							/>;
						}}
					</TableDefinition>
					<TableDefinition label={t('custom_os_image_version')} name='Version'/>
					<TableDefinition label={t('custom_os_image_hash')} name='Hash'>
						{image =>
							image.Hash ?
								image.Hash :
								<Chip
									color='primary'
									variant='outlined'
									size="small"
									label={t('custom_os_image_hash_pending')}
								/>}
					</TableDefinition>
					<TableDefinition label={t('custom_os_image_created')} name='Created'>
						{image => <DateUtil klbDateObj={image.Created} format='LLL'/>}
					</TableDefinition>

				</Table>
			</Grid>

			<AddVersionDialog
				setOpen={setShowCreate}
				refresh={fetch}
				open={showCreate}
				canAddNewVersion={canAddNewVersion}
				os={os}
			/>
		</Grid>
	);
};

export default OSImageTable;

OSImageTable.propTypes = {
	os: PropTypes.object.isRequired
};
