import PropTypes              from 'prop-types';
import React                  from 'react';
import Panel                  from '../../../components/layout/Container/tab/Panel';
import { Grid }               from '@material-ui/core';
import { Title }              from '../../../components/core/typography/Title';
import ShellDebugButton       from '../../../components/common/Shell/ShellDebugButton';
import { useTranslation }     from 'react-i18next';
import ClipboardCopyTextField from '../../../components/core/input/ClipboardCopyTextField';
import RichAlertTrans         from '../../../components/core/feedback/RichAlertTrans';
import useMediaQuery          from '@material-ui/core/useMediaQuery';

const SupportTab = ({ currentTab, shell }) => {
	const { t } = useTranslation();
	const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'), { noSsr: true });

	return (
		<Panel value={currentTab} index={SHELL_TAB_SUPPORT}>
			<Grid container spacing={3} alignItems='flex-end' justify='space-between'>
				<Grid item xs={12}><Title>{t('shell_debug_title')}</Title></Grid>
				<Grid item xs={12}>
					<RichAlertTrans i18nKey='shell_debug_warn' severity='warning'/>
				</Grid>
				<Grid item xs={12} md={6}>
					<ClipboardCopyTextField
						fullWidth
						value={shell.Shell__}
						variant='outlined'
						label={t('shell_debug_unique_identifier')}
						disabled
					/>
				</Grid>
				<Grid item xs={isMobile ? 12 : undefined}>
					<ShellDebugButton shell={shell} fullWidth/>
				</Grid>
			</Grid>
		</Panel>
	);
};

export default SupportTab;
export const SHELL_TAB_SUPPORT = 5;

SupportTab.propTypes = {
	currentTab: PropTypes.number.isRequired,
	shell: PropTypes.object.isRequired
};
