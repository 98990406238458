import React                from 'react';
import { useTranslation }   from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';
import Box                  from '@material-ui/core/Box';
import Link                 from './Link';

const Legal = () => {
	const { t } = useTranslation();
	return (
		<Grid container spacing={1} direction='column'>
			<Grid item xs={12}>
				<Box my={2}>
					<Typography variant='h4'>{t('footer_nav_legal')}</Typography>
				</Box>
			</Grid>
			<Grid item xs={12}>
				<Link href={`${process.env.REACT_APP_SHELL_URL}/page/terms`}>
					{t('footer_terms_conditions')}
				</Link>
			</Grid>
			<Grid item xs={12}>
				<Link href={`${process.env.REACT_APP_SHELL_URL}/page/privacy`}>
					{t('footer_privacy_policy')}
				</Link>
			</Grid>
		</Grid>
	);
};

export default Legal;
