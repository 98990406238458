export default () => {
	let dt = new Date().getTime();
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
		let r = (dt + Math.random() * 16) % 16 | 0;
		dt = Math.floor(dt / 16);
		return (c === 'x' ? r : ((r & 0x3) | 0x8)).toString(16);
	});
};

export const validate = (uuid, prefix= null) => {
	const r1 = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/;
	if (r1.exec(uuid) !== null) return true;

	if(prefix && !uuid.startsWith(prefix)) return false;

	const r2 = /[a-z2-7]{6}-[a-z2-7]{4}-[a-z2-7]{4}-[a-z2-7]{4}-[a-z2-7]{8}$/;
	return r2.exec(uuid) !== null;
};
