import React, { useCallback, useEffect } from 'react';
import PropTypes                         from 'prop-types';
import PaypalPayment                     from '../../../../../core/input/Paypal/Paypal';
import Grid                              from '@material-ui/core/Grid';
import RichAlertTrans                    from '../../../../../core/feedback/RichAlertTrans';
import { useOrderProcess }               from '@karpeleslab/klb-react-services';
import Button                            from '../../../../../core/input/Button';
import { useTranslation }                from 'react-i18next';

const Paypal = ({ order, setPaying, settings, refreshOrder, setDialogActions = null }) => {
	const { t } = useTranslation();

	const [processOrder, processing] = useOrderProcess(order.Order__);

	const handleComplete = useCallback((data) => {
		processOrder(
			settings.session,
			settings.method,
			{ order_id: data.orderID }
			, { snackMessageToken: 'order_paid', handleError: false })
			.then(d => refreshOrder(d.order));
	}, []);

	useEffect(() => {
		setPaying(processing);
	}, [setPaying, processing]);

	useEffect(() => {
		if (!setDialogActions) return;
		if (processing) {
			setDialogActions(
				<Button
					variant='contained'
					color='primary'
					disabled={true}
					loading={true}
				>
					{t('processing_order_btn')}
				</Button>
			);
		} else {
			setDialogActions(
				<PaypalPayment
					disabled={processing}
					clientId={settings.fields._internal_finalize.attributes.client_id}
					order={settings.fields.order_id.attributes.options}
					onApproved={handleComplete}
					style={{ height: 24 }}
				/>);
		}

	}, [setDialogActions, processing]);

	return (
		<Grid container spacing={3} justify='center' alignItems='center'>
			<Grid item xs={12}>
				<RichAlertTrans i18nKey='paypal_info_desc' severity='info'/>
			</Grid>
			{(!setDialogActions && !processing) && <Grid item xs={12}>
				<PaypalPayment
					processing={processing}
					clientId={settings.fields._internal_finalize.attributes.client_id}
					order={settings.fields.order_id.attributes.options}
					onApproved={handleComplete}
				/>
			</Grid>
			}
			{(!setDialogActions && processing) && <Grid item xs={12}>
				<Button
					variant='contained'
					color='primary'
					disabled={true}
					loading={true}
				>
					{t('processing_order_btn')}
				</Button>
			</Grid>
			}
		</Grid>
	);
};

export default Paypal;

Paypal.propTypes = {
	order: PropTypes.object.isRequired,
	refreshOrder: PropTypes.func.isRequired,
	setDialogActions: PropTypes.func,
	setPaying: PropTypes.func.isRequired,
	settings: PropTypes.object.isRequired
};
