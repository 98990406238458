import PropTypes                                      from 'prop-types';
import React                                          from 'react';
import { useTranslation }                             from 'react-i18next';
import FormGroup                                      from '@material-ui/core/FormGroup';
import FormControlLabel                               from '@material-ui/core/FormControlLabel';
import Checkbox                                       from '@material-ui/core/Checkbox';
import { Grid }                                       from '@material-ui/core';
import { useUserOTPSetAction, useUserOTPUnsetAction } from '@karpeleslab/klb-react-services';

const ACTIONS = ['login'];

const OtpActions = ({ otp, refresh }) => {
	const { t } = useTranslation();
	const [set, setting] = useUserOTPSetAction(otp.User_Otp__);
	const [unset, unsetting] = useUserOTPUnsetAction(otp.User_Otp__);

	const setHandler = action => {
		set(action).then(refresh);
	};

	const unsetHandler = action => {
		unset(action).then(refresh);
	};

	const isActivated = action => {
		return (otp.Actions ?? []).includes(action);
	};

	const createChangeHandler = action => e => {
		if (e.target.checked) setHandler(action);
		else unsetHandler(action);
	};

	return (
		<FormGroup>
			<Grid container spacing={3}>
				{ACTIONS.map(a =>
					<Grid item key={`opt_action_${a}`}>
						<FormControlLabel
							control={<Checkbox
								color='primary'
								disabled={setting || unsetting}
								checked={isActivated(a)}
								onChange={createChangeHandler(a)}
								name={a}/>
							}
							label={t(`opt_action_${a}`)}
						/>
					</Grid>
				)}
			</Grid>
		</FormGroup>
	);
};

export default OtpActions;

OtpActions.propTypes = {
	otp: PropTypes.object,
	refresh: PropTypes.func.isRequired
};
