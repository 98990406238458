import React, { useContext } from 'react';
import LoginComponent        from './../../components/Login/Login';
import Helmet                from 'react-helmet';
import { useHistory }        from 'react-router';
import { useTranslation }        from 'react-i18next';
import { getPrefix }             from '@karpeleslab/klbfw';
import { UserContext }           from '../../context/UserContext';
import { getShellsRoute }        from '../../components/RouteController/Factory';
import Redirect                  from '../../components/core/utils/Redirect';
import Grid                      from '@material-ui/core/Grid';
import Hidden                    from '@material-ui/core/Hidden';
import Box                       from '@material-ui/core/Box';
import Graphic                   from '../../assets/img/shells_logo_vertical_white.svg';
import makeStyles                from '@material-ui/core/styles/makeStyles';
import Button                    from '../../components/core/input/Button';
import { LoginContextContainer } from '@karpeleslab/klb-react-services';

const useStyles = makeStyles((theme) => {
	return {
		fillHeight: {
			height: '100vh',
		},
		container: {
			width: '100%',
			[theme.breakpoints.down('sm')]: {
				paddingRight: '12px',
				paddingLeft: '12px',
				margin: '0px'
			}
		},

	};
});

const Login = () => {
	const [user, setUser] = useContext(UserContext);
	const history = useHistory();
	const { t } = useTranslation();
	const classes = useStyles();

	const onValidated = (data) => {
		setUser({
			loggedIn: true,
			user: { data: data.user }
		});
		history.push(data.Redirect.replace(getPrefix(), ''));
	};

	if (user.loggedIn)
		return <Redirect target={getShellsRoute()}/>;

	return (
		<main>
			<Helmet>
				{ /* https://devhints.io/html-meta */}
				<title>{t('login')}</title>
			</Helmet>
			<Grid
				container
				justify='center'
				alignItems='center'
				className={classes.fillHeight + ' ' + classes.container}
			>
				<Hidden smDown>
					<Grid item md={7} className={classes.fillHeight}>
						<Box bgcolor="primary.main" className={classes.fillHeight}>
							<Grid
								container
								direction="row"
								justify="center"
								alignItems="center"
								className={classes.fillHeight}
							>
								<Grid item xs={6}>
									<img src={Graphic} width='100%' alt='Shells™'/>
								</Grid>
							</Grid>
						</Box>
					</Grid>
				</Hidden>
				<Grid item xs={12} md={5}>
					<Grid
						container
						direction="row"
						justify="center"
						alignItems="center"
						className={classes.fillHeight}
					>
						<Grid item xs={12} md={8}>
							<LoginContextContainer onValidated={onValidated}>
								<LoginComponent
									profilePicture={false}
									TitleComponent='h3'
									ButtonComponent={Button}
								/>
							</LoginContextContainer>

						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</main>
	);
};

export default Login;
