import React                     from 'react';
import { Trans, useTranslation } from 'react-i18next';

import EmptyState from './../../../../core/feedback/State/Empty';
import Graphic    from './../../../../../assets/img/oauthTokens.svg';

const Empty = () => {
	const { t } = useTranslation();

	return (
		<EmptyState
			title={t('profile_tokens_empty_title')}
			text={<Trans i18nKey='profile_tokens_empty_desc'>
				sample<br/>
				<strong>sample</strong>
			</Trans>}
			graphic={Graphic}
		/>
	);

};

export default Empty;
