import React                     from 'react';
import { Trans, useTranslation } from 'react-i18next';
import EmptyState                from '../../../core/feedback/State/Empty';
import Graphic                   from '../../../../assets/img/emptyCart.svg';

const EmptyCart = () => {
	const { t } = useTranslation();

	return (
		<EmptyState
			title={t('cart_empty_title')}
			text={<Trans i18nKey='cart_empty_desc'>
				sample<br/>
				<strong>sample</strong>
			</Trans>}
			graphic={Graphic}
		/>
	);
};

export default EmptyCart;
