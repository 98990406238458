import React, { useState }            from 'react';
import { useTranslation }             from 'react-i18next';
import { useRest }                    from '@karpeleslab/react-klbfw-hooks';
import Dialog                         from '@material-ui/core/Dialog';
import DialogActions                  from '@material-ui/core/DialogActions';
import DialogContent                  from '@material-ui/core/DialogContent';
import DialogContentText              from '@material-ui/core/DialogContentText';
import DialogTitle                    from '@material-ui/core/DialogTitle';
import Grid                           from '@material-ui/core/Grid';
import List                           from '@material-ui/core/List';
import CircularProgress               from '@material-ui/core/CircularProgress';
import PropTypes                      from 'prop-types';
import Button                         from '../../../core/input/Button';
import OAuthItem                      from './OAuthItem';
import { getOAuth2ConsumersEndpoint } from '@karpeleslab/klb-react-services';

const AddDialog = ({ open, setOpen }) => {
	const { t } = useTranslation();
	const [selectedConsumer, setSelectedConsumer] = useState(null);
	const [consumers] = useRest(getOAuth2ConsumersEndpoint());

	return (
		<Dialog
			open={open}
			aria-labelledby={t('link_service')}
			aria-describedby={t('link_service')}
		>
			<DialogTitle id='link-service-title'>{t('link_service')}</DialogTitle>
			<DialogContent>
				<DialogContentText id='link-service-description'>
					{t('link_service_description')}
				</DialogContentText>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						{selectedConsumer && <CircularProgress thickness={1}/>}
						{!selectedConsumer &&

						<List>
							{
								consumers && consumers.data && consumers.data.map(
									consumer => {
										const selected = selectedConsumer && selectedConsumer.OAuth2_Consumer__ === consumer.OAuth2_Consumer__;

										return (
											<OAuthItem
												setSelected={setSelectedConsumer}
												key={consumer.OAuth2_Consumer__}
												consumer={consumer}
												disabled={!!selectedConsumer}
												selected={selected}
											/>);
									}
								)
							}
						</List>
						}
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button
					color='default'
					onClick={() => setOpen(false)} disabled={!!selectedConsumer}
				>
					{t('close_btn')}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default AddDialog;

AddDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	setOpen: PropTypes.func.isRequired,
};
