import PropTypes                 from 'prop-types';
import React, { useState }       from 'react';
import { Grid }                  from '@material-ui/core';
import TextField                 from '@material-ui/core/TextField';
import { useTranslation }        from 'react-i18next';
import { useCatalogCartProcess } from '@karpeleslab/klb-react-services';
import AddShoppingCartIcon       from '@material-ui/icons/AddShoppingCart';
import InputAdornment            from '@material-ui/core/InputAdornment';
import Tooltip                   from '@material-ui/core/Tooltip';
import IconButton                from '@material-ui/core/IconButton';

const CartAddCoupon = ({ refresh }) => {
	const { t } = useTranslation();
	const [coupon, setCoupon] = useState('');
	const [processQuery, processingCart] = useCatalogCartProcess();

	const handleAdd = () => {
		const query = `coupon,coupon_code=${coupon.trim()}`;
		processQuery(query, { snackMessageToken: null })
			.then(cart => {
				setCoupon('');
				refresh(cart);
			});
	};

	return (
		<Grid container spacing={3} justify='flex-end'>
			<Grid item xs={12} md={4}>
				<TextField
					size='small'
					variant='outlined'
					label={t('cart_add_coupon')}
					helperText={t('cart_add_coupon_helperText')}
					disabled={processingCart}
					fullWidth
					value={coupon}
					onChange={e => setCoupon(e.target.value)}
					InputProps={
						{
							endAdornment:
								(<InputAdornment position='end'>

									<Tooltip title={t('apply_btn')} placement='down' arrow={false}>
										<IconButton
											size='small'
											color='primary'
											disabled={processingCart || !coupon.trim()}
											onClick={handleAdd}
										>
											<AddShoppingCartIcon/>
										</IconButton>
									</Tooltip>
								</InputAdornment>)
						}

					}
				/>
			</Grid>
		</Grid>
	);
};

export default CartAddCoupon;

CartAddCoupon.propTypes = {
	refresh: PropTypes.func.isRequired
};
