import PropTypes                     from 'prop-types';
import React, { useEffect }          from 'react';
import ShellOpenWrapper              from '../ShellOpenWrapper';
import { IconButton }                from '@material-ui/core';
import { PlayArrow }                 from '@material-ui/icons';
import Tooltip                       from '@material-ui/core/Tooltip';
import { useTranslation }            from 'react-i18next';
import { useShellStart }             from '@karpeleslab/klb-react-services';
import { SHELL_START_ENABLE_STATES } from '../../../../hooks/useShells';

const ShellActionStart = ({ setShell, shell, setLoading, disabled = false }) => {
	const { t } = useTranslation();
	const [start, starting] = useShellStart(shell.Shell__);

	useEffect(() => {
		setLoading(starting);
	}, [starting]);

	const startHandler = s => {
		start().then(() => setShell({ ...s, State: 'running' }));
	};

	return (
		<Tooltip arrow title={t('shell_action_start')} placement='bottom'>
			<span>
				<ShellOpenWrapper
					setShell={setShell}
					shell={shell}
					disabled={!SHELL_START_ENABLE_STATES.includes(shell.State) || starting || disabled}
					callback={startHandler}
				>
					<IconButton
						aria-label={t('shell_action_start')}
						disabled={!SHELL_START_ENABLE_STATES.includes(shell.State) || starting || disabled}
					>
						<PlayArrow/>
					</IconButton>
				</ShellOpenWrapper>
			</span>
		</Tooltip>
	);
};

export default ShellActionStart;

ShellActionStart.propTypes = {
	disabled: PropTypes.bool,
	setLoading: PropTypes.func.isRequired,
	setShell: PropTypes.func.isRequired,
	shell: PropTypes.object.isRequired
};
