import PropTypes           from 'prop-types';
import React, { useState } from 'react';
import ButtonGroup         from '@material-ui/core/ButtonGroup';
import { useTranslation }  from 'react-i18next';
import makeStyles          from '@material-ui/core/styles/makeStyles';
import CircularProgress    from '@material-ui/core/CircularProgress';
import ShellActionStart    from './ShellActionStart';
import ShellActionStop     from './ShellActionStop';
import ShellActionReboot   from './ShellActionReboot';


const useStyles = makeStyles(theme => {
	return {
		buttonGroup: {
			'border': `solid 1px ${theme.palette.primary.main}`,
			'border-radius': '25px',
		},
		container: {
			position: 'relative'
		},
		loader: {
			position: 'absolute',
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			margin: 'auto'
		}
	};
});

const ShellAction = ({ shell, setShell, disabled = false }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const [loading, setLoading] = useState(false);

	return (
		<span className={classes.container}>
			<ButtonGroup
				className={classes.buttonGroup}
				color='primary'
				variant='outlined'
				disabled={loading}
				aria-label={t('shell_action_group')}
			>
				<ShellActionStart shell={shell} disabled={disabled} setShell={setShell} setLoading={setLoading}/>
				<ShellActionStop shell={shell} disabled={disabled} setShell={setShell} setLoading={setLoading}/>
				<ShellActionReboot shell={shell} disabled={disabled} setShell={setShell} setLoading={setLoading}/>
			</ButtonGroup>
			{loading && <CircularProgress className={classes.loader}/>}
		</span>
	);
};

export default ShellAction;

ShellAction.propTypes = {
	disabled: PropTypes.bool,
	setShell: PropTypes.func.isRequired,
	shell: PropTypes.object.isRequired
};
