import PropTypes                      from 'prop-types';
import React, { useEffect, useState } from 'react';
import RichAlert                      from '../../../core/feedback/RichAlert';
import { Trans }                      from 'react-i18next';
import { getSSHKeychainRoute }        from '../../../RouteController/Factory';

const SSHKeyWarning = ({ os, keychain }) => {
	const [show, setShow] = useState(false);

	useEffect(() => {
		if (!os || os.Purpose !== 'server') {
			setShow(false);
			return;
		}

		if (!keychain || keychain === 'none') {
			setShow(true);
			return;
		}

		setShow(false);

	}, [setShow, os, keychain]);

	if (!show) return <></>;

	return (
		<RichAlert severity='error'>
			<Trans i18nKey='os_ssh_key_required_warning'>
				sample<br/>
				<strong>sample</strong>
				<a href={getSSHKeychainRoute()}>sample</a>
				<a
					target='_blank'
					rel='noopener noreferrer'
					href={`${process.env.REACT_APP_SHELL_URL}/tutorial/SSH-Keys-and-Shells-Passwordless-Entry-in-to-your-Server-Cloud-Desktop`}>
					sample
				</a>
			</Trans>
		</RichAlert>
	);
};

export default SSHKeyWarning;

SSHKeyWarning.propTypes = {
	keychain: PropTypes.string,
	os: PropTypes.object
};
