import PropTypes                      from 'prop-types';
import React, { useEffect, useState } from 'react';
import DialogContent                  from '@material-ui/core/DialogContent';
import DialogActions                  from '@material-ui/core/DialogActions';
import Grid                           from '@material-ui/core/Grid';
import RichAlert                      from '../../../../core/feedback/RichAlert';
import { Trans, useTranslation }      from 'react-i18next';
import QRCode                         from 'qrcode.react';
import { Typography }                 from '@material-ui/core';
import TextField                      from '@material-ui/core/TextField';
import { useUserOTPAssociate }        from '@karpeleslab/klb-react-services';
import { TAB_ACTIVATE }               from './AddDialog';
import Button                         from '../../../../core/input/Button';

const buildOtpUrl = (name, keyB32) => {
	return `otpauth://totp/${encodeURIComponent(`Shells™:${name}`)}?secret=${keyB32}&issuer=${encodeURIComponent('Shells™')}&digits=6&period=30`;
};

const StepAssociate = ({ otp, handleClose, setStep, refresh, setLoading }) => {
	const { t } = useTranslation();
	const [otpConfirm, setOtpConfirm] = useState();
	const [associate, associating] = useUserOTPAssociate(otp.User_Otp__);

	useEffect(() => {
		setLoading(associating);
	}, [associating]);

	const handleAssociate = () => {
		associate(otpConfirm, otp.Name)
			.then(() => {
				refresh();
				setStep(TAB_ACTIVATE);
			});
	};

	return (
		<>
			<DialogContent>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<RichAlert severity='info'>
							<Trans i18nKey='otp_associate_info'>
								sample<br/>
								<strong>sample</strong>
							</Trans>
						</RichAlert>
					</Grid>
					<Grid item xs={12}>
						<Grid container spacing={2} alignItems='center'>
							<Grid item xs={12} md={2}>
								<QRCode value={buildOtpUrl(otp.Name, otp.Key_base32)}/>
							</Grid>
							<Grid item xs={12} md={10}>
								<Grid container spacing={2} direction='column'>
									<Grid item>
										<Typography>
											{t('otp_b16_key', { key: otp.Key })}
										</Typography>
									</Grid>
									<Grid item>
										<Typography>
											{t('otp_b32_key', { key: otp.Key_base32 })}
										</Typography>
									</Grid>
									<Grid item>
										<TextField
											label={t('otp_confirm_label')}
											helperText={t('otp_confirm_helperText')}
											fullWidth
											value={otpConfirm}
											onChange={(e) => setOtpConfirm(e.target.value)}
											variant='outlined'
											required
											disabled={associating}
										/>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button color='default' onClick={handleClose}>{t('close_btn')}</Button>
				<Button
					loading={associating}
					disabled={associating || !(otpConfirm ?? '').trim()}
					variant='contained'
					type='submit'
					color='primary'
					onClick={handleAssociate}
				>
					{t('otp_step_associate_btn')}
				</Button>
			</DialogActions>
		</>
	);
};

export default StepAssociate;

StepAssociate.propTypes = {
	handleClose: PropTypes.func.isRequired,
	otp: PropTypes.object.isRequired,
	refresh: PropTypes.func.isRequired,
	setLoading: PropTypes.func.isRequired,
	setStep: PropTypes.func.isRequired
};
