import PropTypes                      from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Grid }                       from '@material-ui/core';
import { Title }                      from '../../../../core/typography/Title';
import { useTranslation }             from 'react-i18next';
import { useOrderProcess }            from '@karpeleslab/klb-react-services';
import Loader                         from '../../../../layout/Loader/Loader';
import Tabs                           from '@material-ui/core/Tabs';
import useMediaQuery                  from '@material-ui/core/useMediaQuery';
import PaymentMethodTab               from './PaymentMethodTab';
import PaymentMethodContent           from './PaymentMethodContent';

const OrderPayments = ({ order, refresh, setDialogActions = null }) => {
	const { t } = useTranslation();
	const [processOrder, processingOrder] = useOrderProcess(order.Order__);
	const [processResult, setProcessResult] = useState(null);

	const [paying, setPaying] = useState(false);

	const [paymentMethod, setPaymentMethod] = useState();

	const isMobile = useMediaQuery(theme => theme.breakpoints.down('xs'));

	const handleTabChange = (event, newValue) => {
		setPaymentMethod(newValue);
	};

	useEffect(() => {
		processOrder().then(setProcessResult);
	}, [processOrder, setProcessResult]);


	useEffect(() => {
		if (paymentMethod || !processResult) return;

		const clientSecret = new URLSearchParams(window.location.search).get('payment_intent_client_secret');

		if (clientSecret) {
			// If we are redirected to the order page from stripe, select the stripe payment method;
			setPaymentMethod('Stripe');
			return;
		}

		// Select default payment method
		if (processResult.methods_order.includes('OnFile')) {
			setPaymentMethod('OnFile');
		} else {
			setPaymentMethod(processResult.methods_order[0]);
		}
	}, [processResult, setPaymentMethod]);

	if (processingOrder || !processResult) {
		return (
			<Grid container spacing={3}>
				<Grid item xs={12}> <Loader/></Grid>
			</Grid>
		);
	}

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Title>
					{t('order_payment_methods')}
				</Title>
			</Grid>

			<Grid item xs={12}>
				<Tabs
					variant='scrollable'
					scrollButtons={isMobile ? 'on' : 'auto'}
					value={paymentMethod}
					indicatorColor='primary'
					textColor='primary'
					onChange={handleTabChange}
				>
					{processResult.methods_order.map(method => <PaymentMethodTab
						method={method}
						key={method}
						value={method}
						disabled={processingOrder || paying}
					/>)}
				</Tabs>
			</Grid>

			<Grid item xs={12}>
				{processResult.methods_order.map(method => <PaymentMethodContent
					method={method}
					methodData={processResult.methods[method]}
					key={`${method}_content_${order.Status}`}
					selectedPaymentMethod={paymentMethod}
					setPaying={setPaying}
					order={order}
					refreshOrder={refresh}
					setDialogActions={setDialogActions}
				/>)}
			</Grid>
		</Grid>
	);
};

export default OrderPayments;

OrderPayments.propTypes = {
	order: PropTypes.object.isRequired,
	refresh: PropTypes.func.isRequired,
	setDialogActions: PropTypes.func // Used when the component is used in a dialog in order to integrate the action into the dialog actions
};
