import PropTypes                        from 'prop-types';
import React                            from 'react';
import CardContent                      from '@material-ui/core/CardContent';
import Typography                       from '@material-ui/core/Typography';
import { UNIT_CPU, UNIT_HDD, UNIT_RAM } from '../../../../../hooks/useShells';
import Card                             from '@material-ui/core/Card';
import ShellPlanListItemPrice           from '../../../Shell/Plan/ShellPlanListItemPrice';
import { useTranslation }               from 'react-i18next';
import { Grid }                         from '@material-ui/core';
import { getPrice, isCustomPlan }       from '../../../../../utils/shell';

const Plan = ({ product, from = null }) => {
	const { t } = useTranslation();

	const getColor = (v1, v2) => {
		return v1 !== v2 ? 'red' : 'inherit';
	};
	const getStyle = (v1, v2) => {
		return { color: getColor(v1, v2) };
	};

	const forceShowMonthly = (p) => {
		return p['Basic.ServiceLifetime'] === '1y' || p['Description.AuthorCode'] === 'custom';
	};

	return (
		<Card>
			<CardContent>
				<Typography gutterBottom variant='h5' component='h2'>
					{product['Basic.Name']}
				</Typography>
				<Typography
					variant='body2'
					color='textSecondary'
					component='div'
					dangerouslySetInnerHTML={{ __html: product['Description.CatchPhrase'] }}
				/>
				<Grid container spacing={3} justify='center' alignItems='center'>
					<Grid item xs={3}>
						<p>
							<b
								style={from ? getStyle(Math.ceil(product['Shell.Size'] * UNIT_CPU), Math.ceil(from['Shell.Size'] * UNIT_CPU)) : {}}
							>
								{Math.ceil(product['Shell.Size'] * UNIT_CPU)}
							</b> VCPU
							<br/>
							<b
								style={from ? getStyle(product['Shell.Size'] * UNIT_HDD, from['Shell.Size'] * UNIT_HDD) : {}}
							>
								{product['Shell.Size'] * UNIT_HDD}
							</b> HDD
							<br/>
							<b
								style={from ? getStyle(product['Shell.Size'] * UNIT_RAM, from['Shell.Size'] * UNIT_RAM) : {}}

							>
								{product['Shell.Size'] * UNIT_RAM} GB
							</b> RAM
							<br/>
							<br/>
							{product['Shell.Timer_Allowance'] &&
							<b
								style={from ? getStyle(product['Shell.Timer_Allowance'], from['Shell.Timer_Allowance']) : {}}

							>
								{t('shell_time_allowance', {
									time: parseInt(product['Shell.Timer_Allowance']) / 3600
								})}
							</b>
							}
							{!product['Shell.Timer_Allowance'] &&
							<b
								style={from ? getStyle(false, 'Shell.Timer_Allowance' in from) : {}}
							>
								{t('shell_unlimited_usage')}
							</b>

							}
						</p>
					</Grid>
					<Grid item xs={9} style={{ textAlign: 'center' }}>
						<ShellPlanListItemPrice
							style={{
								fontWeight: 'bold',
								fontSize: '32px',
								color: from ? getColor(
									getPrice(product, forceShowMonthly(product), isCustomPlan(product), 1),
									getPrice(from, forceShowMonthly(from), isCustomPlan(from), 1),
								) : 'inherit'
							}}
							unit={1}
							item={product}
							forceShowMonthly={forceShowMonthly(product)}
							isCustom={isCustomPlan(product)}
						/>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
};

export default Plan;

Plan.propTypes = {
	from: PropTypes.object,
	product: PropTypes.object.isRequired
};
