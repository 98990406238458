import React     from 'react';
import PropTypes from 'prop-types';
import Paper     from '@material-ui/core/Paper';
import { Grid }  from '@material-ui/core';
import Box       from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';

import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
	wrapper: {
		[theme.breakpoints.down('sm')]: {
			padding: 0
		}
	}
}));

const PageContainer = ({ children }) => {
	const classes = useStyles();

	return (
		<Box pt={4}>
			<Container maxWidth={false} className={classes.wrapper}>
				<Paper>
					<Grid container>
						<Grid  item xs={12}>
							<Box p={3}>
								{children}
							</Box>
						</Grid>
					</Grid>
				</Paper>
			</Container>
		</Box>
	);
};

export default PageContainer;

PageContainer.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node
	]).isRequired,
};
