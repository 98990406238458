import React          from 'react';
import { useCookies } from 'react-cookie';

import Brightness2Icon       from '@material-ui/icons/Brightness2';
import WbSunnyIcon           from '@material-ui/icons/WbSunny';

import './ThemeSwitch.scss';
import { getCookiesOptions } from '../../../utils/misc';

export default ({ ButtonComponent, TextComponent, ...etc }) => {
	const [cookies, setCookie] = useCookies(['theme']);

	const darkBtn = (
		<ButtonComponent
			onClick={() => setCookie('theme', 'light', getCookiesOptions())}
			{...etc}
		>
			<Brightness2Icon className={'c-theme-switch--dark'}/>
			<TextComponent
				style={{ marginLeft: '34px' }}
				primary='Dark theme ON'
			/>
		</ButtonComponent>
	);

	const lightBtn = (
		<ButtonComponent
			onClick={() => setCookie('theme', 'dark', getCookiesOptions())}
			{...etc}
		>
			<WbSunnyIcon className={'c-theme-switch--light'}/>
			<TextComponent
				style={{
					marginLeft: '34px'
				}}
				primary='Light theme ON'
			/>
		</ButtonComponent>
	);

	return cookies.theme === 'dark' ? darkBtn : lightBtn;
};
