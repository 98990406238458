import PropTypes      from 'prop-types';
import React          from 'react';
import { Typography } from '@material-ui/core';
import withStyles     from '@material-ui/core/styles/withStyles';


export const LinkTypo = withStyles(theme => {
	return {
		root: {
			color: theme.palette.type === 'light' ? undefined : 'white',
		},
	};
})(Typography);


const Link = ({ children, href, ...rest }) => {
	return (
		<LinkTypo
			variant='subtitle1'
			component='a'
			target='_blank'
			rel='noopener noreferrer'
			href={href}
			{...rest}
		>
			{children}
		</LinkTypo>
	);
};

export default Link;

Link.propTypes = {
	children: PropTypes.any.isRequired,
	href: PropTypes.string.isRequired
};
