import React                from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useTranslation }   from 'react-i18next';
import Box                  from '@material-ui/core/Box';
import Link                 from './Link';

const About = () => {
	const { t } = useTranslation();
	return (
		<Grid container spacing={1} direction='column'>
			<Grid item xs={12}>
				<Box my={2}>
					<Typography variant='h4'>{t('footer_nav_about')}</Typography>
				</Box>
			</Grid>
			<Grid item xs={12}>
				<Link href={`${process.env.REACT_APP_SHELL_URL}/whyshells`}>
					{t('footer_nav_about_t0')}
				</Link>
			</Grid>
			<Grid item xs={12}>
				<Link href={`${process.env.REACT_APP_SHELL_URL}/team`}>
					{t('footer_nav_about_t1')}
				</Link>
			</Grid>
			<Grid item xs={12}>
				<Link href={`${process.env.REACT_APP_SHELL_URL}/page/careers`}>
					{t('footer_link_careers')}
				</Link>
			</Grid>
		</Grid>
	);
};

export default About;