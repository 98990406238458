import { useContext, useEffect, useState } from 'react';
import { ModeContext }                     from '../context/ModeContext';

export const useIsMode = mode => {
	const [modeContext] = useContext(ModeContext);
	const [ok, setOk] = useState(modeContext === mode);

	useEffect(() => {
		setOk(modeContext === mode);
	}, [mode, modeContext, setOk]);

	return ok;
};
