import PropTypes          from 'prop-types';
import React              from 'react';
import { Typography }     from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import HelpOutlineIcon    from '@material-ui/icons/HelpOutline';
import Tooltip            from '@material-ui/core/Tooltip';

const OsItemShellKernel = ({ detailed = false }) => {
	const { t } = useTranslation();

	if (detailed) {
		return (
			<Typography variant='caption' component='p'>
				{t('os_powered_by_shell_desc')}
			</Typography>
		);
	}
	return (
		<Typography variant='caption' component='p'>
			{t('os_powered_by_shell_title')}
			<span>
				<Tooltip title={t('os_powered_by_shell_desc')}>
					<HelpOutlineIcon fontSize='small'/>
				</Tooltip>
			</span>
		</Typography>
	);
};

export default OsItemShellKernel;

OsItemShellKernel.propTypes = {
	detailed: PropTypes.bool
};
