import React                    from 'react';
import { Title }                from './Title';
import { Toolbar as MUToolbar } from '@material-ui/core';
import Grid                     from '@material-ui/core/Grid';
import PropTypes                from 'prop-types';
import PageTitle                from './PageTitle';


const Toolbar = ({ title, children, disableGutters = false, justify = 'space-between', alignItems = 'stretch', startAdornment = null, endAdornment = null, pageTitle = false, pageTitleProps = {}, childItemProps = {}, titleItemProps = {}, toolbarProps = {} }) => {
	return (
		<MUToolbar disableGutters={disableGutters} {...toolbarProps}>
			<Grid container justify={justify} alignItems={alignItems}>
				<Grid item {...titleItemProps}>
					<Grid container>
						{startAdornment && <Grid item>{startAdornment}</Grid>}
						<Grid item>
							{!pageTitle && <Title>{title}</Title>}
							{pageTitle && <PageTitle title={title} {...pageTitleProps}/>}
						</Grid>
						{endAdornment && <Grid item>{endAdornment}</Grid>}

					</Grid>
				</Grid>
				{children && <Grid item {...childItemProps}>{children}</Grid>}
			</Grid>
		</MUToolbar>
	);
};

export default Toolbar;

Toolbar.propTypes = {
	alignItems: PropTypes.string,
	childItemProps: PropTypes.object,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	disableGutters: PropTypes.bool,
	endAdornment: PropTypes.node,
	justify: PropTypes.string,
	pageTitle: PropTypes.bool,
	pageTitleProps: PropTypes.object,
	startAdornment: PropTypes.node,
	title: PropTypes.any,
	titleItemProps: PropTypes.object,
	toolbarProps: PropTypes.object
};
