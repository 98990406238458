import PropTypes                 from 'prop-types';
import React, { useContext }     from 'react';
import PageContainer             from '../../components/layout/Container/PageContainer';
import Helmet                    from 'react-helmet';
import { Trans, useTranslation } from 'react-i18next';
import { ModeContext }           from '../../context/ModeContext';
import { Grid }                  from '@material-ui/core';
import Graphic                   from '../../assets/img/modeGraphic.svg';
import EmptyState                from '../../components/core/feedback/State/Empty';
import { getExpectedModeInfo }   from '../../utils/mode';
import { useLocation }           from 'react-router';
import Link                      from '@material-ui/core/Link';
import { getHostname }           from '@karpeleslab/klbfw';

const PageWrongMode = ({ expectedLite = false, expectedAdvanced = false }) => {
	const { t } = useTranslation();
	const [mode, setMode] = useContext(ModeContext);
	const location = useLocation();
	const [expectedModeToken, expectedModeHostname, expectedMode] = getExpectedModeInfo(expectedLite, expectedAdvanced);

	return (
		<PageContainer>
			<Helmet>
				{ /* https://devhints.io/html-meta */}
				<title>{t('page_wrong_mode_title', { mode: t(`${mode.toLowerCase()}_label`) })}</title>
			</Helmet>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<EmptyState
						title={t('page_wrong_mode_title', { mode: t(`${mode.toLowerCase()}_label`) })}
						text={
							<Trans values={{ mode: t(expectedModeToken) }} i18nKey='page_wrong_mode_desc'>
								sample<br/>
								<strong>sample</strong>
							</Trans>
						}
						graphic={Graphic}
						action={{
							component: Link,
							onClick: e => {
								if (getHostname() !== 'localhost') return;
								e.preventDefault();
								setMode(expectedMode);
							},
							href: `https://${expectedModeHostname}${location.pathname}${location.search}${location.hash}`,
							title: t('mode_change_btn', { mode: t(expectedModeToken) })
						}}
					/>
				</Grid>
			</Grid>
		</PageContainer>
	);
};

export default PageWrongMode;

PageWrongMode.propTypes = {
	expectedAdvanced: PropTypes.bool,
	expectedLite: PropTypes.bool
};
