import React, { useEffect, useState } from 'react';
import { Redirect as ReactRedirect }  from 'react-router-dom';
import PageLoader                     from '../../layout/Loader/PageLoader';
import PropTypes                      from 'prop-types';

const Redirect = ({ target }) => {
	const [isExternal] = useState(target.startsWith('http'));

	useEffect(() => {
		if (!isExternal || typeof window === 'undefined') return;
		window.location.replace(target);
	}, [isExternal, target]);

	if (isExternal) return <PageLoader/>;
	return <ReactRedirect to={target}/>;
};

export default Redirect;

Redirect.propTypes = {
	target: PropTypes.string.isRequired
};
