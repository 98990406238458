import PropTypes            from 'prop-types';
import React                from 'react';
import { useTranslation }   from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';
import { Title }            from '../../../../core/typography/Title';
import ProductWarning       from './ProductWarning';
import ConcernedShellTable  from './ConcernedShellTable';

const ProductWarnings = ({ warnings = [] }) => {
	const { t } = useTranslation();

	if (warnings.length < 1) return <></>;

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Title>{t('account_warning_product')}</Title>
			</Grid>
			{warnings.map(w =>
				<Grid item xs={12} key={w.Shell_Warning__}>
					<ProductWarning warning={w}/>
				</Grid>
			)}
			<Grid item xs={12}>
				<Typography variant='subtitle1'>{t('account_warning_product_shells')}</Typography>
			</Grid>
			<Grid item xs={12}>
				<ConcernedShellTable/>
			</Grid>
		</Grid>
	);
};

export default ProductWarnings;

ProductWarnings.propTypes = {
	warnings: PropTypes.array.isRequired
};
