import React, { useEffect, useState } from 'react';
import { useTranslation }             from 'react-i18next';
import TextField                      from '@material-ui/core/TextField';
import PropTypes                      from 'prop-types';
import moment                         from 'moment';
import { isString }                   from '../../../utils/misc';

const YearSelector = ({ value, setValue, interval = [-3, 0], disabled = false, required = false, ...rest }) => {
	const { t } = useTranslation();
	const [possibleValues, setPossibleValues] = useState([]);

	useEffect(() => {
		const now = parseInt(moment().format('YYYY'));
		let cleanedInterval = interval;
		if(cleanedInterval.length < 1) cleanedInterval.push(0);
		if(cleanedInterval.length === 1) cleanedInterval.push(0);
		const tmp = [];

		const from = isString(cleanedInterval[0]) ? parseInt(cleanedInterval[0]) : now + cleanedInterval[0];
		const to = isString(cleanedInterval[1]) ? parseInt(cleanedInterval[1]) : now + cleanedInterval[1];

		for (let i = to; i >= from; i--) {
			tmp.push(i);
		}

		setPossibleValues(tmp);
	}, [setPossibleValues]);

	return (
		<TextField
			label={t('year_selector_label')}
			value={value}
			onChange={e => setValue(e.target.value)}
			select
			fullWidth
			disabled={disabled}
			required={required}
			variant='outlined'
			SelectProps={{
				native: true
			}}
			{...rest}
		>
			{possibleValues.map(v => <option key={`year_selector_${v}`} value={v}>{v}</option>)}
		</TextField>
	);
};

export default YearSelector;

YearSelector.propTypes = {
	disabled: PropTypes.bool,
	interval: PropTypes.number,
	required: PropTypes.bool,
	setValue: PropTypes.func.isRequired,
	value: PropTypes.string
};
