import PropTypes           from 'prop-types';
import React, { useState } from 'react';
import makeStyles          from '@material-ui/core/styles/makeStyles';
import { useTranslation }  from 'react-i18next';
import Grid                from '@material-ui/core/Grid';

import cpuImg                           from '../../../../assets/img/plans/chip.png';
import hddImg                           from '../../../../assets/img/plans/disk.png';
import ramImg                           from '../../../../assets/img/plans/memory.png';
import timeAllowanceImg                 from '../../../../assets/img/plans/hours.png';
import ClickAwayListener                from '@material-ui/core/ClickAwayListener';
import { ShellPlanCustomTooltip }       from './ShellPlanCustomTooltip';
import Typography                       from '@material-ui/core/Typography';
import { UNIT_CPU, UNIT_HDD, UNIT_RAM } from '../../../../hooks/useShells';
import HelpOutlineOutlinedIcon          from '@material-ui/icons/HelpOutlineOutlined';
import * as NumericInput                from 'react-numeric-input';
import { Box }                          from '@material-ui/core';
import { useTheme }                     from '@material-ui/styles';
import { isCustomPlan }                 from '../../../../utils/shell';

const useStyles = makeStyles((theme) => ({
	root: {
		color: theme.palette.type === 'light' ? '#03222D' : '#fff',
		fontWeight: 'bold',
		textAlign: 'center',
		marginBottom: '12px',
		background: 'rgba(0, 0, 0, .1)'
	},
	tooltipImageWrapper: {
		color: theme.palette.type === 'light' ? '#03222D' : '#fff',
		fontWeight: 'bold',
		background: 'transparent',
		position: 'relative'
	},
	tooltipImage: {
		fontSize: '14px',
		marginTop: '-8px',
		position: 'absolute',
		top: '50%',
		right: '-15px',
		color: '#B0E170'
	},
}));

const ShellPlanDetails = ({ selected, nbUnit, setNbUnit, minUnits, disabled = false }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const theme = useTheme();

	const [tooltips, setTooltips] = useState({
		cpu: false,
		hdd: false,
		ram: false,
		time: false
	});

	const handleOpenTooltip = tooltip =>
		setTooltips({ ...tooltips, [tooltip]: true });
	const handleClickAway = tooltip =>
		setTooltips({ ...tooltips, [tooltip]: false });

	const buildVcpu = selected => {
		switch (selected['Description.AuthorCode']) {
			case 'custom':
				return Math.ceil(UNIT_CPU * nbUnit);
			default:
				return Math.ceil(selected['Shell.Size'] * UNIT_CPU);
		}
	};

	const buildTimeAllowance = selected => {
		return parseInt(selected['Shell.Timer_Allowance']) / 3600;
	};

	const buildHdd = selected => {
		switch (selected['Description.AuthorCode']) {
			case 'custom':
				return UNIT_HDD * nbUnit;
			default:
				return selected['Shell.Size'] * UNIT_HDD;
		}
	};

	const buildRam = selected => {
		switch (selected['Description.AuthorCode']) {
			case 'custom':
				return UNIT_RAM * nbUnit;
			default:
				return selected['Shell.Size'] * UNIT_RAM;
		}
	};

	const onChangeHandler = event => {
		setNbUnit(event);
	};

	const getMinUnitsValue = () => {
		// Minimum unit is 8 or the current plan number of unit
		if (!selected) {
			return 1;
		}

		if (minUnits !== undefined)
			return minUnits < 8 ? 8 : minUnits;

		return isCustomPlan(selected) ? 8 : 1;
	};


	const buildOtherField = selected => {
		switch (selected['Description.AuthorCode']) {
			case 'custom':
				return (
					<>
						<p
							style={{
								color: theme.palette.type === 'light' ? '#03222D' : '#fff',
								fontWeight: 'bold',
								margin: 'auto',
								marginBottom: '12px'
							}}
						>
							{t('custom_shell_title')}
						</p>
						<Grid
							container
							spacing={1}
							style={{
								color: theme.palette.type === 'light' ? '#03222D' : '#fff',
								fontWeight: 'bold'
							}}
						>
							<p>
								<span style={{ marginRight: '16px' }}>{t('order_choose_number_units')}</span>
								<NumericInput
									disabled={disabled}
									min={getMinUnitsValue()}
									max={265}
									defaultValue={minUnits !== undefined ? minUnits : nbUnit}
									onChange={onChangeHandler}
									strict
								/>
							</p>
							<p
								style={{
									color: '#888888',
									fontWeight: '400',
									textAlign: 'left'
								}}
							>
								{t('order_choose_number_units_desc')}
							</p>
						</Grid>
					</>
				);
			default:
				return (
					<>
						<p
							style={{
								color: theme.palette.type === 'light' ? '#03222D' : '#fff',
								fontWeight: 'bold',
								margin: 'auto',
								marginBottom: '12px'
							}}
						>
							{selected['Basic.Name']}
						</p>
						<Grid
							justify='center'
							alignItems='center'
							container
							spacing={1}
							style={{
								color: theme.palette.type === 'light' ? '#03222D' : '#fff',
								fontWeight: 'bold',
								textAlign: 'center'
							}}
						>
							<p
								style={{
									color: '#888888',
									fontWeight: '400',
									textAlign: 'left'
								}}
							>
								{selected['Description.CatchPhrase']}
							</p>
						</Grid>
					</>
				);
		}
	};

	return (
		<Box p={1} className={classes.root}>
			<Grid container spacing={1}>
				<Grid item xs={12}>
					<p style={{
						color: theme.palette.type === 'light' ? '#3C4858' : '#fff',
						fontWeight: 'bold'
					}}
					>
						{t('selected_plan_vm_custom_following_specs')}
					</p>
				</Grid>
				<Grid item container xs={12} spacing={3}>
					<React.Fragment>
						<Grid item xs={4} style={{ textAlign: 'center' }}>
							<img src={cpuImg} alt='cpu'/>
						</Grid>
						<Grid
							item
							xs={4}
							style={{ margin: 'auto' }}
							onClick={() => handleOpenTooltip('cpu')}
							onMouseEnter={() => handleOpenTooltip('cpu')}
							onMouseLeave={() => handleClickAway('cpu')}
						>
							<ClickAwayListener onClickAway={() => handleClickAway('cpu')}>
								<ShellPlanCustomTooltip
									disableFocusListener
									open={tooltips.cpu}
									title={
										<React.Fragment>
											<Typography color='inherit'>
												{t('cpu_tooltip')}
											</Typography>
											{t('cpu_tooltip_desc')}
										</React.Fragment>
									}
								>
									<div className={classes.tooltipImageWrapper}>
										<span style={{ marginRight: '8px' }}>VCPU</span>
										<span className={classes.tooltipImage}>
											<HelpOutlineOutlinedIcon style={{ color: '#B0E170' }}/>
										</span>
									</div>
								</ShellPlanCustomTooltip>
							</ClickAwayListener>
						</Grid>
						<Grid item xs={4} style={{ margin: 'auto' }}>
							{buildVcpu(selected)}
						</Grid>
					</React.Fragment>
				</Grid>

				<Grid
					container
					item
					xs={12}
					spacing={3}
					onClick={() => handleOpenTooltip('hdd')}
					onMouseEnter={() => handleOpenTooltip('hdd')}
					onMouseLeave={() => handleClickAway('hdd')}
				>
					<React.Fragment>
						<Grid item xs={4} style={{ textAlign: 'center' }}>
							<img src={hddImg} alt='hdd'/>
						</Grid>
						<Grid item xs={4} style={{ margin: 'auto' }}>
							<ClickAwayListener onClickAway={() => handleClickAway('hdd')}>
								<ShellPlanCustomTooltip
									disableFocusListener
									open={tooltips.hdd}
									title={
										<React.Fragment>
											<Typography color='inherit'>
												{t('hdd_tooltip')}
											</Typography>
											{t('hdd_tooltip_desc')}
										</React.Fragment>
									}
								>
									<div className={classes.tooltipImageWrapper}>
										<span style={{ marginRight: '8px' }}>SSD</span>
										<span className={classes.tooltipImage}>
											<HelpOutlineOutlinedIcon/>
										</span>
									</div>
								</ShellPlanCustomTooltip>
							</ClickAwayListener>
						</Grid>
						<Grid item xs={4} style={{ margin: 'auto' }}>
							{buildHdd(selected)} GB
						</Grid>
					</React.Fragment>
				</Grid>

				<Grid container item xs={12} spacing={3}>
					<React.Fragment>
						<Grid item xs={4} style={{ textAlign: 'center' }}>
							<img src={ramImg} alt='ram'/>
						</Grid>
						<Grid
							item
							xs={4}
							style={{ margin: 'auto' }}
							onClick={() => handleOpenTooltip('ram')}
							onMouseEnter={() => handleOpenTooltip('ram')}
							onMouseLeave={() => handleClickAway('ram')}
						>
							<ClickAwayListener onClickAway={() => handleClickAway('ram')}>
								<ShellPlanCustomTooltip
									disableFocusListener
									open={tooltips.ram}
									title={
										<React.Fragment>
											<Typography color='inherit'>
												{t('ram_tooltip')}
											</Typography>
											{t('ram_tooltip_desc')}
										</React.Fragment>
									}
								>
									<div className={classes.tooltipImageWrapper}>
										<span style={{ marginRight: '8px' }}>RAM</span>
										<span className={classes.tooltipImage}>
											<HelpOutlineOutlinedIcon/>
										</span>
									</div>
								</ShellPlanCustomTooltip>
							</ClickAwayListener>
						</Grid>
						<Grid item xs={4} style={{ margin: 'auto' }}>
							{buildRam(selected)} MB
						</Grid>
					</React.Fragment>
				</Grid>
				{selected['Shell.Timer_Allowance'] && (
					<Grid container item xs={12} spacing={3}>
						<React.Fragment>
							<Grid item xs={4} style={{ textAlign: 'center' }}>
								<img src={timeAllowanceImg} alt='time allowance'/>
							</Grid>
							<Grid
								item
								xs={4}
								style={{ margin: 'auto' }}
								onClick={() => handleOpenTooltip('time')}
								onMouseEnter={() => handleOpenTooltip('time')}
								onMouseLeave={() => handleClickAway('time')}
							>
								<ClickAwayListener
									onClickAway={() => handleClickAway('time')}
								>
									<ShellPlanCustomTooltip
										disableFocusListener
										open={tooltips.time}
										title={
											<React.Fragment>
												<Typography color='inherit'>
													{t('time_allowance_tooltip')}
												</Typography>
												{t('time_allowance_tooltip_desc')}
											</React.Fragment>
										}
									>
										<div className={classes.tooltipImageWrapper}>
											<span style={{ marginRight: '8px' }}>Time allowance</span>
											<span className={classes.tooltipImage}>
												<HelpOutlineOutlinedIcon/>
											</span>
										</div>
									</ShellPlanCustomTooltip>
								</ClickAwayListener>
							</Grid>
							<Grid item xs={4} style={{ margin: 'auto' }}>
								{t('shell_time_allowance', {
									time: buildTimeAllowance(selected)
								})}
							</Grid>
						</React.Fragment>
					</Grid>
				)}

				<Grid item xs={12} spacing={3}>
					{buildOtherField(selected)}
				</Grid>
			</Grid>

		</Box>
	);
};

export default ShellPlanDetails;

ShellPlanDetails.propTypes = {
	disabled: PropTypes.bool,
	minUnits: PropTypes.number.isRequired,
	nbUnit: PropTypes.number.isRequired,
	selected: PropTypes.object.isRequired,
	setNbUnit: PropTypes.func.isRequired
};
