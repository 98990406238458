import PropTypes                      from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation }             from 'react-i18next';
import { useOrderProcess }            from '@karpeleslab/klb-react-services';
import { Grid }                       from '@material-ui/core';
import Button                         from '../../../../../core/input/Button';
import TextField                      from '@material-ui/core/TextField';

const OnFile = ({ order, settings, setPaying, refreshOrder, setDialogActions = null }) => {
	const { t } = useTranslation();
	const [selected, setSelected] = useState();

	const [processOrder, processingOrder] = useOrderProcess(order.Order__);

	useEffect(() => {
		setPaying(processingOrder);
	}, [processingOrder]);

	useEffect(() => {
		const billings = settings.fields.user_billing.values;
		const billingId = billings.length > 0 ? billings[0].User_Billing__ : null;
		setSelected(billingId);
	}, [settings, setSelected]);

	const handleProcess = () => {
		processOrder(settings.session, settings.method, { user_billing: selected }, { snackMessageToken: 'order_paid' })
			.then(d => refreshOrder(d.order));
	};

	useEffect(() => {
		if (!setDialogActions) return;
		setDialogActions((
			<Button
				onClick={handleProcess}
				variant='contained'
				color='primary'
				disabled={processingOrder || !selected}
				loading={processingOrder}
			>
				{t('onfile_checkout_btn')}
			</Button>
		));
	}, [setDialogActions, processingOrder, selected]);

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<TextField
					label={t('user_billing_selector')}
					helperText={t('user_billing_selector_helperText')}
					select
					native
					variant='outlined'
					fullWidth
					value={selected}
					onChange={e => setSelected(e.target.value)}
					disabled={processingOrder}
					SelectProps={{
						native: true
					}}
					inputProps={{
						id: 'billing-select'
					}}
				>
					<option/>
					{settings.fields.user_billing.values.map(billing => (
						<option key={billing.User_Billing__} value={billing.User_Billing__}>
							{t('user_billing_selector_value', {
								name: billing.Methods[0].Name,
								exp: billing.Methods[0].Expiration
							})}
						</option>
					))}
				</TextField>
			</Grid>
			{!setDialogActions && <Grid item xs={12}>
				<Grid container spacing={3} justify='center'>
					<Grid item>
						<Button
							onClick={handleProcess}
							variant='contained'
							color='primary'
							disabled={processingOrder || !selected}
							loading={processingOrder}
						>
							{t('onfile_checkout_btn')}
						</Button>
					</Grid>
				</Grid>
			</Grid>
			}
		</Grid>
	);
};

export default OnFile;

OnFile.propTypes = {
	refreshOrder: PropTypes.func.isRequired,
	setPaying: PropTypes.func.isRequired,
	settings: PropTypes.object.isRequired,
	order: PropTypes.object.isRequired,
	setDialogActions: PropTypes.func // Used when the component is used in a dialog in order to integrate the action into the dialog actions
};
