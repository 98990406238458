import React                   from 'react';
import { useFirstUserBilling } from '../../hooks/useUserBilling';
import UpdateBillingWrapper    from './UpdateBillingWrapper';
import Loader                  from '../../components/layout/Loader/Loader';
import NotFound                from '../../components/common/User/Billing/NotFound';

const Billing = () => {
	const [billing, refresh] = useFirstUserBilling('@');

	if (!billing) return <Loader/>;
	if (!billing.data) return <NotFound/>;

	return (
		<UpdateBillingWrapper setBilling={refresh} billing={billing.data}/>
	);
};

export default Billing;
