import PropTypes          from 'prop-types';
import React              from 'react';
import Table              from '../../core/data/table/Table';
import { useTranslation } from 'react-i18next';
import TableDefinition    from '../../core/data/table/TableDefinition';
import DateUtil           from '../../core/data/Date/DateUtil';
import { useOrders }      from '@karpeleslab/klb-react-services';
import OrdersExport       from './OrdersExport';

const PendingOrder = ({ affiliate }) => {
	const { t } = useTranslation();
	const [orders, fetch, loading] = useOrders();

	return (
		<Table
			title={t('partnership_pending_order_title')}
			initialFilters={{ Affiliate__: affiliate.Affiliate__, Affiliate_Status: 'waiting' }}
			fetch={fetch}
			data={orders}
			search={false}
			loading={loading}
			paperProps={{ elevation: 0 }}
			toolbarProps={{ style: { paddingLeft: 0 } }}
			extra={[
				<OrdersExport key='affiliate-order-export' affiliate={affiliate}/>
			]}
		>
			<TableDefinition label={t('history_order_date')} name='Invoice_Date'>
				{order => <DateUtil klbDateObj={order.Invoice_Date} format='LLL'/>}
			</TableDefinition>

			<TableDefinition label={t('partnership_suffer')} name='Affiliate_Surfer'/>

			<TableDefinition label={t('partnership_order_income')} name='Order__' align='right'>
				{order => order.Affiliate_Total.display ?? '-'}
			</TableDefinition>
		</Table>
	);
};

export default PendingOrder;

PendingOrder.propTypes = {
	affiliate: PropTypes.object.isRequired
};
