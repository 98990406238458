import React                      from 'react';
import List                       from '@material-ui/core/List';
import { useTranslation }         from 'react-i18next';
import DrawerItem                 from './DrawerItem';
import {
	getBillingRoute,
	getCustomOsRoute,
	getISOsRoute,
	getOrderViewRoute,
	getPartnershipRoute,
	getSSHKeychainRoute
}                                 from '../../RouteController/Factory';
import { Route }                  from 'react-router';
import VpnKeyIcon                 from '@material-ui/icons/VpnKey';
import AccountBalanceWalletIcon   from '@material-ui/icons/AccountBalanceWallet';
import PaymentIcon                from '@material-ui/icons/Payment';
import AlbumIcon                  from '@material-ui/icons/Album';
import SettingsSystemDaydreamIcon from '@material-ui/icons/SettingsSystemDaydream';

const DrawerContent = () => {
	const { t } = useTranslation();

	return (
		<List>
			<Route path='*'>
				<DrawerItem
					anyMode
					Icon={PaymentIcon}
					tooltip={t('navigation_billing')}
					title={t('navigation_billing')}
					to={getBillingRoute()}
					extraActive={[getOrderViewRoute()]}
					toProps={{
						exact: false
					}}
				/>

				<DrawerItem
					anyMode
					Icon={AccountBalanceWalletIcon}
					tooltip={t('navigation_partnership')}
					title={t('navigation_partnership')}
					to={getPartnershipRoute()}
					toProps={{
						exact: false
					}}
				/>

				<DrawerItem
					advancedMode
					Icon={VpnKeyIcon}
					tooltip={t('navigation_ssh_keychains')}
					title={t('navigation_ssh_keychains')}
					to={getSSHKeychainRoute()}
					toProps={{
						exact: false
					}}
				/>

				<DrawerItem
					advancedMode
					Icon={SettingsSystemDaydreamIcon}
					tooltip={t('navigation_os')}
					title={t('navigation_os')}
					to={getCustomOsRoute()}
					toProps={{
						exact: false
					}}
				/>

				<DrawerItem
					forceHide
					advancedMode
					Icon={AlbumIcon}
					tooltip={t('navigation_iso')}
					title={t('navigation_iso')}
					to={getISOsRoute()}
					toProps={{
						exact: false
					}}
				/>
			</Route>
		</List>
	);
};

export default DrawerContent;
