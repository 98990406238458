import { useCallback, useEffect, useState } from 'react';
import {
	getOrderPaymentMethodInfoEndpoint,
	useUserBillingMethods,
	useUserBillings
}                                           from '@karpeleslab/klb-react-services';
import { useRest }                          from '@karpeleslab/react-klbfw-hooks';

export const useFirstUserBilling = userId => {
	const [, fetch] = useUserBillings(userId);
	const [billing, setBilling] = useState(null);

	const refresh = useCallback(
		data => {
			if (data) {
				setBilling({ ...(billing ?? {}), data: data });
				return;
			}

			fetch()
				.then(d => (d.data && d.data.length > 0) ? setBilling({ data: d.data[0] }) : setBilling({ data: null }))
				.catch(e => {
					setBilling({ error: e });
				});
		}
		, [billing]); //eslint-disable-line

	useEffect(() => {
		refresh();
	}, []); //eslint-disable-line


	return [billing, refresh];
};

export const useFirstPaymentMethod = billingId => {
	const [, fetch] = useUserBillingMethods(billingId);
	const [method, setMethod] = useState(null);

	const refresh = useCallback(
		data => {
			if (data) {
				setMethod({ ...(method ?? {}), data: data });
				return;
			}

			fetch()
				.then(d => (d.data && d.data.length > 0) ? setMethod({ data: d.data[0] }) : setMethod({ data: null }))
				.catch(e => {
					setMethod({ error: e });
				});
		}
		, [method]); //eslint-disable-line

	useEffect(() => {
		refresh();
	}, []); //eslint-disable-line


	return [method, refresh];
};

export const usePaymentMethodInfo = method => {
	return useRest(getOrderPaymentMethodInfoEndpoint(), { method: method });
};
