import React, { useEffect, useState } from 'react';
import { useTranslation }             from 'react-i18next';
import TextField                      from '@material-ui/core/TextField';
import moment                         from 'moment';
import DateTime                       from '../../../core/input/DateTime';
import Grid                           from '@material-ui/core/Grid';
import PropTypes                      from 'prop-types';
import { useKeychainKeyCheck }        from '@karpeleslab/klb-react-services';
import RichAlertTrans                 from '../../../core/feedback/RichAlertTrans';
import KeyHashes                      from './KeyHashes';

const Form = ({ data, setData, setIsValid, setChecking, disabled = false }) => {
	const { t } = useTranslation();

	const [hashes, setHashes] = useState(null);
	const [validForm, setValidForm] = useState(false);

	const [check, loading] = useKeychainKeyCheck();

	useEffect(() => {
		setChecking(loading);
	}, [setChecking, loading]);

	const createChangeHandler = key => e => {
		let toSet = { ...data, [key]: e.target.value };

		if (key === 'Data' && !getValue('Label')) {
			const splited = toSet.Data.split(' ');
			if (splited.length >= 3) {
				toSet.Label = splited[2];
			}
		}

		setData(toSet);
	};

	const getValue = (key, def = '') => {
		if (!(key in (data ?? {}))) return def;
		return data[key];
	};

	const getDateValue = (key, def = undefined) => {
		const value = getValue(key, def);
		if (!value) return value;
		if (value instanceof moment) return value;
		return moment(parseInt(data[key].unixms));
	};

	useEffect(() => {
		setIsValid(validForm && hashes !== 'error' && Array.isArray(hashes));
	}, [setIsValid, hashes, validForm]);

	useEffect(() => {
		if (!('Data' in (data ?? {}))) {
			setHashes('error');

		} else {
			check(data.Data, { handleError: false })
				.then(({ Hash }) => {
					setHashes(Hash);
				})
				.catch(() => {
					setHashes('error');
				});
		}
	}, [data, setHashes]);

	useEffect(() => {

		if (!('Label' in (data ?? {})) || !('Data' in (data ?? {})))
			setValidForm(false);
		else {
			setValidForm(true);

		}
	}, [data, setValidForm]);

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<TextField
					variant='outlined'
					label={t('keychain_key_data')}
					helperText={t('keychain_key_data_helperText')}
					placeholder={t('keychain_key_data_placeholder')}
					disabled={disabled}
					multiline
					rows={10}
					required
					fullWidth
					value={getValue('Data', '')}
					onChange={createChangeHandler('Data')}
				/>
			</Grid>
			<Grid item xs={6}>
				<TextField
					variant='outlined'
					label={t('keychain_key_label')}
					helperText={t('keychain_key_label_helperText')}
					disabled={disabled}
					required
					fullWidth
					value={getValue('Label', '')}
					onChange={createChangeHandler('Label')}
				/>
			</Grid>
			<Grid item xs={6}>
				<DateTime
					label={t('keychain_key_expires')}
					inputVariant='outlined'
					value={getDateValue('Expires', null)}
					disabled={disabled}
					onChange={d => setData({ ...data, Expires: d })}
					clearable={true}
					helperText={t('keychain_key_expires_helperText')}
					fullWidth
					disablePast
				/>
			</Grid>
			{hashes === 'error' &&
			<Grid item xs={12}>
				<RichAlertTrans severity='error' i18nKey='ssh_key_create_invalid'/>
			</Grid>}

			{Array.isArray(hashes) && <Grid item xs={12}>
				<KeyHashes hashes={hashes}/>
			</Grid>}
		</Grid>
	);
};

export default Form;

Form.propTypes = {
	data: PropTypes.object.isRequired,
	disabled: PropTypes.bool,
	setChecking: PropTypes.func.isRequired,
	setData: PropTypes.func.isRequired,
	setIsValid: PropTypes.func.isRequired
};
