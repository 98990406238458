import PropTypes                from 'prop-types';
import React                    from 'react';
import TextField                from '@material-ui/core/TextField';
import { useTranslation }       from 'react-i18next';
import { useRest }              from '@karpeleslab/react-klbfw-hooks';
import InputAdornment           from '@material-ui/core/InputAdornment';
import CircularProgress         from '@material-ui/core/CircularProgress';
import { getCountriesEndpoint } from '@karpeleslab/klb-react-services';

const CountrySelector = ({ value, setValue, label = null, helperText = null, disabled = false, required = false, ...rest }) => {
	const { t } = useTranslation();
	const [countries] = useRest(getCountriesEndpoint());

	return (
		<TextField
			label={label ?? t('country_selector_label')}
			helperText={helperText}
			value={value}
			onChange={e => setValue(e.target.value)}
			select
			fullWidth
			InputLabelProps={{ shrink: !!value }}
			disabled={disabled || !countries}
			required={required}
			variant='outlined'
			InputProps={!countries ?
				{
					endAdornment:
						(<InputAdornment position='end'>
							<CircularProgress size={30} thickness={1}/>
						</InputAdornment>)
				} : undefined
			}
			SelectProps={{
				native: true
			}}
			{...rest}
		>
			<option value=''/>
			{countries && countries.data.map(
				country => <option key={country.Country__} value={country.Country__}>{country.Name}</option>
			)}
		</TextField>
	);
};

export default CountrySelector;

CountrySelector.propTypes = {
	disabled: PropTypes.bool,
	helperText: PropTypes.string,
	label: PropTypes.string,
	required: PropTypes.bool,
	setValue: PropTypes.func.isRequired,
	value: PropTypes.string.isRequired
};
