import React, { useEffect }                from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

import { useSetSnackbar, useSnackbar } from '../../../hooks/useSnackbar';
import CloseIcon                       from '@material-ui/icons/Close';
import Grid                            from '@material-ui/core/Grid';
import Box                             from '@material-ui/core/Box';

import './Snackbar.scss';
import useMediaQuery                   from '@material-ui/core/useMediaQuery';

export default function Snackbar() {
	const { snackbar } = useSnackbar();
	const { message, type } = snackbar;
	const isMobile = useMediaQuery(theme => theme.breakpoints.down('xs'), { noSsr: true });

	const setSnackbar = useSetSnackbar();
	const handleClose = () => {
		setSnackbar(null);
	};

	useEffect(() => {
		if (message) {
			setTimeout(() => {
				if (message)
					setSnackbar(null);
			}, 5000);
		}
	}, [message, setSnackbar]);

	return (
		<Grid container align="center" justify="center" className={`c-snackbar c-snackbar--${type}`}>
			<SwitchTransition>
				<CSSTransition
					key={message ? 'show-snack' : 'hide-snack'}
					addEndListener={(node, done) => node.addEventListener('transitionend', done, false)}
					classNames='u-transition--vertical'
				>
					{message ?
						<Grid
							xs={isMobile ? 11 : undefined}
							item
							className="c-snackbar__inner"
						>
							<Box py={2}>
								{message}
								<button
									className="c-snackbar__close-btn"
									onClick={() => handleClose()}
								>
									<CloseIcon/>
								</button>
							</Box>
						</Grid>
						:
						<Grid item/>
					}
				</CSSTransition>
			</SwitchTransition>
		</Grid>
	);
}
