import React, { useContext } from 'react';
import { useTranslation }    from 'react-i18next';
import Button                from '@material-ui/core/Button';
import Grid                  from '@material-ui/core/Grid';
import PropTypes             from 'prop-types';
import { LoginContext }      from '@karpeleslab/klb-react-services';


const ButtonsBuilder = ({ ButtonComponent = Button }) => {
	const { goBack, initialStep } = useContext(LoginContext);

	const { t } = useTranslation();
	const Next = (
		<ButtonComponent
			type='submit'
			fullWidth={initialStep}
			variant='contained'
			color='primary'
		>
			{t('next_btn')}
		</ButtonComponent>
	);

	if (initialStep)
		return Next;
	else {
		return (
			<Grid
				container
				direction='row-reverse'
				justify='flex-start'
				alignItems='center'
				spacing={2}
			>
				<Grid item>
					{Next}
				</Grid>
				<Grid item>
					<ButtonComponent
						type='button'
						variant='contained'
						onClick={goBack}
						color='default'
					>
						{t('back_btn')}
					</ButtonComponent>
				</Grid>
			</Grid>
		);
	}
};

export default ButtonsBuilder;

ButtonsBuilder.propTypes = {
	ButtonComponent: PropTypes.any,
};
