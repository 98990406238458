import PropTypes                from 'prop-types';
import React, { useState }      from 'react';
import { useTranslation }       from 'react-i18next';
import { Grid }                 from '@material-ui/core';
import Button                   from '../../core/input/Button';
import PublishIcon              from '@material-ui/icons/Publish';
import ShellPlanSelectionDialog from './Plan/ShellPlanSelectionDialog';

const ShellUpgradeButton = ({ shell, refresh }) => {
	const { t } = useTranslation();
	const [showDialog, setShowDialog] = useState(false);

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Button
					fullWidth
					startIcon={<PublishIcon/>}
					size='small'
					color='primary'
					variant='contained'
					onClick={() => setShowDialog(true)}
				>
					{t('upgrade_btn')}
				</Button>
			</Grid>
			<ShellPlanSelectionDialog
				refresh={refresh}
				shell={shell}
				open={showDialog}
				setOpen={setShowDialog}
			/>
		</Grid>
	);
};

export default ShellUpgradeButton;

ShellUpgradeButton.propTypes = {
	shell: PropTypes.object.isRequired,
	refresh: PropTypes.func.isRequired,
};
