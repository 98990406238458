import React              from 'react';
import { useTranslation } from 'react-i18next';
import { Link }           from 'react-router-dom';

import Box            from '@material-ui/core/Box';
import Typography     from '@material-ui/core/Typography';
import PropTypes      from 'prop-types';
import Button         from '../input/Button';
import Helmet         from 'react-helmet';
import { useHistory } from 'react-router';
import useMediaQuery  from '@material-ui/core/useMediaQuery';

const PageTitle = ({ backButton = false, backButtonText = null, children, title }) => {
	const { t } = useTranslation();
	const history = useHistory();
	const isBackBtnUrl = typeof backButton === 'string' || backButton instanceof String;
	const isMobile = useMediaQuery(theme => theme.breakpoints.down('xs'));

	return (
		<>
			<Helmet>
				{ /* https://devhints.io/html-meta */}
				<title>{title}</title>
			</Helmet>
			{
				backButton &&
				<Box pb={4}>
					<Button
						variant='outlined'
						component={isBackBtnUrl ? Link : undefined}
						{
							...isBackBtnUrl ? { to: backButton } : { onClick: () => history.goBack() }
						}
					>
						{backButtonText || t('back_btn')}
					</Button>
				</Box>
			}
			<Typography tag='h1' style={{fontWeight:'bold'}} variant={isMobile ? 'h4' : 'h3'} gutterBottom>
				{children ?? title}
			</Typography>
		</>
	);
};

export default PageTitle;

PageTitle.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node
	]),
	backButton: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.bool
	]),
	backButtonText: PropTypes.string,
	title: PropTypes.string,
};
