import PropTypes            from 'prop-types';
import { useTranslation }   from 'react-i18next';
import React, { useEffect } from 'react';
import Grid                 from '@material-ui/core/Grid';
import TextField            from '@material-ui/core/TextField';
import OSFamilySelector     from '../../core/input/OSFamilySelector';
import OSBootSelector       from '../../core/input/OSBootSelector';
import OSPublicSelector     from '../../core/input/OSPublicSelector';
import OSBetaSelector       from '../../core/input/OSBetaSelector';
import OSCPUSelector        from '../../core/input/OSCPUSelector';
import Picture              from '../../core/input/Picture';
import OSPurposeSelector    from '../../core/input/OSPurposeSelector';

const Form = ({ data, setData, setIsValid, disabled = false }) => {
	const { t } = useTranslation();

	const createChangeHandler = (key, property = 'value') => e => {
		setData({ ...data, [key]: (property ? e.target[property] : e) });
	};

	const getValue = (key, def = '') => {
		if (!(key in (data ?? {}))) return def;
		return data[key];
	};

	useEffect(() => {
		if (!('Name' in data ?? {}) || !data['Name'].trim()) setIsValid(false);
		else if (!('CPU' in data ?? {}) || !data['CPU']) setIsValid(false);
		else if (!('Boot' in data ?? {}) || !data['Boot']) setIsValid(false);
		else if (!('Family' in data ?? {}) || !data['Family']) setIsValid(false);
		else if ((!('Public' in data ?? {}) || !data['Public'])) setIsValid(false);
		else setIsValid(true);
	}, [data, setIsValid]);

	return (
		<Grid container spacing={2} alignItems='center'>
			<Grid item xs={12} md={12}>
				<TextField
					variant='outlined'
					value={getValue('Name')}
					onChange={createChangeHandler('Name')}
					required
					fullWidth
					disabled={disabled}
					label={t('custom_os_name_label')}
					helperText={t('custom_os_name_helperText')}
				/>
			</Grid>
			<Grid item xs={12} md={6}>
				<OSPurposeSelector
					setValue={createChangeHandler('Purpose', null)}
					value={getValue('Purpose', 'unknown')}
					disabled={disabled}
					hideAll
				/>
			</Grid>
			<Grid item xs={12} md={6}>
				<Picture
					variant='outlined'
					value={getValue('New_Media_Image', getValue('Media_Image', null))}
					setValue={createChangeHandler('New_Media_Image', null)}
					defaultValue={getValue('Media_Image', null)}
					disabled={disabled}
					label={t('custom_os_icon_label')}
					helperText={t('custom_os_icon_helperText')}
					square
					reverseDisplay
					minHeight={250}
					minWidth={250}
					previewStyle={{ height: '75px', width: '75px' }}
				/>
			</Grid>
			<Grid item xs={12}>
				<TextField
					variant='outlined'
					value={getValue('Short_Description')}
					onChange={createChangeHandler('Short_Description')}
					multiline
					rows={2}
					rowsMax={Infinity}
					fullWidth
					disabled={disabled}
					label={t('custom_os_short_description_label')}
					helperText={t('custom_os_short_description_helperText')}
				/>
			</Grid>
			<Grid item xs={12}>
				<TextField
					variant='outlined'
					value={getValue('Description')}
					onChange={createChangeHandler('Description')}
					multiline
					rows={4}
					rowsMax={Infinity}
					fullWidth
					disabled={disabled}
					label={t('custom_os_description_label')}
					helperText={t('custom_os_description_helperText')}
				/>
			</Grid>
			<Grid item xs={12} md={6}>
				<OSFamilySelector
					disabled={disabled}
					required
					fullWidth
					value={getValue('Family')}
					setValue={createChangeHandler('Family', null)}
				/>
			</Grid>
			<Grid item xs={12} md={6}>
				<OSBootSelector
					disabled={disabled}
					required
					fullWidth
					value={getValue('Boot')}
					setValue={createChangeHandler('Boot', null)}
				/>
			</Grid>
			<Grid item xs={12} md={6}>
				<OSCPUSelector
					disabled={disabled}
					required
					fullWidth
					value={getValue('CPU')}
					setValue={createChangeHandler('CPU', null)}
				/>
			</Grid>
			<Grid item xs={12} md={6}>
				<OSPublicSelector
					disabled={disabled}
					required
					fullWidth
					value={getValue('Public')}
					setValue={createChangeHandler('Public', null)}
				/>
			</Grid>
			<Grid item xs={12} md={6}>
				<OSBetaSelector
					disabled={disabled}
					required
					fullWidth
					value={getValue('Beta')}
					setValue={createChangeHandler('Beta', null)}
				/>
			</Grid>
		</Grid>
	);
};

export default Form;

Form.propTypes = {
	data: PropTypes.object.isRequired,
	disabled: PropTypes.bool,
	setData: PropTypes.func.isRequired,
	setIsValid: PropTypes.func.isRequired
};
