import React          from 'react';
import RichAlertTrans from '../../core/feedback/RichAlertTrans';

const ShellWindowsOsWarning = () => {
	return (
		<RichAlertTrans i18nKey='shell_os_windows_warning' severity='warning'/>
	);
};

export default ShellWindowsOsWarning;

export const isShellWindowsOSWarningNeeded = (shell, os) => {
	// Only for windows
	if(!os) return false;
	if (os.Family !== 'windows') return false;

	// Only if the shell has basic, lite, or none plan
	return !(!!shell && !['lite', 'basic'].includes(shell.Catalog_Product['Description.AuthorCode']));
};
