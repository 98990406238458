import PropTypes           from 'prop-types';
import React, { useState } from 'react';
import { Grid }            from '@material-ui/core';
import { useTranslation }  from 'react-i18next';
import Stepper             from '@material-ui/core/Stepper';
import Step                from '@material-ui/core/Step';
import StepLabel           from '@material-ui/core/StepLabel';
import StepSettings        from './StepSettings';
import StepValidation      from './StepValidation';

const getDefaultState = s => {
	return {
		keepPassword: true,
		os: s.OS ?? null,
		datacenter: s.Shell_Datacenter__ ?? 'none',
		keychain: s.Keychain__ ?? 'none',
		erase: false,
	};
};

const ShellReinstallWizard = ({ shell, setShell }) => {
	const { t } = useTranslation();
	const [stepIndex, setStepIndex] = useState(0);

	const [data, setData] = useState(getDefaultState(shell));

	const next = () => {
		setStepIndex(stepIndex + 1);
	};

	const onFail = () => {
		setStepIndex(0);
	};

	const onComplete = s => {
		setData(getDefaultState(s));
		setStepIndex(0);
		setShell(s);
		window.scrollTo(0, 0); // This will display clearly the warning 'preparing' state message
	};

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Stepper activeStep={stepIndex}>
					<Step>
						<StepLabel>{t('shell_reinstall_step_settings')}</StepLabel>
					</Step>
					<Step>
						<StepLabel>{t('shell_reinstall_step_validation')}</StepLabel>
					</Step>
				</Stepper>
			</Grid>
			<Grid item xs={12}>
				{stepIndex === 0 && <StepSettings
					data={data}
					setData={setData}
					shell={shell}
					next={next}
				/>}
				{stepIndex === 1 && <StepValidation data={data} shell={shell} onFail={onFail} onComplete={onComplete}/>}
			</Grid>
		</Grid>
	);
};

export default ShellReinstallWizard;

ShellReinstallWizard.propTypes = {
	setShell: PropTypes.func.isRequired,
	shell: PropTypes.object.isRequired
};
