import React, { useContext, useEffect, useState } from 'react';
import { useTranslation }                         from 'react-i18next';

import { Grid }                  from '@material-ui/core';
import TextField                 from '@material-ui/core/TextField';
import { Title }                 from '../../core/typography/Title';
import RequestPasswordDialog     from './RequestPasswordDialog';
import { UserContext }           from '../../../context/UserContext';
import Button                    from '../../core/input/Button';
import { useUserChangePassword } from '@karpeleslab/klb-react-services';

const Security = () => {
	const { t } = useTranslation();
	const [newPassword, setNewPassword] = useState('');
	const [newPasswordRepeat, setNewPasswordRepeat] = useState('');
	const [saveEnabled, setSaveEnabled] = useState(false);
	const [requestPwdOpen, setRequestPwdOpen] = useState(false);
	const [user] = useContext(UserContext);
	const [changePassword, changingInfo] = useUserChangePassword('@');

	const resetFields = () => {
		setNewPassword('');
		setNewPasswordRepeat('');
	};

	useEffect(() => {
		if (newPassword === '') {
			setSaveEnabled(false);
			return;
		}

		if (newPassword !== newPasswordRepeat) {
			setSaveEnabled(false);
			return;
		}

		setSaveEnabled(true);
	}, [newPassword, newPasswordRepeat]);

	const handleSave = old => {
		changePassword(old, newPassword)
			.finally(resetFields);
	};

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Title>{t('profile_security')}</Title>
			</Grid>
			<Grid item xs={12} md={6}>
				<TextField
					label={t('label_new_password')}
					fullWidth
					value={newPassword}
					onChange={(e) => setNewPassword(e.target.value)}
					variant='outlined'
					type='password'
					disabled={changingInfo}
				/>
			</Grid>
			<Grid item xs={12} md={6}>
				<TextField
					label={t('label_new_password_repeat')}
					fullWidth
					value={newPasswordRepeat}
					onChange={(e) => setNewPasswordRepeat(e.target.value)}
					variant='outlined'
					type='password'
					error={newPasswordRepeat !== newPassword}
					helperText={newPasswordRepeat !== newPassword ? t('confirm_password_not_match') : ''}
					disabled={changingInfo}
				/>
			</Grid>
			<Grid item xs={12}>
				<Grid container justify='flex-end'>
					<Button
						color='primary'
						variant='contained'
						disabled={!saveEnabled || changingInfo}
						onClick={() => {
							if (user.user.data.Has_Password)
								setRequestPwdOpen(true);
							else
								handleSave(null);
						}}
					>
						{t('save_btn')}
					</Button>

				</Grid>
			</Grid>
			{user.user.data.Has_Password &&
			<RequestPasswordDialog
				open={requestPwdOpen}
				setOpen={setRequestPwdOpen}
				closeCallback={handleSave}
			/>
			}
		</Grid>
	);
};

export default Security;
