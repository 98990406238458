import React                               from 'react';
import { useTranslation }                  from 'react-i18next';
import { useRest }                         from '@karpeleslab/react-klbfw-hooks';
import TextField                           from '@material-ui/core/TextField';
import InputAdornment                      from '@material-ui/core/InputAdornment';
import CircularProgress                    from '@material-ui/core/CircularProgress';
import PropTypes                           from 'prop-types';
import { getShellDatacentersListEndpoint } from '@karpeleslab/klb-react-services';

const ShellDatacenterSelector = ({ value, setValue, label = null, allowEmpty = false, helperText = null, disabled = false, required = false, ...rest }) => {
	const { t } = useTranslation();
	const [datacenter] = useRest(getShellDatacentersListEndpoint(), { results_per_page: 100 });

	return (
		<TextField
			label={label ?? t('shell_datacenter_selector_label')}
			helperText={helperText ?? t('shell_datacenter_selector_helperText')}
			value={value}
			onChange={e => setValue(e.target.value)}
			select
			fullWidth
			InputLabelProps={{ shrink: !!value }}
			disabled={disabled || !datacenter}
			required={required}
			variant='outlined'
			InputProps={!datacenter ?
				{
					endAdornment:
						(<InputAdornment position='end'>
							<CircularProgress size={30} thickness={1}/>
						</InputAdornment>)
				} : undefined
			}
			SelectProps={{
				native: true
			}}
			{...rest}
		>
			{allowEmpty &&
			<option value='none'>

			</option>
			}
			{datacenter && datacenter.data.map(
				d => <option
					key={d.Shell_Datacenter__}
					value={d.Shell_Datacenter__}
				>
					{`${d.Location} - ${d.Name}`}
				</option>
			)}
		</TextField>
	);
};

export default ShellDatacenterSelector;

ShellDatacenterSelector.propTypes = {
	allowEmpty: PropTypes.bool,
	disabled: PropTypes.bool,
	helperText: PropTypes.string,
	label: PropTypes.string,
	required: PropTypes.bool,
	setValue: PropTypes.func.isRequired,
	value: PropTypes.string.isRequired
};
