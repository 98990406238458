import PropTypes                   from 'prop-types';
import React                       from 'react';
import Dialog                      from '@material-ui/core/Dialog';
import DialogTitle                 from '@material-ui/core/DialogTitle';
import DialogContent               from '@material-ui/core/DialogContent';
import DialogActions               from '@material-ui/core/DialogActions';
import Button                      from '../../core/input/Button';
import { useTranslation }          from 'react-i18next';
import { useShellISOMountUnmount } from '@karpeleslab/klb-react-services';
import DeleteIcon                  from '@material-ui/icons/Delete';
import RichAlertTrans              from '../../core/feedback/RichAlertTrans';
import DialogContentText           from '@material-ui/core/DialogContentText';


const IsoUnmountDialog = ({ open, setOpen, mount, refresh }) => {
	const { t } = useTranslation();
	const [unmount, unmounting] = useShellISOMountUnmount(mount.Shell_ISO_Mount__);

	const handleClose = () => {
		setOpen(false);
	};

	const handleUnmount = (e) => {
		e.preventDefault();
		unmount()
			.then(() => refresh())
			.then(handleClose);
	};

	return (
		<Dialog
			open={open}
			aria-labelledby={t('dialog_unmount_iso_title')}
			aria-describedby={t('dialog_unmount_iso_title')}
			maxWidth='lg'
			fullWidth
		>
			<DialogTitle id='unmount-iso'>
				{t('dialog_unmount_iso_title')}
			</DialogTitle>
			<DialogContent>
				<DialogContentText id='unmount-iso-description'>
					<RichAlertTrans
						severity='warning'
						i18nKey='dialog_unmount_iso_description'
						values={{ label: mount.Shell_ISO.Filename }}
					/>
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button color='default' onClick={handleClose} disabled={unmounting}>
					{t('close_btn')}
				</Button>
				<Button
					color='secondary'
					onClick={handleUnmount}
					variant='outlined'
					disabled={unmounting}
					loading={unmounting}
					startIcon={<DeleteIcon/>}
				>
					{t('unmount_btn')}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default IsoUnmountDialog;

IsoUnmountDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	refresh: PropTypes.func.isRequired,
	setOpen: PropTypes.func.isRequired,
	mount: PropTypes.object.isRequired
};
