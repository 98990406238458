import PropTypes                 from 'prop-types';
import React                     from 'react';
import { Trans, useTranslation } from 'react-i18next';

import Grid          from '@material-ui/core/Grid';
import SetStatus     from './../SetStatus/SetStatus';
import Empty         from '../../feedback/State/Empty';
import Graphic       from '../../../../assets/img/404.svg';
import PageContainer from '../../../layout/Container/PageContainer';

export default function APIErrorPage({ message, errorID }) {
	const { t } = useTranslation();
	return (
		<SetStatus statusCode={500}>
			<PageContainer>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<Empty
							title={t('error_api_fatal_title')}
							text={
								<Trans
									values={{ id: errorID, message: message }}
									i18nKey='error_api_fatal_description'
								>
									sample<br/>
									<strong>sample</strong>
								</Trans>
							}
							graphic={Graphic}
							action={
								{
									component: 'a',
									title: t('footer_link_contact'),
									rel: 'noopener noreferrer',
									target: '_blank',
									href: process.env.REACT_APP_ZENDESK
								}
							}
						/>
					</Grid>
				</Grid>
			</PageContainer>
		</SetStatus>
	);
}

APIErrorPage.propTypes = {
	errorID: PropTypes.string,
	message: PropTypes.string
};
