import PropTypes                      from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation }             from 'react-i18next';
import makeStyles                     from '@material-ui/core/styles/makeStyles';
import { getPrice }                   from '../../../../utils/shell';

const useStyles = makeStyles(() => ({
	price: {
		fontSize: '17px',
		margin: '0 0 5px',
	},
}));

const ShellPlanListItemPrice = ({ item, unit, forceShowMonthly = false, isCustom = false, ...rest }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const [price, setPrice] = useState(null);

	useEffect(() => {
		setPrice(getPrice(item, forceShowMonthly, isCustom, unit));
	}, [item, unit, isCustom, forceShowMonthly, setPrice]);

	return (
		<div className={classes.price} {...rest}>
			{price}
			<small>{t('shell_price_by_month')}</small>
		</div>
	);
};

export default ShellPlanListItemPrice;

ShellPlanListItemPrice.propTypes = {
	forceShowMonthly: PropTypes.bool,
	isCustom: PropTypes.bool,
	item: PropTypes.object,
	unit: PropTypes.number
};
