import PropTypes                                   from 'prop-types';
import React                                       from 'react';
import ErrorPage                                   from './ErrorPage/ErrorPage';
import { rest }                                    from '@karpeleslab/klbfw';
import { getDebugLogJsErrorEndpoint, RestContext } from '@karpeleslab/klb-react-services';
import APIErrorPage                                from './ErrorPage/APIErrorPage';

class ErrorBoundary extends React.Component {
	static contextType = RestContext;

	constructor(props) {
		super(props);
		this.state = { hasError: false, hasAPIError: false, errorUUID: null, errorMessage: null };
	}

	static getDerivedStateFromError(/*error*/) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	componentDidCatch(error, errorInfo) {
		// Can log error here
		console.log(error.message, errorInfo, error.stack, error.messageData);
		rest(getDebugLogJsErrorEndpoint(), 'POST', {
			errorMsg: error.message,
			url: typeof window !== 'undefined' ? window.location.href : '',
			lineNumber: 0,
			stack: error.stack
		}).then(d => {
			this.setState({ ...this.state, errorUUID: d.data.id });
		});
	}

	componentDidUpdate() {
		const { restContext, setLastError } = this.context;

		if (restContext.lastError && !this.state.hasAPIError) {
			if (('token' in restContext.lastError) && restContext.lastError.token !== 'unknown_error') return;

			const uuid = restContext.lastError.headers ? restContext.lastError.headers.get('X-Request-Id') : '';

			this.setState({
				...this.state,
				hasAPIError: true,
				errorUUID: uuid,
				errorMessage: restContext.lastError.error
			});

			setLastError(null); // error has been handled, revert it not clear the context and not having always the error displayed
		}
	}

	componentWillUnmount() {
		const { setLastError } = this.context;
		setLastError(null); // error has been handled, revert it not clear the context and not having always the error displayed
	}

	render() {
		if (this.state.hasAPIError) {
			return <APIErrorPage message={this.state.errorMessage} errorID={this.state.errorUUID}/>;
		}

		if (this.state.hasError) {
			// You can render any custom fallback UI
			return <ErrorPage errorID={this.state.errorUUID}/>;
		}

		return this.props.children;
	}
}

export default ErrorBoundary;

ErrorBoundary.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node
	]),
};
