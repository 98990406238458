import PropTypes           from 'prop-types';
import React               from 'react';
import ListItemAvatar      from '@material-ui/core/ListItemAvatar';
import { OauthBtn }        from '../../../Login/OauthBtnsBuilder';
import ListItemText        from '@material-ui/core/ListItemText';
import CircularProgress    from '@material-ui/core/CircularProgress';
import ListItem            from '@material-ui/core/ListItem';
import { useConsumerLink } from '@karpeleslab/klb-react-services';

const OAuthItem = ({ consumer, setSelected, disabled = false, selected = false }) => {
	const [fetchConsumerLink] = useConsumerLink(consumer.OAuth2_Consumer__);

	const handleConsumerClick = () => {
		setSelected(consumer);
		fetchConsumerLink(window.location.href)
			.then(d => window.location = d.redirect_url);
	};

	return (
		<ListItem
			button
			disabled={disabled}
			onClick={() => handleConsumerClick(consumer)}
			key={consumer.OAuth2_Consumer__}
			selected={selected}
		>
			<ListItemAvatar>
				<OauthBtn
					alt={consumer.Name}
					className={consumer.Token_Name}
					backgroundColor={
						consumer.Button_Style['background-color']
					}
					logo={consumer.Button_Style.logo}
				/>
			</ListItemAvatar>
			<ListItemText inset primary={consumer.Name}/>
			{selected && <CircularProgress thickness={1}/>}
		</ListItem>
	);
};

export default OAuthItem;

OAuthItem.propTypes = {
	consumer: PropTypes.object.isRequired,
	disabled: PropTypes.bool,
	selected: PropTypes.bool,
	setSelected: PropTypes.func.isRequired
};
