import PropTypes                                   from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation }                          from 'react-i18next';
import { useOrderProcess }                         from '@karpeleslab/klb-react-services';
import Grid                                        from '@material-ui/core/Grid';
import Button                                      from '../../../../../core/input/Button';
import { useSetSnackbar }                          from '../../../../../../hooks/useSnackbar';
import RichAlertTrans                              from '../../../../../core/feedback/RichAlertTrans';

const Bitpay = ({ order, settings, setPaying, refreshOrder, setDialogActions = null }) => {
	const { t } = useTranslation();

	const [processOrder] = useOrderProcess(order.Order__);

	// Setting the bitpay script will set this var
	const [loading, setLoading] = useState(!!window.bitpay_loading);

	const setSnackbar = useSetSnackbar();

	const bitpayListenerCallback = useCallback((e) => {
		if (e.data.status !== 'paid') return;

		window.removeEventListener('message', bitpayListenerCallback);
		// Doing so will refresh the order data
		// handleError: false because bitpay is crap
		processOrder(settings.session, settings.method, {}, { snackMessageToken: 'order_paid', handleError: false })
			.then(d => refreshOrder(d.order));
	}, []);

	const loadBitpayScript = (src, onLoad) => {
		const existing = document.getElementById('bitpay-script');
		if (existing) {
			delete window.bitpay;
			existing.remove();
		}

		const scriptEl = document.createElement('script');
		scriptEl.setAttribute('id', 'bitpay-script');
		scriptEl.async = false;
		scriptEl.src = src;
		scriptEl.onload = onLoad;
		document.body.appendChild(scriptEl);
	};

	const resetScript = useCallback(() => {
		const existing = document.getElementById('bitpay-script');
		if (existing) {
			delete window.bitpay;
			existing.remove();
		}
		window.dispatchEvent(new CustomEvent('loadingChange', { detail: false }));
	}, []);


	const init = async () => {
		const process = await processOrder(settings.session, settings.method, {}, { handleError: false });

		if (!process) {
			return false;
		}

		const js = process.methods.Bitpay.fields._javascript;
		const invoiceId = process.methods.Bitpay.fields.Bitpay_Invoice_Id;

		if (!js) {
			return false;
		}

		loadBitpayScript(js, () => {
			if (process) refreshOrder(process.order);

			window.dispatchEvent(new CustomEvent('loadingChange', { detail: true }));
			window.bitpay.showInvoice(invoiceId);
			window.bitpay.onModalWillLeave(resetScript);
		});

		return true;
	};

	useEffect(() => {
		if (typeof window === 'undefined' || order.Status === 'pending') {
			return;
		}
		// Bitpay is sometimes crap and the event 'message' is not trigger
		// So we use an active pulling just in case to check if the order is paid
		const interval = setInterval(() => {
			// handleError: false because bitpay is crap
			processOrder(settings.session, settings.method, {}, { handleError: false })
				.then(process => {
					if (!process.order.Paid) return;
					// We need to set the confirmation message manually
					setSnackbar(t('order_paid'), 'success');
					refreshOrder(process.order);
				});
		}, 60 * 1000);

		return () => {
			clearInterval(interval);
		};
	}, [order]);

	// Listen for bitpay events
	useEffect(() => {
		if (typeof window === 'undefined')
			return;

		window.addEventListener('message', bitpayListenerCallback);

		return () => {
			window.removeEventListener('message', bitpayListenerCallback);
		};
	}, [bitpayListenerCallback]);

	const handleLoading = useCallback(event => {
		window.bitpay_loading = event.detail;
		setLoading(event.detail);
	}, []);

	useEffect(() => {
		window.addEventListener('loadingChange', handleLoading);
		return () => {
			window.removeEventListener('loadingChange', handleLoading);
		};

	}, []);


	// The button is only use the first time, when the payment method hasn't been set
	const handleProcess = async () => {
		if (typeof window === 'undefined') return;

		// We simply at maximum the process, always reload bitpay completely,
		// It will be slower, but if will simplify A LOT the process
		setPaying(true); // Will block all other payment method

		window.dispatchEvent(new CustomEvent('loadingChange', { detail: true }));

		const result = await init();
		if (!result) {
			setPaying(false);
			window.dispatchEvent(new CustomEvent('loadingChange', { detail: false }));
		}
	};

	useEffect(() => {
		if (!setDialogActions) return;
		setDialogActions((
			<Button
				loading={loading}
				disabled={loading}
				variant='contained'
				color='primary'
				onClick={handleProcess}
			>
				{t(order.Status !== 'pending' ? 'continue_with_bitpay_btn' : 'checkout_with_bitpay_btn')}
			</Button>
		));
	}, [setDialogActions, loading, order]);

	return (
		<Grid container spacing={3}>
			<Grid xs={12}>
				<Grid container spacing={3} justify='center'>
					{order.Status !== 'pending' &&
					<Grid item xs={12}>
						<RichAlertTrans
							severity='info'
							i18nKey='order_bitpay_payment_initiated_message'
						/>
					</Grid>
					}
					{order.Status === 'pending' &&
					<Grid item xs={12}>
						<RichAlertTrans
							severity='info'
							i18nKey='order_bitpay_payment_pending_message'
						/>
					</Grid>
					}
					{!setDialogActions && <Grid item>
						<Button
							loading={loading}
							disabled={loading}
							variant='contained'
							color='primary'
							onClick={handleProcess}
						>
							{t(order.Status !== 'pending' ? 'continue_with_bitpay_btn' : 'checkout_with_bitpay_btn')}
						</Button>
					</Grid>
					}
				</Grid>
			</Grid>
		</Grid>
	);
};

export default Bitpay;

Bitpay.propTypes = {
	refreshOrder: PropTypes.func.isRequired,
	setPaying: PropTypes.func.isRequired,
	settings: PropTypes.object.isRequired,
	order: PropTypes.object.isRequired,
	setDialogActions: PropTypes.func // Used when the component is used in a dialog in order to integrate the action into the dialog actions
};
