import PropTypes  from 'prop-types';
import React      from 'react';
import Alert      from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { Trans }  from 'react-i18next';

const OsWarning = ({ warning }) => {
	return (
		<Alert severity='info'>
			<AlertTitle>
				<Trans
					i18nKey='warning_concerning_os'
					values={{ name: warning.MetaObject.Name }}
				>
					sample
				</Trans>
			</AlertTitle>
			<div dangerouslySetInnerHTML={{ __html: warning.Data }}/>
		</Alert>
	);
};

export default OsWarning;

OsWarning.propTypes = {
	warning: PropTypes.object.isRequired
};
