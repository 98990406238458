import React, { useContext } from 'react';
import AvatarEditor          from './AvatarEditor';
import Checkbox              from '@material-ui/core/Checkbox';
import FormControlLabel      from '@material-ui/core/FormControlLabel';
import TextField             from '@material-ui/core/TextField';
import PropTypes             from 'prop-types';
import { Typography }        from '@material-ui/core';
import useMediaQuery         from '@material-ui/core/useMediaQuery';
import Link                  from '../common/Links/Link';
import { LoginContext }      from '@karpeleslab/klb-react-services';


const FormBuilder = ({ profilePicture = true }) => {
	const isMobile = useMediaQuery(theme => theme.breakpoints.down('xs'));
	const { onInputChange, setAvatarImage, fields } = useContext(LoginContext);

	if (!fields)
		return <></>;

	return fields.map((field, idx) => {
		const fieldId = (field.name ?? 0) + idx;
		switch (field.type) {
			case 'text':
			case 'password':
			case 'phone':
			case 'email':
				return (
					<TextField
						variant='outlined'
						margin='normal'
						required={field.required}
						fullWidth
						name={field.name}
						label={field.label}
						type={field.type}
						id={fieldId}
						key={fieldId}
						onChange={onInputChange}
					/>
				);
			case 'label':
				if (field.link)
					return <Link href={field.link} key={fieldId}>{field.label}</Link>;

				// only snackbar on mobile (https://git.atonline.com/shells/shells-cp/-/issues/31)
				if (field.style === 'error' && isMobile)
					return <></>;

				return (
					<Typography
						variant='body2'
						key={fieldId}
						paragraph
						color={field.style === 'error' ? 'secondary' : 'initial'}
					>
						{field.label}
					</Typography>
				);
			case 'checkbox': {
				const checkbox = <Checkbox
					key={fieldId}
					value='remember'
					color='primary'
					name={field.name}
					required={field.required}
					id={fieldId}
					onChange={onInputChange}
				/>;

				let label = field.label;

				if (field.link)
					label = <Link href={field.link} target='_blank' rel='noopener noreferrer'>{field.label}</Link>;

				return (
					<FormControlLabel
						key={fieldId}
						control={checkbox}
						label={label}
					/>
				);
			}
			case 'image':
				if (!profilePicture) return <div key={fieldId}/>;
				return (
					<AvatarEditor
						key={fieldId}
						id={fieldId}
						fieldId={fieldId}
						field={field}
						required={field.required}
						setAvatarImage={setAvatarImage}
					/>
				);
			default:
				return <div key={fieldId}/>;
		}
	});
};

export default FormBuilder;

FormBuilder.propTypes = {
	profilePicture: PropTypes.bool,
};
