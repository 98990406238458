import PropTypes from 'prop-types';
import React, {useContext, useState} from 'react';
import {Grid} from '@material-ui/core';
import {Title} from '../../../core/typography/Title';
import {useTranslation} from 'react-i18next';
import Button from '../../../core/input/Button';
import Stripe from '../../../core/input/Stripe/Stripe';
import Alert from '@material-ui/lab/Alert';
import {useUserBillingMethodUpdate} from '@karpeleslab/klb-react-services';
import PaymentMethod from './PaymentMethod';
import {UserContext} from '../../../../context/UserContext';


// We only support Stripe for now
const UpdatePaymentMethodForm = ({method, setMethod, location}) => {
	const {t} = useTranslation();
	const [update] = useUserBillingMethodUpdate(method.User_Billing_Method__);
	const [updating, setUpdating] = useState(false);

	const [userContext] = useContext(UserContext);

	const [showForm, setShowForm] = useState(false);
	const [stripe, setStripe] = useState(null);
	const [stripeIntent, setStripeIntent] = useState(null);
	const [stripeElements, setStripeElements] = useState(null);

	const [saveEnabled, setSaveEnabled] = useState(false);
	const [error, setError] = useState(null);

	const onStripeChange = e => {
		setSaveEnabled(e.complete === true);
	};

	const handleSave = async () => {
		if (!stripe || !stripeElements)
			return; // stripe not loaded yet

		setUpdating(true);

		const result = await stripe.confirmSetup({
			elements: stripeElements,
			redirect: 'if_required',
			confirmParams: {
				payment_method_data: {
					billing_details: {
						name: `${location.First_Name} ${location.Last_Name}`,
						email: userContext.user.data.Email,
						address: {
							country: location.Country__,
							postal_code: location.Zip,
							state: location.Province ? location.Province : '',
							city: location.City ? location.City : '',
							line1: location.Address ? location.Address : '',
							line2: location.Address2 ? location.Address2 : '',
						}
					},
				},
			}
		});

		if (result.error) {
			if (result.error.type === 'card_error' || result.error.type === 'validation_error') {
				setError(result.error.message);
			} else {
				setError(t('unexpected_error'));
			}

			setUpdating(false);
			return;
		}

		update({stripe_intent: stripeIntent.stripe_intent, method: 'Stripe'})
			.then(setMethod)
			.then(() => setShowForm(false))
			.finally(() => setUpdating(false));
	};

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Title>{t('billing_payment_method_update_title')}</Title>
			</Grid>
			{!showForm &&
				<>
					<Grid item xs={12}>
						<Alert severity='info'>
							<PaymentMethod method={method}/>
						</Alert>
					</Grid>

					<Grid item xs={12}>
						<Grid container justify='flex-end'>
							<Button
								color='primary'
								variant='contained'
								onClick={() => setShowForm(true)}
							>
								{t('edit_btn')}
							</Button>
						</Grid>
					</Grid>
				</>
			}

			{showForm &&
				<>

					{method.Expired && <Grid item xs={12}>
						<Alert severity='error'>
							{t('billing_method_expired')}
						</Alert>
					</Grid>
					}

					{method.Fail_Count_Reached && <Grid item xs={12}>
						<Alert severity='error'>
							{t('billing_method_failed')}
						</Alert>
					</Grid>
					}

					{error && <Grid item xs={12}>
						<Alert severity='error'>
							{error}
						</Alert>
					</Grid>
					}

					<Grid item xs={12}>
						<Stripe
							setIntent={setStripeIntent}
							setStripeElements={setStripeElements}
							setStripe={setStripe}
							onChange={onStripeChange}
							disabled={updating}
						/>
					</Grid>

					<Grid item xs={12}>
						<Grid container justify='flex-end'>
							<Button
								color='primary'
								variant='contained'
								loading={updating}
								disabled={!stripe || !stripeElements || updating || !saveEnabled}
								onClick={handleSave}
							>
								{t('save_btn')}
							</Button>
						</Grid>
					</Grid>
				</>
			}
		</Grid>
	);
};

export default UpdatePaymentMethodForm;

UpdatePaymentMethodForm.propTypes = {
	method: PropTypes.object.isRequired,
	setMethod: PropTypes.func.isRequired,
	location: PropTypes.object.isRequired
};
