import React              from 'react';
import { Grid }           from '@material-ui/core';
import ShellConnection    from '../../../components/common/Shell/ShellConnection';
import ModeCheck          from '../../../components/layout/Mode/ModeCheck';
import ShellIpTable       from '../../../components/common/Shell/ShellIpTable';
import { Title }          from '../../../components/core/typography/Title';
import ShellLicenseTable  from '../../../components/common/Shell/License/Table/ShellLicenseTable';
import Panel              from '../../../components/layout/Container/tab/Panel';
import { useTranslation } from 'react-i18next';
import PropTypes          from 'prop-types';

const AdministrationTab = ({ currentTab, shell, refresh }) => {
	const { t } = useTranslation();
	return (
		<Panel value={currentTab} index={SHELL_TAB_ADMINISTRATION}>
			<Grid container spacing={3}>
				<Grid item xs={12}><ShellConnection shell={shell}/></Grid>
				<ModeCheck advanced>
					<Grid item xs={12}><ShellIpTable shell={shell} setShell={refresh}/></Grid>
				</ModeCheck>
				<Grid item xs={12}><Title>{t('shell_license_table')}</Title></Grid>
				<Grid item xs={12}><ShellLicenseTable shell={shell}/></Grid>
			</Grid>
		</Panel>
	);
};

export default AdministrationTab;
export const SHELL_TAB_ADMINISTRATION = 1;

AdministrationTab.propTypes = {
	currentTab: PropTypes.number.isRequired,
	refresh: PropTypes.func.isRequired,
	shell: PropTypes.object.isRequired
};
