import React                     from 'react';
import { Trans, useTranslation } from 'react-i18next';
import EmptyState                from '../../../../core/feedback/State/Empty';
import Graphic                   from '../../../../../assets/img/shell_licenses_empty_state.svg';

const Empty = () => {
	const { t } = useTranslation();

	return (
		<EmptyState
			title={t('shell_license_empty_title')}
			text={<Trans i18nKey='shell_license_empty_desc'>
				sample<br/>
				<strong>sample</strong>
			</Trans>}
			graphic={Graphic}
		/>
	);
};

export default Empty;
