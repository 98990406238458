import PropTypes                      from 'prop-types';
import React, { useEffect, useState } from 'react';
import { IconButton }                 from '@material-ui/core';
import Tooltip                        from '@material-ui/core/Tooltip';
import { useTranslation }             from 'react-i18next';
import { useShellStop }               from '@karpeleslab/klb-react-services';
import StopIcon                       from '@material-ui/icons/Stop';
import ShellActionConfirmationDialog  from '../ShellActionConfirmationDialog';
import { SHELL_STOP_ENABLE_STATES }   from '../../../../hooks/useShells';

const ShellActionStop = ({ setShell, shell, setLoading, disabled = false }) => {
	const { t } = useTranslation();
	const [stop, stopping] = useShellStop(shell.Shell__);
	const [showConfirm, setShowConfirm] = useState(false);

	useEffect(() => {
		setLoading(stopping);
	}, [stopping]);

	const stopHandler = () => {
		return stop().then(() => setShell({ ...shell, State: 'shutoff' }));
	};

	return (
		<>
			<Tooltip arrow title={t('shell_action_stop')} placement='bottom'>
				<span>
					<IconButton
						aria-label={t('shell_action_stop')}
						disabled={!SHELL_STOP_ENABLE_STATES.includes(shell.State) || stopping || disabled}
						onClick={() => setShowConfirm(true)}
					>
						<StopIcon/>
					</IconButton>
				</span>
			</Tooltip>
			<ShellActionConfirmationDialog
				actionName='stop'
				action={stopHandler}
				open={showConfirm}
				setOpen={setShowConfirm}
				loading={stopping}
			/>
		</>
	);
};

export default ShellActionStop;

ShellActionStop.propTypes = {
	disabled: PropTypes.bool,
	setLoading: PropTypes.func.isRequired,
	setShell: PropTypes.func.isRequired,
	shell: PropTypes.object.isRequired
};
