import React                   from 'react';
import { Switch, useLocation } from 'react-router-dom';
import { default as Route }    from '../core/utils/RouteTransition/RouteTransition';
import Login                   from '../../pages/Login/Login';
import {
	getAccountWarningRoute,
	getBillingRoute,
	getCustomOsEditRoute,
	getCustomOsRoute,
	getISORoute,
	getISOsRoute,
	getLinksRoute,
	getLoginRoute,
	getOrderViewRoute,
	getPartnershipRoute,
	getShellsRoute,
	getShellViewRoute,
	getSSHKeychainEditRoute,
	getSSHKeychainRoute,
	getSwitchToModeRoute,
	getUserSettingsRoute
}                              from './Factory';
import Profile                 from '../../pages/Profile/Profile';
import Keychains               from '../../pages/Keychain/Keychains';
import KeychainEdit            from '../../pages/Keychain/KeychainEdit';
import PageNotFound            from '../../pages/System/PageNotFound';
import Partnership             from '../../pages/Partnership/Partnership';
import ViewOrder               from '../../pages/Order/ViewOrder';
import ShellList               from '../../pages/Shell/ShellList';
import Shell                   from '../../pages/Shell/Shell';
import Links                   from '../../pages/Links/Links';
import CustomOSList            from '../../pages/OperatingSystem/CustomOSList';
import CustomOsEdit            from '../../pages/OperatingSystem/CustomOSEdit';
import SwitchMode              from '../../pages/Mode/SwitchMode';
import PaymentInfo             from '../../pages/Billing/PaymentInfo';
import ISOList                 from '../../pages/ISO/ISOList';
import IsoEdit                 from '../../pages/ISO/ISOEdit';
import Warning                 from '../../pages/Warning/Warning';


const Routes = () => {
	const location = useLocation();

	return (
		<Switch location={location}>
			<Route anyMode path={getLoginRoute()} loginRequired={false} exact Component={Login}/>
			<Route anyMode path={getUserSettingsRoute()} loginRequired={true} exact Component={Profile}/>
			<Route advanced path={getSSHKeychainRoute()} loginRequired={true} exact Component={Keychains}/>
			<Route advanced path={getSSHKeychainEditRoute()} loginRequired={true} exact Component={KeychainEdit}/>
			<Route anyMode path={getPartnershipRoute()} loginRequired={true} exact Component={Partnership}/>
			<Route anyMode path={getOrderViewRoute()} loginRequired={true} exact Component={ViewOrder}/>
			<Route anyMode path={getBillingRoute()} loginRequired={true} exact Component={PaymentInfo}/>
			<Route anyMode path={getShellViewRoute()} loginRequired={true} exact Component={Shell}/>
			<Route anyMode path={getShellsRoute()} loginRequired={true} exact Component={ShellList}/>
			<Route anyMode path={getLinksRoute()} loginRequired={true} exact Component={Links}/>
			<Route advanced path={getCustomOsRoute()} loginRequired={true} exact Component={CustomOSList}/>
			<Route advanced path={getCustomOsEditRoute()} loginRequired={true} exact Component={CustomOsEdit}/>
			<Route advanced forceHide path={getISOsRoute()} loginRequired={true} exact Component={ISOList}/>
			<Route advanced forceHide path={getISORoute()} loginRequired={true} exact Component={IsoEdit}/>
			<Route lite advanced path={getSwitchToModeRoute()} loginRequired={true} exact Component={SwitchMode}/>
			<Route anyMode path={getAccountWarningRoute()} loginRequired={true} exact Component={Warning}/>

			<Route anyMode path='*' loginRequired={true} exact Component={PageNotFound}/>
		</Switch>
	);
};


export default Routes;
