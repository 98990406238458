import React, { useCallback, useEffect, useState } from 'react';
import makeStyles                                  from '@material-ui/core/styles/makeStyles';
import { Trans }                                   from 'react-i18next';
import { Link, useLocation }                       from 'react-router-dom';
import { getAccountWarningRoute }                  from '../../RouteController/Factory';
import { Typography }                              from '@material-ui/core';
import Ellipsis                                    from '../../core/typography/Ellipsis';
import { darken, lighten }                         from '@material-ui/core/styles';
import Paper                                       from '@material-ui/core/Paper';
import Grid                                        from '@material-ui/core/Grid';
import ReportProblemOutlinedIcon                   from '@material-ui/icons/ReportProblemOutlined';
import { LinkTypo }                                from '../../common/Links/Link';
import { useShellAccountWarnings }                 from '@karpeleslab/klb-react-services';

const useStyles = makeStyles(theme => {
	// based on https://github.com/mui-org/material-ui/blob/master/packages/material-ui-lab/src/Alert/Alert.js
	const getColor = theme.palette.type === 'light' ? darken : lighten;
	const getBackgroundColor = theme.palette.type === 'light' ? lighten : darken;

	return {
		root: {
			padding: theme.spacing(3),
			justifyContent: 'center',
			color: getColor(theme.palette.warning.main, 0.6),
			backgroundColor: getBackgroundColor(theme.palette.warning.main, 0.9),
		},
		icon: {
			color: theme.palette.warning.main,
		},
		message: {
			textAlign: 'center'
		},
		action: {
			marginLeft: 'unset',
		}
	};
});

const AccountWarnings = () => {
	const classes = useStyles();

	const [fetch] = useShellAccountWarnings();
	const [warnings, setWanings] = useState(null);
	const location = useLocation();

	const execute = useCallback(() => {
		fetch()
			.then(warnings => {
				let found = {};
				let result = [];
				// We only keep 1 warning of each type, and counting the number of time they occured
				warnings.forEach(w => {
					if (w.Type in found) {
						result[found[w.Type]].count += 1;
						return;
					}
					result.push({ ...w, count: 1 });
					found[w.Type] = result.length - 1;
				});

				setWanings(result);
			});
	}, [fetch, setWanings]);

	useEffect(() => {
		execute();
	}, [fetch, setWanings, location]);

	useEffect(() => {
		const interval = setInterval(() => {
			execute();
		}, 1000 * 60 * 5); // every 5 minutes
		return () => {
			clearInterval(interval);
		};
	}, [execute]);

	if (location.pathname === getAccountWarningRoute() || !warnings || warnings.length < 1) return <></>;

	// This is a custom warning alert basically

	// icon | title | action
	// warnings
	return (
		<Paper elevation={0} className={classes.root}>
			<Grid container spacing={2} alignItems='center' justify='center' direction='column'>
				<Grid item>
					<Grid container alignItems='center' justify='center' spacing={2} direction='row'>
						<Grid item>
							<ReportProblemOutlinedIcon className={classes.icon}/>
						</Grid>
						<Grid item>
							<LinkTypo
								component={Link}
								to={getAccountWarningRoute()}
							>
								<Trans i18nKey='shell_account_warning_desc'>
									sample<br/>
									<strong>sample</strong>
								</Trans>
							</LinkTypo>
						</Grid>
					</Grid>

				</Grid>
				<Grid item>
					<div align='left'>
						{warnings.map(w =>
							<Ellipsis key={w.Shell_Warning__} row={1}>
								<Typography variant='body1'>
									<Trans
										i18nKey={`${w.Type}_warning_title`}
										values={{ count: w.count, text: w.Data.replace(/(<([^>]+)>)/ig, '') }}
									>
										sample<br/>
										<strong>sample</strong>
									</Trans>
								</Typography>
							</Ellipsis>)}
					</div>
				</Grid>
			</Grid>

		</Paper>);
};

export default AccountWarnings;
