import PropTypes                      from 'prop-types';
import React, { useEffect, useState } from 'react';
import RichAlertTrans                 from '../../core/feedback/RichAlertTrans';

const TIMER_VALUE = 15; // in seconds

const PreparingRefresh = ({ shell, refresh }) => {
	// We use this method instead of just at setTimeout so we can have the number of seconds left before refresh and display it
	const [counter, setCounter] = useState(TIMER_VALUE);

	const isPreparing = () => shell.State === 'preparing';

	useEffect(() => {
		if (!isPreparing()) return;

		const timer = setInterval(() => {
			setCounter(prev => prev - 1);
		}, 1000);
		return () => clearInterval(timer);
		// eslint-disable-next-line
	}, [shell, setCounter]);

	useEffect(() => {
		if (counter > 0) return;
		refresh()
			.then(() => setCounter(TIMER_VALUE));
		// eslint-disable-next-line
	}, [counter]);

	if (!isPreparing()) return null;

	return <RichAlertTrans values={{ timer: counter }} i18nKey='custom_os_preparing_info' severity='warning'/>;
};

export default PreparingRefresh;

PreparingRefresh.propTypes = {
	refresh: PropTypes.func.isRequired,
	shell: PropTypes.object.isRequired
};
