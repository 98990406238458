import PropTypes      from 'prop-types';
import React          from 'react';
import { Grid }       from '@material-ui/core';
import RichAlertTrans from '../../../core/feedback/RichAlertTrans';
import RichAlert                 from '../../../core/feedback/RichAlert';
import { Trans, useTranslation } from 'react-i18next';
import Button                    from '../../../core/input/Button';

const SelectOsLicenseInfo = ({ os, loadingLicense, hasLicense, handleBuyLicense }) => {
	const  {t} = useTranslation();

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				{(loadingLicense || hasLicense) &&
				<RichAlertTrans
					severity={loadingLicense ? 'info' : 'success'}
					i18nKey={loadingLicense ? 'shell_os_loading_license' : 'shell_os_loading_found'}
					values={{ name: os.Catalog_Product['Basic.Name'] }}
				/>
				}
				{(!loadingLicense && !hasLicense) &&
				<RichAlert
					severity='warning'
					action={
						<Button
							color='primary'
							variant='contained'
							onClick={handleBuyLicense}
						>
							{t('buy_license_btn')}
						</Button>
					}
				>
					<Trans
						i18nKey='shell_os_license_not_found'
						values={{
							name: os.Catalog_Product['Basic.Name'],
							price: os.Catalog_Product['Price.Price'].display,
						}}
					>
						sample<br/>
						<strong>sample</strong>
						<a href='' onClick={handleBuyLicense}>sample</a>
					</Trans>
				</RichAlert>
				}
			</Grid>
		</Grid>
	);
};

export default SelectOsLicenseInfo;

SelectOsLicenseInfo.propTypes = {
	handleBuyLicense: PropTypes.func.isRequired,
	hasLicense: PropTypes.bool.isRequired,
	loadingLicense: PropTypes.bool.isRequired,
	os: PropTypes.object.isRequired,
};
