import PropTypes                                         from 'prop-types';
import React, { useCallback, useEffect, useState }       from 'react';
import DialogTitle                                       from '@material-ui/core/DialogTitle';
import DialogActions                                     from '@material-ui/core/DialogActions';
import Button                                            from '../../../core/input/Button';
import Dialog                                            from '@material-ui/core/Dialog';
import { useTranslation }                                from 'react-i18next';
import { getShellGraphic, isShellYearly, useShellPlans } from '../../../../hooks/useShells';
import Loader                                            from '../../../layout/Loader/Loader';
import Grid                                              from '@material-ui/core/Grid';
import Tab                                               from '@material-ui/core/Tab';
import Tabs                                              from '@material-ui/core/Tabs';
import Panel                                             from '../../../layout/Container/tab/Panel';
import { List }                                          from '@material-ui/core';
import ShellPlanListItem                                 from './ShellPlanListItem';
import ShellPlanDetails                                  from './ShellPlanDetails';
import useMediaQuery                                     from '@material-ui/core/useMediaQuery';
import { isCustomPlan, isYearly }                        from '../../../../utils/shell';
import MaxPlan                                           from './MaxPlan';
import NewOrderDialog                                    from '../../Order/order/NewOrderDialog';


const MONTHLY_TAB = 1;
const YEARLY_TAB = 0;

const ShellPlanSelectionDialog = ({ open, setOpen, shell, refresh }) => {
	const { t } = useTranslation();
	const [plans, fetchPlans] = useShellPlans();

	// We will use slightly more RAM but it will be easier to change the UI is there is plans or not, and be less cpu intensive
	const [monthlyPlans, setMonthlyPlans] = useState([]);
	const [yearlyPlans, setYearlyPlans] = useState([]);

	const [selectedTab, setSelectedTab] = useState(isShellYearly(shell) ? YEARLY_TAB : MONTHLY_TAB);
	const [selectedPlan, setSelectedPlan] = useState(null);

	const isMobile = useMediaQuery(theme => theme.breakpoints.down('xs'));

	const [nbUnit, setNbUnit] = useState(Math.max(8, shell.Size));
	const [selectedProduct, setSelectedProduct] = useState(null);

	// Related to the cart
	const [showOrder, setShowOrder] = useState(false);
	const [request, setRequest] = useState(null);

	// Calling refresh() will change the shell and will trigger the plans to refresh thus to reconstruct the available list
	// refresh() is called when an upgrade payment is completed (see handleComplete)
	useEffect(() => {
		fetchPlans();
	}, [shell]);

	useEffect(() => {
		if (!plans || !plans.data) return;
		const filtered = plans.data.filter(p => p.Catalog_Product__ === selectedPlan);
		if (!filtered || filtered.length < 1) setSelectedProduct(null);
		else setSelectedProduct(filtered[0]);
	}, [setSelectedProduct, plans, selectedPlan]);

	useEffect(() => {
		if (!plans || !plans.data) return;

		setMonthlyPlans(plans.data.filter(p => !isYearly(p) && isShown(p)));
		setYearlyPlans(plans.data.filter(p => isYearly(p) && isShown(p)));

	}, [plans, setMonthlyPlans, setYearlyPlans]);

	const init = useCallback(() => {
		setShowOrder(false);
		setRequest(null);
		setSelectedPlan(null);
		setSelectedTab(isShellYearly(shell) ? YEARLY_TAB : MONTHLY_TAB);
	}, [shell, setSelectedTab, setSelectedPlan]);

	useEffect(() => {
		init();
	}, [init]);

	const handleClose = () => {
		setOpen(false);
	};

	const handleTabChange = (event, newValue) => {
		setSelectedTab(newValue);
	};

	const createSelectHandler = product => () => {
		setSelectedPlan(product);
	};

	const isSelected = product => {
		return product === selectedPlan;
	};

	const isShown = plan => {
		if (isCustomPlan(plan)) return true;

		if (plan['Shell.Size'] < shell.Size) return false;

		// Special hardcoded rule, that prevent to be able to upgrade to lite yearly when we are in basic monthly
		// Technically we could do it (because basic and lite plan are the same),
		// but in term of UX/marketing we consider the lite plan as an inferior plan
		if (
			plan['Description.AuthorCode'] === 'lite' &&
			isYearly(plan) &&
			!isShellYearly(shell) &&
			shell.Catalog_Product['Description.AuthorCode'] === 'basic'
		)
			return false;

		return parseFloat(plan['Price.Price'].value) > parseFloat(shell.Catalog_Product['Price.Price'].value);
	};

	const handleRenew = () => {
		const productId = selectedPlan;
		const shellId = shell.Shell__;

		const isCustom = isCustomPlan(selectedProduct);

		const urlParams = `${productId},shell=${shellId}${
			isCustom ? `,shell_size=${nbUnit}` : ''
		},mode=upgrade`;

		setRequest(urlParams);
		setShowOrder(true);
	};

	const handleComplete = () => {
		refresh();
		handleClose();
	};

	return (
		<Dialog
			open={open}
			aria-labelledby={t('renew_shell_title')}
			aria-describedby={t('renew_shell_title')}
			onEnter={init}
			fullWidth
			maxWidth='lg'
		>
			<DialogTitle id='shell-renew-title'>
				{t('renew_shell_title')}
			</DialogTitle>
			<div style={{ padding: 20, overflow: 'auto' }}>
				{!plans && <Loader/>}
				{plans && <Grid container spacing={2}>
					<Grid item xs={12}>
						<Tabs
							variant='scrollable'
							scrollButtons='off'
							value={selectedTab}
							indicatorColor='primary'
							textColor='primary'
							onChange={handleTabChange}
						>
							{(yearlyPlans.length > 0) &&
							<Tab label={t('shell_plans_yearly')} value={YEARLY_TAB}/>
							}

							{(!isShellYearly(shell) && monthlyPlans.length > 0) &&
							<Tab label={t('shell_plans_monthly')} value={MONTHLY_TAB}/>
							}

						</Tabs>
					</Grid>
					<Grid item xs={12}>
						<Grid container spacing={3} justify='center' alignItems='center'>
							<Grid item xs={12} md={!selectedProduct ? 12 : 6}>
								<Panel index={YEARLY_TAB} value={selectedTab} boxProps={{ p: isMobile ? 0 : 3 }}>
									{yearlyPlans.length < 1 && <MaxPlan/>}
									{yearlyPlans.length > 0 && <List>
										{yearlyPlans.map(plan =>
											<ShellPlanListItem
												key={plan.Catalog_Product__}
												plan={plan}
												graphic={getShellGraphic(plan['Description.AuthorCode'])}
												onClick={createSelectHandler(plan.Catalog_Product__)}
												forceShowMonthly={true}
												disabled={showOrder}
												selected={isSelected(plan.Catalog_Product__)}
												unit={nbUnit}
											/>
										)}
									</List>}
								</Panel>
								{(!isShellYearly(shell) && monthlyPlans.length > 0) &&
								<Panel index={MONTHLY_TAB} value={selectedTab} boxProps={{ p: isMobile ? 0 : 3 }}>
									<List>
										{monthlyPlans.map(plan =>
											<ShellPlanListItem
												key={plan.Catalog_Product__}
												plan={plan}
												graphic={getShellGraphic(plan['Description.AuthorCode'])}
												onClick={createSelectHandler(plan.Catalog_Product__)}
												forceShowMonthly={isCustomPlan(plan)}
												disabled={showOrder}
												selected={isSelected(plan.Catalog_Product__)}
												unit={nbUnit}
											/>
										)
										}
									</List>
								</Panel>}
							</Grid>
							{
								!!selectedProduct && <Grid item xs={12} md={6}>
									<ShellPlanDetails
										disabled={showOrder}
										selected={selectedProduct}
										minUnits={shell.Size}
										nbUnit={nbUnit}
										setNbUnit={setNbUnit}
									/>
								</Grid>
							}
						</Grid>
					</Grid>

				</Grid>}
			</div>
			<DialogActions>
				<Button
					disabled={showOrder}
					color='default'
					onClick={handleClose}
				>
					{t('close_btn')}
				</Button>
				{(yearlyPlans.length > 0 || !isShellYearly(shell)) &&
				<Button
					disabled={showOrder}
					loading={showOrder}
					onClick={handleRenew}
					color='primary'
					variant='contained'
				>
					{t('select_plan_bt')}
				</Button>}
			</DialogActions>

			{
				(showOrder && request) &&
				<NewOrderDialog
					setOpen={setShowOrder}
					request={request}
					open={showOrder}
					onComplete={handleComplete}
				/>
			}

		</Dialog>
	);
};

export default ShellPlanSelectionDialog;

ShellPlanSelectionDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	setOpen: PropTypes.func.isRequired,
	refresh: PropTypes.func.isRequired,
	shell: PropTypes.object.isRequired
};
