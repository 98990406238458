import React, { useState }         from 'react';
import Dialog                      from '@material-ui/core/Dialog';
import DialogContentText           from '@material-ui/core/DialogContentText';
import DialogContent               from '@material-ui/core/DialogContent';
import { useTranslation }          from 'react-i18next';
import DialogTitle                 from '@material-ui/core/DialogTitle';
import DialogActions               from '@material-ui/core/DialogActions';
import { useHistory }              from 'react-router-dom';
import Form                        from './Form';
import PropTypes                   from 'prop-types';
import { getSSHKeychainEditRoute } from '../../RouteController/Factory';
import Grid                        from '@material-ui/core/Grid';
import Button                      from '../../core/input/Button';
import { useKeychainCreate }       from '@karpeleslab/klb-react-services';

const CreateDialog = ({ open, setOpen }) => {
	const { t } = useTranslation();
	const history = useHistory();
	const [saveEnabled, setSaveEnabled] = useState(false);
	const [data, setData] = useState({});
	const [create, creating] = useKeychainCreate();

	const handleClose = () => {
		setOpen(false);
	};

	const handleCreate = e => {
		e.preventDefault();
		create(data.Label)
			.then(d => history.push(getSSHKeychainEditRoute(d.Keychain__)));
	};

	return (
		<Dialog
			open={open}
			aria-labelledby={t('dialog_create_keychain_title')}
			aria-describedby={t('dialog_create_keychain_title')}
			onEnter={() => setData({})}
		>
			<form onSubmit={handleCreate}>
				<DialogTitle id='create-keychain-title'>
					{t('dialog_create_keychain_title')}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id='create-keychain-description'>
						{t('account_keychains_description')}
					</DialogContentText>
					<Grid container spacin={2}>
						<Grid item xs={12}>
							<Form
								data={data}
								setData={setData}
								setIsValid={setSaveEnabled}
								disabled={creating}
							/>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button color='default' onClick={handleClose} disabled={creating}>
						{t('close_btn')}
					</Button>
					<Button
						onClick={handleCreate}
						color='primary'
						type='submit'
						variant='contained'
						disabled={creating || !saveEnabled}
						loading={creating}
					>
						{t('create_btn')}
					</Button>
				</DialogActions>
			</form>
		</Dialog>
	);
};

export default CreateDialog;

CreateDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	setOpen: PropTypes.func.isRequired,
};
