import PropTypes           from 'prop-types';
import React, { useState } from 'react';
import Dialog              from '@material-ui/core/Dialog';
import { useTranslation }  from 'react-i18next';
import DialogTitle         from '@material-ui/core/DialogTitle';
import DialogContent       from '@material-ui/core/DialogContent';
import { Grid }            from '@material-ui/core';
import RichAlertTrans      from '../../core/feedback/RichAlertTrans';
import RefreshIcon         from '@material-ui/icons/Refresh';
import Button              from '../../core/input/Button';
import { useLocation }     from 'react-router';

const ExpiredModal = ({ open }) => {
	const { t } = useTranslation();
	const location = useLocation();
	// Just ui stuff to provide user feedback while the page is being refreshed
	const [loading, setLoading] = useState(false);

	return (
		<Dialog
			open={open}
			aria-labelledby={t('dialog_session_expired_title')}
			aria-describedby={t('dialog_session_expired_title')}
		>
			<DialogTitle id='dialog_session_expired_title'>
				{t('dialog_session_expired_title')}
			</DialogTitle>
			<DialogContent>
				<Grid container spacing={2} alignItems='center' justify='center' direction='column'>
					<Grid item>
						<RichAlertTrans i18nKey='dialog_session_expired_desc' severity='info'/>
					</Grid>
					<Grid item>
						<Button
							loading={loading}
							onClick={() => {
								setLoading(true);
							}}
							component='a'
							href={`${location.pathname}${location.search}${location.hash}`}
							endIcon={<RefreshIcon/>}
							variant='contained'
							color='primary'
						>
							{t('reload_btn')}
						</Button>
					</Grid>
				</Grid>
			</DialogContent>

		</Dialog>
	);
};

export default ExpiredModal;

ExpiredModal.propTypes = {
	open: PropTypes.bool.isRequired
};
