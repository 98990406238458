import PropTypes                      from 'prop-types';
import React, { useEffect, useState } from 'react';
import makeStyles                     from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
	root: {
		position: 'relative',
		textAlign: props => props.centered ? 'center' : 'inherit',
		'&::after': {
			top: 0,
			display: props => props.loaded ? 'none' : 'block',
			content: '""',
			position: 'absolute',
			width: '100%',
			height: '100%',
			transform: 'translateX(-100%)',
			//background: '-webkit-gradient(linear, left top, right top, from(transparent), color-stop(rgba(255, 255, 255, 0.2)), to(transparent))',
			'background': 'linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.2), transparent)',
			'animation': '$loading 0.8s infinite',
		}
	},

	/* Loading Animation */
	'@keyframes loading': {
		'100%': {
			transform: 'translateX(100%)',
		}
	},

	placeholder: {
		'-webkit-transition': 'opacity 0.2s ease-in-out',
		'-moz-transition': 'opacity 0.2s ease-in-out',
		'-o-transition': 'opacity 0.2s ease-in-out',
		'transition': 'opacity 0.2s ease-in-out',
		filter: 'grayscale(0.7)',
		'opacity': props => props.loaded ? '0' : '1',
	},

	final: {
		left: props => props.centered && !props.cleaned ? '50%' : '0',
		transform: props => props.centered && !props.cleaned ? 'translate(-50%, 0%)' : 'unset',
		position: 'absolute',
		'-webkit-transition': 'opacity 0.2s ease-in-out',
		'-moz-transition': 'opacity 0.2s ease-in-out',
		'-o-transition': 'opacity 0.2s ease-in-out',
		'transition': 'opacity 0.2s ease-in-out',
		'opacity': props => props.loaded ? '1' : '0',
	}
}));

const Img = ({ loaderStyle = {}, rootStyle = {}, centered = false, ...rest }) => {
	const [loading, setLoading] = useState(true);
	// Use the remove all reference to the loader image and lighten the dom
	const [cleaned, setCleaned] = useState(false);
	const classes = useStyles({ loaded: !loading, cleaned: cleaned, centered: centered });
	const [cleanedStyle, setCleanedStyle] = useState({});

	useEffect(() => {
		if (loading) return;
		const t = setTimeout(() => {
			setCleanedStyle({
				position: 'inherit',
				transform: 'unset',
				left: 'unset',
			});
			setCleaned(true);
		}, 1000);
		return () => {
			clearTimeout(t);
		};
	}, [loading, setCleaned, setCleanedStyle]);

	return (
		<div className={classes.root} style={rootStyle}>
			{!cleaned && <img
				{...rest}
				src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkEAQAABcAEzAsq6cAAAAASUVORK5CYII='
				className={`${rest.className ?? ''} ${classes.placeholder}`}
				style={{ ...(rest.style ?? {}), ...loaderStyle }}
			/>}

			<img
				{...rest}
				style={{ ...(rest.style ?? {}), ...(cleanedStyle) }}
				className={`${rest.className ?? ''} ${classes.final}`}
				onLoad={() => setLoading(false)}
			/>
		</div>
	);
};

export default Img;

Img.propTypes = {
	loaderStyle: PropTypes.object,
	rootStyle: PropTypes.object,
	style: PropTypes.any,
	centered: PropTypes.bool
};
