import MuiTheme             from './components/core/MuiTheme/MuiTheme';
import React                from 'react';
import ScrollToTop          from './components/core/utils/ScrollToTop/ScrollToTop';
import Helmet               from 'react-helmet';
import Layout               from './components/layout/Layout';
import Snackbar             from './components/layout/Snackbar/Snackbar';
import AppContextContainers from './AppContextContainers';
import { ModeContainer }    from './context/ModeContext';

function App() {
	return (
		<ModeContainer>
			<MuiTheme>
				<AppContextContainers>
					<ScrollToTop/>
					<Helmet>
						<meta charset='utf-8'/>
						<meta
							name='viewport'
							content='width=device-width, initial-scale=1, user-scalable=no'
						/>
						<meta name='theme-color' content='#000000'/>
					</Helmet>
					<Layout/>
					<Snackbar/>
				</AppContextContainers>
			</MuiTheme>
		</ModeContainer>
	);
}

export default App;
