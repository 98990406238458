import PropTypes              from 'prop-types';
import React                  from 'react';
import ListItem               from '@material-ui/core/ListItem';
import ListItemIcon           from '@material-ui/core/ListItemIcon';
import ListItemText           from '@material-ui/core/ListItemText';
import makeStyles             from '@material-ui/core/styles/makeStyles';
import useMediaQuery          from '@material-ui/core/useMediaQuery';
import ShellPlanListItemPrice from './ShellPlanListItemPrice';
import { isCustomPlan }       from '../../../../utils/shell';

const useStyles = makeStyles(theme => ({

	root: {
		border: '1px solid #B0E170',
		padding: theme.spacing(2),
		marginBottom: theme.spacing(1),
		// background: 'white',
		minHeight: '105px',
	},
	disabled: {
		filter: 'grayscale(1)'
	},
	selected: {
		background: '#B0E170!important',
	},

	oldPrice: {
		color: theme.palette.secondary.main,
		textDecoration: 'line-through',
		fontSize: '14px',
		'fontWeight': 400,
	},
	discountWrapper: {
		display: 'flex',
		textAlign: 'center',
		justifyContent: 'center',
		alignItems: 'center',
		flexWrap: 'wrap'
	},
	discount: {
		background: '#fddce0',
		padding: '0 4px',
		borderRadius: '6px',
		fontSize: '12px',
		fontWeight: 400,
		margin: 0,
		color: '#f64f64'
	}
}));


const ShellPlanListItem = ({ plan, unit = 1, graphic, forceShowMonthly = false, ...rest }) => {
	const classes = useStyles();
	const isMobile = useMediaQuery(theme => theme.breakpoints.down('xs'));

	return (
		<ListItem
			button
			{...rest}
			classes={{
				root: classes.root,
				selected: classes.selected,
				disabled: classes.disabled,
			}}
		>
			<ListItemIcon>
				<img
					width={isMobile ? 60 : 130}
					style={{ height: '100%' }}
					src={graphic}
					alt={plan['Basic.Name']}
				/>
			</ListItemIcon>
			<ListItemText primary={plan['Basic.Name']}/>
			<div>
				<ShellPlanListItemPrice
					unit={unit}
					item={plan}
					forceShowMonthly={forceShowMonthly}
					isCustom={isCustomPlan(plan)}
				/>
			</div>
		</ListItem>
	);
};

export default ShellPlanListItem;

ShellPlanListItem.propTypes = {
	forceShowMonthly: PropTypes.bool,
	graphic: PropTypes.string.isRequired,
	plan: PropTypes.object.isRequired,
	selected: PropTypes.bool,
	unit: PropTypes.number
};
