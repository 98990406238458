import { useTranslation } from 'react-i18next';

export const useHumanTimeFormatter = () => {
	const { t } = useTranslation();
	return timeInSeconds => {
		const secNum = parseInt(timeInSeconds, 10);
		let hours = Math.floor((secNum ) / 3600);
		let minutes = Math.floor((secNum  - hours * 3600) / 60);
		let seconds = secNum - hours * 3600 - minutes * 60;

		if (hours > 0)
			return t('remaining_time_hours', { hours: hours, minutes: minutes });
		if (minutes > 0)
			return t('remaining_time_minutes', {
				minutes: minutes,
				seconds: seconds
			});
		if (seconds > 0) return t('remaining_time_seconds', { seconds: seconds });
		return t('remaining_time_none', { seconds: seconds });
	};
};
