import PropTypes            from 'prop-types';
import React, { useEffect } from 'react';
import { useTranslation }   from 'react-i18next';
import Grid                 from '@material-ui/core/Grid';
import TextField            from '@material-ui/core/TextField';
import CountrySelector      from '../../../core/input/CountrySelector';

const Form = ({ data, setData, setIsValid, disabled = false }) => {
	const { t } = useTranslation();

	const createChangeHandler = (key, isEvent = true) => e => {
		setData({ ...data, [key]: isEvent ? e.target.value : e });
	};

	const getValue = (key, def = '') => {
		if (!(key in (data ?? {}))) return def;
		return data[key];
	};

	useEffect(() => {
		if (!('First_Name' in (data ?? {})) || !getValue('First_Name').trim()) setIsValid(false);
		else if (!('Last_Name' in (data ?? {})) || !getValue('Last_Name').trim()) setIsValid(false);
		else if (!('Zip' in (data ?? {})) || !getValue('Zip').trim()) setIsValid(false);
		else if (!('Country__' in (data ?? {})) || !getValue('Country__')) setIsValid(false);
		else setIsValid(true);
	}, [data, setIsValid]);

	return (
		<Grid container spacing={2}>
			<Grid item xs={12} md={6}>
				<TextField
					variant='outlined'
					label={t('user_location_first_name_label')}
					disabled={disabled}
					required
					fullWidth
					value={getValue('First_Name', '')}
					onChange={createChangeHandler('First_Name')}
				/>
			</Grid>
			<Grid item xs={12} md={6}>
				<TextField
					variant='outlined'
					label={t('user_location_last_name_label')}
					disabled={disabled}
					required
					fullWidth
					value={getValue('Last_Name', '')}
					onChange={createChangeHandler('Last_Name')}
				/>
			</Grid>
			<Grid item xs={12} md={6}>
				<TextField
					variant='outlined'
					label={t('user_location_zip_label')}
					disabled={disabled}
					required
					fullWidth
					value={getValue('Zip', '')}
					onChange={createChangeHandler('Zip')}
				/>
			</Grid>
			<Grid item xs={12} md={6}>
				<CountrySelector
					label={t('user_location_country_label')}
					disabled={disabled}
					required
					fullWidth
					value={getValue('Country__', '')}
					setValue={createChangeHandler('Country__', false)}
				/>
			</Grid>
		</Grid>
	);
};

export default Form;

Form.propTypes = {
	data: PropTypes.object.isRequired,
	disabled: PropTypes.bool,
	setData: PropTypes.func.isRequired,
	setIsValid: PropTypes.func.isRequired
};
