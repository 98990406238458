import PropTypes          from 'prop-types';
import React              from 'react';
import { useTranslation } from 'react-i18next';
import { Grid }           from '@material-ui/core';
import { Title }    from '../../../../core/typography/Title';
import ShellWarning from './ShellWarning';

const ShellWarnings = ({ warnings = [] }) => {
	const { t } = useTranslation();

	if (warnings.length < 1) return <></>;

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Title>{t('account_warning_shell')}</Title>
			</Grid>
			{warnings.map(w =>
				<Grid item xs={12} key={w.Shell_Warning__}>
					<ShellWarning warning={w}/>
				</Grid>
			)}
		</Grid>
	);
};

export default ShellWarnings;

ShellWarnings.propTypes = {
	warnings: PropTypes.array.isRequired
};
