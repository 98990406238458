import PropTypes             from 'prop-types';
import React                 from 'react';
import Grid                  from '@material-ui/core/Grid';
import DateUtil              from '../../core/data/Date/DateUtil';
import { IconButton }        from '@material-ui/core';
import GetAppIcon            from '@material-ui/icons/GetApp';
import { Link }              from 'react-router-dom';
import { getOrderViewRoute } from '../../RouteController/Factory';
import VisibilityIcon        from '@material-ui/icons/Visibility';
import { useTranslation }    from 'react-i18next';

const OrderRowMobile = ({ order }) => {
	const { t } = useTranslation();

	return (
		<Grid container spacing={1}>
			<Grid item xs={6}>{order.Invoice_Number}</Grid>
			<Grid item xs={6}>{`${order.Status ?? '-'}`}</Grid>

			<Grid item xs={6}>{t('history_order_date')}</Grid>
			<Grid item xs={6}><DateUtil klbDateObj={order.Invoice_Date} format="LL"/></Grid>
			<Grid item xs={6}>{t('history_paid_date')}</Grid>
			<Grid item xs={6}><DateUtil klbDateObj={order.Paid} format="LL"/></Grid>
			<Grid item xs={6}>{`${order.Total.display ?? '-'}`}</Grid>
			<Grid item xs={6}>
				<Grid container spacing={1} justify='space-between'>
					<IconButton
						componant='a'
						href={order.Invoice_Url}
						target="blank"
						variant="outlined"
						size="small"
						color="default"
					>
						<GetAppIcon/>
					</IconButton>
					<IconButton
						component={Link}
						to={getOrderViewRoute(order.Order__)}
						variant="contained"
						size="small"
						color="primary">
						<VisibilityIcon/>
					</IconButton>
				</Grid>
			</Grid>
		</Grid>
	);

};

export default OrderRowMobile;

OrderRowMobile.propTypes = {
	order: PropTypes.object.isRequired
};
