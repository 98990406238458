import PropTypes                      from 'prop-types';
import React, { useEffect, useState } from 'react';
import Dialog                         from '@material-ui/core/Dialog';
import DialogTitle                    from '@material-ui/core/DialogTitle';
import DialogContent                  from '@material-ui/core/DialogContent';
import Grid                           from '@material-ui/core/Grid';
import DialogActions                  from '@material-ui/core/DialogActions';
import Button                         from '../../core/input/Button';
import { useTranslation }             from 'react-i18next';
import { useShellMountISO }           from '@karpeleslab/klb-react-services';
import ShellISOSelector               from '../../core/input/ShellISOSelector';


const IsoMountDialog = ({ open, setOpen, shellId, refresh }) => {
	const { t } = useTranslation();
	const [selected, setSelected] = useState(null);
	const [saveEnabled, setSaveEnabled] = useState(false);

	const [mount, mounting] = useShellMountISO(selected);

	useEffect(() => {
		setSaveEnabled(!!selected);
	}, [selected, setSaveEnabled]);

	const handleClose = () => {
		setOpen(false);
	};

	const handleSave = (e) => {
		e.preventDefault();
		mount(shellId)
			.then(() => refresh())
			.then(handleClose);
	};

	const reset = () => {
		setSelected(null);
	};

	return (
		<Dialog
			open={open}
			aria-labelledby={t('dialog_mount_iso_title')}
			aria-describedby={t('dialog_mount_iso_title')}
			onEnter={() => reset()}
			maxWidth='lg'
			fullWidth
		>
			<form onSubmit={handleSave}>
				<DialogTitle id='mount-iso'>
					{t('dialog_mount_iso_title')}
				</DialogTitle>
				<DialogContent>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<ShellISOSelector
								value={selected}
								setValue={setSelected}
								required
								disabled={mounting}
								allowEmpty
							/>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button color='default' onClick={handleClose} disabled={mounting}>
						{t('close_btn')}
					</Button>
					<Button
						color='primary'
						type='submit'
						variant='contained'
						disabled={mounting || !saveEnabled}
						loading={mounting}
					>
						{t('mount_btn')}
					</Button>
				</DialogActions>
			</form>
		</Dialog>
	);
};

export default IsoMountDialog;

IsoMountDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	refresh: PropTypes.func.isRequired,
	setOpen: PropTypes.func.isRequired,
	shellId: PropTypes.string.isRequired
};
