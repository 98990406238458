import PropTypes            from 'prop-types';
import React                from 'react';
import { useTranslation }   from 'react-i18next';
import { Grid }             from '@material-ui/core';
import List                 from '@material-ui/core/List';
import ListItem             from '@material-ui/core/ListItem';
import ListItemText         from '@material-ui/core/ListItemText';
import ListItemIcon         from '@material-ui/core/ListItemIcon';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import RoomIcon             from '@material-ui/icons/Room';
import AlbumIcon            from '@material-ui/icons/Album';
import PersonIcon           from '@material-ui/icons/Person';
import { PlanChip }         from './ShellPicture/PlanBadge';
import CardMembershipIcon   from '@material-ui/icons/CardMembership';

const ShellInfo = ({ shell }) => {
	const { t } = useTranslation();

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<List>
					<ListItem>
						<ListItemIcon>
							<CardMembershipIcon/>
						</ListItemIcon>
						<ListItemText
							primary={
								<>
									{t('shell_plan')}
									<PlanChip
										style={{ marginLeft: '5px' }}
										label={t(`shell_badge_${shell.Catalog_Product['Description.AuthorCode'].toLowerCase()}`)}
										size='small'
										color='primary'
									/>
								</>
							}/>
					</ListItem>
					<ListItem>
						<ListItemIcon>
							<PowerSettingsNewIcon/>
						</ListItemIcon>
						<ListItemText primary={t('shell_info_status', { state: t(`shell_status_${shell.State}`) })}/>
					</ListItem>
					<ListItem>
						<ListItemIcon>
							<AlbumIcon/>
						</ListItemIcon>
						<ListItemText
							primary={
								shell.OS ?
									t('shell_info_os', { name: shell.OS.Name }) :
									t('shell_info_os_na')
							}/>
					</ListItem>
					<ListItem>
						<ListItemIcon>
							<PersonIcon/>
						</ListItemIcon>
						<ListItemText
							primary={t('shell_ssh_username', { username: shell.Username })}/>
					</ListItem>
					<ListItem>
						<ListItemIcon>
							<RoomIcon/>
						</ListItemIcon>
						<ListItemText
							primary={t('shell_location', {
								location: shell.Shell_Datacenter ? `${shell.Shell_Datacenter.Location} - ${shell.Shell_Datacenter.Name}` : '-'
							})}/>
					</ListItem>
				</List>
			</Grid>
		</Grid>
	);
};

export default ShellInfo;

ShellInfo.propTypes = {
	shell: PropTypes.object.isRequired
};
