import PropTypes              from 'prop-types';
import React                  from 'react';
import { Grid }               from '@material-ui/core';
import RichAlertTrans         from '../../core/feedback/RichAlertTrans';
import ClipboardCopyTextField from '../../core/input/ClipboardCopyTextField';

const OperatingSystemShare = ({ os }) => {
	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<RichAlertTrans i18nKey='custom_os_share_info' severity='info'/>
			</Grid>

			{os.Public !== 'Y' &&
			<Grid item xs={12}>
				<RichAlertTrans i18nKey='custom_os_share_warning' severity='warning'/>
			</Grid>
			}

			<Grid item xs={12}>
				<ClipboardCopyTextField
					value={os.Shell_OS__}
					fullWidth
					variant='outlined'
					editable={false}
				/>
			</Grid>
		</Grid>
	);
};

export default OperatingSystemShare;

OperatingSystemShare.propTypes = {
	os: PropTypes.object.isRequired
};
